import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import Header from './header'; 
import axios from 'axios';
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import moment from 'moment'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getBaseURL } from './baseUrl';

function UserProfile() {
 

  const userProfileData =  JSON.parse(localStorage.getItem('UserProfile'))
  const location = useLocation(); 
  const [profile, setProfile] = useState({
    age: userProfileData.age, 
    email: userProfileData.email, 
    height: userProfileData.height, 
    weight: userProfileData.weight,
  });
  const [fullName, setFullName] = useState(userProfileData.fullName);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+'+userProfileData.phoneNumber);
  const [maskedPhoneNumber, setMaskedPhoneNumber] = useState(''); // Initially masked number
  const [CountryCode, setCountryCode] = useState(''); // Initially masked number
  const [password, setPassword] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState(userProfileData.dob); 
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState(userProfileData.insurance);
  const [GenderTxt, setGenderTxt] = useState(userProfileData.gender);
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(userProfileData.dob);
  const ageCal = moment().diff(DOBTxt, 'years');   
  const [AgeTxt, setAgeTxt] = useState(ageCal);  
  const [WeightTxt, setWeightTxt] = useState(userProfileData.weight);
  const [HeightTxt, setHeightTxt] = useState(userProfileData.height);
  const [ShowLoader, setShowLoader] = useState(false); 


  const [UserId, setUserId] = useState(userProfileData.user_id);  
  const [showCalendar, setShowCalendar] = useState(false);  
  const [isValid, setIsValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  
  const [unit, setUnit] = useState('cm'); // Initial unit is 'cm' 
  const [weightUnit, setWeightUnit] = useState('lbs');

   // Function to convert kg to lbs
   const convertKgToLbs = (kg) => (parseFloat(kg) * 2.20462).toFixed(0);

   // Function to convert feet to cm
   const convertFeetToCm = (feet) => (parseFloat(feet) * 30.48).toFixed(0);
    
    // Function to mask the local part of the phone number (not the country code)
    useEffect(() => {
      if (phoneNumber) {
        // Regex to extract country code and local number
        const phoneParts = phoneNumber.match(/^(\+?\d{1,2})(.*)$/); 
        const countryCode = phoneParts[1];  // Extracted country code (e.g., +91) 
        const localNumber = phoneParts[2];  // Local part of the phone number (e.g., 7999081001)
    

        setCountryCode(countryCode)
        // Mask all but the last 2 digits of the local number
        const maskedLocalNumber = localNumber
          .slice(0, -2) // All digits except the last 2
          .replace(/\d/g, 'X') + localNumber.slice(-2);  // Mask and keep last 2 digits visible
  
        setMaskedPhoneNumber(maskedLocalNumber); // Combine country code with masked local number
      }
    }, [phoneNumber]); 
  useEffect(() => { 
    const userProfileData =  JSON.parse(localStorage.getItem('UserProfile'))
    localStorage.setItem('UserId', JSON.stringify(UserId));
    setProfile(userProfileData);
    setOriginalProfile(userProfileData);
  }, [UserId]);
   
  const onChangeCalendar = (date) => {     
    setDOBTxt(formatDate(date));
    setShowCalendar(false); 
};
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
};
const formatDate = (date) => { 
  return date ? moment(date).format("YYYY-MM-DD") : '';
};
  const handleChangeCancel = () => {
    setIsEditing(!isEditing);
};
const handleGenderChange = (e) => {
  setGenderTxt(e.target.value); 
};
// const handleGenderChange = (event) => {
//   let { value } = event.target; 
//       // Allow delete action
//       if (value === '') {
//         setGenderTxt(''); 
//     } 
//     // Convert input to uppercase for comparison
//     value = value.toUpperCase();
//   // Allow only 'M' or 'F' characters
//   if ( value === 'M' || value === 'F') { 
//       setGenderTxt(value === 'M' ? 'Male' :  'Female');
//   }
// };
 
const handleChange = (e) => {
  const { name, value } = e.target; 
  setProfile((prevState) => ({
    ...prevState,
    [name]: value,
  }));
};

const handleSubmitProfile = (event) => { 

  let newDOBTxt = '';
  event.preventDefault(); 
  // Simple form validation
  let formErrors = {}; 
  setErrorMessage('');
  // Set form as submitted
  setIsSubmitted(true); 

  // Check if all date parts (YYYY, MM, DD) are filled out
  if (!YYYYTxt.trim() || !MMTxt.trim() || !DDTxt.trim()) {
    formErrors.DOBTxt = 'Please select a complete date (YYYY/MM/DD)';
  } else {
    // Validate each part
    const currentYear = moment().year();
    const year = parseInt(YYYYTxt, 10);
    const month = parseInt(MMTxt, 10);
    const day = parseInt(DDTxt, 10);
    const maxAllowedYear = currentYear + 2; 
    if (isNaN(year) || year > maxAllowedYear) {
      formErrors.DOBTxt = `Year cannot be more than ${maxAllowedYear}`; 
    } else if (isNaN(month) || month < 1 || month > 12) {
      formErrors.DOBTxt = 'Invalid month. Month must be between 1 and 12';
    } else if (isNaN(day) || day < 1 || day > 31) {
      formErrors.DOBTxt = 'Invalid day. Day must be between 1 and 31';
    } else {
      // Create DOBTxt from the validated year, month, and day
      const date = moment(`${YYYYTxt}-${MMTxt}-${DDTxt}`, 'YYYY-MM-DD'); 
      if (!date.isValid()) {
        formErrors.DOBTxt = 'Invalid date. Please check your input';
      } else {
        newDOBTxt = date.format('YYYY-MM-DD'); // Store the formatted date
        setDOBTxt(newDOBTxt); // Update the state
      }
    }
  } 

  if (!phoneNumber.trim()) {
    formErrors.phoneNumber = 'Please enter your phone number';
  }
  if (!DOBTxt.length > 0) {
    formErrors.DOBTxt = 'Please select a date';
  }  
  if (!GenderTxt.trim()) {
      formErrors.GenderTxt = 'Please enter your sex';
  }
  // if (!InsuranceIdTxt.trim()) {
  //     formErrors.InsuranceIdTxt = 'Please enter your Insurance ID';
  // }   
  // if (!WeightTxt.trim()) {
  //     formErrors.WeightTxt = 'Please enter your weight';
  // }
  // if (!HeightTxt.trim()) {
  //     formErrors.HeightTxt = 'Please enter your height';
  // } 
  if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
  }
  setShowLoader(true)

  let finalWeight = WeightTxt;
  let finalHeight = HeightTxt;

  if (weightUnit === 'kg') {
    finalWeight = convertKgToLbs(WeightTxt); // Convert kg to lbs
  }

  if (unit === 'feet') {
    finalHeight = convertFeetToCm(HeightTxt); // Convert feet to cm
  }


  // If validation passes, send data to API
  const dataReq = { 
    phoneNumber: phoneNumber, 
    dob: DOBTxt,
    gender:GenderTxt,
    // insurance: InsuranceIdTxt,   
    // height: finalHeight,
    // weight: finalWeight, 
    user_id: UserId,     
}; 
// Here, you would replace 'apiEndpoint' with your actual API endpoint
fetch(getBaseURL+'/update_user_details', {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataReq),
})
 
.then(response => response.json())
.then(data => {
  if( data.status == 'success'){
    toast.success("User profile update successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });  
    localStorage.setItem('UserProfile', JSON.stringify(data));
 
    setShowLoader(false) 
    setError('')
    handleCancel()
  }
  if( data.status == 'error'){ 
      toast.error(data.message, {
         position: toast.POSITION.TOP_RIGHT,
        });   
        setError('')
        setShowLoader(false)
  }
    // Optionally, you can redirect the user or show a success message
})
.catch((error) => {
    console.error('Error:', error);
    // Optionally, you can display an error message to the user
});
};

 

const handleCancel = () => {
  setProfile(originalProfile);
  setIsEditing(false);
  setError('')
};

const handleFullNameChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  setFullName(value);   
};
const handlePhoneNumberChange = (event) => {
  // Remove non-numeric characters from the input value
  const value = event.target.value.replace(/\D/g, '');
  setPhoneNumber(value);
};

const InsuranceIdHandleChange = (e) => {
  // Get the new value
  const value = e.target.value;    

  // Regex to allow only alphanumeric characters, hyphens, and spaces
  const regex = /^[\w\s-]*$/;

  if (value === '' || regex.test(value)) {
    let newValue = value.replace(/\s+/g, ' '); 
    setInsuranceIdTxt(newValue);
  }  
};


 // Handle unit change (cm or feet)
 const handleUnitChange = (event) => {
  const newUnit = event.target.value;
  setUnit(newUnit);

  // Convert height if a value exists
  if (HeightTxt) {
    setHeightTxt(convertHeight(HeightTxt, newUnit));
  }
};

  // Convert the value based on the selected unit
  const convertHeight = (value, toUnit) => {
    if (toUnit === 'feet') {
      return (parseFloat(value) * 0.0328084).toFixed(2); // Convert cm to feet
    } else if (toUnit === 'cm') {
      return (parseFloat(value) * 30.48).toFixed(0); // Convert feet to cm
    }
    return value;
  };
   // Handle height input change
   const handleHeightChange = (event) => {
    const value = event.target.value; 
    if (unit === 'cm') {
      // Allow only whole numbers for cm
      const wholeNumber = value.match(/^\d+$/);
      if (wholeNumber) {
        setHeightTxt(wholeNumber[0]);
      }
    } else {
      // Remove any non-digit characters for feet
      const numericValue = value.replace(/\D/g, '');
      if (numericValue.length <= 4) {
        setHeightTxt(numericValue);
      }
    }
  };

  // Function to convert the weight based on the selected unit
  const convertWeight = (value, toUnit) => {
    if (toUnit === 'kg') {
      return (parseFloat(value) / 2.20462).toFixed(2); // Convert lbs to kg
    } else if (toUnit === 'lbs') {
      return (parseFloat(value) * 2.20462).toFixed(0); // Convert kg to lbs
    }
    return value;
  };
 // Handle unit change (lbs or kg)
 const handleWeightUnitChange = (event) => {
  const newUnit = event.target.value;
  setWeightUnit(newUnit);

  // Convert weight if a value exists
  if (WeightTxt) {
    setWeightTxt(convertWeight(WeightTxt, newUnit));
  }
};

// Handle weight input change
const handleWeightChange = (event) => {
  const value = event.target.value;

  // Allow only numeric values and up to 2 decimal places
  const numericValue = value.match(/^\d*\.?\d{0,2}$/);
  if (numericValue) {
    setWeightTxt(numericValue[0]);
  }
};


const yearRef = useRef(null);
const monthRef = useRef(null);
const dayRef = useRef(null);
  
const [YYYYTxt, setYYYYTxt] = useState('');
const [MMTxt, setMMTxt] = useState('');
const [DDTxt, setDDTxt] = useState('');

useEffect(() => {
  console.log(DOBTxt)
  if (DOBTxt) {
    const [year, month, day] = DOBTxt.split('-');
    setYYYYTxt(year);
    setMMTxt(month);
    setDDTxt(day);
  }
}, [DOBTxt]); 

const handleInput = (e, nextRef, prevRef, setState) => {
    let value = e.target.value;
    const fieldName = e.target.placeholder;
    // Reset submission flag when user types
    setIsSubmitted(false); 

    // Update the state as the user types
    setState(value); 

  // Forward jump when the max length is reached
  if (value.length === e.target.maxLength) {
    // Year Validation (YYYY)
    // if (fieldName === "YYYY") {
    //   const currentYear = moment().year();
    //   if (parseInt(value, 10) > currentYear) {
    //     setErrorMessage("Year cannot be more than the current year");
    //     yearRef.current.focus();
    //     return;
    //   }
    // }
    if (fieldName === "YYYY") {
      const currentYear = moment().year();
      const maxAllowedYear = currentYear + 2; // Allow up to 2 years from the current year
      if (parseInt(value, 10) > maxAllowedYear) {
        setErrorMessage(`Year cannot be more than ${maxAllowedYear}`);
        yearRef.current.focus();
        return;
      }
    }

    // Month Validation (MM)
    if (fieldName === "MM") {
      if (parseInt(value, 10) < 1 || parseInt(value, 10) > 12) {
        setErrorMessage("Month must be between 1 and 12");
        monthRef.current.focus();
        return;
      }
    }

    // Day Validation (DD)
    if (fieldName === "DD") {
      if (parseInt(value, 10) < 1 || parseInt(value, 10) > 31) {
        setErrorMessage("Day must be between 1 and 31");
        dayRef.current.focus();
        return;
      }
    }

    // Clear error message if validation passes
    setErrorMessage("");
    if (nextRef) {
      nextRef.current.focus();
    }
  } 
  // Backward jump when "Backspace" is pressed and the input is empty
  else if (e.nativeEvent.inputType === "deleteContentBackward" && value.length === 0) {
    if (prevRef) {
      prevRef.current.focus();
    }
  }
 
};

  

const handleKeyDown = (e, nextRef, prevRef, setState) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Handle Right Arrow Key
  if (e.key === "ArrowRight") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    // Check for single-digit values in MM and DD
    if ((fieldName === "MM" || fieldName === "DD") && value.length === 1) {
      const paddedValue = `0${value}`; // Add leading zero
      setState(paddedValue); // Update state
      e.target.value = paddedValue; // Update input value
    }

    if (nextRef) {
      nextRef.current.focus();
    }
  }

  // Handle Left Arrow Key
  if (e.key === "ArrowLeft") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    // Check for single-digit values in MM and DD
    if ((fieldName === "MM" || fieldName === "DD") && value.length === 1) {
      const paddedValue = `0${value}`; // Add leading zero
      setState(paddedValue); // Update state
      e.target.value = paddedValue; // Update input value
    }

    if (prevRef) {
      prevRef.current.focus();
    }
  }
};

const handleBlur = (e, setState) => {
  const value = e.target.value;

  // Check if the value is a single digit
  if (value.length === 1 && parseInt(value, 10) > 0) {
    const paddedValue = `0${value}`; // Add leading zero
    setState(paddedValue); // Update state
    e.target.value = paddedValue; // Update input value
  }
};  


  return (
    <div > 
     <div className='container-fluid p-0 bgInnerDiv'>
        <Header />
        <div className='container-fluid p-0 contentBody  '>  
        <div className='loginBoxDiv ForgotPassswordMain UserProfileMain whiteBgColor centerDivAlign'> 
        <h2>My Profile   <button className={'editIcon ' + (isEditing ?  'btnactive' : '') } onClick={() => setIsEditing(true)}>Edit
           <img src={isEditing ? Icons[0].editRed : Icons[0].editIcon } className="logoImg" alt="" /> 
           </button>
        </h2>  
       <form onSubmit={handleSubmitProfile}> 
       <fieldset className='editUserProfile'  id="sign_up" >
          <div className='inputDivBox' >
            <label>Full Name:</label>
              <div className={"inputTxtBox registerInputDiv  disabled"}>
                    <img src={Icons[0].userImg} className="logoImg" alt="" />
                    <input type="text" value={fullName} placeholder="Full Name" onChange={handleFullNameChange}   maxLength="100" readOnly />
            </div>
          </div>
          <div className='inputDivBox' >
            <label>Email:</label>
             <div className={"inputTxtBox registerInputDiv disabled"}>
                    <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={profile.email} placeholder="Email id"  onChange={handleChange}  readOnly /> 
                  </div>
          </div>
          <div className='inputDivBox' >
            <label>Phone Number:</label> 
            <div className={"registerInputDiv "+ (isEditing ? '' : 'disabled')} style={{position:'relative'}}>
                    <PhoneInput
                      autocomplete="off" 
                      defaultCountry="US"
                      country={"us"}
                     id="phone"
                     placeholder="Enter phone number"
                    //  value={phoneNumber}
                     value={isEditing ? phoneNumber : CountryCode }
                     onChange={setPhoneNumber} 
                     international={false}
                     error={!isValid && phoneNumber.trim().length > 0}
                     onBlur={() => setIsValid(!!phoneNumber.trim())}
                     inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true, 
                       autoComplete: 'off',
                     }}  
                     disabled={isEditing ? '' : 'true'}
                    />
                   
                    <label className={'maskLabelTxt ' + (isEditing ?  'd-none' : '')}>{maskedPhoneNumber}</label>
                  </div> 
                  {error.phoneNumber && <span  className="errorTxt" >{error.phoneNumber}</span>}
          </div>
          <div className='inputDivBox' >
            <label>Date of Birth:</label> 
            <div className={"inputTxtNewBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                        <img src={Icons[0].dateIcon} className="logoImg" alt="" />
                        {/* <input type="text"    value={DOBTxt} placeholder="Date of Birth (YYYY-MM-DD)" readOnly /> 
                        <button className={"toggleCalendarBtn " + (isEditing ? '' : 'd-none')}  disabled={isEditing ? '' : 'true'}  onClick={toggleCalendar}> <img src={Icons[0].calendarIcon} className="logoImg" alt="" /></button>
                          {showCalendar && (
                              <Calendar 
                                className= 'inputCaledar' 
                                onChange={onChangeCalendar}
                                value={DOBTxt}
                                maxDate={new Date()}  
                              />
                          )} */}
                          <div className="timepicker">
                              <input
                                type="text"
                                className="hh"
                                maxLength="4"
                                placeholder="YYYY"
                                ref={yearRef}
                                value={YYYYTxt}
                                disabled={isEditing ? '' : 'true'} 
                                onInput={(e) => handleInput(e, monthRef, null, setYYYYTxt)}
                                onKeyDown={(e) => handleKeyDown(e, monthRef, null)}
                              />
                              /
                              <input
                                type="text"
                                className="mm"
                                maxLength="2"
                                placeholder="MM"
                                value={MMTxt}
                                ref={monthRef}
                                disabled={isEditing ? '' : 'true'} 
                                  onInput={(e) => handleInput(e, dayRef, yearRef, setMMTxt)}
                                  onKeyDown={(e) => handleKeyDown(e, dayRef, yearRef, setMMTxt)}
                              />
                              /
                              <input
                                type="text"
                                className="mm"
                                maxLength="2"
                                placeholder="DD"
                                ref={dayRef}
                                value={DDTxt}
                                disabled={isEditing ? '' : 'true'} 
                                onInput={(e) => handleInput(e, null, monthRef, setDDTxt)}
                                onKeyDown={(e) => handleKeyDown(e, null, monthRef, setDDTxt)}
                                onBlur={(e) => handleBlur(e, setDDTxt)} // Add onBlur event
                              />
                           
                            </div>
                   </div>
                  {error.DOBTxt && <span  className="errorTxt" >{error.DOBTxt}</span>} 
          </div>
          {/* <div className='inputDivBox' >
            <label>Insurance:</label> 
            <div className={"inputTxtBox  registerInputDiv " + (isEditing ? '' : 'disabled')}>
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                       <input type="text" value={InsuranceIdTxt} placeholder="Insurance ID"  
                          disabled={isEditing ? '' : 'true'}
                          onChange={InsuranceIdHandleChange}
                          maxLength="20" 
                        // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                        /> 
                  </div>
                  {error.InsuranceIdTxt && <span  className="errorTxt" >{error.InsuranceIdTxt}</span>} 
          </div> */}
          <div className='inputDivBox' >
            <label>Gender:</label>
            <div className={"inputTxtBox  registerInputDiv d-flex justify-content-start " + (isEditing ? '' : 'disabled')}>
                        <img src={Icons[0].GenderImg} className="logoImg" alt="" />
                        <input type="text" value={GenderTxt} placeholder="Sex" onChange={handleGenderChange} maxLength="1" className={isEditing ? 'd-none' : ''}
                        // onKeyUp={(e) => { if (e.key === 'Backspace') { setGenderTxt('');  } }}
                        /> 
                         <div className={"gender-selector  d-flex justify-content-between " + (isEditing ? "": "d-none")}>
                            <label className="d-flex align-items-center">
                              <input
                                type="radio"
                                value="Male"
                                checked={GenderTxt === 'Male'}
                                onChange={handleGenderChange}
                              />
                              Male
                            </label>
                            <label  className="d-flex align-items-center">
                              <input
                                type="radio"
                                value="Female"
                                checked={GenderTxt === 'Female'}
                                onChange={handleGenderChange}
                              />
                              Female
                            </label>
                            {/* <label>
                              <input
                                type="radio"
                                value="Other"
                                checked={GenderTxt === 'Other'}
                                onChange={handleGenderChange}
                              />
                              Other
                            </label> */}
                          </div>
            </div>
            {error.GenderTxt && <span  className="errorTxt" >{error.GenderTxt}</span>} 
          </div>
          {/* <div className='inputDivBox' >
            <label>Height:</label> 
            <div className={"inputTxtBox  registerInputDiv newInputTxtBox " + (isEditing ? '' : 'disabled')}>
            <img src={Icons[0].heightIcon} className="iconInput" alt="" />
            <span className='d-flex justify-content-between'>
                <input type="text"  
                  //  value={HeightTxt}   
                  // value={unit === 'feet' ? convertFeetToCm(HeightTxt) : HeightTxt}
                  value={isEditing ? HeightTxt : unit === 'feet' ? convertFeetToCm(HeightTxt) : HeightTxt}
                   onChange={(e) => {
                    const value = e.target.value;
                    // Remove any non-digit characters
                    const numericValue = value.replace(/\D/g, '');
                    // Limit to 4 digits
                    if (numericValue.length <= 5) {
                      setHeightTxt(numericValue);
                    }
                  }}
                  // onChange={(e) => setHeightTxt(e.target.value)}  
                  disabled={isEditing ? '' : 'true'} /> 
                    <span  className={isEditing ? 'd-none' : '' }>
                       <label>cm</label>  
                   </span>
              <span className={'radioConvertDiv ' + (!isEditing ? 'd-none' : '')}>
                    <label>
                        <input
                          type="radio"
                          value="cm"
                          checked={unit === 'cm'}
                          onChange={handleUnitChange}
                        />
                        Cm
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="feet"
                          checked={unit === 'feet'}
                          onChange={handleUnitChange}
                        />
                        Feet
                      </label>           
              </span> 
              </span>
            </div>
            {error.HeightTxt && <span  className="errorTxt" >{error.HeightTxt}</span>}
          </div>
          <div className='inputDivBox' >
            <label>Weight:</label> 
            <div className={"inputTxtBox  registerInputDiv newInputTxtBox " + (isEditing ? '' : 'disabled')}>
            <img src={Icons[0].weightIcon} className="iconInput" alt="" style={{    filter: "opacity(0.5)"}} />
            <span className='d-flex justify-content-between'>
                <input type="text" 
                 
                 value={isEditing ? WeightTxt : weightUnit === 'kg' ? convertKgToLbs(WeightTxt) : WeightTxt}
                onChange={(e) => {
                  const value = e.target.value;
                  // Remove any non-digit characters
                  const numericValue = value.replace(/\D/g, '');
                  // Limit to 4 digits
                  if (numericValue.length <= 3) {
                    setWeightTxt(numericValue);
                  }
                }}
                //  onChange={(e) => setWeightTxt(e.target.value)}  
                disabled={isEditing ? '' : 'true'} />   
                   <span  className={isEditing ? 'd-none' : '' }>
                    <label>lbs</label>  
                  </span>
                 <span className={'radioConvertDiv ' + (isEditing ? '' : 'd-none')}>
                      <label>
                        <input
                          type="radio"
                          value="lbs"
                          checked={weightUnit === 'lbs'}
                          onChange={handleWeightUnitChange}
                        />
                        Lbs
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="kg"
                          checked={weightUnit === 'kg'}
                          onChange={handleWeightUnitChange}
                        />
                        Kg
                      </label>       
              </span>   
              </span>   
            </div>
            {error.WeightTxt && <span  className="errorTxt" >{error.WeightTxt}</span>}
          </div>
           */}
          <div className='inputDivBox d-none' > 
            <label>Age:</label> 
            <div className={"inputTxtBox  registerInputDiv newInputTxtBox " + (isEditing ? '' : 'disabled')}>
                <input type="text" value={AgeTxt}   
                onChange={(e) => {
                  const value = e.target.value;
                  // Remove any non-digit characters
                  const numericValue = value.replace(/\D/g, '');
                  // Limit to 4 digits
                  if (numericValue.length <= 3) {
                    setAgeTxt(numericValue);
                  }
                }}
            //  onChange={(e) => setAgeTxt(e.target.value)}  
             disabled={isEditing ? '' : 'true'} />  
              <label>Years</label>  
            </div>
          </div>
          </fieldset>
{
  isEditing ? (<>
  <div className={"saveOptDivBox "}>
          {ShowLoader ? (<>
                      <button  className="   " > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSubmitProfile} type="submit" >Save</button>
                    </>)} 
                  <button type="button" onClick={handleCancel}>Cancel</button>
            </div>
  </>) : ('')
}
          
          
        </form>
       
       

                 
      </div>
         </div>
    </div> 
    </div> 
  );
}

export default UserProfile;
