 
import logoIcon from "../assest/img/logo.png";
import bgImg from "../assest/img/bgImg.png"; 
import verifiedChecked from "../assest/img/verifiedChecked.png"; 
import googleImg from "../assest/img/google.png"; 
import appleImg from "../assest/img/apple.png"; 
import amazonImg from "../assest/img/amazon.png"; 
import sumsungImg from "../assest/img/sumsung.png"; 

import mailImg from "../assest/img/mail.png"; 
import lockedImg from "../assest/img/locked.png"; 
import pwdHide from "../assest/img/pwdHide.png"; 
import gmailImg from "../assest/img/gmail.png"; 
import fbImg from "../assest/img/fb.png"; 
import iphoneImg from "../assest/img/iphone.png"; 
import twitterImg from "../assest/img/twitter.png"; 
import userImg from "../assest/img/userImg.png"; 
import mailIcon from "../assest/img/mailIcon.png"; 
import checkedIcon from "../assest/img/checked.png"; 
import dateIcon from "../assest/img/date.png"; 
import GenderImg from "../assest/img/gender.png"; 
import InsuranceIDIcon from "../assest/img/compliance-document.png"; 
import sideUserImg from "../assest/img/sideUserImg.png";
import micIcon from "../assest/img/micIcon.png";
import attachIcon from "../assest/img/attachIcon.png";
import chatbot from "../assest/img/chatbot.png";
import AIImg from "../assest/img/AI.png";
import submitArrow from "../assest/img/submitArrow.png";
import bgInner from "../assest/img/bgInner.png"; 
import logoutIcon from "../assest/img/logoutIcon.png"; 
import opneEye from "../assest/img/opneEye.png"; 
import closeEye from "../assest/img/closeEye.png"; 
import calendarIcon from "../assest/img/calendarIcon.png"; 
import deleteIcon from "../assest/img/deleteIcon.png"; 
import editIcon from "../assest/img/edit.svg"; 
import editRed from "../assest/img/editRed.svg"; 
import weightIcon from "../assest/img/weightIcon.png";  
import heightIcon from "../assest/img/heightIcon.png";  
import homeIcon from "../assest/img/homeIcon.png";  

import admin_calendarIcon from "../assest/img/admin/calendarIcon.png";  
import admin_copyIcon from "../assest/img/admin/copyIcon.png";  
import admin_editIcon from "../assest/img/admin/edit.png";  
import admin_filterIcon from "../assest/img/admin/filterIcon.png";  
import admin_listIcon from "../assest/img/admin/listIcon.png";  
import admin_logoutIcon from "../assest/img/admin/logoutIcon.png";  
import admin_mailIcon from "../assest/img/admin/mailIcon.png";  
import admin_moreIcon from "../assest/img/admin/moreIcon.png";  
import admin_paitentIcon from "../assest/img/admin/paitent.png";  
import admin_phoneIcon from "../assest/img/admin/phoneIcon.png";  
import admin_userIcon from "../assest/img/admin/userIcon.png";  
import admin_adminIcon from "../assest/img/admin/adminIcon.png";  
import admin_addUser from "../assest/img/admin/addUser.png";  
import admin_userPic from "../assest/img/admin/userPic.png";  
import admin_backBtn from "../assest/img/admin/backBtn.png";  
import admin_icon911 from "../assest/img/admin/icon911.png";  
import admin_searchIcon from "../assest/img/admin/searchIcon.png";  
import admin_deleteIcon from "../assest/img/admin/deleteIcon.png";  
import pdf_Icon from "../assest/img/pdf_Icon.png";  

import cardIcon from "../assest/img/card.png";  
import groupnameIcon from "../assest/img/groupname.png";  
import expirydateIcon from "../assest/img/expirydate.png";  
import policyidIcon from "../assest/img/policyid.png";  
import uploadIcon from "../assest/img/uploadIcon.png";  
import addIcon from "../assest/img/addIcon.png";  
import OTPIcon from "../assest/img/OTP.png";  
import downloadPDF from "../assest/img/downloadPDF.jpg";  
import viewPDF from "../assest/img/viewPDF.jpg";   
import copyclip from "../assest/img/copyclip.svg";   
import closeModal from "../assest/img/closeModal.png";   
import downloadBtn from "../assest/img/downloadBtn.png";   

import ZoomIcon from "../assest/img/Zoom_Icon.png";  
import editIconNew from "../assest/img/editIcon.png";  
import calendarEdit from "../assest/img/calendarEdit.png";  
import CheckIcon from "../assest/img/checkIcon.svg";
import CheckedIcon from "../assest/img/CheckedIcon.svg";
import errorImg from "../assest/img/errorImg.png";

import headerImageUrlLink from '../assest/img/header.png';
import footerImageUrlLink from '../assest/img/footer.png';
import shareBtn from '../assest/img/shareBtn.png';
import locationIcon from '../assest/img/location.png';


export const Icons = [
  { 
    logoIcon:logoIcon,
    shareBtn:shareBtn,
    locationIcon:locationIcon,
    headerImageUrlLink:headerImageUrlLink,
    footerImageUrlLink:footerImageUrlLink,
    errorImg:errorImg,
    CheckIcon:CheckIcon,
    CheckedIcon:CheckedIcon,
    calendarEdit:calendarEdit,
    editIconNew:editIconNew,
    downloadBtn:downloadBtn,
    closeModal:closeModal, 
    viewPDF:viewPDF,
    copyclip:copyclip,
    ZoomIcon:ZoomIcon,
    downloadPDF:downloadPDF,
    OTPIcon:OTPIcon,
    addIcon:addIcon,
    uploadIcon:uploadIcon, 
    pdf_Icon:pdf_Icon,
    cardIcon:cardIcon,
    policyidIcon:policyidIcon,
    expirydateIcon:expirydateIcon,
    groupnameIcon:groupnameIcon,
    admin_deleteIcon:admin_deleteIcon,
    admin_searchIcon:admin_searchIcon,
    admin_icon911:admin_icon911,
    admin_backBtn:admin_backBtn,
    admin_userPic:admin_userPic,
    admin_addUser:admin_addUser,
    admin_adminIcon:admin_adminIcon,
    admin_filterIcon:admin_filterIcon,
    admin_userIcon:admin_userIcon,
    admin_copyIcon:admin_copyIcon,
    admin_phoneIcon:admin_phoneIcon,
    admin_paitentIcon:admin_paitentIcon,
    admin_moreIcon:admin_moreIcon,
    admin_mailIcon:admin_mailIcon,
    admin_logoutIcon:admin_logoutIcon,
    admin_listIcon:admin_listIcon,
    admin_editIcon:admin_editIcon,
    admin_calendarIcon:admin_calendarIcon,
    homeIcon:homeIcon,
    heightIcon:heightIcon,
    weightIcon:weightIcon,
    editIcon:editIcon,
    editRed:editRed,
    deleteIcon:deleteIcon,
    bgInner:bgInner,
    calendarIcon:calendarIcon,
    closeEye:closeEye,
    opneEye:opneEye,
    logoutIcon:logoutIcon,
    submitArrow:submitArrow,
    bgImg:bgImg,  
    chatbot:chatbot,
    AIImg:AIImg,
    micIcon:micIcon,
    attachIcon:attachIcon,
    sideUserImg:sideUserImg,
    InsuranceIDIcon:InsuranceIDIcon,
    dateIcon:dateIcon,
    mailIcon:mailIcon,
    checkedIcon:checkedIcon,
    userImg:userImg,
    GenderImg:GenderImg,
    fbImg:fbImg,
    twitterImg:twitterImg,
    iphoneImg:iphoneImg,
    gmailImg:gmailImg,
    pwdHide:pwdHide,
    mailImg:mailImg,
    lockedImg:lockedImg,
    verifiedChecked:verifiedChecked,
    googleImg:googleImg,
    appleImg:appleImg,
    amazonImg:amazonImg,
    sumsungImg:sumsungImg,
  },
];
