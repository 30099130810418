import  React, { useState, useRef, useEffect  } from "react";   
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Moment from 'react-moment';
import moment from 'moment'; 
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getBaseURL } from './baseUrl';
 


import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
 
 
function InsuranceAddInfo() {  
  const location = useLocation(); 
  const [data, setdata] = useState(location.state); 
  const userDataInfo =  JSON.parse(localStorage.getItem('UserProfile'))
 
  let navigate = useNavigate();   

  const [UserData, setUserData] = useState([]); 
  const [ShowLoader, setShowLoader] = useState(false);

  const [UserId, setUserId] = useState(userDataInfo.user_id);
  const [firstStep, setFirstStep] = useState(false);
  const [twoStep, setTwoStep] = useState(false);
  const [threeStep, setThreeStep] = useState(true);
  const [finalStep, setFinalStep] = useState(false);
    
  const [selectedToggle, setSelectedToggle] = useState(true);   
  const [ClearBtn, setClearBtn] = useState(false); 

  const [policyIDTxt, setpolicyIDTxt] = useState('');
  const [GroupIDTxt, setGroupIDTxt] = useState('23');  
  const [GroupNameTxt, setGroupNameTxt] = useState('');
  const [InsuranceCarrierTxt, setInsuranceCarrierTxt] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false); 
  const [CheckLogin, setCheckLogin] = useState(userDataInfo.login_from ?  userDataInfo.login_from : '');
  
  const [focused, setFocused] = React.useState(false);  
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
     
  const [DOBTxt, setDOBTxt] = useState(''); 
  const [selectedFrontImage, setSelectedFrontImage] = useState('');
  const [frontPreview, setFrontPreview] = useState('');
  const [selectedBackImage, setSelectedBackImage] = useState('');
  const [backPreview, setBackPreview] = useState('');

  const frontFileInputRef = useRef(null);  // Ref for Front file input
  const backFileInputRef = useRef(null);   // Ref for Back file input
 
 // Separate states for immediate validation and form submission errors
 const [formChkErrors, setFormChkErrors] = useState({ frontError: '', backError: '' });

 const [isfrontFileSubmitted, setIsfrontFileSubmitted] = useState(false); 
 const [isbackFileSubmitted, setIsbackFileSubmitted] = useState(false);

  function handleOnFocus() {
    setFocused(true);
  }

  function handleOnBlur() {
    setFocused(false);
  } 

  const nav = useNavigate();
  const routeChange = () =>{     
    localStorage.setItem('UserLoginStatus',  JSON.stringify(true) ); 
    nav("/dashboard", { state:UserData}); 
  } 
 
  const handleResetForm2 = () => { 
    setDOBTxt('');
    setpolicyIDTxt('');
    setGroupNameTxt('');
    setInsuranceCarrierTxt(''); 
    setSelectedFrontImage('');
    setSelectedBackImage('');
    setFrontPreview('');
    setBackPreview('');
    setErrors('');
  }; 

  const handleOptionChangeToogle = () => {
    setSelectedToggle(!selectedToggle); 
  };
 
  const handleClickThree = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };

  const handleClickFinal = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
 

const handleInsuranceCarrierChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');//.replace(/[^a-zA-Z\s]/g, '');
  setInsuranceCarrierTxt(value);
  setErrors('');
};

const handleGroupNameChange = (event) => {
  // Remove non-text characters from the input value
  // const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  const value = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');//.replace(/[^a-zA-Z\s]/g, '');
  setGroupNameTxt(value);
  setErrors('');
};

const handleSubmitPart2 = (event) => { 

      event.preventDefault();
      let setDOBTxtCheck = '';
      let newDOBTxtChk = '';
      // Simple form validation
      const formErrors = {}; 
      setErrorMessage('');
      // Set form as submitted
      setIsSubmitted(true);
      setIsfrontFileSubmitted(true)
      setIsbackFileSubmitted(true)
 
     
  // Check if all date parts (YYYY, MM, DD) are filled out
if (!YYYYTxt.trim() || !MMTxt.trim() || !DDTxt.trim()) {
  formErrors.DOBTxt = 'Please select a complete date (YYYY/MM/DD)';
} else {
  const currentYear = moment().year();
  const year = parseInt(YYYYTxt, 10);
  const month = parseInt(MMTxt, 10);
  const day = parseInt(DDTxt, 10);
  const dateCheck = moment(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`, 'YYYY-MM-DD', true).format('YYYY-MM-DD'); // Strict parsing
   setDOBTxtCheck = dateCheck;
  
  const currentMoment = moment().startOf('day'); // Today's date
  const maxAllowedDate = moment().add(2, 'years').endOf('day'); // Max date 2 years from now
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    formErrors.DOBTxt = 'Invalid input. Ensure all fields are numbers';
  } else if (year < currentYear - 100 || year > currentYear + 2) {
    formErrors.DOBTxt = `Invalid year. Year must be between ${currentYear - 100} and ${currentYear + 2}`;
  } else if (month < 1 || month > 12) {
    formErrors.DOBTxt = 'Invalid month. Month must be between 1 and 12';
  } else if (day < 1 || day > 31) {
    formErrors.DOBTxt = 'Invalid day. Day must be between 1 and 31';
  } else {
    const date = moment(`${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`, 'YYYY-MM-DD', true); // Strict parsing
      
    if (!date.isValid()) {
      formErrors.DOBTxt = 'Invalid date. Please check your input (e.g., Feb 30 is not valid)';
    } else if (date.isBefore(currentMoment, 'day')) {
      formErrors.DOBTxt = 'Date cannot be in the past'; // Past dates are not allowed
    } else if (date.isAfter(maxAllowedDate, 'day')) {
      formErrors.DOBTxt = `Date cannot be more than ${maxAllowedDate.format('YYYY-MM-DD')}`; // Limit for future dates
    } else {
      // All checks passed (current date or future date within allowed range)
      const newDOBTxtChk = date.format('YYYY-MM-DD');
      setDOBTxt(newDOBTxtChk); // Update state with the valid date
      delete formErrors.DOBTxt; // Remove the error key when validation passes
    }
  }
}

     
      if (!InsuranceCarrierTxt.trim()) {
          formErrors.InsuranceCarrierTxt = 'Please enter your Insurance Carrier';
      }   
      
      if (!policyIDTxt.trim()) {
        formErrors.policyIDTxt = 'Please enter your Policy ID';
      }   
    
      if (!GroupNameTxt.trim()) {
        formErrors.GroupNameTxt = 'Please enter your Group Name';
      } 
      
      // if (!selectedFrontImage) {
      //   formErrors.selectedFrontImage = 'Please upload insurance card front';
      // } 
      
      // if (!selectedBackImage) {
      //   formErrors.selectedBackImage = 'Please upload insurance card back';
      // }  
        if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors); // Set errors when validation fails
        return;
      } else {
        setErrors({}); // Clear all error messages when no errors exist
      }
      setShowLoader(true)   

      // Create FormData object to include files
    const formData = new FormData();
    formData.append('user_id', UserId);
    formData.append('expiry_date', setDOBTxtCheck);
    formData.append('group_id', GroupIDTxt);
    formData.append('group_name', GroupNameTxt); 
    formData.append('policy_id', policyIDTxt);
    formData.append('insurance_carrier', InsuranceCarrierTxt);
    formData.append('front_image', selectedFrontImage);    
    formData.append('back_image', selectedBackImage);
      // Log each entry in FormData for debugging
    // for (let [key, value] of formData.entries()) {
    //   // console.log(key, value);
    // }
    //console.log('FormData contents:', Array.from(formData.entries()));
    // Here, you would replace 'apiEndpoint' with your actual API endpoint
    fetch(getBaseURL+'/insurance/upload', {
        method: 'POST', 
        //body: JSON.stringify(formData),
        body: formData, 
    })
     
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){
        toast.success("User profile update successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        }); 
        // localStorage.setItem('UserProfile', JSON.stringify(data));
        // setUserData(data) 
        setShowLoader(false)
        handleClickThree()
        handleResetForm2()
      }
      if( data.status == 'error'){ 
          toast.error(data.message, {
             position: toast.POSITION.TOP_RIGHT,
            });
            setShowLoader(false)
      }
        // Optionally, you can redirect the user or show a success message
    })
    .catch((error) => {
        console.error('Error:', error);
        // Optionally, you can display an error message to the user
    });
  };
  const formatDate = (date) => { 
    return date ? moment(date).format("YYYY-MM-DD") : '';
};
const disablePastDates = ({ activeStartDate, date, view }) => {
  // Disable dates before the current date
return date >= new Date();
 // return date.getMonth() > new Date().getMonth() || date.getFullYear() > new Date().getFullYear() || date >= new Date();
};


const InsuranceIdHandleChange = (e) => {
  // Get the new value
  const value = e.target.value;    

  // Regex to allow only alphanumeric characters, hyphens, and spaces
  const regex = /^[\w\s-]*$/;

  if (value === '' || regex.test(value)) {
    let newValue = value.replace(/\s+/g, ' '); 
    setpolicyIDTxt(newValue);
  }  
};

const yearRef = useRef(null);
const monthRef = useRef(null);
const dayRef = useRef(null);
  
const [YYYYTxt, setYYYYTxt] = useState('');
const [MMTxt, setMMTxt] = useState('');
const [DDTxt, setDDTxt] = useState('');

const handleInput = (e, nextRef, prevRef, setState) => {
    let value = e.target.value;
    const fieldName = e.target.placeholder;
    // Reset submission flag when user types
    setIsSubmitted(false); 

    // Update the state as the user types
    setState(value); 

  // Forward jump when the max length is reached
  if (value.length === e.target.maxLength) { 
    if (fieldName === "YYYY") {
      const currentYear = moment().year();
      const maxAllowedYear = currentYear + 2; // Allow up to 2 years from the current year
      if (parseInt(value, 10) > maxAllowedYear) {
        setErrorMessage(`Year cannot be more than ${maxAllowedYear}`);
        yearRef.current.focus();
        return;
      }
    }

    // Month Validation (MM)
    if (fieldName === "MM") {
      if (parseInt(value, 10) < 1 || parseInt(value, 10) > 12) {
        setErrorMessage("Month must be between 1 and 12");
        monthRef.current.focus();
        return;
      }
    }

    // Day Validation (DD)
    if (fieldName === "DD") {
      if (parseInt(value, 10) < 1 || parseInt(value, 10) > 31) {
        setErrorMessage("Day must be between 1 and 31");
        dayRef.current.focus();
        return;
      }
    }

    // Clear error message if validation passes
    setErrorMessage(""); 
    if (nextRef) {
      nextRef.current.focus();
    }
  } 
  // Backward jump when "Backspace" is pressed and the input is empty
  else if (e.nativeEvent.inputType === "deleteContentBackward" && value.length === 0) {
    if (prevRef) {
      prevRef.current.focus();
    }
  }
 
};

  

const handleKeyDown = (e, nextRef, prevRef, setState) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Handle Right Arrow Key
  if (e.key === "ArrowRight") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    // Check for single-digit values in MM and DD
    if ((fieldName === "MM" || fieldName === "DD") && value.length === 1) {
      const paddedValue = `0${value}`; // Add leading zero
      setState(paddedValue); // Update state
      e.target.value = paddedValue; // Update input value
    }

    if (nextRef) {
      nextRef.current.focus();
    }
  }

  // Handle Left Arrow Key
  if (e.key === "ArrowLeft") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    // Check for single-digit values in MM and DD
    if ((fieldName === "MM" || fieldName === "DD") && value.length === 1) {
      const paddedValue = `0${value}`; // Add leading zero
      setState(paddedValue); // Update state
      e.target.value = paddedValue; // Update input value
    }

    if (prevRef) {
      prevRef.current.focus();
    }
  }
};

const handleBlur = (e, setState) => {
  const value = e.target.value;

  // Check if the value is a single digit
  if (value.length === 1 && parseInt(value, 10) > 0) {
    const paddedValue = `0${value}`; // Add leading zero
    setState(paddedValue); // Update state
    e.target.value = paddedValue; // Update input value
  }
};  
// Handles the file input change
const handleImageChange = (e, type) => {
   
  const file = e.target.files[0]; 
  let errors = { ...formChkErrors };

  // Reset submission flags
  if (type === 'front') setIsfrontFileSubmitted(false);
  if (type === 'back') setIsbackFileSubmitted(false);

  if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
    
    // Check if the same file is being uploaded for both front and back
    if (type === 'front' && selectedBackImage && selectedBackImage.name === file.name) {
      errors.frontError = 'Front image cannot be the same as the back image';
    } else if (type === 'back' && selectedFrontImage && selectedFrontImage.name === file.name) {
      errors.backError = 'Back image cannot be the same as the front image';
    } else {
      // Proceed with normal file upload handling
      if (type === 'front') {
        setSelectedFrontImage(file);
        setFrontPreview(URL.createObjectURL(file)); // Create Front preview URL
        errors.frontError = '';  // Clear any previous front image errors  
      } else if (type === 'back') {
        setSelectedBackImage(file);
        setBackPreview(URL.createObjectURL(file)); // Create Back preview URL
        errors.backError = '';  // Clear any previous back image errors
      }
    }

  } else {
    // if (type === 'front') {
    //   errors.frontError = 'Please upload an image file (jpg or png)';
    // } else if (type === 'back') {
    //   errors.backError = 'Please upload an image file (jpg or png)';
    // }
  } 
  setFormChkErrors(errors);  // Update form errors state
};

// // Handles the file input change
// const handleImageChange = (e, type) => {
//   const file = e.target.files[0]; 
//   let errors = { ...formChkErrors };
//   // Reset submission flags
//   if (type === 'front') setIsfrontFileSubmitted(false);
//   if (type === 'back') setIsbackFileSubmitted(false);

//   if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
//     if (type === 'front') {
//       setSelectedFrontImage(file);
//       setFrontPreview(URL.createObjectURL(file)); // Create Front preview URL
//       errors.frontError = '';  // Clear any previous front image errors  
//     } else if (type === 'back') {
//       setSelectedBackImage(file);
//       setBackPreview(URL.createObjectURL(file)); // Create Back preview URL
//       errors.backError = '';  // Clear any previous back image errors
//     }
//   } else {
//     if (type === 'front') {
//       errors.frontError = 'Please upload an image file (jpg or png)';
//     } else if (type === 'back') {
//       errors.backError = 'Please upload an image file (jpg or png)';
//     }
//   }

//   setFormChkErrors(errors);  // Update form errors state
// };
// Handles the file input change
// const handleImageChange = (e, type) => {
//   const file = e.target.files[0];
//   if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
//     if (type === 'front') {
//       setSelectedFrontImage(file);
//       setFrontPreview(URL.createObjectURL(file)); // Create Front preview URL
//     } else if (type === 'back') {
//       setSelectedBackImage(file);
//       setBackPreview(URL.createObjectURL(file)); // Create Back preview URL
//     }
//   } else {
//     alert('Please upload an image file (jpg or png)');
//   }
// };

// Function to trigger the hidden file input click
const handleUploadClick = (type) => {
  if (type === 'front') {
    frontFileInputRef.current.click();  // Trigger Front input
  } else if (type === 'back') {
    backFileInputRef.current.click();   // Trigger Back input
  }
};

  // Reset file and preview
  const resetImage = (type) => {
    let errors = { ...formChkErrors };
    if (type === 'front') {
      setSelectedFrontImage(null);
      setFrontPreview(null);
      errors.frontError = '';  // Clear front image error when reset
    } else if (type === 'back') {
      setSelectedBackImage(null);
      setBackPreview(null);
      errors.backError = '';  // Clear back image error when reset
    }
    setFormChkErrors(errors);  // Update form errors state
  };


  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

       <LeftSideLogin/>
          <div className="col-lg-5 d-inline-block position-relative">
            <div className="loginBoxDiv">
            {
              threeStep ? (<>
               <div className={"singUpThreeStep " }>
              <h3 className="titleDespTxt">Please fill Insurance details</h3>
                <form  onSubmit={handleSubmitPart2} autoComplete="off">
                  <div className="inputTxtBox registerInputDiv">
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                    <input type="text" 
                    value={InsuranceCarrierTxt} 
                    placeholder="Insurance Carrier" 
                   
                    // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                    onChange={handleInsuranceCarrierChange}
                     /> 
                  </div>
                  {errors.InsuranceCarrierTxt && <span  className="errorTxt" >{errors.InsuranceCarrierTxt}</span>} 

                  
                  <div className="inputTxtBox registerInputDiv">
                    <img src={Icons[0].policyidIcon} className="logoImg" alt="" />
                    <input type="text" 
                    value={policyIDTxt} 
                    placeholder="Policy ID" 
                    maxLength="20" 
                    // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                    onChange={InsuranceIdHandleChange}
                     /> 
                  </div>
                  {errors.policyIDTxt && <span  className="errorTxt" >{errors.policyIDTxt}</span>} 
              
                  <div className="inputTxtBox registerInputDiv">
                    <img src={Icons[0].groupnameIcon} className="logoImg" alt="" />
                    <input type="text" 
                    value={GroupNameTxt} 
                    placeholder="Group Name" 
                    maxLength="20" 
                    // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                    onChange={handleGroupNameChange}
                     /> 
                  </div>
                  {errors.GroupNameTxt && <span  className="errorTxt" >{errors.GroupNameTxt}</span>} 
                  
                      <div className="inputTxtNewBox  registerInputDiv">
                        <img src={Icons[0].expirydateIcon} className="logoImg" alt="" />
                        <div className="timepicker">
                              <input
                                type="text"
                                className="hh"
                                maxLength="4"
                                placeholder="YYYY"
                                ref={yearRef}
                                onInput={(e) => handleInput(e, monthRef, null, setYYYYTxt)}
                                onKeyDown={(e) => handleKeyDown(e, monthRef, null)}
                              />
                              /
                              <input
                                type="text"
                                className="mm"
                                maxLength="2"
                                placeholder="MM"
                                ref={monthRef}
                                  onInput={(e) => handleInput(e, dayRef, yearRef, setMMTxt)}
                                  onKeyDown={(e) => handleKeyDown(e, dayRef, yearRef, setMMTxt)}
                              />
                              /
                              <input
                                type="text"
                                className="mm"
                                maxLength="2"
                                placeholder="DD"
                                ref={dayRef}
                                onInput={(e) => handleInput(e, null, monthRef, setDDTxt)}
                                onKeyDown={(e) => handleKeyDown(e, null, monthRef, setDDTxt)}
                                onBlur={(e) => handleBlur(e, setDDTxt)} // Add onBlur event
                              />

                              <b style={{fontWeight:'500',fontSize: '14px', color:'#999'}}>
                                {/* Expiry Validity */}
                                Effective Date
                                </b>
                           
                            </div>  
                   </div>
                   {isSubmitted && errors.DOBTxt ? (
                    <span className="errorTxt">{errors.DOBTxt}</span>
                  ) : (
                    !isSubmitted && errorMessage && <span className="errorTxt">{errorMessage}</span>
                  )} 
                 

                    {/* Front Image Upload */}
      <div className="inputTxtBox registerInputDiv uploadImgDiv">
        <img src={Icons[0].cardIcon} className="logoImg cardIcon" alt="Front" />
        <label htmlFor="insuranceCardFront">Insurance Card Front</label> 
      
        {/* Custom Upload Button for Front Image */}
        {!frontPreview  && (
          <>
            <button className="uploadBtn" 
             onClick={(e) => {
              e.preventDefault(); // Stop form submission
              handleUploadClick("front");
            }}
            // onClick={() => handleUploadClick('front')}
            >
              Upload File
            </button> 
            {/* Hidden Front file input */}
            <input 
              type="file" 
              accept="image/jpeg, image/png" 
              ref={frontFileInputRef} 
              onClick={(e) => {
                handleImageChange(e, 'front'); // Trigger file input click
              }}
              // onChange={(e) => handleImageChange(e, 'front')}
              style={{ display: 'none' }} 
            />
          </>
        )}  

       {frontPreview && (
          <span className="previewImg"> 
            <b><img src={frontPreview} alt="Front Preview" /></b>
            <button className="removedBtn" onClick={() => resetImage('front')}>&#10006;</button>
          </span>
        )}  
        
      </div>
      {/* {formChkErrors.frontError && (<span  className="errorTxt" >{formChkErrors.frontError}</span>)}
      {errors.selectedFrontImage && <span  className="errorTxt" >{errors.selectedFrontImage}</span>} */}

        {isfrontFileSubmitted && errors.selectedFrontImage ? (
                    <span className="errorTxt">{errors.selectedFrontImage}</span>
                  ) : (
                    !isfrontFileSubmitted && formChkErrors.frontError && <span className="errorTxt dd">{formChkErrors.frontError}</span>
                  )} 

 

      {/* Back Image Upload */}
      <div className="inputTxtBox registerInputDiv uploadImgDiv">
        <img src={Icons[0].cardIcon} className="logoImg cardIcon" alt="Back" />
        <label htmlFor="insuranceCardBack">Insurance Card Back</label>
      
        {/* Custom Upload Button for Back Image */}
        {!selectedBackImage && (
          <>
            <button className="uploadBtn" 
               onClick={(e) => {
                e.preventDefault(); // Stop form submission
                handleUploadClick("back");
              }}
              // onClick={() => handleUploadClick('back')}
              >
              Upload File
            </button> 
            {/* Hidden Back file input */}
            <input 
              type="file" 
              accept="image/jpeg, image/png" 
              ref={backFileInputRef} 
              onClick={(e) => { 
                handleImageChange(e, 'back'); // Trigger file input click
              }}
              // onChange={(e) => handleImageChange(e, 'back')}
              style={{ display: 'none' }} 
            />
          </>
        )}

        {backPreview && (
          <span className="previewImg"> 
            <b><img src={backPreview} alt="Back Preview" /></b>
            <button className="removedBtn" onClick={() => resetImage('back')}>&#10006;</button>
          </span>
        )}
      </div>
      {/* {formChkErrors.backError && (
          <span  className="errorTxt" >{formChkErrors.backError}</span>
        )}
      {errors.selectedBackImage && <span  className="errorTxt" >{errors.selectedBackImage}</span>}    */}

      {/* {isbackFileSubmitted && errors.selectedBackImage ? (
                    <span className={"errorTxt ff "  + (backPreview === 'true' ? 'd-none':'false')}>{errors.selectedBackImage}</span>
                  ) : (
                    !isbackFileSubmitted && formChkErrors.backError && <span className={"errorTxt dd " + (backPreview === 'true' ? 'd-none':'false')}>{formChkErrors.backError}</span>
                  )}  */}

{isbackFileSubmitted && errors.selectedBackImage && !backPreview ? (
  <span className="errorTxt ff">{errors.selectedBackImage}</span>
) : (
  !isbackFileSubmitted &&
  formChkErrors.backError &&
  !backPreview && (
    <span className="errorTxt dd">{formChkErrors.backError}</span>
  )
)}
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn registerBtn mb-0"   >Finish</button>
                    </>)}
              </form>
              </div>
              </>):(<>
                <div className={"singUpFinalStep  " }>
                    <span className="emailVerificationDiv">
                    <img src={Icons[0].checkedIcon} className="logoImg" alt="" />
                    <h3>Success!</h3>
                    <p>Congratulations! Your account has beed created successfully. Continue to the dashboard.</p>
                    <button className="signInBtn mb-0"   onClick={routeChange} >Continue</button>
               
                    </span>
              </div>
              </>)
            }
           
              
            </div>

           
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default InsuranceAddInfo;