import  React, { useState, useRef, useEffect  } from "react";   
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../assest/icons"; 
import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
import { useGoogleLogin } from "@react-oauth/google";
import { getBaseURL } from './baseUrl';


const Switch = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [password, setPassword] = useState('');

  useEffect(() => {
    // Check if the password is saved in localStorage
    const savedPassword = localStorage.getItem('savedPassword');
    if (savedPassword) {
      setIsChecked(true);
      setPassword(atob(savedPassword)); // Decode the saved password
    }
  }, []);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      savePassword(password);
    } else {
      clearSavedPassword();
    }
  };

  const savePassword = (password) => {
    const encodedPassword = btoa(password); // Encode the password before saving
    localStorage.setItem('savedPassword', encodedPassword);
  };

  const clearSavedPassword = () => {
    localStorage.removeItem('savedPassword');
  };

  
  return (
    <div className="switch-container d-flex">
      <label>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          className="switch"
        />
        <div className="switch-slider">
          <div className="switch-knob"></div>
        </div> 
      </label>
      <span style={{ marginLeft: "5px" }}>Remember me</span>
    </div>
  );
};



function LoginScreen() {  
  const [email, setEmail] = useState('');  
  const [showAccessToken, setAccessToken] = useState('');  
  const [UserLoginStatus, setUserLoginStatus] = useState('');  
  const [password, setPassword] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [ShowLoader, setShowLoader] = useState(false);
  const [GShowLoader, setGShowLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [UserId, setUserId] = useState('');  
  const [UserData, setUserData] = useState([]);
  const [passwordType, setPasswordType] = useState("password"); 

  const handleClickPassword = (event) => { 
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setErrors('');
};
// const handleEmailChange = (event) => {
//   const { value } = event.target;
//   setEmail(value); 

//   // Simple regex for email validation
//   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//   setIsValid(emailRegex.test(value));
//   setErrors('');
// };

// const handleEmailChange = (e) => {
//   const value = e.target.value;
//   const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//   // if (emailRegex.test(value)) {
//   //   setErrors('');
//   // } else { 
//   //   setErrors('Invalid email format');
//   // }
//   // console.log(errors)
//   // setEmail(value);
//    // Remove characters that are not alphanumeric, hyphens, dots, or @
//    // let value1 = value.replace(/[^a-zA-Z0-9.-@]/g, '');
  
//     setEmail(value);

//     // Validate the email using regex
//     if (emailRegex.test(value)) {
//       setIsValid(true);
//       setErrors({ email: '' });
//     } else {
//       setIsValid(false);
//       setErrors({ email: 'Invalid email format. Only alphanumeric characters, hyphens, underscores, dots, and @ are allowed.' });
//     }
 
// };
 // Regex pattern allowing alphanumeric characters, hyphens, underscores, dots, and @
 const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

 const handleEmailChange = (e) => {
   const value = e.target.value;
   setEmail(value);

  // Validate the email using regex
  if (emailRegex.test(value)) {
    setIsValid(true);
    setErrors({ email: '' });
  } else {
    setIsValid(false);
    setErrors({ email: 'Enter a valid email' });
  }
};
 
  useEffect(() => {
    // Check if there is a stored rememberMe preference
    const storedRememberMe = localStorage.getItem('rememberMe');
    if (storedRememberMe) {
        setRememberMe(JSON.parse(storedRememberMe));
    }
}, []);

  const handleResetForm= () => { 
    setEmail(''); 
    setPassword(''); 
    setErrors('');
  };
  const nav = useNavigate();
  const routeChange = () =>{  
    nav("/dashboard", { state:UserData}); 
  }

  const handleSubmit = (event) => {

    event.preventDefault();

    // Simple form validation
    let formErrors = {};
    if (!email.trim()) {
      formErrors.email = 'Please enter your email';
    } 
    if (!password.trim()) {
        formErrors.password = 'Please enter your password';
   }
  //   if (isValidEmail(email)) {
  //     formErrors.email = 'Please enter a valid email address'; 
  // }
    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }
    setShowLoader(true)
    // If validation passes, send data to API
    const data = { 
      email: email, 
      password: password,
    }; 

  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/signin ', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
      toast.success("User Signin successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      setUserData(data)
      
      localStorage.setItem('UserId',  JSON.stringify(data.user_id) );
      localStorage.setItem('UserLoginStatus',  JSON.stringify(true) ); 
      if (rememberMe) {
        localStorage.setItem('rememberMe', JSON.stringify(true));
      } else {
          localStorage.removeItem('rememberMe');
      }
      localStorage.setItem('UserProfile', JSON.stringify(data));
      setShowLoader(false)
      handleResetForm()
      routeChange()
    }
    if( data.status == 'error'){ 
        toast.error(data.message, {
           position: toast.POSITION.TOP_RIGHT,
          }); 
          setShowLoader(false)
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};
const isValidEmail = (email) => {
  // Regular expression to validate email format
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};
const isValidNewPassword = (password) => {
  return password.length >= 8;
};
//Google Sign up & Sign in Fucntion

const googleLogin = useGoogleLogin({
  onSuccess: async (tokenResponse) => { 
    console.log(tokenResponse.access_token)
    setAccessToken(tokenResponse.access_token);
    checkGoogleLogin(tokenResponse.access_token)
  },
  onError: (errorResponse) => console.log(errorResponse),
});
const checkGoogleLogin = async (event) => {    
  setGShowLoader(true); 
    // If validation passes, send data to API
    const data = {     
      access_token: event,
 }; 

  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/send_access_token', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){ 
      toast.success("Login successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });  
      localStorage.setItem('UserProfile', JSON.stringify(data));
      if( data.isExist == 'false' ){
        // nav("/filluserinfo"); 
      }else{
        setGShowLoader(false)
        nav("/dashboard"); 
      } 
    }
    if( data.status == 'error'){ 
        toast.error(data.message, {
           position: toast.POSITION.TOP_RIGHT,
          }); 
          setGShowLoader(false)
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};

const handleUserRemove1s = async () => { 
  const data = '664e171d68a60019dd923e02';
  const formUploadFiles = new FormData();
    formUploadFiles.append('user_id', data); 
    const data1 = {     
      user_id: data,
      illness:"Bleeding",
     }; 
// Here, you would replace 'apiEndpoint' with your actual API endpoint
fetch(getBaseURL+'/chatbot', {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data1),
})

  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){ 
    } 
  })
  .catch((error) => {
    console.log('Error:', error);
  });
  
};

const handleUserRemove = async () => { 
  const userIdRemove = '674f100b68a600268ec843e7'; 
  // fetch(getBaseURL+'/delete_by_id/'+userIdRemove, {
    fetch(getBaseURL+'/api/delete/delete_by_id/'+userIdRemove, {
   
    method: 'DELETE',  
  })
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){ 
    } 
  })
  .catch((error) => {
    console.log('Error:', error);
  });
  
};

  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

       <LeftSideLogin />

          <div className="col-lg-5 d-inline-block  position-relative">
            <div className="loginBoxDiv">
            <h3  className="titleDespTxt">Sign in to AIGI Care</h3>
            <form  onSubmit={handleSubmit} autoComplete="off">
            
                              
           <div className='inputGroupDiv'> 
                <div className="inputTxtBox registerInputDiv">
                   <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id" autoComplete="off"
                     onChange={handleEmailChange}
                     style={{
                      borderColor: isValid ? 'black' : 'red'
                    }}
                    />  
                 
                </div>
                {errors.email && <span  className="errorTxt" >{errors.email}</span>}
          </div>
            
          <div className='inputGroupDiv'> 
                <div className="inputTxtBox registerInputDiv ">
                   <img src={Icons[0].lockedImg} className="logoImg" alt="" />
                   
                   <input type={passwordType}  value={password} placeholder="Password" autocomplete="new-password" onChange={handlePasswordChange}/>   
                    <label className='eyePwdIcon' onClick={handleClickPassword}>  
                      <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                    </label>                
                </div>
                {errors.password && <span  className="errorTxt" >{errors.password}</span>}
          </div>

             
              <div className="optDivBox d-flex justify-content-between">
               
                
                  {/* <input type="checkbox" id="switch" checked={rememberMe}  onChange={(e) => setRememberMe(e.target.checked)}/><label for="switch">Toggle</label> */}
                  <Switch />  
                 <span className="forgotPwd"> <Link to="/forgotpassword">Forgot Password?</Link></span> 
              </div>
              {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn" type="submit" >Sign In</button>
                    </>)}
            
              <span className="otherSignInOpt">
                <p>or sign in with</p>
                <ul>
                  <li className="w-100" onClick={() => googleLogin()}>
                    <img src={Icons[0].gmailImg} className="" alt="" />
                    {GShowLoader ? (<>
                     Continue with Google <div className="spinner-border " role="status">  </div> 
                     
                    </>):(<>
                      Continue with Google
                    </>)}
                  </li>
                

                </ul>
              </span>
              <div className="signupDiv">
                    Don't have an account  <Link to="/signup">Sign up</Link>
              </div>
            </form>
                <button style={{display:'none'}} onClick={()=>handleUserRemove()}>Remove</button>
            </div>
          </div>
          
        </div>
      
      </div>
    </div>
  );
}

export default LoginScreen;