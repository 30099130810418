
import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  
import LoginScreen from "./component/login";  
import SingupScreen from "./component/signup";  
import SingupFillUserInfo from "./component/filluserinfo";  
import Userforgot from "./component/userforgot";  
import ForgotPassword from "./component/forgotpwd";  
import ResetPwd from "./component/resetpwd";  
import BMIScreen from "./component/BMI_Step/mainstep"; 
import AdminLoginScreen from "./component/admin/login";  
import AdminDashboard from "./component/admin/dashboard";  
import SearchFilter from "./component/admin/searchfilter";  
import ViewPatientProfile from "./component/admin/viewpatient";  
import AdminUser from "./component/admin/adminUser";  
import AdminUserinfo from "./component/admin/adminUserinfo";  
import AddAdminUser from "./component/admin/addAdminUser";  
import Adminforgot from "./component/admin/adminforgot";  
import UserProfile from "./component/userprofile"; 
import ChatBot from "./component/chatbot"; 
import "./assest/css/style.css"; 
import { ToastContainer, toast } from "react-toastify"; 
import "react-toastify/dist/ReactToastify.css";
import { SearchProvider  } from '../src/component/admin/SearchContext';
import InsuranceAddInfo from "./component/insuranceinfo";
import UserMyInsurance from "./component/myinsurance";
import PatientDashboard from "./component/dashboard";
import ProcedureData from "./component/BMI_Step/proceduredata";
import ViewDocuments from "./component/admin/viewdocs";
import ConsentNotice from "./component/consenttext";
import NPIRegistry from "./component/autosuggest";
import PatientHistory from "./component/patienthistory";
import DashboardView from "./component/dashboardView";
import ViewFullReport from "./component/admin/viewFullHistory";


function App() {
  return (
    <div className="App">
    <ToastContainer/>
      <Router>
    <SearchProvider>
          <Routes> 
            <Route path="/" element={<LoginScreen />} />       
            <Route path="/signup" element={<SingupScreen />} />  
            <Route path="/filluserinfo" element={<SingupFillUserInfo />} />  
            <Route path="/addinsuranceinfo" element={<InsuranceAddInfo />} />  
            <Route path="/newappointment" element={<BMIScreen />} />       
            <Route path="/dashboard" element={<PatientDashboard />} />     
            <Route path="/dashboardview" element={<DashboardView />} />     
            <Route path="/patienthistory/:sessionIDNew" element={<PatientHistory />} />    
            
            <Route path="/chatbot" element={<ChatBot />} />    
            <Route path="/userprofile" element={<UserProfile />} />    
            <Route path="/myinsurance" element={<UserMyInsurance />} />    
            <Route path="/changepassword" element={<Userforgot />} /> 
            <Route path="/reset_password" element={<ResetPwd />} /> 
            <Route path="/forgotpassword" element={<ForgotPassword />} />  
            <Route path="/admin" element={<AdminLoginScreen />} /> 
            <Route path="/admin/dashboard" element={<AdminDashboard />} /> 
            <Route path="/admin/patientprofile" element={<ViewPatientProfile />} /> 
            <Route path="/admin/adminusers" element={<AdminUser />} /> 
            <Route path="/admin/adminusersinfo" element={<AdminUserinfo />} /> 
            <Route path="/admin/adduseradmin" element={<AddAdminUser />} /> 
            <Route path="/admin/changepassword" element={<Adminforgot />} /> 
            <Route path="/admin/searchresult" element={<SearchFilter />} />  
            <Route path="/autosuggestion" element={<ProcedureData />} />  
            <Route path="/admin/viewdocuments" element={<ViewDocuments />} />  
            <Route path="/admin/fullreport" element={<ViewFullReport />} />     

            <Route path="/termcondition" element={<ConsentNotice />} />  
            <Route path="/NPIRegistry" element={<NPIRegistry />} />  
            
         </Routes>
    </SearchProvider>
    </Router> 
    </div>
  );
}

export default App;