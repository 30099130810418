import React, { useEffect, useState } from 'react';

const ProcedureData = () => {
  const [query, setQuery] = useState(''); // For search input
  const [results, setResults] = useState([]); // Store the search results
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error handling

  // Function to handle API search
  const searchProcedure = async () => {
  //  const url = `https://fhir-api.akosmd.com/api/fhir/resource/Procedure?code=${query}`; // Surgical History
    const url = `https://fhir-api.akosmd.com/api/fhir/resource/Condition?name=${query}`; // Medical History

    const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMTZhZDc0NDBkYjlmNGEwZDNhZDhkYzM1MTNjODgzYTg5N2E0NTk3MzYyYjU2YTU4NmEzMmRkNzA3NDcyZTU2YTNkMTU1OTkzNmRmYmVlYWQiLCJpYXQiOjE3MjkwMDEwOTUsIm5iZiI6MTcyOTAwMTA5NSwiZXhwIjoxNzYwNTM3MDk1LCJzdWIiOiIyMzcxODQiLCJzY29wZXMiOlsiMjAwMDAiLCJ1c2VyX2NyZWRlbnRpYWxzIiwic291cmNlLXdlYiJdfQ.artPomIKTfkqnVyTOPXrupXJEBXpQNA_L3A5jUKdHxL-rlYMhybcFhRWg9lF42gV_9ww-SIucSFMF5bLMpWmD5iM0pROmKwNh2DxlI-td7eykI4NaWDjK0GPIzrjbQw4TFwLcd6mK5PLXT1RbRM5iEkn6rbX1Z3M2QfniwrvRBK_Ukq2t1zRLrwJtwuENh-JTme4VBye2pNtLB6LuebZIEwa9FkEiR83bS1aOCGqBGfR7NteptL_mhzhJEmOesD8p6qewUzE6ODvrRaVBI4Wf6S7FJMaNGK4qT9DTVeIrjBXgapS0ldd49d-o_wvu4D9O97H3mdGSiuh3SJKmvNrW9lXhGK6lLFYVhaSl_AlIiAgQAcfxy4t7bgbP9m9SCHptWsc_pPx63lyjF5JUSjXMfF8RD3QtofkjzHLM47PlGCSVB-yRrysSNJdUzxRBmLb3BJgQDVsQelFPDmKrTDgnFTVRJqdckjpIfwjY-yVBgCr_Mgb-o5aK_nsjuUI39wus5BvmDZF4SUhULlPCeo5bocl7W6lISK-T72b5_Rtul2DX54ECYAT-Sl_ccfOGcZ-APnBbJMo0tYoXAXe37vicGYRD3_KGjsv-y-vYGJHki84dR0NXT8oQgq4UkxOofNaMDdc23i2Bif43KpIy8bvrI_8bhpn7hiAzin1zsVt2gE'; // Use your full Bearer token

    setLoading(true); // Show loading state

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const data = await response.json(); // Parse the response JSON
      setResults(data.entry || []); // Store results (assuming 'entry' contains the list)
    } catch (err) {
      setError(err.message); // Handle any errors
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div>
      <h1>Search Procedures</h1>

      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Enter procedure name"
      />
      <button onClick={searchProcedure}>Search</button>

      {loading && <p>Loading...</p>}
      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      <ul>
        {results.map((item, index) => (
          <li key={index}>
           {item.resource?.code?.text || 'N/A'} <br />
            {/* <strong>Status:</strong> {item.resource?.clinicalStatus?.coding[0]?.code || 'N/A'} <br />
            <strong>Recorded Date:</strong> {item.resource?.recordedDate || 'N/A'} */}
          </li>
        ))}
      </ul>
    </div>
  );
};
  
  export default ProcedureData;