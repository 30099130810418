import  React, { useState, useRef, useEffect  } from "react";
import Modal from 'react-modal';
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { Icons } from "../../assest/icons";
import Header from './header';
import LoaderSpinner from '../loaderSpinner';
import SideBarMenu from './sideBarMenu'; 
import {getBaseURL} from '../baseUrl'; 

  import AbdominalCT from '../../assest/document/AIGICare_PDF_AbdominalCT.pdf';
  import AbdominalUS from '../../assest/document/AIGICare_PDF_AbdominalUS.pdf';
  import AnemiaLabs from '../../assest/document/AIGICare_PDF_AnemiaLabs.pdf';
  import BariumStudies from '../../assest/document/AIGICare_PDF_BariumStudies.pdf';
  import BasicGastroLabs from '../../assest/document/AIGICare_PDF_BasicGastroLabs.pdf';
  import ColonOrder from '../../assest/document/AIGICare_PDF_Colon_Order.pdf';
  import Colonoscopy from '../../assest/document/AIGICare_PDF_Colonoscopy.pdf';
  import ColonScreeningGuidelines from '../../assest/document/AIGICare_PDF_ColonScreeningGuidelines.pdf';
  import Diverticulosis from '../../assest/document/AIGICare_PDF_Diverticulosis.pdf';
  import EGDOrder from '../../assest/document/AIGICare_PDF_EGD_Order.pdf';
  import FattyLiver from '../../assest/document/AIGICare_PDF_FattyLiver.pdf';
  import FODMAP_Diet from '../../assest/document/AIGICare_PDF_FODMAP_Diet.pdf';
  import GERD_Diet_and_Lifestyle from '../../assest/document/AIGICare_PDF_GERD_Diet_and_Lifestyle.pdf';
  import HeapticLabs from '../../assest/document/AIGICare_PDF_HeapticLabs.pdf';
  import High_Fiber_Diet from '../../assest/document/AIGICare_PDF_High_Fiber_Diet.pdf';
  import InflammatoryBowelDisease from '../../assest/document/AIGICare_PDF_InflammatoryBowelDisease.pdf';
  import IrritableBowelSyndrome from '../../assest/document/AIGICare_PDF_IrritableBowelSyndrome.pdf';
  import ProceduralSedation from '../../assest/document/AIGICare_PDF_ProceduralSedation.pdf';
  import StoolStudies from '../../assest/document/AIGICare_PDF_StoolStudies.pdf';
  import StressManagement from '../../assest/document/AIGICare_PDF_StressManagement.pdf';
  import TelehealthDisclaimer from '../../assest/document/AIGICare_PDF_TelehealthDisclaimer.pdf';
  import UpperGIEndoscopy from '../../assest/document/AIGICare_PDF_UpperGIEndoscopy.pdf';
  import Weight_Reducing_Diet from '../../assest/document/AIGICare_PDF_Weight_Reducing_Diet.pdf';
 
function ViewDocuments() { 

  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const [selectedFile, setSelectedFile] = useState(null); // Store selected file info

  const [documents, setDocuments] = useState([]); // Document list state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
  const [selectedDocument, setSelectedDocument] = useState(null); // Track the selected document

    // Open modal and set selected document
    const openModal = (doc) => {
      console.log(doc)
      setSelectedDocument(doc);
      setIsModalOpen(true);
    };

    // Close modal and reset selected document
    const closeModal = () => {
      setIsModalOpen(false);
      setSelectedDocument(null);
    };


  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/admin/patientprofile`;
    navigate(path);
  }
  const nav = useNavigate(); 
 
  const [ShowLoader, setShowLoader] = useState(false);

  
  const handleRowClick = (id) => { 
    nav("/admin/adminusersinfo", { state: id }); 
  };
  
  const openAddAdmin = () => { 
    nav("/admin/adduseradmin"); 
  };
  
  // Sample file array
  const fileArray = [
    { id: 1, fileName: 'Abdominal CT', url: AbdominalCT },
    { id: 2, fileName: 'Abdominal US', url: AbdominalUS },
    { id: 3, fileName: 'Anemia Labs', url: AnemiaLabs },
    { id: 4, fileName: 'Barium Studies', url: BariumStudies },
    { id: 5, fileName: 'Basic Gastro Labs', url: BasicGastroLabs },
    { id: 6, fileName: 'Colon Order', url: ColonOrder },
    { id: 7, fileName: 'Colonoscopy', url: Colonoscopy },
    { id: 8, fileName: 'Colon Screening Guidelines', url: ColonScreeningGuidelines },
    { id: 9, fileName: 'Diverticulosis', url: Diverticulosis },
    { id: 10, fileName: 'EGD Order', url: EGDOrder },
    { id: 11, fileName: 'FattyLiver', url: FattyLiver }, 
    { id: 12, fileName: 'FODMAP Diet', url: FODMAP_Diet },
    { id: 13, fileName: 'GERD Diet and Lifestyle', url: GERD_Diet_and_Lifestyle },
    { id: 14, fileName: 'Heaptic Labs', url: HeapticLabs },
    { id: 15, fileName: 'High Fiber Diet', url: High_Fiber_Diet },    
    { id: 16, fileName: 'Inflammatory Bowel Disease', url: InflammatoryBowelDisease },
    { id: 17, fileName: 'Irritable Bowel Syndrome', url: IrritableBowelSyndrome },
    { id: 18, fileName: 'Procedural Sedation', url: ProceduralSedation },
    { id: 19, fileName: 'Stool Studies', url: StoolStudies },
    { id: 20, fileName: 'Stress Management', url: StressManagement },
    { id: 21, fileName: 'Telehealth Disclaimer', url: TelehealthDisclaimer },
    { id: 22, fileName: 'Upper GI Endoscopy', url: UpperGIEndoscopy },
    { id: 23, fileName: 'Weight Reducing Diet', url: Weight_Reducing_Diet },
  ];

 
  // Function to handle click and open the modal with the file
  const handleFileClick = (file) => {
    setSelectedFile(file); // Set the selected file
    setShowModal(true); // Open the modal
  };

  // Function to close the modal
  const handleClose = () => {
    setShowModal(false);
    setSelectedFile(null); // Clear selected file on close
  };

 
  return (
    <div>
    {ShowLoader && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
             <Header />
             <div className="innerBodyBox bgInnerDiv">

              <div className="contentInnerBody">
                <div className="titleDiv">
                  <h2>
                    {/* Procedures List */}
                    Standard Precaution List
                    </h2> 
                </div>
                <div className="contentMainBox scrollHeightOverflow"> 
                <ul className="list-group viewDocumentList">

                  {fileArray.map((file) => (
                    <li
                      key={file.id}
                      className="list-group-item d-flex justify-content-between align-items-center" 
                      // onClick={() => handleFileClick(file)} 
                    >
                      {file.fileName}
                      <span className="d-flex justify-content-between align-items-center gap-2">
                          <button className=" badge bg-primary rounded-pill border-0" style={{ cursor: 'pointer', width:'100px', padding: '9px 0' }} onClick={() => openModal(file.url)}>View</button>
                          <a href={file.url} target="_blank" rel="noopener noreferrer" style={{ textTransform: "capitalize", textDecoration: "none" , width:'100px'}} className=" pdfButton badge bg-primary rounded-pill" >Download </a>
                      </span>
                    </li>
                  ))}
                </ul>

               {/* Modal Popup for Viewing Documents */}
                  <Modal
                  className='viewDocModal'
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Document Viewer"
                  
                  >
                    <button
                      onClick={closeModal}
                      style={{
                        float: 'right',
                        cursor: 'pointer',
                        background: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '4px 10px',
                        borderRadius: '0px',
                      }}
                    >
                      Close
                    </button>
                  
                    {/* Ensure selectedDocument exists before rendering */}
                    {selectedDocument ? (
                      <>
                        <h3>{selectedDocument.name}</h3>
                        <embed src={selectedDocument} width="100%" height="100%" type="application/pdf"  className="innerContainer" />
                        {/* Render PDF or Image based on file type */} 
                      </>
                    ) : (
                      <p>Loading document...</p> // Fallback content if document is not available
                    )}
                  </Modal>
                          
 
                    
                
               
                       
                </div>
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default ViewDocuments;