import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useNavigate } from "react-router-dom"; 
import Header from './header'; 
import { Icons } from "../assest/icons";
 
// const ConsentNotice = () =>
  function ConsentNotice({ onAgree, onDecline })  {
  return (
    <div>
        <div className='container-fluid p-0 bgInnerDiv ConsentNoticeMain'>
        <div className="containerDiv whiteBg headerDivBox d-none" >
          <div className="leftDiv d-inline-block">
            <Link to="/">
              <img src={Icons[0].logoIcon} className="logoImg companyLogo" alt="" />
            </Link>
          </div> 
          </div>
        <div className='container-fluid p-4 contentBody mt-0 h-100' style={{overflow:'auto'}}>  
        <b className="d-block" style={{ opacity: 0.7 , marginBottom:'0px', textAlign:'center', fontSize:'20px', color : '#df4747'}}>
            {/* Note: Please read the Consent and Notice of Privacy Practices by scrolling through the document. */}
            Note: Please scroll to the bottom and click agree
      </b>
      <div className="pt-4">
        <h2 style={{ textAlign: 'center'  }}>Consent and Notice of Privacy Practices</h2>
      </div>
   
      <div className="mt-4">
        <h4 style={{ textAlign: 'center', textDecoration: 'underline' }}>
          Gastro Intestinal Medicine Policies and Procedures Manual Virtual
        </h4>
        <h5 style={{ textDecoration: 'underline' }}>Virtual Visit Consent Policy</h5>
        <h6>A. PURPOSE</h6>
        <p>
          Defined as the practice of health care delivery, diagnosis,
          consultation, treatment, transfer of medical data, and education using
          interactive audio, video, or data communications. Before delivering
          any health care by telemedicine, a health care practitioner who has
          the ultimate authority over the care or primary diagnosis of a patient
          must obtain the patient’s verbal and written informed consent. The
          informed consent procedure must ensure that at least all of the
          following information is given to the patient verbally and in writing.
        </p>
        <h6>B. POLICY</h6>
        <p>
          The patient must sign a written statement before the delivery of
          health care by telemedicine, indicating that the patient understands
          the written information provided above and that this information has
          been discussed with the health care practitioner, or someone
          designated by him or her. This law does not apply when the patient is
          not directly involved in the telemedicine interaction (i.e., when one
          health care practitioner consults with another health care
          practitioner). However, all existing confidentiality protections for
          patient’s medical information will continue to apply. The law does not
          apply in an emergency situation in which the patient is unable to give
          informed consent and the patient’s representative is not available.
          The law also does not apply to a patient who is under the jurisdiction
          of the Department of Corrections.
        </p>
        <ol>
          <li>
            The patient has the option to withhold or withdraw consent at any
            time without affecting his or her right to future health care or
            treatment, and without risking a loss or withdrawal of any program
            benefits to which the patient would otherwise be entitled.
          </li>
          <li>
            A description of the potential risks, consequences, and benefits of
            telemedicine will be disclosed at the patient’s request.
          </li>
          <li>All existing confidentiality protections apply.</li>
          <li>
            The patient is guaranteed access to all medical information
            transmitted during a telemedicine consultation, and copies of this
            information are available for a reasonable fee.
          </li>
          <li>
            Dissemination of any patient-identifiable images or information from
            the telemedicine interaction to researchers or others will not occur
            without the patient’s consent.
          </li>
        </ol>

        <h6>C. PROCEDURE</h6>
      <ol>
        <li>
          A copy of the consent form must be given to the patient and a copy
          must be retained in the patient’s medical record. Copies of court
          orders, etc., relating to the consent will also become part of the
          patient’s medical record.
        </li>
      </ol>
      <h5 style={{ textAlign: 'center' }}>
        <strong>Gastro Intestinal Medicine Patient Virtual Visit Consent Form</strong>
      </h5>
      <p>
        I understand that my health care provider Gastro Intestinal Medicine
        wishes me to have a telemedicine consultation. This means that I and/or
        my healthcare provider or designee will, through interactive video and
        audio connection, consult with the above-named consultant about my condition.
      </p>
      <p>
        My healthcare provider has explained to me how the telemedicine
        technology will be used to do such a consultation.
      </p>
      <p><strong>I understand there are potential risks with this technology:</strong></p>
      <ol>
        <li>
          The video connection may not work or may stop working during the consultation.
          I will have the right to choose to continue via audio or decline the consultation.
        </li>
        <li>
          I may need to visit the consulting physician in person if telemedicine is insufficient
          for diagnosis.
        </li>
      </ol>
      <p><strong>The benefits of a telemedicine consultation are:</strong></p>
      <ol>
        <li>You may not need to travel to the consult location.</li>
        <li>You gain access to a specialist through this consultation.</li>
        <li>You receive a more convenient and affordable healthcare solution.</li>
      </ol>
      <p>
        I consent to being interviewed by the consulting health care provider. 
        I understand others may be present to operate the video equipment and 
        will take reasonable steps to maintain confidentiality.
      </p>
      <p>
        I authorize the release of relevant medical information to the consulting provider,
        their staff, third-party payers, and other healthcare providers involved in my care.
      </p>
      <p>
        I release Gastro Intestinal Medicine TeleClinic and its personnel from any liability
        related to the authorized use of video recordings, digital recordings, or photographs.
      </p>
      <p>
        I have read and understand this document, including the risks and benefits of telemedicine.
        I consent to participate in a telemedicine visit under the conditions described.
      </p>
      <h5 style={{ textAlign: 'center', textDecoration: 'underline' }}>
        Notice of Privacy Practices for Protected Health Information
      </h5>
      <h5 style={{ textAlign: 'center', textDecoration: 'underline' }}>
        This notice describes how medical information about you may be used and disclosed,
        and how you can get access to this information. Please review it carefully!
      </h5>
      <p>
        With your consent, the practice is permitted by federal privacy laws to use and disclose
        your health information for treatment, payment, and healthcare operations. Protected health
        information includes documenting symptoms, examination results, and billing documents.
      </p>
      <p><strong>Example of uses of your health information for treatment purposes:</strong></p>
      <p>
        A nurse records treatment information in your health record. During your treatment, the
        doctor may consult with a specialist, sharing relevant information and obtaining input.
      </p>
      <p><strong>Example of use of your health information for payment purposes:</strong></p>
      <p>
        We submit a payment request to your insurance company, which may request information
        about the care provided. We share this information as required.
      </p>
      <p><strong>Example of use of your information for healthcare operations:</strong></p>
      <p>
        We obtain services from insurers or business associates for quality assessment, protocol
        development, training, and more. Your information may be shared with these entities to obtain services.
      </p>
      <p><strong>Your Health Information Rights:</strong></p>
      <p>
        The physical health and billing records belong to the practice, but the information belongs
        to you. You have a right to access and manage this information.
      </p>

      <ul>
        <li>
          Request a restriction on certain uses and disclosures of your health
          information by delivering a request in writing to our office. We are
          not required to grant the request but will comply with any request
          granted.
        </li>
        <li>
          Request to inspect and copy your health record and billing record. 
          You may exercise this right by delivering the request in writing to our office.
        </li>
        <li>
          Appeal a denial of access to your protected health information, except 
          in certain circumstances.
        </li>
        <li>
          Request that your health care record be amended to correct incomplete 
          or incorrect information by delivering a written request to our office.
        </li>
        <li>
          File a statement of disagreement if your amendment is denied, and 
          require that the request for amendment and any denial be attached to all 
          future disclosures of your protected health information.
        </li>
        <li>
          Obtain an accounting of disclosures of your health information as 
          required by law by delivering a written request to our office. An 
          accounting will not include internal uses of information for treatment, 
          payment, or operations, disclosures made to you, or disclosures made at 
          your request or to family members or friends in the course of providing care.
        </li>
        <li>
          Request that communication of your health information be made by 
          alternative means or at an alternative location by delivering the request 
          in writing to our office.
        </li>
        <li>
          Revoke authorizations that you made previously to use or disclose 
          information, except to the extent information or action has already been 
          taken, by delivering a written revocation to our office.
        </li>
      </ul>
      <p>
        If you want to exercise any of the above rights, please contact our 
        administrator, in person or in writing, during normal hours. They will 
        provide you with assistance on the steps to take to exercise your rights.
      </p>
      <p><strong>Our responsibilities:</strong></p>
      <p>The practice is required to:</p>
      <ul>
        <li>Maintain the privacy of your health information as required by law.</li>
        <li>
          Provide you with a notice of our duties and privacy practices as to 
          the information we collect and maintain about you.
        </li>
        <li>Abide by the terms of this Notice.</li>
        <li>
          Notify you if we cannot accommodate reasonable requests regarding 
          methods to communicate health information with you.
        </li>
      </ul>

      <p>
        We reserve the right to amend, change, or eliminate provisions in our privacy practices and access practices and to enact new provisions regarding the protected health information we maintain. If our information practices change, we will amend our Notice. You are entitled to receive a revised copy of the Notice by calling and requesting a copy or by visiting our office to pick one up.
      </p>
      <h5>To Request Information or File a Complaint</h5>
      <p>
        If you have questions, would like additional information, or want to report a problem regarding the handling of your information, you may contact our office administrator. If you believe your privacy rights have been violated, you may file a written complaint at our office by delivering it to our office administrator. You may also file a complaint with the Secretary of Health and Human Services at:
      </p>
      <address>
        200 Independence Ave S.W., Washington, D.C., 20201<br />
        Phone: 1-877-696-6775<br />
        Website: <a href="http://HHS.gov" target="_blank" rel="noreferrer">http://HHS.gov</a>
      </address>
      <ul>
        <li>We cannot, and will not, require you to waive the right to file a complaint with the Secretary of Health and Human Services as a condition of receiving treatment.</li>
        <li>We cannot, and will not, retaliate against you for filing a complaint.</li>
      </ul>
      <h3>Other Disclosures and Uses</h3>
      <h5>Notification</h5>
      <p>
        Unless you object, we may use or disclose your protected health information to notify, or assist in notifying, a family member, personal representative, or other person responsible for your care, about your location, general condition, or death.
      </p>
      <h5>Communication with Family</h5>
      <p>
        Using our best judgment, we may disclose to a family member, relative, close friend, or any other person you identify, health information relevant to that person’s involvement in your care or payment for such care, provided you do not object or in an emergency.
      </p>
      <h5>Food and Drug Administration (FDA)</h5>
      <p>
        We may disclose to the FDA your protected health information relating to adverse events with respect to products, product defects, or post-marketing surveillance to enable product recalls, repairs, or replacements.
      </p>
      <h5>Worker's Compensation</h5>
      <p>
        If you are seeking compensation through Worker’s Compensation, we may disclose your protected health information as necessary to comply with applicable laws.
      </p>
      <h5>Public Health</h5>
      <p>
        As required by law, we may disclose your protected health information to public health or legal authorities charged with preventing or controlling disease, injury, or disability.
      </p>
      <h5>Abuse and Neglect</h5>
      <p>
        We may disclose your protected health information to public authorities as allowed by law to report abuse or neglect.
      </p>
      <h5>Correctional Institutions</h5>
      <p>
        If you are an inmate, we may disclose your protected health information to the institution or its agents as necessary for your health and the health and safety of others.
      </p>
      <h5>Law Enforcement</h5>
      <p>
        We may disclose your protected health information for law enforcement purposes as required by law, such as by court order, in felony cases, or when an individual is in custody.
      </p>
      <h5>Health Oversight</h5>
      <p>
        Federal law allows us to release your protected health information to oversight agencies for health oversight activities.
      </p>
      <h5>Judicial/Administrative Proceedings</h5>
      <p>
        We may disclose your protected health information in any judicial or administrative proceeding as allowed by law, with your consent, or as directed by a court order.
      </p>
      <h5>Other Uses</h5>
      <p>
        Other uses and disclosures not covered in this Notice will be made only with your written authorization, which you may revoke at any time.
      </p>
      <h5>Website</h5>
      <p>
        If we maintain a website, this Notice will be available online. Effective Date: 04/01/2003
      </p>
      
      </div>
      <p>
        I hereby acknowledge that I have received a copy of this practice's Notice of Privacy Practices. 
        I have had the opportunity to ask any questions regarding this Notice.
      </p>

      <div className="d-grid gap-2 mb-4">
        <h5>TELEMEDICINE DISCLAIMER</h5>

        <p>I hereby consent to engaging in telemedicine. I understand that "telemedicine" includes
the practice of health care delivery, diagnosis, consultation, treatment, transfer of
medical data, and education using interactive audio, video, or data communications. I
understand that telemedicine also involves the communication of my medical
information, both orally and visually, to health care practitioners</p>
        <p>The laws that protect the confidentiality of my medical information also apply to
telemedicine. As such, I understand that the information disclosed by me during the
course of my treatment is confidential. I understand that there are risks and
consequences from telemedicine, including, but not limited to, the possibility, despite
reasonable efforts on the part of my Provider, that: the transmission of my medical
information could be disrupted or distorted by technical failures; the transmission of my
medical information could be interrupted by unauthorized persons; and/or the
electronic storage of my medical information could be accessed by unauthorized
persons. </p>
        <p>In addition, I understand that telemedicine based services and care may not be as
complete as face-to-face services. I also understand that if my Provider believes I would
be better served by another form of medical services (e.g. face-to-face services) I will be
referred to a medical services provider who can provide such services in my area.
Finally, I understand that there are potential risks and benefits associated with any form
of medical treatment, and that despite the efforts of my Provider, my condition may not
be improve, and in some cases may even get worse. Therefore, I understand that I may
benefit from telemedicine, but that results cannot be guaranteed or assured. </p>
        <p>I HAVE NO OTHER PRE-EXISTING MEDICAL CONDITIONS THAT HAVE NOT ALREADY BEEN
DISCLOSED HERE. I understand that this visit/encounter does not and should not
replace a traditional doctor's office visit; and therefore, I am proceeding with this teleevaluation at my own risk and understanding. I also understand that should my
condition or my responsible party's be an emergency, I should contact local emergency
response by dialing 911. I certify that the information provided in this medical form is
true and accurate to the best of my ability. I also understand that omitting medical
information or misinforming the Provider may result in an inaccurate diagnosis and
treatment. </p>
<p>I have read and understand the information provided above. I have discussed with the
Provider, and all of my questions have been answered to my satisfaction.</p>
      </div>

      <div className="d-grid text-center gap-2 mb-4">

    

     

      <span className="d-flex m-auto gap-4 ">
      <button className="viewBtnClass" style={{width:"100px", background:"#c14646"}} onClick={onDecline}>Decline</button>
      <button className="viewBtnClass" style={{width:"100px"}} onClick={onAgree}>I Agree</button>
      </span>
        
      </div>
    </div>
    </div>
    </div>
  );
};

export default ConsentNotice;