import React, { useState, useEffect, useRef } from 'react'; 
import * as RecordRTC from 'recordrtc';
import { Icons } from "../../assest/icons";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify"; 
import { getBaseURL } from '../baseUrl'; 

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const PageTwo = () => {
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/chatbot`;
    navigate(path);
  } 

  const fileInputRef = useRef(null);
  const fileInputRefMedications = useRef(null);
  const fileInputRefMediConditions = useRef(null);
  const fileInputRefPSHistory = useRef(null);
  const fileInputRefPhysicalExam = useRef(null);
  const fileInputRefSocialHistory = useRef(null);
  const fileInputRefReport1 = useRef(null);
  const fileInputRefReport2 = useRef(null);
  const fileInputRefReport3 = useRef(null);
  const fileInputRefReport4 = useRef(null);
  const fileInputRefReport5 = useRef(null);

  const textareaRefAllergies = useRef(null);
  const textareaRefMedications = useRef(null);
  const textareaRefMediConditions = useRef(null);
  const textareaRefPSHistory = useRef(null);
  const textareaRefPhysicalExam = useRef(null);
  const textareaRefSocialHistory = useRef(null);
  const textareaRefPCP = useRef(null);
  const textareaRefPharmacy = useRef(null);

  
  const inputRefeMedicalConditionTxt = useRef(null);
  const inputRefePShistorytxt = useRef(null);
  const inputRefeMedicationstxt = useRef(null);
  const inputRefeAllergiestxt = useRef(null);
  
  const [PCPData, setPCPData] = useState('');
  const [PharmacyData, setPharmacyData] = useState('');


  const [uploadFiles, setUploadFiles] = useState([]);

  const [apiCallCount, setApiCallCount] = useState(0);
  const [apiSuccessCount, setApiSuccessCount] = useState(0);

  const [userIdVal, setUserId] = useState([]);
  const [ShowBMICal, setShowBMICal] = useState('');
  const [ShowLoader, setShowLoader] = useState(false);
  const [TxtLoader, setTxtLoader] = useState(false);

  const [selectedSmoking, setSelectedSmoking] = useState('');
  const [selectedAlcohol, setSelectedAlcohol] = useState('');
  const [selectedExercise, setSelectedExercise] = useState('');

  const [AllergiesData, setAllergiesData] = useState('');
  const [AllergiesFile, setAllergiesFile] = useState(null);
  const [fileNameAllergies, setFileNameAllergies] = useState('');

  const [MedicationsData, setMedicationsData] = useState('');
  const [MedicationsFile, setMedicationsFile] = useState(null);
  const [fileNameMedications, setFileNameMedications] = useState('');

  const [SocialHistoryData, setSocialHistoryData] = useState('');
  const [SocialHistoryFile, setSocialHistoryFile] = useState(null);
  const [fileNameSocialHistory, setFileNameSocialHistory] = useState('');

  const [PhysicalExamData, setPhysicalExamData] = useState('');
  const [PhysicalExamFile, setPhysicalExamFile] = useState(null);
  const [fileNamePhysicalExam, setFileNamePhysicalExam] = useState('');

  const [MedicalConditionsData, setMedicalConditionsData] = useState('');
  const [MedicalConditionFile, setMedicalConditionFile] = useState(null);
  const [fileNameMedicalCondition, setFileNameMedicalCondition] = useState('');

  const [PastSurgicalHistoryData, setPastSurgicalHistoryData] = useState('');
  const [PSHistoryFile, setPSHistoryFile] = useState(null);
  const [fileNamePSHistory, setFileNamePSHistory] = useState('');

  const [UploadReport, setUploadReport] = useState('');

  const [BPMin, setBPMin] = useState('');
  const [BPHigh, setBPHigh] = useState('');
  const [HeartRate, setHeartRate] = useState('');
  const [bodyTemperature, setTemperature] = useState('');


  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [activeSpeech, setActiveSpeech] = useState(null);
  const [transcripts, setTranscripts] = useState({ allergies: '', medications: '', medicalcondition: '', pshistory: '', physicalexam : '', socialhistory: '', pcp: '', pharmacy:'', pcpName:'', pcpAddress:'', pharmacyName:'', pharmacyAddress:'' });
  const { transcript, resetTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const socket = useRef(null);
  const recorder = useRef(null);
  const [isRecording, setIsRecording] = useState({allergies: false, medications: false, medicalcondition: false, pshistory: false, physicalexam : false, socialhistory: false });
  const [activeMic, setActiveMic] = useState(null); 
  const [isConnecting, setIsConnecting] = useState(false);
  const [reportId, setReportId] = useState('');

  const [suggestions, setSuggestions] = useState([]);
  const suggestionListRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allergiesSuggestions, setAllergiesSuggestions] = useState([]);
  const [medicationsSuggestions, setMedicationsSuggestions] = useState([]);
  const [pastSurgicalSuggestions, setPastSurgicalSuggestions] = useState([]);
  const [medicalConditionSuggestions, setMedicalConditionSuggestions] = useState([]);

  const [pcpSuggestions, setPCPSuggestions] = useState([]);
  const [pharmacySuggestions, setPharmacySuggestions] = useState([]);


  const [drugNames, setDrugNames] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);

  const generateUniqueId = (id) => {
    const timestamp = new Date().getTime();
    setReportId(`report_${timestamp}_${id}`);
    localStorage.setItem('reportId', JSON.stringify(`report_${timestamp}_${id}`));
  };

  const fetchSuggestions = async (input, setSuggestions) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${getBaseURL}/auto_suggest?query=${input}`);
      setSuggestions(response.data.suggestions || []);
      if (suggestionListRef.current) {
        suggestionListRef.current.scrollTop = 0;
      }
    } catch (error) { 
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };

  
  const fetchSuggestionsMedicalCondition = async (input, setSuggestions) => {
    setIsLoading(true);
    try {
      const response = await axios.get(  `https://clinicaltables.nlm.nih.gov/api/conditions/v1/search?terms=${encodeURIComponent(input)}&df=term_icd9_text,primary_name&autocomp=1`);
    
    const suggestionsArray = response.data[3]?.map((item) => item[1]) || [];
    setSuggestions(suggestionsArray); // Update suggestions state

    // Scroll the suggestion list to the top
    if (suggestionListRef.current) {
      suggestionListRef.current.scrollTop = 0;
    }
    } catch (error) { 
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchSuggestionsPastSurgical  = async (input, setSuggestions) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://clinicaltables.nlm.nih.gov/api/procedures/v3/search?terms=${encodeURIComponent(input)}`);
    
    const suggestionsArray = response.data[3]?.map((item) => item[0]) || [];
    setSuggestions(suggestionsArray); // Update suggestions state

    // Scroll the suggestion list to the top
    if (suggestionListRef.current) {
      suggestionListRef.current.scrollTop = 0;
    }
    } catch (error) { 
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };
 
  const fetchSuggestionsdrugName  = async (input, setSuggestions) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://rxnav.nlm.nih.gov/REST/Prescribe/displaynames.json`
      );
      const suggestions = response.data.displayTermsList.term || [];
      const filteredSuggestions = suggestions.filter((name) =>
        name.toLowerCase().startsWith(input.toLowerCase())
      );
      setSuggestions(filteredSuggestions);

      if (suggestionListRef.current) {
        suggestionListRef.current.scrollTop = 0;
      }
    } catch (error) { 
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };
 
  const fetchSuggestionsPlace = async (input, setSuggestions) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `https://places.googleapis.com/v1/places:searchText`,
        {
          textQuery: `${input}`, // Enhance query with "pharmacy"
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Goog-Api-Key': "AIzaSyBlhG-E470kB32nqMKUjVmT8LNcMKCTDdU",
            'X-Goog-FieldMask': 'places.displayName,places.formattedAddress,places.id,places.name', // Specify the fields you want
          },
        }
      );
      // setSuggestions(response.data || []);
      if (response.data && response.data.places) {
        // Filter results to include only places in the US
        const usPlaces = response.data.places.filter((place) =>
          place.formattedAddress && place.formattedAddress.includes("")
        );

        setSuggestions(usPlaces); // Set filtered results 
      } else {
        setSuggestions([]);
      }
      if (suggestionListRef.current) {
        suggestionListRef.current.scrollTop = 0;
      }
    } catch (error) {
      // console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  };
 

  const highlightMatch = (name, query) => {
    const regex = new RegExp(`(${query.trim().split(' ').pop()})`, 'gi');
    const parts = name.split(regex);
    return parts.map((part, index) => (
      part.toLowerCase() === query.trim().split(' ').pop().toLowerCase() ? (
        <span key={index} style={{ textDecoration: 'underline' }}>
          {part}
        </span>
      ) : (
        part
      )
    ));
  };
   
  const getToken = async () => {
    const response = await fetch(getBaseURL+'/new_token'); // Update this URL to your actual token endpoint
    const data = await response.json();
    if (data.error) { 
      return null;
    }
    return data.token;
  };

  const generateTranscript = async (targetTextArea) => {
    try {
      const token = await getToken();
      if (!token) return;
      setIsConnecting(true);
      socket.current = new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);

      const texts = {};
      socket.current.onmessage = (voicePrompt) => {
       // let msg = '';
        let msg = transcripts[targetTextArea] || ''; 
        const res = JSON.parse(voicePrompt.data);
        if (res.text && res.text.trim() !== '') {  // Check if res.text is not null or blank
          texts[res.audio_start] = res.text;
        }
        // texts[res.audio_start] = res.text;
        const keys = Object.keys(texts).filter(key => key !== 'undefined').sort((a, b) => a - b);
        keys.forEach((key) => {
          msg += ` ${texts[key]}`;
        });

        setTranscripts((prevState) => ({
          ...prevState,
          [targetTextArea]: msg.trim(), // || '',
        }));
      };

      socket.current.onerror = (event) => {
       // console.error('WebSocket error:', event);
        socket.current.close();
        socket.current = null;
        setIsConnecting(false);
      };

      socket.current.onclose = (event) => { 
        socket.current = null;
        setIsConnecting(false);
      };

      socket.current.onopen = () => {
        setIsConnecting(false);
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then((stream) => {
            recorder.current = new RecordRTC(stream, {
              type: 'audio',
              mimeType: 'audio/webm;codecs=pcm',
              recorderType: RecordRTC.StereoAudioRecorder,
              timeSlice: 250,
              desiredSampRate: 16000,
              numberOfAudioChannels: 1,
              bufferSize: 4096,
              audioBitsPerSecond: 128000,
              ondataavailable: (blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64data = reader.result;
                  if (socket.current) {
                    socket.current.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
                  }
                };
                reader.readAsDataURL(blob);
              },
            });
            recorder.current.startRecording();
          })
          .catch((err) => console.error('getUserMedia error:', err));
      };

      setIsRecording((prevState) => ({
        ...prevState,
        [targetTextArea]: true,
      }));

      setActiveMic(targetTextArea);
    } catch (error) {
      console.error('Error generating transcript:', error);
    }
  };

  const endTranscription = (targetTextArea) => {
    if (socket.current) {
      socket.current.send(JSON.stringify({ terminate_session: true }));
      socket.current.close();
      socket.current = null;
    }

    if (recorder.current) {
      recorder.current.stopRecording();
      recorder.current = null;
    }

    setIsRecording((prevState) => ({
      ...prevState,
      [targetTextArea]: false,
    }));

    setActiveMic(null);
  };

  const handleMicClick = (targetTextArea) => {
    if (isConnecting) { 
     // console.log('Connecting, please wait...');
      return;
    }
    if (activeMic === targetTextArea) {
      endTranscription(targetTextArea);
    } else {
      if (activeMic) {
        endTranscription(activeMic);
      }
      generateTranscript(targetTextArea);
    }
  };

  const resetTranscription = (targetTextArea) => {
    setTranscripts((prevState) => ({
      ...prevState,
      [targetTextArea]: '',
    }));

    setIsRecording((prevState) => ({
      ...prevState,
      [targetTextArea]: false,
    }));

    if (recorder.current) {
      recorder.current.stopRecording();
      recorder.current = null;
    }
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }

    setActiveMic(null);
  };

  const handleTextChange = (e, field) => {
    setTranscripts({ ...transcripts, [field]: e.target.value });
  };

  useEffect(() => {
    return () => {
      if (recorder.current) {
        recorder.current.stopRecording(() => {
          recorder.current = null;
        });
      }
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);
  const handleSuggestionClickAllergies1 = (name) => { 
    const words = transcripts.allergies.trim().split(' ');
    words.pop();
    // words.push(name); 
    // setTranscripts({ ...transcripts, allergies: words.join(' ') + ' '}); 
    // setAllergiesSuggestions([]);
    if (!words.includes(name)) {
      // If there are existing words, add a comma before the new word
      const formattedText = words.length > 0 ? words.join(' ') + ', ' + name + ' ' : name;
    
      // Update the state with the formatted text
      setTranscripts({ ...transcripts, allergies: formattedText });
      setAllergiesSuggestions([]);
      if (inputRefeAllergiestxt.current) {
        inputRefeAllergiestxt.current.focus();
      } 
    } else {
      // If the word is already present, reset the suggestions
      setAllergiesSuggestions([]);
    }
    window.scrollTo(0, 0);
  };
  const handleTextChangeAllergies1 = (e) => {
    setTranscripts({ ...transcripts, allergies: e.target.value });    
    const AllergiesTxt =  e.target.value;
    setAllergiesData(AllergiesTxt);    
    const lastWord = AllergiesTxt.trim().split(' ').pop();
    const lastChar = AllergiesTxt.slice(-1);
    if (!isConnecting && lastWord.length >= 3 && lastWord.trim() !== '' && lastChar !== ' ') {
      fetchSuggestions(lastWord, setAllergiesSuggestions);
    } else {
      setAllergiesSuggestions([]);
    }
  }; 
  const handleSuggestionClickMedications = (name) => { 
    const words = transcripts.medications.trim().split(' ');
    words.pop();
    // words.push(name); 
    // setTranscripts({ ...transcripts, medications: words.join(' ') + ' '}); 
    // setMedicationsSuggestions([]);
    if (!words.includes(name)) {
      // If there are existing words, add a comma before the new word
      const formattedText = words.length > 0 ? words.join(' ') + ', ' + name + ' ' : name;
    
      // Update the state with the formatted text
      setTranscripts({ ...transcripts, medications: formattedText });
      setMedicationsSuggestions([]);
      if (inputRefeMedicationstxt.current) {
        inputRefeMedicationstxt.current.focus();
      } 
    } else {
      // If the word is already present, reset the suggestions
      setMedicationsSuggestions([]);
    }
    window.scrollTo(0, 0);
  };
  const handleTextChangeMedications1 = (e) => {
    setTranscripts({ ...transcripts, medications: e.target.value }); 
    const MedicationsTxt =  e.target.value;
    setMedicationsData(MedicationsTxt);  
    const lastWord = MedicationsTxt.trim().split(' ').pop();
    const lastChar = MedicationsTxt.slice(-1);
    if (!isConnecting && lastWord.length >= 3 && lastWord.trim() !== '' && lastChar !== ' ') {
      fetchSuggestionsdrugName(lastWord, setMedicationsSuggestions);
    } else {
      setMedicationsSuggestions([]);
    }
  }; 

  const handleSpeechRecognition = (field) => {
    if (listening && activeSpeech === field) {
      SpeechRecognition.stopListening();
      setActiveSpeech(null);
    } else {
      setActiveSpeech(field);
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  useEffect(() => {
    if (activeSpeech) {
      setTranscripts((prev) => ({ ...prev, [activeSpeech]: transcript }));
    }
  }, [transcript, activeSpeech]);

 
    const checkBMICal = async () => {
      const data = {
        user_id: JSON.parse(localStorage.getItem('UserId')),
      };
  
      // Here, you would replace 'apiEndpoint' with your actual API endpoint
      fetch(getBaseURL+'/bmi', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
  
        .then(response => response.json())
        .then(data => {
          if (data.status == 'success') {  
            setShowBMICal(data.bmi)
            setTxtLoader(false)
          }
          if (data.status == 'error') {
            setTxtLoader(false) 
          }
          // Optionally, you can redirect the user or show a success message
        })
        .catch((error) => { 
          // Optionally, you can display an error message to the user
        });
    };
    const generateReportSummary = async () => {
      try {
        const response = await fetch(`${getBaseURL}/api/medical/documents/summarize/${reportId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
    
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
    
        const data = await response.json();
    
        if (data.status === 'success') {
          // console.log(data); // Log the data received
        } else if (data.status === 'error') {
          // console.error('Error:', data.message); // Handle error message
        }
      } catch (error) {
        console.error('Fetch failed:', error.message); // Log fetch errors
      }
    };
    
  useEffect(() => {
    setTxtLoader(true)
    checkBMICal()
    const items = JSON.parse(localStorage.getItem('UserId'));    
    generateUniqueId(items)
    if (items) {
      setUserId(items);
    }
  }, []);
    

  const handleFilesChange = (event) => {
    const files = Array.from(event.target.files).slice(0, 5); // Accept up to 5 files
    // setUploadFiles(files);
    setUploadFiles(prevFiles => [...prevFiles, ...files]); // Append new files to the existing ones
    fileInputRefReport1.current.value = ''; 
  };
  const triggerFileUploadFiles = () => {
    fileInputRefReport1.current.click();
  };
  const handleDeleteFile = (index) => {
    const updatedFiles = [...uploadFiles];
    updatedFiles.splice(index, 1);
    setUploadFiles(updatedFiles);
    
  };
   
 
  // Handler to update the selected option state
  const handleOptionChangeSmoke = (event) => {
    setSelectedSmoking(event.target.value);
  };
  const handleOptionChangeAlcohol = (event) => {
    setSelectedAlcohol(event.target.value);
  };
  const handleOptionChangeExercise = (event) => {
    setSelectedExercise(event.target.value);
  };

  // Options array
  const SmokeOptions = [
    { value: 'Current Smoker', label: 'Current Smoker' },
    { value: 'Frequent Smoker', label: 'Frequent Smoker' },
    { value: 'Former Smoker', label: 'Former Smoker' },
    { value: 'Never', label: 'Never' },
  ];
  // Options array
  const AlcoholOptions = [
    { value: 'Occasional', label: 'Occasional' },
    { value: 'Frequent', label: 'Frequent' },
    { value: 'Never', label: 'Never' },
  ];
  // Options array
  const ExerciseOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];

  const handleTextChangeMedications = (event) => {
    setMedicationsData(event.target.value);
  };
  const handleFileChangeMedications = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setMedicationsFile(selectedFile);
      setFileNameMedications(selectedFile.name);
    } else {
      alert('Please select a PDF file.');
    }
  }; 
  const handleDeleteFileMedications = () => {
    setMedicationsFile(null);
    setFileNameMedications('');
    fileInputRefMedications.current.value = '';
  };
  const triggerFileInputMedications = () => {
    fileInputRefMedications.current.click();
  };
 
  
  const handleSuggestionClickMedicalCondition = (name) => { 
    const words = transcripts.medicalcondition.trim().split(' ');
    words.pop();
    // words.push(name);   
  // Check if the word is already present
if (!words.includes(name)) {
  // If there are existing words, add a comma before the new word
  const formattedText = words.length > 0 ? words.join(' ') + ', ' + name + ' ' : name;

  // Update the state with the formatted text
  setTranscripts({ ...transcripts, medicalcondition: formattedText });
  setMedicalConditionSuggestions([]);
  if (inputRefeMedicalConditionTxt.current) {
    inputRefeMedicalConditionTxt.current.focus();
  } 
} else {
  // If the word is already present, reset the suggestions
  setMedicalConditionSuggestions([]);
}
    // setTranscripts({ ...transcripts, medicalcondition: formattedText }); 
    // setTranscripts({ ...transcripts, medicalcondition: words.join(' ') + ' '}); 
    // setMedicalConditionSuggestions([]);
    window.scrollTo(0, 0);
  }; 
  const handleTextChangeMedicalCondition = (e) => {   
    setTranscripts({ ...transcripts, medicalcondition: e.target.value });     
    const valueTxt =  e.target.value;
    setMedicalConditionsData(valueTxt); 
    const lastWord = valueTxt.trim().split(' ').pop();
    const lastChar = valueTxt.slice(-1);
    if (!isConnecting && lastWord.length >= 3 && lastWord.trim() !== '' && lastChar !== ' ') {
      fetchSuggestionsMedicalCondition(lastWord, setMedicalConditionSuggestions);
    } else {
      setMedicalConditionSuggestions([]);
    }
    
  };
  const handleFileChangeMedicalCondition = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setMedicalConditionFile(selectedFile);
      setFileNameMedicalCondition(selectedFile.name);
    } else {
      alert('Please select a PDF file.');
    }
  };

  const handleDeleteFileMedicalCondition = () => {
    setMedicalConditionFile(null);
    setFileNameMedicalCondition('');
    fileInputRefMediConditions.current.value = '';
  };
  const triggerFileInputMedicalCondition = () => {
    fileInputRefMediConditions.current.click();
  };

  const handleTextChangePCP = (e) => {
    setTranscripts({ ...transcripts, pcp: e.target.value });     
    const valueTxt =  e.target.value; 
    setPCPData(valueTxt);     
    const lastWord = valueTxt.trim();
    const lastChar = valueTxt.slice(-1);
    if (!isConnecting && lastWord.length >= 3 && lastWord.trim() !== '' && lastChar !== ' ') {
      fetchSuggestionsPlace(lastWord, setPCPSuggestions);
    } else {
      setPCPSuggestions([]);
    }
  };
  
  const handleSuggestionClickPCP = (name) => {  
    const valueTxt = name.displayName.text +', '+ name.formattedAddress;
    const nameText = name.displayName.text; // Extract the name
    const addressText = name.formattedAddress; // Extract the address 
   
      setTranscripts({ ...transcripts,  
      pcpName: nameText, 
      pcpAddress: addressText,
      pcp: valueTxt});

    if (textareaRefPCP.current) {
      textareaRefPCP.current.focus();
    }    
    window.scrollTo(0, 0);
    setPCPSuggestions([]);
  }; 

  
  const handleTextChangePharmacy = (e) => {
    setTranscripts({ ...transcripts, pharmacy: e.target.value });     
    const valueTxt =  e.target.value; 
    setPharmacyData(valueTxt);     
    const lastWord = valueTxt.trim();
    const lastChar = valueTxt.slice(-1);
    if (!isConnecting && lastWord.length >= 3 && lastWord.trim() !== '' && lastChar !== ' ') {
      fetchSuggestionsPlace(lastWord, setPharmacySuggestions);
    } else {
      setPharmacySuggestions([]);
    }
  };
  
  const handleSuggestionClickPharmacy = (name) => {  
    const valueTxt1 = name.displayName.text +', '+ name.formattedAddress;
    const nameText = name.displayName.text; // Extract the name
    const addressText = name.formattedAddress;   
    setTranscripts({
      ...transcripts,
      pharmacyName: nameText,
      pharmacyAddress: addressText,
      pharmacy: valueTxt1,
    });
    if (textareaRefPharmacy.current) {
      textareaRefPharmacy.current.focus();
    }    
    window.scrollTo(0, 0);
    setPharmacySuggestions([]);
  }; 
 
  const handleTextChangeSocialHistory = (e) => {
    setTranscripts({ ...transcripts, socialhistory: e.target.value });     
    const valueTxt =  e.target.value;
    setSocialHistoryData(valueTxt);  
  };
  const handleFileChangeSocialHistory = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setSocialHistoryFile(selectedFile);
      setFileNameSocialHistory(selectedFile.name);
    } else {
      alert('Please select a PDF file.');
    }
  };
   
  const handleDeleteFileSocialHistory = () => {
    setSocialHistoryFile(null);
    setFileNameSocialHistory('');
    fileInputRefSocialHistory.current.value = '';
  };
  const triggerFileInputSocialHistory = () => {
    fileInputRefSocialHistory.current.click();
  };

  const handleSuggestionClickPSHistory = (name) => { 
    const words = transcripts.pshistory.trim().split(' ');
    words.pop();
    // words.push(name); 
    // setTranscripts({ ...transcripts, pshistory: words.join(' ') + ' '}); 
    // setPastSurgicalSuggestions([]);
      // Check if the word is already present
      if (!words.includes(name)) {
        // If there are existing words, add a comma before the new word
        const formattedText = words.length > 0 ? words.join(' ') + ', ' + name + ' ' : name;
      
        // Update the state with the formatted text
        setTranscripts({ ...transcripts, pshistory: formattedText });
        setPastSurgicalSuggestions([]);
        if (inputRefePShistorytxt.current) {
          inputRefePShistorytxt.current.focus();
        } 
      } else {
        // If the word is already present, reset the suggestions
        setPastSurgicalSuggestions([]);
      }
   
    window.scrollTo(0, 0);
  };
 

  const handleTextChangePSHistory = (e) => {
    setTranscripts({ ...transcripts, pshistory: e.target.value });     
    const valueTxt =  e.target.value;
    setPastSurgicalHistoryData(valueTxt);   
    const lastWord = valueTxt.trim().split(' ').pop();
    const lastChar = valueTxt.slice(-1);
    if (!isConnecting && lastWord.length >= 3 && lastWord.trim() !== '' && lastChar !== ' ') {
      fetchSuggestionsPastSurgical(lastWord, setPastSurgicalSuggestions);
    } else {
      setPastSurgicalSuggestions([]);
    }
  };
  const handleFileChangePSHistory = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setPSHistoryFile(selectedFile);
      setFileNamePSHistory(selectedFile.name);
    } else {
      alert('Please select a PDF file.');
    }
  };
 
  const handleDeleteFilePSHistory = () => {
    setPSHistoryFile(null);
    setFileNamePSHistory('');
    fileInputRefPSHistory.current.value = '';
    
  };
  const triggerFileInputPSHistory = () => {
    fileInputRefPSHistory.current.click();
  };
 

  const handleTextChangePhysicalExam = (e) => {
    setTranscripts({ ...transcripts, physicalexam: e.target.value });     
    const valueTxt =  e.target.value;
    setPhysicalExamData(valueTxt);   
  };
  const handleFileChangePhysicalExam = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setPhysicalExamFile(selectedFile);
      setFileNamePhysicalExam(selectedFile.name);
    } else {
      alert('Please select a PDF file.');
    }
  }; 
  const handleDeleteFilePhysicalExam = () => {
    setPhysicalExamFile(null);
    setFileNamePhysicalExam('');
    fileInputRefPhysicalExam.current.value = '';
  };
  const triggerFileInputPhysicalExam = () => {
    fileInputRefPhysicalExam.current.click();
  };

 
 
  const handleFileChangeAllergie = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setAllergiesFile(selectedFile);
      setFileNameAllergies(selectedFile.name);
    } else {
      alert('Please select a PDF file.');
    }
  };
   
  const handleDeleteFileAllergie = () => {
    setAllergiesFile(null);
    setFileNameAllergies('');
    fileInputRef.current.value = '';
  };
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
 
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    setShowLoader(true);

    const UserId = JSON.parse(localStorage.getItem('UserId'));

    const formAllergy = new FormData();
    formAllergy.append('user_id', UserId);
    if (AllergiesFile) formAllergy.append('file', AllergiesFile);
    if (transcripts.allergies) formAllergy.append('text', transcripts.allergies);
    if (reportId) formAllergy.append('reports_id', reportId);
     

    // const formPhysicalExam = new FormData();
    // formPhysicalExam.append('user_id', UserId);
    // if (PhysicalExamFile) formPhysicalExam.append('file', PhysicalExamFile);
    // if (transcripts.physicalexam) formPhysicalExam.append('text', transcripts.physicalexam);
    // if (reportId) formPhysicalExam.append('reports_id', reportId);


    const formAPSHistory = new FormData();
    formAPSHistory.append('user_id', UserId);
    if (PSHistoryFile) formAPSHistory.append('file', PSHistoryFile);
    if (transcripts.pshistory) formAPSHistory.append('text', transcripts.pshistory);
    if (reportId) formAPSHistory.append('reports_id', reportId);


    const formMedicalCondition = new FormData();
    formMedicalCondition.append('user_id', UserId);
    if (MedicalConditionFile) formMedicalCondition.append('file', MedicalConditionFile);
    if (transcripts.medicalcondition) formMedicalCondition.append('text', transcripts.medicalcondition);
    if (reportId) formMedicalCondition.append('reports_id', reportId);


    const formMedications = new FormData();
    formMedications.append('user_id', UserId);
    if (MedicationsFile) formMedications.append('file', MedicationsFile);
    if (transcripts.medications) formMedications.append('text', transcripts.medications);
    if (reportId) formMedications.append('reports_id', reportId);

    const formSocialHistory = new FormData();
    formSocialHistory.append('user_id', UserId);
    if (SocialHistoryFile) formSocialHistory.append('file', SocialHistoryFile);
    if (transcripts.socialhistory) formSocialHistory.append('text', transcripts.socialhistory);
    if (reportId) formSocialHistory.append('reports_id', reportId);
 
    const formPCPData = new FormData();
    formPCPData.append('user_id', UserId); 
    if (transcripts.pcp) formPCPData.append('text', transcripts.pcp);
    if (reportId) formPCPData.append('reports_id', reportId);
 

    const formUploadFiles = new FormData();
    formUploadFiles.append('user_id', UserId); 
    if (reportId) formUploadFiles.append('reports_id', reportId);
    if (uploadFiles.length != 0) {
    uploadFiles.forEach((file, index) => {
      formUploadFiles.append(`file`, file);
    }); 
  }
 
    const apiCalls = [];

    if (AllergiesFile || transcripts.allergies) {
      apiCalls.push(fetch(getBaseURL+'/medical_content/allergy', {
        method: 'POST',
        body: formAllergy,
      }));
    }
    if (MedicationsFile || transcripts.medications) {
      apiCalls.push(fetch(getBaseURL+'/medical_content/medications', {
        method: 'POST',
        body: formMedications,
      }));
    }
    if (MedicalConditionFile || transcripts.medicalcondition) {
      apiCalls.push(fetch(getBaseURL+'/medical_content/medical_conditions', {
        method: 'POST',
        body: formMedicalCondition,
      }));
    }
    if (PSHistoryFile || transcripts.pshistory) {
      apiCalls.push(fetch(getBaseURL+'/medical_content/past_surgical_history', {
        method: 'POST',
        body: formAPSHistory,
      }));
    }
    if (SocialHistoryFile || transcripts.socialhistory) {
      apiCalls.push(fetch(getBaseURL+'/medical_content/social_history', {
        method: 'POST',
        body: formSocialHistory,
      }));
    }
    // if (PhysicalExamFile || transcripts.physicalexam) {
    //   apiCalls.push(fetch(getBaseURL+'/medical_content/physical_exam', {
    //     method: 'POST',
    //     body: formPhysicalExam,
    //   }));
    // }
    const payload_PCP_Pharmacy = {
      pcp: {
        name: transcripts.pcpName,
        phone: "",
        address: transcripts.pcpAddress,
        email: "",
      },
      pharmacy: {
        name: transcripts.pharmacyName,
        phone: "",
        address: transcripts.pharmacyAddress,
        email: "",
      },
    }; 
    if (transcripts.pcp || transcripts.pharmacy) {      
      apiCalls.push(
        fetch(`${getBaseURL}/api/patient/create/details/pcp-pharmacy/${reportId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload_PCP_Pharmacy),
        })
      );
    }

  
    if (uploadFiles.length != 0) {
      apiCalls.push(fetch(getBaseURL+'/medical_content/upload_report', {
        method: 'POST',
        body: formUploadFiles,
      }));
    }

    try {
      const responses = await Promise.all(apiCalls); 

      // Check if all API calls were successful
      const allResponsesSuccessful = responses.every(response => response.status === 200); 
      if(responses.length > 0 ){
            if (allResponsesSuccessful) { 
              generateReportSummary()
              setApiCallCount(prevCount => prevCount + responses.length); 
              toast.success('Information uploaded successfully!', {
                position: toast.POSITION.TOP_RIGHT,
               });
              routeChange()
              // Handle successful responses as needed
            } else {  
              toast.error('Information upload failed!', {
                position: toast.POSITION.TOP_RIGHT,
               });
              // Handle failed responses as needed
            } 
        }else{ 
          toast.error('Update required any one information!', {
            position: toast.POSITION.TOP_RIGHT,
           }); 
        }
    } catch (error) { 
      alert('Error during API calls');
      // Handle the error as needed
    } finally {
      setShowLoader(false);
    }
  };

  // Function to handle scrolling to the top of the textarea
  const handleScrollUp = (ref) => {
    if (ref.current) { 
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); 
    }
  };
 
  return (

    <main
      className="pt5 black-80 center stepFirstForm"
      style={{ maxWidth: "50%", margin: "20px auto" }}
    >
      

      <form onSubmit={handleSubmitForm} className="measure w-100 ">
        <h2>Please fill the details</h2>
        
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0 fillDetailsMainForm">
          <div className={"mt3 inputDiv fillDetailsForm " + (TxtLoader ? '' : 'disabledTxtField')}>
            <label>BMI</label>
            {TxtLoader ? (<>
              <div class="spinnerTxt">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div></>) : (
              <input type="text" value={ShowBMICal} readOnly />
            )}

          </div>
        
          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm" 
           onClick={() => handleScrollUp(textareaRefAllergies)}
           ref={textareaRefAllergies}
           >
            <label>Allergies</label>
            <textarea ref={inputRefeAllergiestxt} value={transcripts.allergies} onChange={handleTextChangeAllergies1} placeholder={activeMic === 'allergies' ? 'Mic On ' : ""}></textarea>
            <span className="attachmentSpan">
              <a onClick={() => handleMicClick('allergies')} className={(activeMic === 'allergies' ? 'deactiveBtnClass' : 'activeBtnClass')}
                disabled={isConnecting || activeMic === 'connecting'}
               >
                <img src={Icons[0].micIcon} alt="Mic" className="logoImg " />
              </a> 
              <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file"
                accept="application/pdf"
                onChange={handleFileChangeAllergie}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRef}
              />
              <a onClick={triggerFileInput}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            {fileNameAllergies && (
              <div className='attachmentListData'>
                <p>{fileNameAllergies}</p>
                <button type="button" onClick={handleDeleteFileAllergie} className='attachmentDeleteBtn'>
                  <img src={Icons[0].deleteIcon} className="logoImg " alt="" />
                </button>
              </div>
            )}
             <ul ref={suggestionListRef} className={'suggestionMedicineList ' + (allergiesSuggestions.length > 0 ? '' : 'd-none' )}>
                {allergiesSuggestions.map((name, index) => (
                  <li key={index} onClick={() => handleSuggestionClickAllergies1(name)}> 
                    {highlightMatch(name , transcripts.allergies)}
                  </li>
                ))}
              </ul>
          </div> 
          {errors.AllergiesData && <span  className="errorTxt" >{errors.AllergiesData}</span>}
 
          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm"  ref={textareaRefMedications} 
          onClick={() => handleScrollUp(textareaRefMedications)}>
            <label>Medications</label>
            <textarea ref={inputRefeMedicationstxt} value={transcripts.medications} onChange={handleTextChangeMedications1} placeholder={activeMic === 'medications' ? 'Mic On ' : ""}   ></textarea>
            <span className="attachmentSpan">
              <a onClick={() => handleMicClick('medications')}  
                disabled={isConnecting || activeMic === 'connecting'}
                className={(activeMic === 'medications' ? 'deactiveBtnClass' : 'activeBtnClass')} >
                <img  src={Icons[0].micIcon}  className="logoImg "   alt="Mic" />
              </a> 
              <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file"
                accept="application/pdf"
                onChange={handleFileChangeMedications}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRefMedications}
              />
              <a onClick={triggerFileInputMedications}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            {fileNameMedications && (
              <div className='attachmentListData'>
                <p>{fileNameMedications}</p>
                <button type="button" onClick={handleDeleteFileMedications} className='attachmentDeleteBtn'>
                  <img src={Icons[0].deleteIcon} className="logoImg " alt="" />
                </button>
              </div>
            )}
            <ul ref={suggestionListRef} className={'suggestionMedicineList ' + (medicationsSuggestions.length > 0 ? '' : 'd-none' )}>
                {medicationsSuggestions.map((name, index) => (
                  <li key={index} onClick={() => handleSuggestionClickMedications(name)}> 
                    {highlightMatch(name , transcripts.allergies)}
                  </li>
                ))}
              </ul>
          </div>
          {errors.MedicationsData && <span  className="errorTxt" >{errors.MedicationsData}</span>}

       
          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm"  ref={textareaRefMediConditions} 
          onClick={() => handleScrollUp(textareaRefMediConditions)}>
            <label>Medical Conditions</label>
            <textarea  ref={inputRefeMedicalConditionTxt}  value={transcripts.medicalcondition} onChange={handleTextChangeMedicalCondition} placeholder={activeMic === 'medicalcondition' ? 'Mic On ' : ""} ></textarea>
            <span className="attachmentSpan">
              <a onClick={() => handleMicClick('medicalcondition')}  disabled={isConnecting || activeMic === 'connecting'} className={ (activeMic === 'medicalcondition' ? 'deactiveBtnClass' : 'activeBtnClass')}> 
              <img src={Icons[0].micIcon}  className= "logoImg "   alt="" /></a>
              <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file" 
                accept="application/pdf"
                onChange={handleFileChangeMedicalCondition}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRefMediConditions}
              />
              <a onClick={triggerFileInputMedicalCondition}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            {fileNameMedicalCondition && (
              <div className='attachmentListData'>
                <p>{fileNameMedicalCondition}</p>
                <button type="button" onClick={handleDeleteFileMedicalCondition} className='attachmentDeleteBtn'>
                  <img src={Icons[0].deleteIcon} className="logoImg " alt="" />
                </button>
              </div>
            )}
         <ul ref={suggestionListRef} className={'suggestionMedicineList ' + (medicalConditionSuggestions.length > 0 ? '' : 'd-none' )}>
                {medicalConditionSuggestions.map((name, index) => (
                  <li key={index} onClick={() => handleSuggestionClickMedicalCondition(name)}> 
                    {highlightMatch(name , transcripts.allergies)}
                  </li>
                ))}
              </ul>
          </div>
          {errors.MedicalConditionsData && <span  className="errorTxt" >{errors.MedicalConditionsData}</span>}
 
          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm"  ref={textareaRefPSHistory} 
          onClick={() => handleScrollUp(textareaRefPSHistory)}>
            <label>Past Surgical History</label>
            <textarea  ref={inputRefePShistorytxt} value={transcripts.pshistory} onChange={handleTextChangePSHistory} placeholder={activeMic === 'pshistory' ? 'Mic On ' : ""} ></textarea>
            <span className="attachmentSpan">
              <a  onClick={() => handleMicClick('pshistory')}  disabled={isConnecting || activeMic === 'connecting'} className={(activeMic === 'pshistory' ? 'deactiveBtnClass' : 'activeBtnClass')}  > 
              <img src={Icons[0].micIcon}  className="logoImg "  alt="" /></a>
              <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file"
                accept="application/pdf"
                onChange={handleFileChangePSHistory}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRefPSHistory}
              />
              <a onClick={triggerFileInputPSHistory}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            {fileNamePSHistory && (
              <div className='attachmentListData'>
                <p>{fileNamePSHistory}</p>
                <button type="button" onClick={handleDeleteFilePSHistory} className='attachmentDeleteBtn'>
                  <img src={Icons[0].deleteIcon} className="logoImg " alt="" />
                </button>
              </div>
            )}
               <ul ref={suggestionListRef} className={'suggestionMedicineList ' + (pastSurgicalSuggestions.length > 0 ? '' : 'd-none' )}>
                {pastSurgicalSuggestions.map((name, index) => (
                  <li key={index} onClick={() => handleSuggestionClickPSHistory(name)}> 
                    {highlightMatch(name , transcripts.allergies)}
                  </li>
                ))}
              </ul>
          </div>
          {errors.PastSurgicalHistoryData && <span  className="errorTxt" >{errors.PastSurgicalHistoryData}</span>}

          
          {/* <div className="mt3 inputDiv fillDetailsForm newFillDetailForm" ref={textareaRefPhysicalExam} 
          onClick={() => handleScrollUp(textareaRefPhysicalExam)}>
            <label>Physical Exam</label>
            <textarea  value={transcripts.physicalexam} onChange={handleTextChangePhysicalExam} placeholder={activeMic === 'physicalexam' ? 'Mic On ' : ""} ></textarea>
            <span className="attachmentSpan">
              <a onClick={() => handleMicClick('physicalexam')} disabled={isConnecting || activeMic === 'connecting'}  className={ (activeMic === 'physicalexam' ? 'deactiveBtnClass' : 'activeBtnClass')}  > 
              <img src={Icons[0].micIcon}  className= "logoImg "   alt="" /></a>
              <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file"
                accept="application/pdf"
                onChange={handleFileChangePhysicalExam}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRefPhysicalExam}
              />
              <a onClick={triggerFileInputPhysicalExam}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            {fileNamePhysicalExam && (
              <div className='attachmentListData'>
                <p>{fileNamePhysicalExam}</p>
                <button type="button" onClick={handleDeleteFilePhysicalExam} className='attachmentDeleteBtn'>
                  <img src={Icons[0].deleteIcon} className="logoImg " alt="" />
                </button>
              </div>
            )}
          </div>
          {errors.PhysicalExamData && <span  className="errorTxt" >{errors.PhysicalExamData}</span>} */}
          
          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm"
          onClick={() => handleScrollUp(textareaRefSocialHistory)}>
            <label>Social History</label>
            <textarea  ref={textareaRefSocialHistory} value={transcripts.socialhistory} onChange={handleTextChangeSocialHistory} placeholder={activeMic === 'socialhistory' ? 'Mic On ' : ""}></textarea>
            <span className="attachmentSpan">
              <a onClick={() => handleMicClick('socialhistory')} disabled={isConnecting || activeMic === 'connecting'}   className={ (activeMic === 'socialhistory' ? 'deactiveBtnClass' : 'activeBtnClass')} > 
              <img src={Icons[0].micIcon}  className= "logoImg " alt="" /></a>
              <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file"
                accept="application/pdf"
                onChange={handleFileChangeSocialHistory}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRefSocialHistory}
              />
              <a onClick={triggerFileInputSocialHistory}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            {fileNameSocialHistory && (
              <div className='attachmentListData'>
                <p>{fileNameSocialHistory}</p>
                <button type="button" onClick={handleDeleteFileSocialHistory} className='attachmentDeleteBtn'>
                  <img src={Icons[0].deleteIcon} className="logoImg " alt="" />
                </button>
              </div>
            )}
          </div>
          {errors.SocialHistoryData && <span  className="errorTxt" >{errors.SocialHistoryData}</span>}
        
             <div className='d-flex gap-3' ref={textareaRefPCP}>
             <div className="mt3 inputDiv fillDetailsForm newFillDetailForm">
          <div className="  fillDetailsForm newFillDetailForm"
          onClick={() => handleScrollUp(textareaRefPCP)}>
            <label>Primary Care Physician (PCP)</label>
           
            <textarea className='w-100'  ref={textareaRefPCP} value={transcripts.pcp} 
           onChange={handleTextChangePCP} placeholder="Enter the name or address"></textarea>
           
           
          </div>
          <ul ref={textareaRefPCP} className={'suggestionMedicineList w-100 ' + (pcpSuggestions.length > 0 ? '' : 'd-none' )} 
              >
                {pcpSuggestions.map((name, index) => (
                  <li key={index} onClick={() => handleSuggestionClickPCP(name)}> 
                    {highlightMatch(name.displayName.text , transcripts.pcp)}
                    <p>{name.formattedAddress}</p>
                  </li>
                ))}
              </ul>
             </div>

          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm">
          <div className="  fillDetailsForm newFillDetailForm"
          onClick={() => handleScrollUp(textareaRefPharmacy)}>
            <label> Pharmacy information</label>
            <textarea className='w-100' ref={textareaRefPharmacy} value={transcripts.pharmacy} 
            onChange={handleTextChangePharmacy} placeholder="Enter the name or address"></textarea>
          </div>
          <ul ref={textareaRefPharmacy} className={'suggestionMedicineList w-100 ' + (pharmacySuggestions.length > 0 ? '' : 'd-none' )} 
              >
                {pharmacySuggestions.map((name, index) => (
                  <li key={index} onClick={() => handleSuggestionClickPharmacy(name)}> 
                    {highlightMatch(name.displayName.text , transcripts.pharmacy)}
                    <p>{name.formattedAddress}</p>
                  </li>
                ))}
              </ul>
             </div>
       </div>
          <div className="mt3 inputDiv fillDetailsForm newFillDetailForm uploadFilelistMain">
            <label>Upload Report (Max. file size 15 MB)</label> 
            <span className="attachmentSpan">
               <label style={{ display: 'none' }} htmlFor="file">Upload PDF:</label>
              <input
                type="file"
                id="file"
                accept="application/pdf"
                multiple
                onChange={handleFilesChange}
                style={{ display: 'none' }} // Hide the default file input
                ref={fileInputRefReport1}
              />
              <a onClick={triggerFileUploadFiles}  >
                <img src={Icons[0].attachIcon} className="logoImg " alt="" />
              </a>
            </span>
            <div className='attachmentListData '>
            <table>
             {uploadFiles.map((file, index) => (
                <tr key={index} className='uploadFilelistOpt'  >
                  <td>{file.name}</td>
                  <td><button type="button" className='attachmentDeleteBtn' onClick={() => handleDeleteFile(index)}><img src={Icons[0].deleteIcon} className="logoImg " alt="" /></button></td>
                </tr>
              ))}
              </table>
          </div>
          </div>
        </fieldset>
        <div>
          {ShowLoader ? (<>
            <button className="signInBtn mb-0" > Loading
              <div className="spinner-border text-light  " role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </button>
          </>) : (<>
            <button className="signInBtn mb-0" type="submit" >Next</button>
          </>)}
 
    
        </div>
      </form>
    </main>
  );
}

export default PageTwo;