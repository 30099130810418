import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify"; 
import axios from 'axios';
import { Icons } from "../../assest/icons";
import Header from './header';
import SideBarMenu from './sideBarMenu';
import moment from 'moment'; 
import LoaderSpinner from '../loaderSpinner';
import {getBaseURL} from '../baseUrl';
import { SearchContext } from './SearchContext'; 
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from "html-to-pdf-js"; 
import {  PDFDocument, rgb, StandardFonts } from "pdf-lib"; 
import generatePDF, { Resolution, Margin, Options } from "react-to-pdf";
// import PDFViewer from './PDFViewer';

import HeightFormatter, { formatHeight } from '../HeightFormatter';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AbdominalCT from '../../assest/document/AIGICare_PDF_AbdominalCT.pdf';
import AbdominalUS from '../../assest/document/AIGICare_PDF_AbdominalUS.pdf';
import AnemiaLabs from '../../assest/document/AIGICare_PDF_AnemiaLabs.pdf';
import BariumStudies from '../../assest/document/AIGICare_PDF_BariumStudies.pdf';
import BasicGastroLabs from '../../assest/document/AIGICare_PDF_BasicGastroLabs.pdf';
import ColonOrder from '../../assest/document/AIGICare_PDF_Colon_Order.pdf';
import Colonoscopy from '../../assest/document/AIGICare_PDF_Colonoscopy.pdf';
import ColonScreeningGuidelines from '../../assest/document/AIGICare_PDF_ColonScreeningGuidelines.pdf';
import Diverticulosis from '../../assest/document/AIGICare_PDF_Diverticulosis.pdf';
import EGDOrder from '../../assest/document/AIGICare_PDF_EGD_Order.pdf';
import FattyLiver from '../../assest/document/AIGICare_PDF_FattyLiver.pdf';
import FODMAP_Diet from '../../assest/document/AIGICare_PDF_FODMAP_Diet.pdf';
import GERD_Diet_and_Lifestyle from '../../assest/document/AIGICare_PDF_GERD_Diet_and_Lifestyle.pdf';
import HeapticLabs from '../../assest/document/AIGICare_PDF_HeapticLabs.pdf';
import High_Fiber_Diet from '../../assest/document/AIGICare_PDF_High_Fiber_Diet.pdf';
import InflammatoryBowelDisease from '../../assest/document/AIGICare_PDF_InflammatoryBowelDisease.pdf';
import IrritableBowelSyndrome from '../../assest/document/AIGICare_PDF_IrritableBowelSyndrome.pdf';
import ProceduralSedation from '../../assest/document/AIGICare_PDF_ProceduralSedation.pdf';
import StoolStudies from '../../assest/document/AIGICare_PDF_StoolStudies.pdf';
import StressManagement from '../../assest/document/AIGICare_PDF_StressManagement.pdf';
import TelehealthDisclaimer from '../../assest/document/AIGICare_PDF_TelehealthDisclaimer.pdf';
import UpperGIEndoscopy from '../../assest/document/AIGICare_PDF_UpperGIEndoscopy.pdf';
import Weight_Reducing_Diet from '../../assest/document/AIGICare_PDF_Weight_Reducing_Diet.pdf';

 



// Set the app element for accessibility (react-modal requirement)
Modal.setAppElement('#root');
 

const AccordionSection = ({ title, content, onEditClick, onSaveClick, onCancelClick, showLoader, isEditing, value, onChange, EmergencyQuest, Icons, isActive,
  setActiveAccordion}) => {
   
  // Sanitize the title to make it a valid selector
  const sanitizedTitle = title.replace(/\s+/g, '_'); // Replace spaces with underscores
 
  const handleAccordionClick = () => {
    // Toggle the accordion: close it if already active, otherwise open it
    if (setActiveAccordion) {
      setActiveAccordion(isActive ? null : sanitizedTitle);
    }
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`heading-${sanitizedTitle}`}>
        <button
         className={`accordion-button ${isActive ? '' : 'collapsed'}`}
         type="button"
         onClick={(e) => { 
          e.preventDefault(); // Prevent default button behavior
          handleAccordionClick(); // Explicitly call the handler
         }}
         aria-expanded={isActive ? 'true' : 'false'}
         aria-controls={`collapse-${sanitizedTitle}`}
        >
          {title}
        </button>
      </h2>
      <div
           id={`collapse-${sanitizedTitle}`}
           className={`accordion-collapse collapse ${isActive ? 'show' : ''}`}
           aria-labelledby={`heading-${sanitizedTitle}`}
        data-bs-parent="#accordionExampleNew"
      >
        <div className="accordion-body">
          
        {!EmergencyQuest && (
            <a
              onClick={onEditClick}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              className="editBtnInner"
            >
              Edit <img src={!isEditing ? Icons[0].editIcon : Icons[0].editRed} />
            </a>
          )}

          {!isEditing ? (
            <pre className="innerDivBoxPC h-auto overflow-hidden">
              {content}
            </pre>
          ) : (
            <div className="textareaSummary innerDivBox w-100">
              <textarea
                value={value}
                onChange={onChange}
                style={{ height: '80%', width: '100%' }}
              />
              <hr />
              <div className="editSaveOpt">
                {showLoader ? (
                  <>
                    <button> Loading
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={onSaveClick}>Save Changes</button>
                  </>
                )}
                <button onClick={onCancelClick}>Cancel</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AccordionSectionPoC = ({ title, content, onEditClick, onSaveClick, onCancelClick, showLoader, isEditing, value, onChange, EmergencyQuest, Icons, isActive,
  setActiveAccordionPoc }) => {
  // Sanitize the title to make it a valid selector
  const sanitizedTitle = title.replace(/\s+/g, '_'); // Replace spaces with underscores

  const handleAccordionClickPoc = () => {
    // Toggle the accordion: close it if already active, otherwise open it
    if (setActiveAccordionPoc) {
      setActiveAccordionPoc(isActive ? null : sanitizedTitle);
    }
  };
 


  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={`headingPoc-${sanitizedTitle}`}>
        <button
          className={`accordion-button ${isActive ? '' : 'collapsed'}`}
          type="button"
          onClick={(e) => { 
           e.preventDefault(); // Prevent default button behavior
           handleAccordionClickPoc(); // Explicitly call the handler
          }}
          aria-expanded={isActive ? 'true' : 'false'}
          aria-controls={`collapse-${sanitizedTitle}`}
        >
          {title}
        </button>
      </h2>
      <div
        id={`collapse-${sanitizedTitle}`}
        className={`accordion-collapse collapse ${isActive ? 'show' : ''}`}
        aria-labelledby={`heading-${sanitizedTitle}`}
        data-bs-parent="#accordionExample"
 
      >
        <div className="accordion-body">
          
        {!EmergencyQuest && (
            <a
              onClick={onEditClick}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
              className="editBtnInner"
            >
              Edit <img src={!isEditing ? Icons[0].editIcon : Icons[0].editRed} />
            </a>
          )}

          {!isEditing ? (
            <pre className="innerDivBoxPC h-auto overflow-hidden">
              {content}
            </pre>
          ) : (
            <div className="textareaSummary innerDivBox w-100">
              <textarea
                value={value}
                onChange={onChange}
                style={{ height: '80%', width: '100%' }}
              />
              <hr />
              <div className="editSaveOpt">
                {showLoader ? (
                  <>
                    <button> Loading
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={onSaveClick}>Save Changes</button>
                  </>
                )}
                <button onClick={onCancelClick}>Cancel</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


function ViewPatientProfile() {  
    let navigate = useNavigate();
    let navigate1 = useNavigate();
    
   

    const routeChange = () => { 
      navigate('/admin/dashboard', {
         state: {backCurrentPage: backCurrentPage, searchTxt: searchTxtCheck } 
      }); 
    }
    const location = useLocation(); 
    const scrollupRef = useRef(null); // Create ref for scroll-up element
  const [active, setActive] = useState(''); // Track active accordion section

  const handleAccordionClick = (title) => {
    // Scroll to the top of the page or a specific section
    if (scrollupRef.current) {
      scrollupRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start', // Align to the top of the viewport
      });
    }
  };
  const handleAccordionClickPoc = (title) => {
    // Scroll to the top of the page or a specific section
    if (scrollupRef.current) {
      scrollupRef.current.scrollIntoView({
        behavior: 'smooth', // Smooth scrolling
        block: 'start', // Align to the top of the viewport
      });
    }
  };
    const { id, currentPageNo, searchString } = location.state || {}; 

    const [ShowLoaderPage, setShowLoaderPage] = useState(false);
    const [userid, setuserid] = useState(id); 
    const [adminId, setAdminId] = useState(JSON.parse(localStorage.getItem('UserIdAdmin'))); 

    const [searchTxtCheck, setSearchTxtCheck] = useState(searchString); 

    const [backCurrentPage, setcurrentPageNo] = useState(currentPageNo);
    const [userProfileData, setUserProfileData] = useState([]);
    const [UserHistoryData, setUserHistoryData] = useState([]);
    const [UserHistoryNumber, setUserHistoryNumber] = useState('');
    const [ChatHistory, setChatHistory] = useState([]);
    const [RecordData, setRecordData] = useState([]);  
    const [BackReload, setBackReload] = useState(false);   
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [shareEmail, setShareEmail] = useState([]);
  const [emailList, setEmailList] = useState("");
  const [responseEmail, setResponseEmail] = useState(null);
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [isLoadingEmailP, setIsLoadingEmailP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userProfile, setUserProfile] = useState('');  
  const [specialty, setSpecialty] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState(''); 
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCD, setIsEditingCD] = useState(false);
  const [isEditingFollowCD, setIsEditingFollowCD] = useState(false);
  const [isEditingFollowPoC, setIsEditingFollowPoC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(DOBTxt);
  const ageCal = moment().diff(DOBTxt, 'years'); 
  const [BMITxt, setBMITxt] = useState('');   

  const [AgeTxt, setAgeTxt] = useState(ageCal);  
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');
  const [ShowLoader, setShowLoader] = useState(false); 
  const [ShowLoaderSummaryAIChatbot, setShowLoaderSummaryAIChatbot] = useState(false); 
  const [ShowLoaderZoomSummary, setShowLoaderZoomSummary] = useState(false); 
  const [ShowLoaderSummaryFollowup, setShowLoaderSummaryFollowup] = useState(false); 

  const [UserId, setUserId] = useState(userid);  
  const [showCalendar, setShowCalendar] = useState(false);  
  const [isValid, setIsValid] = useState(false);
  const [isSession, setIsSession] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [showSummay, setShowSummary] = useState(false);
  const totalPages = Math.ceil(UserHistoryData.length / recordsPerPage);
  const totalPageGroups = Math.ceil(totalPages / 3);
  const currentPageGroup = Math.ceil(currentPage / 3);

  const [illnessesList, setIllnessesList] = useState([]);
  const [selectedIllness, setSelectedIllness] = useState(null);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [EmergencyQuest, setEmergencyQuest] = useState(false);
   
  const [activeTab, setActiveTab] = useState(0);
  const { searchQuery } = useContext(SearchContext);
 
  const [MainPointsDiscussed, setMainPointsDiscussed] = useState('');
  const [PlanOnCareData, setPlanOnCareData] = useState('');
  const [ConditionalDiagnosis, setConditionalDiagnosis] = useState('');
  const [followConditionalDiagnosis, setFollowConditionalDiagnosis] = useState('');
  const [followPlanOnCareData, setFollowPlanOnCareData] = useState('');
  const [zoomPoC, setZoomPoC] = useState('');
  const [zoomCD, setZoomCD] = useState('');

 

  const [followSummaryEditFollowup, setFollowSummaryEditFollowup] = useState('');
  const [followSummaryEditAIChatbot, setFollowSummaryEditAIChatbot] = useState('');
  const [editZoomSummary, setEditZoomSummary] = useState('');
  
  const [summaryEditFollowup, setSummaryEditFollowup] = useState('');
  
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
 
  const [SessionIDCheck, setSessionIDCheck] = useState('');
  const [reportData, setReportData] = useState(null);
  const [PCP_Pharmacy, setPCP_Pharmacy] = useState(null);

  const [myinsuranceinfo, setMyinsuranceinfo] = useState([]);

  
const [documents, setDocuments] = useState([]); // Document list state
const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
const [isModalPhysicalExamOpen, setIsModalPhysicalExamOpen] = useState(false); // Modal open/close state
const [selectedDocument, setSelectedDocument] = useState(null); // Track the selected document
const [isModalOpenViewAll, setIsModalOpenViewAll] = useState(false); // Modal open/close state

const [SessionID, setSessionID] = useState('');

const [patientbpMMTxt, setpatientbpMMTxt] = useState('');
const [bpMMTxt, setbpMMTxt] = useState('');
const [bpHgTxt, setbpHgTxt] = useState('');
const [heartrateTxt, setheartrateTxt] = useState('');

const [editbpMMTxt, seteditbpMMTxt] = useState('');
const [editbpHgTxt, seteditbpHgTxt] = useState('');
const [editheartrateTxt, seteditheartrateTxt] = useState('');

const [errorMessage, setErrorMessage] = useState('');
const [errorShare, setErrorShare] = useState(""); // To store validation errors
const [errors, setErrors] = useState({}); 
 
const bpMMRef = useRef(null);
const bpHgRef = useRef(null);
const hiddenDivRef = useRef(null); 

const [CheckBpMMTxt, setCheckBpMMTxt] = useState(bpMMTxt);
const [CheckBpHgTxt, setCheckBpHgTxt] = useState(bpHgTxt);
const [CheckHeartRateTxt, setCheckHeartRateTxt] = useState(heartrateTxt);

const [patientstatus, setPatientstatus] = useState('');

  const [isOpen, setIsOpen] = useState(false); // Track if the div is open
  const [selectedItem, setSelectedItem] = useState(null); // Button name
  const [activeItem, setActiveItem] = useState(null); // Track selected list item 
  const [isOpenPhysicalExam, setIsOpenPhysicalExam] = useState(false); // Track if the div is open
  const [checkPhysicalExam, setCheckPhysicalExam] = useState(false); // Track if the div is open
  const [filledCheckPhysicalExam, setfilledCheckPhysicalExam] = useState(false); // Track if the div is open

  // const [selectedItemFollowup, setSelectedItemFollowup] = useState(null); // Button name   
  const [isOpenFollowup, setIsOpenFollowup] = useState(false); // Track if the div is open
  const [checkFollowup, setCheckFollowup] = useState(false); // Track if the div is open
  const [filledCheckFollowup, setfilledCheckFollowup] = useState(false); // Track if the div is open
  const [followupDays, setFollowupDays] =  useState(new Date()); //useState('');
  const [followupTime, setFollowupTime] =  useState(new Date()); //useState('');
  const [followupDate, setFollowupDate] =  useState(new Date()); //useState('');
  const [futureDate, setFutureDate] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const now = new Date();
  const [tempBpMMTxt, setTempBpMMTxt] = useState(''); // Temporary Mm value 
  const [openDiv, setOpenDiv] = useState(null);

  
const [followupZoomMeet, setFollowupZoomMeet] = useState(false); 
const [checkFollowupMeet, setCheckFollowupMeet] = useState(false); 
const [checkAllFollowupMeet, setCheckAllFollowupMeet] = useState(false); 

const [selectedItemFollowup, setSelectedItemFollowup] = useState([]); // Followup Date 
const [FollowupData, setFollowupData] = useState([]); // Followup Date 
const [MeetingDuration, setMeetingDuration] = useState([]); // Followup Date 

const [activeOption, setActiveOption] = useState('Routine'); // State to track the active option
  
const [meetingStatusZoom, setMeetingStatusZoom] = useState(''); // Button name   
const [dataProcessingStatus, setDataProcessingStatus] = useState([]); // Button name  

const [selectedZoomMeet, setSelectedZoomMeet] = useState([]); // Button name   
const [isOpenZoomMeet, setIsOpenZoomMeet] = useState(false); // Track if the div is open
const [checkZoomMeet, setCheckZoomMeet] = useState(false); // Track if the div is open 
const [meetingTitle, setMeetingTitle] = useState('AIGI Online Consultation');
const [duration, setDuration] = useState(0);  
const [startTime, setStartTime] = useState(new Date());
const [startDate, setStartDate] = useState(new Date());

const [activeSpeech, setActiveSpeech] = useState(null);
const [transcripts, setTranscripts] = useState({ medicalcondition: ''});
const [MedicalConditionsData, setMedicalConditionsData] = useState('');

const [CheckExpiry, setCheckExpiry] = useState(false);  

const [activeKey, setActiveKey] = useState(null);
// const [Icons, setIcons] = useState([{ editIcon: 'editIcon', editRed: 'editRed', admin_icon911: 'admin_icon911' }]);
 
const [editMode, setEditMode] = useState(null); // Track which follow-up is in edit mode
const [editText, setEditText] = useState(''); // Track the current text being edited


const handleOptionClick = (option) => {
  setActiveOption(option); // Update the active option
};
  
const [activeAccordion, setActiveAccordion] = React.useState(null); 
const [editingAccordion, setEditingAccordion] = useState(null);
const [updatedContent, setUpdatedContent] = useState({}); // Object to track updates for each accordion
 

const handleAccordionToggle = (title) => {
  setActiveAccordion((prev) => (prev === title ? null : title));
};

const [activeAccordionPoc, setActiveAccordionPoc] = React.useState(null);

const handleAccordionTogglePoc = (title) => {
  setActiveAccordionPoc((prev) => (prev === title ? null : title));
};

useEffect(() => {
  // Determine which tab should be active by default
  const followUpExists = FollowupData?.some((item) => item.followup_CD);
  if (followUpExists) {
    setActiveAccordion('Follow up - Differential Diagnosis'); // Set sanitized title for follow-up
  } else {
    setActiveAccordion('Differential Diagnosis'); // Set sanitized title for default tab
  }

  const followUpExistsPoc = FollowupData?.some((item) => item.followup_PoC);
  if (followUpExistsPoc) {
    setActiveAccordionPoc('Follow up - Plan of Care'); // Set sanitized title for follow-up
  } else {
    setActiveAccordionPoc('Plan of Care'); // Set sanitized title for default tab
  }

}, [FollowupData]);



 

const checkOrderList = [
  { Name: "Allergies", doc_type: "allergy" },
  { Name: "Medications", doc_type: "medications" },
  { Name: "Medical Conditions", doc_type: "medical_conditions" },
  { Name: "Past Surgical History", doc_type: "past_surgical_history" },
  { Name: "Social History", doc_type: "social_history" },
  { Name: "Primary Care Physician (PCP)", doc_type: "pcp" },
  { Name: "Pharmacy information", doc_type: "pharmacy" },
  { Name: "Upload Reports", doc_type: "other_report" }
];
const options = [
  'Test Results',
  'Refills',
  'Recall Colon',
  'Recall ECD',
  'Routine',
  'Persistant Symptoms',
];
 


 // console.log(filledCheckPhysicalExam +' '+ checkPhysicalExam )

  // Open modal and set selected document
const openModalViewAll = () => {    
  // navigate1('/admin/fullreport', {
  //   state: {sessionid: SessionIDCheck } 
  // }); 

  setOpenDiv(null)
  setIsModalOpenViewAll(true);
};

  const closeModalViewAll = () => {
    setIsModalOpenViewAll(false); 
  };
  const toggleDivFollowup = () => {
    // handleUserRemove()
    setCheckFollowup(false);    
    setOpenDiv(openDiv === 'Followup' ? null : 'Followup');
  };
 
  // Handle cancel button click
  const closeFollowup = () => { 
      setCheckFollowup(false); // Close the div without saving  
      setOpenDiv('') 
      setErrors({});       
      setErrorMessage(''); 
  };
   // Handle cancel button click
   const handleCancelFollowup = () => {
    setActiveOption('Routine')
    setIsOpenFollowup(false); // Close the div without saving 
      // Reset temporary input values 
      setFollowupDays(''); 
      setFutureDate('');
      setOpenDiv('')
      // Clear any errors
      setErrors({}); 
      setErrorMessage('');
      setLoading(false);
  };

  const handleDaysChange = (e) => {
    const days = e.target.value; 
    // Validate if input is a number
    if (/^\d*$/.test(days)) {
      setFollowupDays(days);
      setErrors({}); 
      setErrorMessage(''); // Clear error if valid input
  
      // Calculate future date only if days is a valid number
      if (days && !isNaN(days)) { 
        const today = new Date();
        today.setDate(today.getDate() + parseInt(days, 10)); // Use parseInt with radix 10
        setFutureDate(today.toISOString().split('T')[0]); // Format as yyyy-mm-dd
        calculateRemainingDays(today);
      } else {
        setFutureDate(null); // Reset if input is invalid
      }
    } else {
      setErrorMessage('Please enter a valid number of days');
      setFollowupDays(''); // Clear the invalid input
      setFutureDate(null); // Reset future date if input is invalid 
    }
  }; 

   // Calculate remaining days based on futureDate
   const calculateRemainingDays = (targetDate) => {
    const currentDate = new Date();
    const differenceInTime = targetDate - currentDate;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    setRemainingDays(differenceInDays);
  };

  // // Set an interval to check remaining days every 24 hours
  // useEffect(() => {
  //   if (futureDate) {
  //     const interval = setInterval(() => {
  //       calculateRemainingDays(new Date(futureDate));
  //     }, 24 * 60 * 60 * 1000); // 24 hours

  //     // Cleanup interval on unmount or if futureDate changes
  //     return () => clearInterval(interval);
  //   }
  // }, [futureDate]);
  const handleFollowTimeChange = (time) => {
    setFollowupTime(time);
  };
  
  const handleFollowDateChange = (date) => {
    setFollowupDate(date);

    const now = new Date();
  
    // Check if the selected date is today
    if (date.toDateString() === now.toDateString()) {
      // Set time to the current time or the next available interval
      setFollowupTime(new Date(date.setHours(now.getHours(), now.getMinutes(), 0, 0)));
    } else {
      // Set time to 9:00 AM for other dates
      setFollowupTime(new Date(date.setHours(9, 0, 0, 0)));
    }    
  
  };
  
  
  const handleUserRemove = async () => { 
    const userIdRemove = "6763fe9099f425d8470e827b"; 
    // fetch(getBaseURL+'/delete_by_id/'+userIdRemove, {
      fetch(getBaseURL+'/delete_by_id/'+userIdRemove, {
     
      method: 'DELETE',  
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){ 
      } 
    })
    .catch((error) => {
      // console.log('Error:', error);
    });
    
  };

  
const handleTextChangeMedicalCondition = (e) => {   
  setTranscripts({ ...transcripts, medicalcondition: e.target.value });     
  const valueTxt =  e.target.value;
  setMedicalConditionsData(valueTxt);    
};

  // Handle form submission
  const handleSaveFollowup = async (event) => { 
    event.preventDefault();
    setLoading(true);
    const startdatetime = formatDateTimeForAPI(followupDate, followupTime);  
  
  
  
    setShowLoader(true);
    const topicTxt = checkAllFollowupMeet? selectedItemFollowup.title : activeOption;

    const data = {
      admin_id: adminId,
      session_id: SessionIDCheck,
      user_id: userid, 
      topic: topicTxt,
      start_time: startdatetime,
      attendee: email, 
      description: transcripts.medicalcondition,
    }; 
    try { 
      const response = await fetch(`${getBaseURL}/api/zoom/followup/create/meeting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = response.headers.get('Content-Type').includes('application/json')
      ? await response.json()
      : {}; 
    if (response.ok) { 
      toast.success(result.message || 'Data updated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedItemFollowup(result);
      if (result.zoom_meeting && Object.keys(result.zoom_meeting).length > 0) {
        setFollowupZoomMeet(true);
      }
  
      // Reset form
      setActiveOption('Routine');
      setFollowupDays('');
      setFutureDate('');
      setOpenDiv(null);
      setErrors({});
    } else {
      toast.error(result.message || 'An unexpected error occurred.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.error('Error occurred:', error);
    toast.error(error.message  || 'An unexpected error occurred. Please try again.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    setLoading(false);
    setShowLoader(false);
  }
};
  // "data_processing_status": false,
  // "meeting_status": "deactive",

 
  const handleDateChange = (date) => {
    setStartDate(date);

    const now = new Date();
  
    // Check if the selected date is today
    if (date.toDateString() === now.toDateString()) {
      // Set time to the current time or the next available interval
      setStartTime(new Date(date.setHours(now.getHours(), now.getMinutes(), 0, 0)));
    } else {
      // Set time to 9:00 AM for other dates
      setStartTime(new Date(date.setHours(9, 0, 0, 0)));
    }
  };

 
  const handleTimeChange = (time) => {
    setStartTime(time);
  };

  // Ensure `startTime` stays on the same day as `startDate`
  useEffect(() => {
    if (startDate && startTime && startDate.toDateString() !== startTime.toDateString()) {
      setStartTime(new Date(startDate.setHours(startTime.getHours(), startTime.getMinutes())));
    }
  }, [startDate]);


  const validateForm = () => {
    const newErrors = {};
    if (!meetingTitle) newErrors.meetingTitle = 'Meeting title is required';
    if (!startDate) newErrors.startDate = 'Meeting date is required';
    if (!startTime) newErrors.startTime = 'Meeting time is required';
   
    setErrors(newErrors);
    setLoading(false);
    return Object.keys(newErrors).length === 0;
  };

  const formatDateTimeForAPI = (date, time) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

 
  const handleSaveZoomInvite = async (event) => {
    event.preventDefault();
  
    const startdatetime = formatDateTimeForAPI(startDate, startTime);
  
    if (!validateForm()) return;
  
    setLoading(true);
    setShowLoader(true);
  
    const data = {
      admin_id: adminId,
      session_id: SessionIDCheck,
      user_id: userid,
      topic: meetingTitle,
      start_time: startdatetime,
      duration: 45,
      attendee: email,
      agenda: meetingTitle,
    };
  
    try {
      const response = await fetch(`${getBaseURL}/api/zoom/create/meeting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        // Handles 200 and 201
        toast.success(result.message || 'Data updated successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
  
        setSelectedZoomMeet(result.zoom_meeting || {}); // Update state
        setMeetingStatusZoom(result.zoom_meeting?.meeting_status || ''); // Safely access status
  
        // Reset the form
        setOpenDiv(null);
        setErrors({});
        setLoading(false);
        setShowLoader(false);
        setMeetingTitle('');
      } else {
        // Handles unexpected status codes like 404
        toast.error(result.message || 'An unexpected error occurred.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        setShowLoader(false);
      }
    } catch (error) {
      // Handles network errors or unexpected issues
      console.error('Error:', error);
      toast.error(error.message || 'Network error. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setShowLoader(false);
    }
  };

  
  const toggleDivZoomMeet = () => {
    setCheckZoomMeet(false);    
    setOpenDiv(openDiv === 'ZoomMeet' ? null : 'ZoomMeet');
  };
 
  // Handle cancel button click
  const closeZoomMeet = () => {
    setCheckFollowup(false); // Close the div without saving  
      setOpenDiv('') 
      setErrors({});       
      setErrorMessage(''); 
  };
   // Handle cancel button click
   const handleCancelZoomMeet = () => {
    // const now = new Date();
       setIsOpenFollowup(false); // Close the div without saving 
      // Reset temporary input values 
      // setZoomMeet('');  
      setOpenDiv('') 
      setErrors({}); 
      setErrorMessage('');
      setLoading(false); 
      // setSelectedZoomMeet(['']);
      setMeetingTitle('');
      setStartDate(now); // set current date
      setStartTime(now); // set current time
      // setDuration(0);
      setErrors({});
  };
  

  const toggleDivPhysicalExam = () => {
    setCheckPhysicalExam(false);    
    setOpenDiv(openDiv === 'PhysicalExam' ? null : 'PhysicalExam');
  };
 
  // Handle cancel button click
  const closePhysicalExam = () => {
      setIsOpenPhysicalExam(false); // Close the div without saving  
      setOpenDiv('') 
      setErrors({});       
      setErrorMessage('');
  };
   // Handle cancel button click
   const handleCancelPhysicalExam = () => {
    setIsOpenPhysicalExam(false); // Close the div without saving 
      // Reset temporary input values
      // setTempBpMMTxt('');
      setbpHgTxt(editbpHgTxt);
      setbpMMTxt(editbpMMTxt);
      setheartrateTxt(editheartrateTxt);
      setOpenDiv('') 
      setErrors({}); 
      setErrorMessage('');
  };
 

const handleSubmit = async (event) => {  
  event.preventDefault(); 
  // Simple form validation
  let formErrors = {};
  setErrorMessage('');
 
  if (!(bpHgTxt || '').trim() || !(bpMMTxt || '').trim()) {
    formErrors.bpHgTxt = 'Please enter your blood pressure (Mm - Hg)';
  }  
  if (!(heartrateTxt || '').trim()) {
    formErrors.heartrateTxt = 'Please enter your heart rate';
  } 
  if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
  }
  setShowLoader(true)
 
  const hasChanged =  
  bpMMTxt !== CheckBpMMTxt ||  // Add blood pressure Mm check
  bpHgTxt !== CheckBpHgTxt ||  // Add blood pressure Hg check
  heartrateTxt !== CheckHeartRateTxt;  // Add heart rate check

if (!hasChanged) {
  // Redirect to another page if no changes
  setShowLoader(false); 
  return;
} 

  // If validation passes, send data to API
  const data = { 
    admin_id: adminId,
    session_id : SessionIDCheck,   
    user_id: userid,
    bp_mm: bpMMTxt,
    bp_hg: bpHgTxt,
    heartrate:heartrateTxt, 
};
// console.log(data)
// Here, you would replace 'apiEndpoint' with your actual API endpoint
fetch(getBaseURL+'/api/admin/update/physicalexam', {
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
})

.then(async (response) => {
  const result = await response.json(); // Parse JSON response

  if (response.status === 200) {
    // Success: Show success toast with the message from the API
    toast.success(result.message || "Data updated successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    }); 

    setbpMMTxt(bpMMTxt);
    setbpHgTxt(bpHgTxt);
    setheartrateTxt(heartrateTxt);

    seteditbpHgTxt(bpHgTxt);
    seteditbpMMTxt(bpMMTxt);
    seteditheartrateTxt(heartrateTxt); 

    setCheckPhysicalExam(true);   
    setIsOpenPhysicalExam(false);

    closePhysicalExam();
    // console.log(bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt)
    // Optionally store the data in localStorage
    localStorage.setItem('UserProfile', JSON.stringify(result));
    setShowLoader(false); // Hide loader
  } else if (response.status === 404) {
    // Handle 404 error with the message from the API
    toast.error(result.message || "User not found or metrics not updated.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    // Handle other error statuses
    toast.error("An unexpected error occurred. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
})
.catch((error) => {
  console.error('Error:', error);
  // Handle network or unexpected errors
  toast.error('Network error. Please try again later.', {
    position: toast.POSITION.TOP_RIGHT,
  });
  setShowLoader(false); // Hide loader on error
});
 
 
 
};

 
const handleInput = (e, nextRef, prevRef) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Convert value to an integer for validation
  const intValue = parseInt(value, 10);

  // Clear the error message when user types
  if (fieldName === "Mm" || fieldName === "Hg") {
    setErrors((prevErrors) => ({ ...prevErrors, bpHgTxt: '' }));
  }

  // Forward jump when the max length is reached
  if (value.length === e.target.maxLength) {
    // Blood Pressure Mm Validation (0-200)
    if (fieldName === "Mm") {
      if (intValue < 0 || intValue > 200 || isNaN(intValue)) {
        setErrorMessage("Mm value must be between 0 and 200");
        bpMMRef.current.focus();
        return;
      } else {
        setbpMMTxt(value);  // Set the bpMMTxt state
      }
    }

    // Blood Pressure Hg Validation (0-200)
    if (fieldName === "Hg") {
      if (intValue < 0 || intValue > 200 || isNaN(intValue)) {
        setErrorMessage("Hg value must be between 0 and 200");
        bpHgRef.current.focus();
        return;
      } else {
        setbpHgTxt(value);  // Set the bpHgTxt state
      }
    }

    // Clear error message if validation passes
    setErrorMessage("");
    if (nextRef) {
      nextRef.current.focus();
    }
  } 
  // Backward jump when "Backspace" is pressed and the input is empty
  else if (e.nativeEvent.inputType === "deleteContentBackward" && value.length === 0) {
    if (prevRef) {
      prevRef.current.focus();
    }
  }
};
  
const handleKeyDown = (e, nextRef, prevRef) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Right Arrow Key
  if (e.key === "ArrowRight") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }
    
    if (nextRef) {
      nextRef.current.focus();
    }
  }

  // Left Arrow Key
  if (e.key === "ArrowLeft") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    if (prevRef) {
      prevRef.current.focus();
    }
  }
};


// Open modal and set selected document
const openModalPhysicalExam = () => { 
  setIsModalPhysicalExamOpen(true);
};
 // Close modal and reset selected document
const closeModalPhysicalExam = () => {
  setIsModalPhysicalExamOpen(false); 
};

// Open modal and set selected document
const openModal = (doc) => {   
  const viewerUrl = `https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(doc)}`;
    setSelectedDocument(viewerUrl); 
    setIsModalOpen(true);
}; 


 

// Close modal and reset selected document
const closeModal = () => {
  setIsModalOpen(false);
  setSelectedDocument(null);
};

// Add document dynamically
const addDocument = (name, url) => {
  const newDocument = { name, url };
  setDocuments([...documents, newDocument]); // Add to the document list
};


  // Example list of items
  const items = ['New', 'Progress', 'Completed'];

  // Open or close the dropdown div
  // const toggleDiv = () => setIsOpen((prev) => !prev);
  // const toggleDiv = () => setIsOpen(true);
  const toggleDivStatus = () => {
    setOpenDiv(openDiv === 'Status' ? null : 'Status');
    setActiveItem(selectedItem)
  };
  // Handle item selection and update the button name
  const handleItemClick = (item) => {
     setActiveItem(item);// Update button name 
  };
 

  // Monitor input changes and set `checkPhysicalExam` when all values are filled
  useEffect(() => {
    if (bpHgTxt && bpMMTxt && heartrateTxt && tempBpMMTxt) {
      setCheckPhysicalExam(true); // All inputs are filled
      setfilledCheckPhysicalExam(true); // All inputs are filled
    }  
  }, [bpHgTxt, bpMMTxt, heartrateTxt]); // Runs whenever these values change

  // Save the selected item via API
  const handleSave = async () => {
    setLoading(true); // Start loading
    const data = { 
      admin_id: adminId,
      session_id : SessionIDCheck,   
      user_id: userid, 
      patientstatus:activeItem, 
  }; 
  // console.log(data)
    try {
      const response = await fetch(getBaseURL+'/api/admin/update/patientstatus', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.status === 200) {
        toast.success(result.message || "Patient status saved successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        }); 
        setSelectedItem(activeItem); 
        handleCancel() 
  
      } else if (response.status === 400 || response.status === 409 || response.status === 500) {
        // Handle 400 or 500 errors with appropriate messages
        toast.error(result.message || 'Failed to save the item.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowLoader(false);
      } 
      
    } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  // Handle cancel button click
  const handleCancel = () => {
    setIsOpen(false); // Close the div without saving  
    let item = selectedItem;  
    setOpenDiv('')
    if (item === null) {
      setActiveItem(''); // Reset active item if selectedItem is null
    } else {
        setActiveItem(item); // Update button name otherwise
    }
  };

 
  useEffect(() => {
    if (searchQuery) {
      navigate('/admin/dashboard');
    }
  }, [searchQuery, navigate]);

   // Fetch illnesses
   const illnessesArray = async () => {
    const response = await axios.get(getBaseURL+'/illnesses');  
    setIllnessesList(response.data.illnesses); 
  };

  const handleMyInsurance = async (UserIdNew) => {  
    fetch(getBaseURL+'/get_insurance_by_userid/'+UserIdNew, {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){ 
        localStorage.setItem('myinsuranceinfo', JSON.stringify(data.data));
         
          setMyinsuranceinfo(data.data)
          const currentDate = moment(); // Get today's date
          const expiry = moment(data.data.expiry_date); // Parse the expiry date using moment
          
          if (expiry.isBefore(currentDate, 'day')) {
            setCheckExpiry(true); // Expiry date is before today
          } else {
            setCheckExpiry(false); // Expiry date is today or in the future
          }
      } 
    })
    .catch((error) => {
      // console.log('Error:', error);
    });
    
  };
  
  const handleDeleteZoomMeet = async (meetingId) => { 
      fetch(getBaseURL+'/delete_zoom_meeting/'+meetingId, { 
      method: 'GET',  
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){  
        setSelectedZoomMeet(null);
        setMeetingStatusZoom(null);
      } 
    })
    .catch((error) => {
      // console.log('Error:', error);
    });
    
  };

  const handleCheckProcessZoomMeeting = async (UserIdNew) => {  
    fetch(getBaseURL+'/api/zoom/process/meeting/'+UserIdNew, {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){ 
      //  console.log(data)
      } 
    })
    .catch((error) => {
      // console.log('Error:', error);
    });
    
  };

  const handleRecordsPerPageChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextGroup = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevGroup = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFindRecord = (id) => {
    
  };
  const handleShowSummary = async (id) => {
    setShowLoaderPage(true);
  
    try {
      const foundRecord = UserHistoryData.find((item) => item.created_on === id);
  
      if (foundRecord) {  
        if (foundRecord?.zoom_meeting?.meeting_status === 'active') {
  
          const data = {
            meeting_id: foundRecord?.zoom_meeting?.meeting_id,
            session_id: foundRecord?.session_id,
          }; 
          try {
            const response = await fetch(getBaseURL + '/api/zoom/process/meeting', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });
  
            const result = await response.json(); 
            if (response.status === 200 || response.status === 201) { 
              showPatientDataResult(result.session_data);
            } else if (response.status === 400) {
              // toast.error(result.message || 'User not found or metrics not updated.', {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              showPatientDataResult(foundRecord);
            } else {
              toast.error(result.message || 'An unexpected error occurred. Please try again.', {
                position: toast.POSITION.TOP_RIGHT,
              });
              showPatientDataResult(foundRecord);
            }
          } catch (error) {
            console.error('Error:', error);
            toast.error(error.message || 'Network error. Please try again later.', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          showPatientDataResult(foundRecord);
        }
      } else {
        setRecordData(null);
      }
    } catch (error) {
      console.error('Error in handleShowSummary:', error);
      toast.error('An error occurred. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
     
    }
    
  };

  const calculateDuration = (startTs, endTs) => { 
    const durationInSeconds = endTs - startTs;
    const durationInMinutes = Math.floor(durationInSeconds / 60);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
  
    const hourString = hours > 0 ? `${hours} hour` : "";
    const minuteString = minutes > 0 ? `${minutes} minute` : "";
  
    return [hourString, minuteString].filter(Boolean).join(" and ");
  };

    // Monitor input changes and set `checkPhysicalExam` when all values are filled 
    const showPatientDataResult = async (foundRecord) => { 
      const filteredConversation = foundRecord.conversation.filter(convo => Object.keys(convo).length !== 0); 
 
      // Set all necessary states based on foundRecord
      setRecordData(foundRecord);
      setFollowSummaryEditAIChatbot(foundRecord.chatbot_summary) 
      setSelectedIllness(foundRecord.illness);
      setChatHistory(filteredConversation);
      setPlanOnCareData(foundRecord.plan_of_care); 
      setConditionalDiagnosis(foundRecord.conditional_diagnosis);
      setSessionIDCheck(foundRecord.session_id);
      setPatientstatus(foundRecord.patientstatus);
      setSelectedItem(foundRecord.patientstatus);
      setActiveItem(foundRecord.patientstatus);
      setbpHgTxt(foundRecord.bp_hg);
      setbpMMTxt(foundRecord.bp_mm);
      setheartrateTxt(foundRecord.heartrate);
      setHeightTxt(foundRecord.height ? foundRecord.height : 0);
      seteditbpHgTxt(foundRecord.bp_hg);
      seteditbpMMTxt(foundRecord.bp_mm);
      seteditheartrateTxt(foundRecord.heartrate); 

      setpatientbpMMTxt(foundRecord.bmi);
      setTempBpMMTxt(foundRecord.bp_mm);
      setFutureDate(foundRecord.follow_up_date);
      // setSelectedItemFollowup(foundRecord.follow_up_date);
      setSelectedZoomMeet(foundRecord?.zoom_meeting || null);
      setMeetingStatusZoom(foundRecord?.zoom_meeting?.meeting_status);
      setDataProcessingStatus(foundRecord?.zoom_meeting?.data_processing_status);
    
      // Check if the answer to the specific question is "yes"
      const emergencyQuestion = filteredConversation.find(convo =>
        convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
      );
    
      if (emergencyQuestion) {
        setEmergencyQuest(true);
      }
    
      setChatHistory([]);
      setShowSummary(true);
    
      if (foundRecord.session) {
        adminCheckedSession(foundRecord.user_id, foundRecord.session_id);
      }
      try {
        ///api/doctor/followup/<session_id>/active_request
        const response = await axios.get(getBaseURL+'/api/doctor/followup/'+foundRecord.session_id+'/all_request'); 
        if (response.status === 200 || response.status === 201) {
          if (response?.data?.data) {
            const sortedData = [...response.data.data].sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
            setFollowupData(sortedData); 
 
          }   
        } 
        
        } catch (error) {
         
        } finally {
          
        }
        try {
          ///api/doctor/followup/<session_id>/active_request
          const response = await axios.get(getBaseURL+'/api/doctor/followup/'+foundRecord.session_id+'/active_request'); 
   
          if (response.status === 200 || response.status === 201) { 
            const { activeFlag, data } = response.data;
          
            // Set the check for all follow-up meetings
            setCheckAllFollowupMeet(activeFlag); // true if activeFlag is false, and vice versa 
            // Update selected follow-up item
            setSelectedItemFollowup(data);
          
            // Check if zoom meeting exists
            if (data.zoom_meeting && Object.keys(data.zoom_meeting).length > 0) {
              setFollowupZoomMeet(true);
            } 
          
            // Check for patient request
            if (data.patient_request) {
              setCheckFollowupMeet(true);
            }  
          }

          } catch (error) {  
          
          } finally {
            
          } 
        
      try {
        const response = await axios.get(getBaseURL+'/illnesses'); 
        if (response.status === 200) {
          setIllnessesList(response.data.illnesses);        
          setShowLoaderPage(false)
        }
    
        } catch (error) {
         
       } finally {
          
        }

      try { 
        const url = `${getBaseURL}/api/medical/reports/fetch/${foundRecord.reports_id}`;
        // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
          
        if (response.data && response.data.status === 'success') { 
          setReportData(response.data.content);
        }
        } catch (error) {
          //console.error('Error posting data:', error);
       } finally {
          
        }

        try { 
          const url = `${getBaseURL}/api/patient/fetch/details/pcp-pharmacy/${foundRecord.reports_id}`;
          // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
          const response = await axios.get(url, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
            
          if (response.data && response.data.status === 'success') {  
            setPCP_Pharmacy(response.data.data); 
          }
          } catch (error) { 
            //console.error('Error posting data:', error);
         } finally {
          setShowLoaderPage(false);
          }
        


    };
 // Group documents by doc_type
//  const groupedDocuments = (reportData || []).reduce((acc, doc) => {
//   if (doc && doc.doc_type) {
//     acc[doc.doc_type] = acc[doc.doc_type] || [];
//     acc[doc.doc_type].push(doc);
//   }
//   return acc;
// }, {}); 
  // Group reportData by doc_type
  const groupedDocuments = (reportData || []).reduce((acc, doc) => {
    if (!doc.doc_type) {
      return acc;
    }
    const docType = doc.doc_type.trim();

    if (!acc[docType]) {
      acc[docType] = [];
    }
  
    acc[docType].push({
      doc_id: doc.doc_id,
      doc_summary: doc.doc_summary,
      doc_text: doc.doc_text,
      doc_url: doc.doc_url,
    });
 

  return acc;
}, {});
 
if (PCP_Pharmacy && PCP_Pharmacy.pcp && PCP_Pharmacy.pcp.name && PCP_Pharmacy.pcp.address) {
groupedDocuments["pcp"] = [
  ...(groupedDocuments["pcp"] || []),
  {
    doc_summary: `${PCP_Pharmacy.pcp.name}, ${PCP_Pharmacy.pcp.address}`,  
    doc_text: null,
    doc_url: null,
  },
];
}
// Push Pharmacy into groupedDocumentspharmacy
if (PCP_Pharmacy && PCP_Pharmacy.pharmacy && PCP_Pharmacy.pharmacy.name && PCP_Pharmacy.pharmacy.address) {
groupedDocuments["pharmacy"] = [
  ...(groupedDocuments[""] || []),
  {
     doc_summary: `${PCP_Pharmacy.pharmacy.name}, ${PCP_Pharmacy.pharmacy.address}`, 
     doc_text: null,
     doc_url: null,
  },
];
}  


// Map checkOrderList to match groupedDocuments and sort by order
const sortedGroupedDocuments = checkOrderList.map(orderItem => ({
  Name: orderItem.Name,
  doc_type: orderItem.doc_type,
  documents: groupedDocuments[orderItem.doc_type] || []
})); 

  const adminCheckedSession = async (patientId, sessionId) => {
    try {
      const response = await axios.post(`${getBaseURL}/admin_log`, {
        
          patient_id: patientId,
          admin_id: adminId,
          session_id: sessionId,  
      
      }); 
  
    
      if( response.data.status == 'success'){ 
        setBackReload(true)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  }

  const handleShowChat = (id) => {
    const filteredConversation = RecordData.conversation.filter(convo => Object.keys(convo).length !== 0);
 
    setActiveTab(id);
    setAnswerLoader(true)
    setTimeout(() =>  { 
      setChatHistory(filteredConversation); 
      setAnswerLoader(false) 
    }, 1000);
  }
  const handleHideSummary = () => { 
    setActiveTab(0);
    setRecordData([])
    setSelectedIllness() 
    if(BackReload){
      window.location.reload(); 
    }
    setShowSummary(false)
    setAnswerLoader(false) 
    setEmergencyQuest(false)
   };
   
  const handleShowDashboard = () => {
    setShowSummary(false)
    routeChange()
   };
  const renderTableBody = () => {
    const start = (currentPage - 1) * recordsPerPage;
    const end = start + recordsPerPage;
    const pageData = UserHistoryData?.slice(start, end);

    return pageData.map((UserHistoryData, index) => (
      <tr key={index}  onClick={() => handleShowSummary(UserHistoryData.created_on)} style={{cursor:'pointer'}} className={UserHistoryData.session === 'true' ? 'active' : ''}>
        <td><span className="d-flex align-items-center"><img src={Icons[0].admin_listIcon} style={{marginRight:'10px'}}/>{UserHistoryData.illness}</span></td>
        {/* <td style={{textAlign:'center'}}> {moment(UserHistoryData.created_on).format('MMM DD, YYYY')}</td>
        <td style={{textAlign:'right'}}> <button className="viewBtnClass">View</button></td>  */}
         <td style={{textAlign:'center'}}>
          {UserHistoryData.bp_hg && UserHistoryData.bp_mm && UserHistoryData.heartrate ? (
            <>
            {UserHistoryData.bp_hg && UserHistoryData.bp_mm ? (`${UserHistoryData.bp_hg} / ${UserHistoryData.bp_mm} -`) : null  }
            {UserHistoryData.heartrate ?`${UserHistoryData.heartrate} BPM` : null } 
            </> 
          ) :  "-"}    

          </td>  
          <td style={{textAlign:'center'}}> 
              {UserHistoryData.weight || UserHistoryData.height ? (
          <> 
            
              {UserHistoryData.height ?`${formatHeight(UserHistoryData.height)}  / ` : null}
              {UserHistoryData.weight ?`${UserHistoryData.weight} lbs` : null}
              </>
              ) : (
                "-"
              )}
      </td>
    <td style={{textAlign:'center'}}> {moment(UserHistoryData.created_on).format('MMM DD, YYYY')}</td>
     <td style={{textAlign:'center', textTransform:'capitalize'}}> {UserHistoryData.patientstatus ? UserHistoryData.patientstatus : '-'}</td>
     <td style={{textAlign:'right'}}> <button className="viewBtnClass">View</button></td>
      </tr>
    ));
    
     
  };

  const renderPagination = () => {
    let pages = [];
    const startPage = (currentPageGroup - 1) * 3 + 1;
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-button ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination">
        <button
          onClick={handlePrevGroup}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {pages}
        <button
          onClick={handleNextGroup}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      
      </div>
    );
  };


   useEffect(() => { 
  // handleUserRemove()
     
    handleMyInsurance(UserId)
    const fetchRecords = async () => {
    
      setShowLoaderPage(true)
 
      try {
        // setShowLoaderPage(true)
        // Make a GET request to fetch records from the API
        const response = await axios.get(getBaseURL+'/get_user_sessions/'+userid, {
          headers: {
            'Content-Type': 'application/json, text/html', // Specify the Content-Type header
          },
        });
        setShowLoaderPage(true)
        // Set user profile data
        setUserProfileData(response.data.user_details);  
      // Sort history data by created_on date in descending order
        const sortedHistory = [...response.data.history];  
        setUserHistoryData(sortedHistory);
        setUserHistoryNumber(response.data.history.length); 
        setBMITxt(response.data.bmi ? response.data.bmi : 0);
        setFullName(response.data.user_details.fullName);
        setEmail(response.data.user_details.email);
        setDOBTxt(response.data.user_details.dob);
        setPhoneNumber(response.data.user_details.phoneNumber ? '+'+response.data.user_details.phoneNumber : '');
        setUserProfile(response.data.user_details.userProfile);
        setInsuranceIdTxt(response.data.user_details.insurance ? response.data.user_details.insurance : 0);
        setGenderTxt(response.data.user_details.gender ? response.data.user_details.gender : '');
        setHeightTxt(response.data.user_details.height ? response.data.user_details.height : 0);
        setWeightTxt(response.data.user_details.weight ? response.data.user_details.weight : 0);        
        setIsSession(response.data.session_status ? response.data.session_status : 'false');        
    
      } catch (error) {
        console.error('Error fetching records:', error);
        // Handle error, show an error message, etc.
      }
      
      setShowLoaderPage(false)
    };
    fetchRecords();
  }, []); 

  useEffect(() => {
    const handlePopstate = () => {
      setActiveTab(0); // Reset the active tab to the default tab index
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setShowMainPoint(false);
  };

  useEffect(() => {
    setActiveTab(0); // Set the default active tab when the component mounts
  }, []);

  // Function to process the user data
  const processUserData = () => { 
    if (!RecordData) return []; // If no recordData, return empty array

    return [RecordData].map(user => {
      // Remove empty objects from the conversation array
      const filteredConversation = user.conversation.filter(convo => Object.keys(convo).length !== 0); 
      
      // Check if the answer to the specific question is "yes"
      const emergencyQuestion = filteredConversation.find(convo => 
        convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
      );

      if (emergencyQuestion) {
        alert("Emergency care is needed!");
      }

      return {
        ...user,
        conversation: filteredConversation
      };
    });
  };
  const [ImageHide, setImageHide] = useState(false); 
  const [showMainPoint, setShowMainPoint] = useState(false);
  const [showMainPointCD, setShowMainPointCD] = useState(false);
  const [showMainPointFollowCD, setShowMainPointFollowCD] = useState(false);
  const [showSummaryEditFollowup, setShowSummaryEditFollowup] = useState(false);
  const [showSummaryEditAIChatbot, setShowSummaryEditAIChatbot] = useState(false);
  const [showZoomSummaryEdit, setShowZoomSummaryEdit] = useState(false);
  const [showMainPointFollowPoC, setShowMainPointFollowPoC] = useState(false);
  
  const handleInputMainPoint = () => { 
        setShowMainPoint(false);   
        {!PlanOnCareData ? 
        (<>
          setShowMainPoint(false);
          </>
        ):(
          <>
          setShowMainPoint(true);
          </>
        )
      }
    setShowMainPoint(true);
    setPlanOnCareData(PlanOnCareData);
   };
  const handleCancelClickMPoint = () => {
    setShowLoader(false);
    setShowMainPoint(false);
  };
  const handleInputChangeMPoint= (e) => {
    setPlanOnCareData(e.target.value);
  };
  const handleSaveClickMPoint = () => {
    setShowLoader(true);
    setTimeout(() => { 
    setIsEditing(false); 
    handleSaveMPoint();
    }, 2000); 
  };
  const handleSaveMPoint = async (event) => {   
    try {   
      const reqData = JSON.stringify({ session_id: SessionIDCheck, plan_of_care: PlanOnCareData }) ;
   
      const response = await axios.put(getBaseURL+'/update_plan_of_care', reqData, {
        headers: {
          'Content-Type': 'application/json', // Specify content type as application/json
        },
      });
      if( response.data.status == 'success'){
        
          setShowLoader(false); 
          setShowMainPoint(false); 
          setBackReload(true)
          
      } 
    } catch (error) {
      console.error('Error updating summary:', error);
      // Handle error, show an error message, etc.
    }
  };
//conditionaldiagnosis  
  const handleInputMainPointCD = () => { 
    setShowMainPointCD(false);   
    {!ConditionalDiagnosis ? 
    (<>
      setShowMainPointCD(false);
      </>
    ):(
      <>
      setShowMainPointCD(true);
      </>
    )
  }
setShowMainPointCD(true);
setConditionalDiagnosis(ConditionalDiagnosis);
};
const handleCancelClickMPointCD = () => {
setShowLoader(false);
setShowMainPointCD(false);
};
const handleInputChangeMPointCD = (e) => {
  setConditionalDiagnosis(e.target.value);
};
const handleSaveClickMPointCD = () => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingCD(false); 
handleSaveMPointCD();
}, 2000); 
};
const handleSaveMPointCD = async (event) => {   
// try {   
  const data = { 
    admin_id: adminId,
    session_id : SessionIDCheck,   
    user_id: userid, 
    conditional_diagnosis: ConditionalDiagnosis , 
};  
  try {
    const response = await fetch(getBaseURL+'/api/admin/update/conditionaldiagnosis', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
 
    const result = await response.json();
    if (response.status === 200) {
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      setShowLoader(false); 
      setShowMainPointCD(false); 
      setBackReload(true)

    } else if (response.status === 400 || response.status === 409 ) {
      // Handle 400 or 500 errors with appropriate messages
      toast.error(result.message || 'Failed to save the item.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowLoader(false);
    } 
  } catch (error) {
    console.error('Error:', error);
    toast.error('An unexpected error occurred.');
  } finally {
    setLoading(false); // Stop loading
  } 
};

//Follow conditionaldiagnosis  



const handleInputMainPointFollowCD = (id, followup_CD, index) => { 
  setEditingAccordion(id);
  // setUpdatedContent(followup_CD);
  setUpdatedContent((prev) => ({ ...prev, [id]: followup_CD })); // Initialize content for this id

  setShowMainPointFollowCD(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowMainPointFollowCD(false);
    </>
  ):(
    <>
    setShowMainPointFollowCD(true);
    </>
  )
}
setShowMainPointFollowCD(true);
setFollowConditionalDiagnosis(followup_CD);
}; 
const handleCancelClickMPointFollowCD = () => {
setShowLoader(false);
setShowMainPointFollowCD(false);
setEditingAccordion(null);
setActiveAccordion(null);
};
const handleInputChangeMPointFollowCD = (e) => {
  setFollowConditionalDiagnosis(e.target.value);
};
const handleSaveClickMPointFollowCD = (followup_id, index) => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingFollowCD(false); 
handleSaveMPointFollowCD(followup_id);
}, 2000); 
};
const handleSaveMPointFollowCD = async (followup_id) => {   
// try {   
const data = { 
  followup_id: followup_id,
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  followup_cd: updatedContent,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/followup/update/followup_cd', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 

      // Update the specific accordion content in the local data array
      const updatedData = data.map((item) =>
        item.id === followup_id
          ? { ...item, content: updatedContent }
          : item
      );

    
    setShowLoader(false); 
    setShowMainPointFollowCD(false); 
    setBackReload(true)
    setEditingAccordion(null);
    setActiveAccordion(null);

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoader(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};

//Follow Plan of Care  
const handleInputMainPointFollowPoC = (followup_PoC) => { 
  setShowMainPointFollowPoC(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowMainPointFollowPoC(false);
    </>
  ):(
    <>
    setShowMainPointFollowPoC(true);
    </>
  )
}
setShowMainPointFollowPoC(true);
setFollowPlanOnCareData(followup_PoC);
}; 
const handleCancelClickMPointFollowPoC = () => {
setShowLoader(false);
setShowMainPointFollowPoC(false);
};
const handleInputChangeMPointFollowPoC = (e) => {
  setFollowPlanOnCareData(e.target.value);
};
const handleSaveClickMPointFollowPoC = (followup_id) => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingFollowPoC(false); 
handleSaveMPointFollowPoC(followup_id);
}, 2000); 
};
const handleSaveMPointFollowPoC = async (followup_id) => {   
// try {   
const data = { 
  followup_id: followup_id,
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  followup_poc: followPlanOnCareData,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/followup/update/followup_poc', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoader(false); 
    setShowMainPointFollowPoC(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoader(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};


//Summary Edit Followup 
const handleInputMainSummaryEditFollowup  = (id, followup_CD) => { 
  setEditMode(id);
  setEditText(followup_CD);
  setShowSummaryEditFollowup(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowSummaryEditFollowup(false);
    </>
  ):(
    <>
    setShowSummaryEditFollowup(true);
    </>
  )
}
setShowSummaryEditFollowup(true);
setFollowSummaryEditFollowup(followup_CD);
}; 
const handleCancelClickSummaryEditFollowup = () => {
setShowLoaderSummaryFollowup(false);
setShowSummaryEditFollowup(false);
setEditMode(null);
setEditText('');
};
const handleInputChangeSummaryEditFollowup = (e) => {
  setFollowSummaryEditFollowup(e.target.value);
};
const handleSaveClickSummaryEditFollowup= (followup_id) => {
setShowLoaderSummaryFollowup(true);
setTimeout(() => { 
setIsEditingFollowCD(false); 
handleSaveMPointFollowCD(followup_id);
}, 2000); 
};
const handleSaveSummaryEditFollowup = async (followup_id) => {   
  setShowLoaderSummaryFollowup(true);
// try {   
const data = { 
  followup_id: followup_id,
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  followup_summary: editText,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/followup/update/summary', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 

      // Update the followupData state to reflect the changes
      setFollowupData((prevData) =>
        prevData.map((item) =>
          item.followup_id === followup_id
            ? { ...item, followup_summary: editText } // Update the summary for the matching ID
            : item
        )
      );
      
    setShowLoaderSummaryFollowup(false); 
    setShowSummaryEditFollowup(false); 
    setBackReload(true)
    setEditMode(null);
    setEditText('');
  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoaderSummaryFollowup(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};



//Summary Chatbot Followup 
const handleInputMainSummaryEditAIChatbot  = (followup_CD) => { 
  setShowSummaryEditAIChatbot(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowSummaryEditAIChatbot(false);
    </>
  ):(
    <>
    setShowSummaryEditAIChatbot(true);
    </>
  )
}
setShowSummaryEditAIChatbot(true);
setFollowSummaryEditAIChatbot(followup_CD);
}; 
const handleCancelClickSummaryEditAIChatbot = () => {
setShowLoaderSummaryAIChatbot(false);
setShowSummaryEditAIChatbot(false);
};
const handleInputChangeSummaryEditAIChatbot = (e) => {
  setFollowSummaryEditAIChatbot(e.target.value);
};
const handleSaveClickSummaryEditAIChatbot= () => {
setShowLoaderSummaryAIChatbot(true); 
};
const handleSaveSummaryEditAIChatbot = async () => {   
setShowLoaderSummaryAIChatbot(true); 

// try {   
const data = {  
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  chatbot_summary: followSummaryEditAIChatbot,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/update/session/main_summary', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoaderSummaryAIChatbot(false); 
    setShowSummaryEditAIChatbot(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoaderSummaryAIChatbot(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};


//---------------------------------//

 


//---------------------------------//

const handleButtonClickFollowZoomMeet = () => {
  const zoomSchedulerUrl = selectedItemFollowup.zoom_meeting.doctor_url; // Zoom Scheduler page URL 
  window.open(zoomSchedulerUrl, "_blank", "noopener,noreferrer");
};
const handleButtonClickZoomMeet = () => {
  const zoomSchedulerUrl = selectedZoomMeet.doctor_url; // Zoom Scheduler page URL 
  window.open(zoomSchedulerUrl, "_blank", "noopener,noreferrer");
};

  // Function to copy email to clipboard
  const copyToClipboard = (text) => { 
    navigator.clipboard.writeText(text)
      .then(() => { 
        toast.success("Text copied to clipboard!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error("Failed to copy text: ", error);
        toast.error("Failed to copy text", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleCopyAll = () => {
    const hiddenDiv = contentRef.current;
 
      // Show the hidden div
      hiddenDiv.style.display = 'block';
      const textContent = hiddenDiv.innerHTML.replace(/<\/span>/g, '</span>\n');
      // Select the text inside the div
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(hiddenDiv);
      selection.removeAllRanges();
      selection.addRange(range);

      // Copy the selected text to the clipboard
      document.execCommand('copy');
 

      // Clear the selection
      selection.removeAllRanges();
      toast.success("Summary copied to clipboard!", {
        position: toast.POSITION.TOP_RIGHT,
      });
  
  };


 // Sample file array
 const fileArray = [
  { id: 1, fileName: 'Abdominal CT', url: AbdominalCT },
  { id: 2, fileName: 'Abdominal US', url: AbdominalUS },
  { id: 3, fileName: 'Anemia Labs', url: AnemiaLabs },
  { id: 4, fileName: 'Barium Studies', url: BariumStudies },
  { id: 5, fileName: 'Basic Gastro Labs', url: BasicGastroLabs },
  { id: 6, fileName: 'Colon Order', url: ColonOrder },
  { id: 7, fileName: 'Colonoscopy', url: Colonoscopy },
  { id: 8, fileName: 'Colon Screening Guidelines', url: ColonScreeningGuidelines },
  { id: 9, fileName: 'Diverticulosis', url: Diverticulosis },
  { id: 10, fileName: 'EGD Order', url: EGDOrder },
  { id: 11, fileName: 'FattyLiver', url: FattyLiver }, 
  { id: 12, fileName: 'FODMAP Diet', url: FODMAP_Diet },
  { id: 13, fileName: 'GERD Diet and Lifestyle', url: GERD_Diet_and_Lifestyle },
  { id: 14, fileName: 'Heaptic Labs', url: HeapticLabs },
  { id: 15, fileName: 'High Fiber Diet', url: High_Fiber_Diet },    
  { id: 16, fileName: 'Inflammatory Bowel Disease', url: InflammatoryBowelDisease },
  { id: 17, fileName: 'Irritable Bowel Syndrome', url: IrritableBowelSyndrome },
  { id: 18, fileName: 'Procedural Sedation', url: ProceduralSedation },
  { id: 19, fileName: 'Stool Studies', url: StoolStudies },
  { id: 20, fileName: 'Stress Management', url: StressManagement },
  { id: 21, fileName: 'Telehealth Disclaimer', url: TelehealthDisclaimer },
  { id: 22, fileName: 'Upper GI Endoscopy', url: UpperGIEndoscopy },
  { id: 23, fileName: 'Weight Reducing Diet', url: Weight_Reducing_Diet },
];

  const contentRef = useRef(); 
  const [selectedFiles, setSelectedFiles] = useState([]);
  
 
const handleCheckboxChange = (file) => {
  setSelectedFiles((prevSelectedFiles) => {
    const exists = prevSelectedFiles.find((f) => f.id === file.id);
    if (exists) {
      // Remove the file if it already exists in the selected list
      return prevSelectedFiles.filter((f) => f.id !== file.id);
    } else {
      // Add the file if not already selected
      return [...prevSelectedFiles, file];
    }
  });
};
  

  
  const downloadPDF = (patientName) => {
    setTimeout(() => {
      const input = contentRef.current;
  
      if (!input) {
        console.error("Element not found in the document.");
        return;
      }
  
      html2canvas(input).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
  
        // Define margins
        const marginLeft = 10; // Left margin in mm
        const marginTop = 10; // Top margin in mm
        const marginRight = 10; // Right margin in mm
        const marginBottom = 10; // Bottom margin in mm
  
        const pdfWidth = pdf.internal.pageSize.getWidth() - marginLeft - marginRight; // Content width
        const pdfHeight = pdf.internal.pageSize.getHeight() - marginTop - marginBottom; // Content height
  
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
  
        const ratio = pdfWidth / canvasWidth; // Scale factor for canvas to fit PDF width
        const scaledHeight = canvasHeight * ratio;
  
        let currentHeight = 0;
        let pageNumber = 1; // Start page number
  
        while (currentHeight < canvasHeight) {
          const sectionCanvas = document.createElement("canvas");
          const sectionContext = sectionCanvas.getContext("2d");
  
          sectionCanvas.width = canvasWidth;
          sectionCanvas.height = Math.min(canvasHeight - currentHeight, pdfHeight / ratio);
  
          sectionContext.drawImage(
            canvas,
            0,
            currentHeight,
            canvasWidth,
            sectionCanvas.height,
            0,
            0,
            canvasWidth,
            sectionCanvas.height
          );
  
          const sectionImageData = sectionCanvas.toDataURL("image/png");
  
          if (currentHeight > 0) pdf.addPage();
  
          pdf.addImage(
            sectionImageData,
            "PNG",
            marginLeft,
            marginTop,
            pdfWidth,
            (sectionCanvas.height * pdfWidth) / canvasWidth
          );
  
          // Add page number in the footer
          const pageHeight = pdf.internal.pageSize.getHeight();
          pdf.setFontSize(8);
          pdf.text(`Page ${pageNumber}`, pdf.internal.pageSize.getWidth() / 2, pageHeight - 4, { align: "center" });
  
          pageNumber++;
          currentHeight += sectionCanvas.height;
        }
  
        // Generate filename
        const now = new Date();
        const date = now.toISOString().split("T")[0]; // yyyy-mm-dd format
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS format
        const PatientNameTxt = patientName.replace(/ /g, "_");
        const filename = `${date}_${time}_${PatientNameTxt}_Report.pdf`;
  
        pdf.save(filename);
      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay of 100ms or adjust as needed
  };
 
   
  const generateFilename = (fullName) => {
    const now = new Date();
    const date = now.toISOString().split("T")[0]; // Format YYYY-MM-DD
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // Format HH-MM-SS
    const sanitizedFullName = fullName.replace(/ /g, "_"); // Replace spaces with underscores
    return `${date}_${time}_${sanitizedFullName}_Report.pdf`;
  };
  
  const generateShareFilename = (fullName) => {
    const now = new Date();
    const date = now.toISOString().split("T")[0]; // Format YYYY-MM-DD
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // Format HH-MM-SS
    const sanitizedFullName = fullName.replace(/ /g, "_"); // Replace spaces with underscores
    return `${sanitizedFullName}-${date}-${time} Report File`;
  };
  
  const downloadPdfNew = async (fullName, selectedFiles) => {
    try {
      // setIsGeneratingPdf(true); // Hide the "Copy All" button
      const filename = generateFilename(fullName);
      const content = contentRef.current;
      const headerImageUrl = Icons[0].headerImageUrlLink;
      const footerImageUrl = Icons[0].footerImageUrlLink;
   
      if (!content) {
        console.error("Content not found!");
        return;
      }
  
      // Step 1: Generate the PDF content using html2pdf.js
      const options = {
        margin: [1.1, 0.2, 1.1, 0.2], // [top, left, bottom, right] in inches
        filename: filename, // Output filename
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
  
      const contentPdfBlob = await html2pdf()
        .set(options)
        .from(content)
        .toPdf()
        .outputPdf('blob'); // Generate a Blob directly
  
      // Step 2: Load the generated content PDF into pdf-lib
      const contentPdfDoc = await PDFDocument.load(await contentPdfBlob.arrayBuffer());
  
      // Step 3: Create a new merged PDF document
      const mergedPdfDoc = await PDFDocument.create();
  
      // Step 4: Add modal content pages to merged PDF
      const contentPages = await mergedPdfDoc.copyPages(contentPdfDoc, contentPdfDoc.getPageIndices());
      contentPages.forEach((page) => mergedPdfDoc.addPage(page));
  
      // Step 5: Filter and merge selected files
      const validSelectedFiles = selectedFiles.filter((file) => file.url);
      if (validSelectedFiles.length === 0) {
        console.warn("No valid files selected for merging.");
      }
  
      for (const file of validSelectedFiles) {
        const fileBlob = await fetch(file.url).then((res) => res.blob());
        const filePdf = await PDFDocument.load(await fileBlob.arrayBuffer());
        const filePages = await mergedPdfDoc.copyPages(filePdf, filePdf.getPageIndices());
        filePages.forEach((page) => mergedPdfDoc.addPage(page));
      }
  
      // Step 6: Add headers and footers to the merged PDF
      const totalPages = mergedPdfDoc.getPageCount();
  
      // Load images for header and footer
      const headerImageBytes = await fetch(headerImageUrl).then((res) => res.arrayBuffer());
      const footerImageBytes = await fetch(footerImageUrl).then((res) => res.arrayBuffer());
      const headerImage = await mergedPdfDoc.embedPng(headerImageBytes);
      const footerImage = await mergedPdfDoc.embedPng(footerImageBytes);
  
      for (let i = 0; i < totalPages; i++) {
        const page = mergedPdfDoc.getPage(i);
        const { width, height } = page.getSize();
  
        // Draw header image
        // const headerImageWidth = 100; // Adjust width as needed
        const headerImageHeight = 70; // Adjust height as needed
        page.drawImage(headerImage, {
          x: 0,
          y: height - headerImageHeight,
          width: width,
          height: headerImageHeight,
        });
  
        // Add page number below the header image
        page.drawText(`Page ${i + 1} of ${totalPages}`, {
          x: width - 70, // Align near the right edge
          y: height - 15, // Near the top of the page
          size: 10,
        });
  
        // Draw footer image 
        const footerImageHeight = 50; // Adjust height as needed
        page.drawImage(footerImage, {
          x: 0,
          y: 0,
          width: width,
          height: footerImageHeight,
        });
      }
  
      // Step 7: Save the merged PDF
      const mergedPdfBytes = await mergedPdfDoc.save();
      const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });
  
      // Step 8: Trigger download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(mergedBlob);
      downloadLink.download = filename;
      downloadLink.click();
  
      // console.log("PDF with images in header and footer downloaded successfully.");
    } catch (error) {
      // console.error("Error while generating and merging PDF:", error);
    }
  };
   

// Handle the paste event to process multiple emails at once

// Function to handle email input change
const handleEmailChange = (event) => {
  const value = event.target.value;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check for space or comma to validate and add email
  if (value.endsWith(" ") || value.endsWith(",")) {
    const trimmedValue = value.trim().replace(/,$/, ""); // Trim and remove trailing comma

    if (emailRegex.test(trimmedValue)) {
      // Check if email is already in the list to prevent duplicates
      setShareEmail((prevEmails) => {
        if (!prevEmails.includes(trimmedValue)) {
          return [...prevEmails, trimmedValue]; // Add email if not already in list
        }
        return prevEmails; // Do not add if email already exists
      });
      setErrorShare("");  // Clear error on valid email
    } else if (trimmedValue) {
      // Set error for invalid email but don't clear the field
      setErrorShare(`Invalid email: ${trimmedValue}`);
    }

    setEmailList(""); // Clear input field for the next email
  } else {
    // Update the input field value (while typing)
    setEmailList(value);
    setErrorShare(""); // Clear error message if no space or comma
  }
 
};

// Function to handle adding email addresses when user clicks "Add Emails"
const addEmailsToList = (event) => {
  event.preventDefault(); 
  const emails = emailList
    .split(",")
    .map((email) => email.trim())
    .filter((email) => email); // Remove empty strings
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const invalidEmails = emails.filter((email) => !emailRegex.test(email));

  if (invalidEmails.length > 0) {
    setErrorShare(`Invalid email(s): ${invalidEmails.join(", ")}`);
    return;
  }

  const uniqueEmails = emails.filter(
    (email) => !shareEmail.includes(email) // Avoid duplicates
  );

  if (uniqueEmails.length === 0) {
    setErrorShare("All emails are already added.");
    return;
  }

  setShareEmail([...shareEmail, ...uniqueEmails]);
  setEmailList(""); // Clear input field
  setErrorShare(""); // Clear errors
};

const handlePaste = (event) => {
  const pastedText = event.clipboardData.getData("text");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  // Split the pasted text by spaces or commas and validate each email
  const emailsToAdd = pastedText
    .split(/[\s,]+/) // Split by spaces or commas
    .map((email) => email.trim())
    .filter((email) => emailRegex.test(email)); // Keep only valid emails

  // Add valid emails to the list, ensuring no duplicates
  setShareEmail((prevEmails) => {
    const newEmails = emailsToAdd.filter((email) => !prevEmails.includes(email));
    return [...prevEmails, ...newEmails];
  });

  // Update the input field and clear the error message
  setEmailList(""); // Clear input after pasting
  setErrorShare(""); // Clear error if paste is valid
};

  const handleInputFocus = () => {
    setError(""); // Clear the error when input is clicked/focused
  };

  

  const handleSharetoPatient = async (event, fullName, selectedFiles, email) => {
    try {
      setIsLoadingEmailP(true);
      if (event) event.preventDefault(); // Prevent form reload if event exists
 
    
      // Ensure content exists
      const content = contentRef.current;
      if (!content) {
        console.error("Content not found!");
        alert("Content not found!");
        return;
      }
  
      // Ensure header and footer image URLs are valid
      const headerImageUrl = Icons[0]?.headerImageUrlLink;
      const footerImageUrl = Icons[0]?.footerImageUrlLink;
      if (!headerImageUrl || !footerImageUrl) {
        console.error("Header or footer image URL is missing.");
        alert("Header or footer image is missing.");
        return;
      }
  
      // Ensure selected files contain valid URLs
      const validSelectedFiles = selectedFiles.filter((file) => file?.url);
      if (validSelectedFiles.length === 0) {
        console.warn("No valid files selected for merging.");
      }
  
      // Step 1: Generate filename and PDF content
      const filename = generateFilename(fullName);
      const options = {
        margin: [1.1, 0.2, 1, 0.2],
        filename: filename,
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
  
      const contentPdfBlob = await html2pdf()
        .set(options)
        .from(content)
        .toPdf()
        .outputPdf("blob");
  
      // Step 2: Load content PDF
      const contentPdfDoc = await PDFDocument.load(await contentPdfBlob.arrayBuffer());
  
      // Step 3: Create a merged PDF document
      const mergedPdfDoc = await PDFDocument.create();
  
      // Step 4: Copy content pages
      const contentPages = await mergedPdfDoc.copyPages(contentPdfDoc, contentPdfDoc.getPageIndices());
      contentPages.forEach((page) => mergedPdfDoc.addPage(page));
  
      // Step 5: Merge selected files
      for (const file of validSelectedFiles) {
        const fileBlob = await fetch(file.url).then((res) => res.blob());
        const filePdf = await PDFDocument.load(await fileBlob.arrayBuffer());
        const filePages = await mergedPdfDoc.copyPages(filePdf, filePdf.getPageIndices());
        filePages.forEach((page) => mergedPdfDoc.addPage(page));
      }
  
      // Step 6: Add headers and footers
      const totalPages = mergedPdfDoc.getPageCount();
      const headerImageBytes = await fetch(headerImageUrl).then((res) => res.arrayBuffer());
      const footerImageBytes = await fetch(footerImageUrl).then((res) => res.arrayBuffer());
      const headerImage = await mergedPdfDoc.embedPng(headerImageBytes);
      const footerImage = await mergedPdfDoc.embedPng(footerImageBytes);
  
      for (let i = 0; i < totalPages; i++) {
        const page = mergedPdfDoc.getPage(i);
        const { width, height } = page.getSize();
  
        // Add header image
        const headerImageHeight = 70;
        page.drawImage(headerImage, {
          x: 0,
          y: height - headerImageHeight,
          width: width,
          height: headerImageHeight,
        });
  
        // Add page number
        page.drawText(`Page ${i + 1} of ${totalPages}`, {
          x: width - 70,
          y: height - 15,
          size: 10,
        });
  
        // Add footer image
        const footerImageHeight = 50;
        page.drawImage(footerImage, {
          x: 0,
          y: 0,
          width: width,
          height: footerImageHeight,
        });
      }
  
      // Step 7: Save merged PDF
      const mergedPdfBytes = await mergedPdfDoc.save();
      const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

      const base64Pdf = await convertBlobToBase64(mergedBlob);
  
      // Reset errors and set loading state

      setErrorShare("");
       
      // Prepare email payload
      const subjectMsg = generateShareFilename(fullName);
      // console.log(subjectMsg) 
      const bodyMsg = "Hello, \n\n Please find the attached report for your reference. If you have any questions or need further assistance, feel free to reach out.\n\nBest regards,\nAIGI Care";
  
      // Step 8: Send FormData to backend
      const formData = new FormData();
      formData.append("file", mergedBlob, filename); // Attach the file with the proper filename 
      formData.append("emails", email);
      formData.append("subject", subjectMsg); // Add the subject
      formData.append("body", bodyMsg); // Add the email body

      // Send the FormData via fetch
      fetch(`${getBaseURL}/api/patient/report/send-email`, {
        method: "POST",
        body: formData,
      })
  .then((response) => response.json())
  .then((data) => {
    if (data.status === "success") {
      toast.success("Emails sent successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      // Reset form data and form state 
      setErrorShare(""); // Clear any error messages
      setIsLoadingEmailP(false); // Stop the loading spinner
    } else if (data.status === "error") {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoadingEmailP(false);
    } else if (data.status === "partial_success") {
      data.details.forEach((detail) => {
        if (detail.status === "error") {
          toast.error(`Failed: ${detail.email} - ${detail.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      setIsLoadingEmailP(false);
    }
  })
  .catch((error) => {
    console.error("Error:", error);
    toast.error("An unexpected error occurred. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
    }); 
  });

          
    } catch (error) {
      console.error("Error while generating and merging PDF:", error);
      alert("An error occurred. Please try again.");
    } finally { 
    }
  };
  
  const handleShareBtn = async (event, fullName, selectedFiles) => {
    try {
      setIsLoadingEmail(true);
      if (event) event.preventDefault(); // Prevent form reload if event exists
 
      // Simple form validation
      let formErrors = {};
      if (shareEmail.length === 0) {
        formErrors.email = "Email list cannot be empty.";
      }
  
      if (Object.keys(formErrors).length > 0) {
        setErrorShare(formErrors);
        return; // Stop if there are validation errors
      }

      // Ensure content exists
      const content = contentRef.current;
      if (!content) {
        console.error("Content not found!");
        alert("Content not found!");
        return;
      }
  
      // Ensure header and footer image URLs are valid
      const headerImageUrl = Icons[0]?.headerImageUrlLink;
      const footerImageUrl = Icons[0]?.footerImageUrlLink;
      if (!headerImageUrl || !footerImageUrl) {
        console.error("Header or footer image URL is missing.");
        alert("Header or footer image is missing.");
        return;
      }
  
      // Ensure selected files contain valid URLs
      const validSelectedFiles = selectedFiles.filter((file) => file?.url);
      if (validSelectedFiles.length === 0) {
        console.warn("No valid files selected for merging.");
      }
  
      // Step 1: Generate filename and PDF content
      const filename = generateFilename(fullName);
      const options = {
        margin: [1.1, 0.2, 1, 0.2],
        filename: filename,
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
  
      const contentPdfBlob = await html2pdf()
        .set(options)
        .from(content)
        .toPdf()
        .outputPdf("blob");
  
      // Step 2: Load content PDF
      const contentPdfDoc = await PDFDocument.load(await contentPdfBlob.arrayBuffer());
  
      // Step 3: Create a merged PDF document
      const mergedPdfDoc = await PDFDocument.create();
  
      // Step 4: Copy content pages
      const contentPages = await mergedPdfDoc.copyPages(contentPdfDoc, contentPdfDoc.getPageIndices());
      contentPages.forEach((page) => mergedPdfDoc.addPage(page));
  
      // Step 5: Merge selected files
      for (const file of validSelectedFiles) {
        const fileBlob = await fetch(file.url).then((res) => res.blob());
        const filePdf = await PDFDocument.load(await fileBlob.arrayBuffer());
        const filePages = await mergedPdfDoc.copyPages(filePdf, filePdf.getPageIndices());
        filePages.forEach((page) => mergedPdfDoc.addPage(page));
      }
  
      // Step 6: Add headers and footers
      const totalPages = mergedPdfDoc.getPageCount();
      const headerImageBytes = await fetch(headerImageUrl).then((res) => res.arrayBuffer());
      const footerImageBytes = await fetch(footerImageUrl).then((res) => res.arrayBuffer());
      const headerImage = await mergedPdfDoc.embedPng(headerImageBytes);
      const footerImage = await mergedPdfDoc.embedPng(footerImageBytes);
  
      for (let i = 0; i < totalPages; i++) {
        const page = mergedPdfDoc.getPage(i);
        const { width, height } = page.getSize();
  
        // Add header image
        const headerImageHeight = 70;
        page.drawImage(headerImage, {
          x: 0,
          y: height - headerImageHeight,
          width: width,
          height: headerImageHeight,
        });
  
        // Add page number
        page.drawText(`Page ${i + 1} of ${totalPages}`, {
          x: width - 70,
          y: height - 15,
          size: 10,
        });
  
        // Add footer image
        const footerImageHeight = 50;
        page.drawImage(footerImage, {
          x: 0,
          y: 0,
          width: width,
          height: footerImageHeight,
        });
      }
  
      // Step 7: Save merged PDF
      const mergedPdfBytes = await mergedPdfDoc.save();
      const mergedBlob = new Blob([mergedPdfBytes], { type: "application/pdf" });

      const base64Pdf = await convertBlobToBase64(mergedBlob);
  
      // Reset errors and set loading state

      setErrorShare("");
       
      // Prepare email payload
      const subjectMsg = generateShareFilename(fullName);
      // console.log(subjectMsg)
      const emailVal = shareEmail;
      const bodyMsg = "Hello, \n\n Please find the attached report for your reference. If you have any questions or need further assistance, feel free to reach out.\n\nBest regards,\nAIGI Care";
  
      // Step 8: Send FormData to backend
      const formData = new FormData();
      formData.append("file", mergedBlob, filename); // Attach the file with the proper filename

      // Append each email individually to avoid sending it as a JSON string
      emailVal.forEach((email) => {
        formData.append("emails", email);
      });

      formData.append("subject", subjectMsg); // Add the subject
      formData.append("body", bodyMsg); // Add the email body

      // Send the FormData via fetch
      fetch(`${getBaseURL}/api/patient/report/send-email`, {
        method: "POST",
        body: formData,
      })
  .then((response) => response.json())
  .then((data) => {
    if (data.status === "success") {
      toast.success("Emails sent successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      // Reset form data and form state
      setShareEmail([]); // Clear the email list
      setEmailList(""); // Reset the email input
      setErrorShare(""); // Clear any error messages
      setIsLoadingEmail(false); // Stop the loading spinner
    } else if (data.status === "error") {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoadingEmail(false);
    } else if (data.status === "partial_success") {
      data.details.forEach((detail) => {
        if (detail.status === "error") {
          toast.error(`Failed: ${detail.email} - ${detail.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      setIsLoadingEmail(false);
    }
  })
  .catch((error) => {
    console.error("Error:", error);
    toast.error("An unexpected error occurred. Please try again.", {
      position: toast.POSITION.TOP_RIGHT,
    }); 
  });

          
    } catch (error) {
      console.error("Error while generating and merging PDF:", error);
      alert("An error occurred. Please try again.");
    } finally { 
    }
  };
  
  // Utility function to convert Blob to base64
const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract base64 part
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

// Function to handle generating and merging PDFs
const generateAndMergePDF = async (patientName) => {
  try {
    const modalContent = contentRef.current;

    // Generate PDF from modal content
    const canvas = await html2canvas(modalContent);
    const contentPdf = new jsPDF("p", "mm", "a4");

    const pageWidth = contentPdf.internal.pageSize.getWidth() - 20;
    const pageHeight = contentPdf.internal.pageSize.getHeight() - 26;
    const imageHeight = (canvas.height * pageWidth) / canvas.width;

    let currentHeight = 10;
    let pageCount = 1;

    while (currentHeight < imageHeight) {
      const sectionHeight = Math.min(pageHeight, imageHeight - currentHeight);
      const sectionCanvas = document.createElement("canvas");
      sectionCanvas.width = canvas.width;
      sectionCanvas.height = (sectionHeight * canvas.width) / pageWidth;

      const ctx = sectionCanvas.getContext("2d");
      ctx.drawImage(
        canvas,
        0,
        currentHeight * (canvas.width / pageWidth),
        canvas.width,
        sectionCanvas.height,
        0,
        0,
        canvas.width,
        sectionCanvas.height
      );

      const imgData = sectionCanvas.toDataURL("image/png");
      if (pageCount > 1) contentPdf.addPage();
      contentPdf.addImage(imgData, "PNG", 10, 10, pageWidth, sectionHeight);

      currentHeight += sectionHeight;
      pageCount++;
    }

    const contentPdfBlob = contentPdf.output("arraybuffer");
    const contentPdfDoc = await PDFDocument.load(contentPdfBlob);
 
    // Create a new merged PDF document
    const mergedPdfDoc = await PDFDocument.create();

    // Add modal content pages to merged PDF
    const contentPages = await mergedPdfDoc.copyPages(contentPdfDoc, contentPdfDoc.getPageIndices());
    contentPages.forEach((page) => mergedPdfDoc.addPage(page));

    // Filter and merge selected files
    const validSelectedFiles = selectedFiles.filter((file) => file.url); 
    if (validSelectedFiles.length === 0) {
      console.warn("No valid files selected for merging.");
    }

    for (const file of validSelectedFiles) {
      const fileBlob = await fetch(file.url).then((res) => res.blob());
      const filePdf = await PDFDocument.load(await fileBlob.arrayBuffer());
      const filePages = await mergedPdfDoc.copyPages(filePdf, filePdf.getPageIndices());
      filePages.forEach((page) => mergedPdfDoc.addPage(page));
    }

      // Add Header with patient name and page number
      const totalPages = mergedPdfDoc.getPageCount();
      for (let i = 0; i < totalPages; i++) {
        const page = mergedPdfDoc.getPage(i);
        const pageHeight = page.getHeight();
        const pageWidth = page.getWidth();
  
        // Draw patient name and page number in the header, right-aligned
        page.drawText(`Page ${i + 1} of ${totalPages}`, {
          x: pageWidth - 70, // Right-aligned with a margin from the right
          y: pageHeight - 20, // Place at the top of the page
          size: 9,
          color: rgb(0, 0, 0), // Black text
          font: await mergedPdfDoc.embedFont(StandardFonts.Helvetica), // Use Helvetica font
        });
      }

    // Serialize and download the merged PDF
    const mergedPdfBytes = await mergedPdfDoc.save();
    const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });

    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const filename = `${date}_${time}_${fullName.replace(/ /g, "_")}_Merged_Report.pdf`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch (error) {
    console.error("Error generating merged PDF:", error);
  }
};
 
 
  const isPastDate = moment(selectedItemFollowup).isBefore(moment(), "day");

  const getOrdinalLabel = (number) => {
    const j = number % 10;
    const k = number % 100;
  
    if (j === 1 && k !== 11) {
      return `${number}st`;
    }
    if (j === 2 && k !== 12) {
      return `${number}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${number}rd`;
    }
    return `${number}th`;
  };

  
  
  return (
    <div>
      {ShowLoaderPage && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
        <Header /> 
             <div className="innerBodyBox bgInnerDiv">

              <div className="contentInnerBody">
               
                
                <div className="contentMainBox viewPatientMain"> 
                    <div className="userInfoMainDiv whiteBg innerDivRecords">
                        <div className="imgBox">
                               <b> 
                                      {fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }  
                                </b> 
                        </div>
                        <div className="titleDivRight">
                            <h2 className="capitalizeTxt">{fullName}</h2>
                            <button className="backBtn" onClick={showSummay ? (handleHideSummary) :(handleShowDashboard)}                        
                            ><img src={Icons[0].admin_backBtn} />Back</button>
                            <ul className="userInfoDetails">
                              <li className={GenderTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_userIcon} />
                                <label className="capitalizeTxt">{GenderTxt}</label>
                              </li>
                              <li className={DOBTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_calendarIcon} />
                                <label>{moment(DOBTxt).format('MMM DD, YYYY')} {' '}({ageCal} years)</label>
                              </li>
                              <li>
                                <img src={Icons[0].admin_mailIcon} />
                                <label className="singleLineEllipsis" onClick={() => copyToClipboard(email)} style={{ cursor: 'pointer' }}>{email} {" "}
                                <img src={Icons[0].copyclip} style={{width:'18px'}} />
                                </label>
                              </li>
                              <li>
                                <img src={Icons[0].admin_phoneIcon} />
                                <label>{phoneNumber}</label>
                              </li>
                            </ul>
                            <div className="listOptDiv">
                            <ul className={"userMedicalInfo " +( CheckExpiry && "CheckExpiryList")}>
                              <li>
                                <b>{BMITxt}</b>
                                <label>BMI</label>
                              </li>
                              {/* <li>
                                <b>{WeightTxt  ? WeightTxt : '0'}{' '}<span>lbs</span></b>
                                <label>Weight</label>
                              </li>
                              <li>
                                <b>{HeightTxt ? HeightTxt : '0'}{' '}<span>cm</span></b>
                                <label>Height</label>
                              </li>
                              <li>
                                <b className="InsuranceIDEllipsis">{InsuranceIdTxt ? InsuranceIdTxt : '0'}</b>
                                <label>Insurance ID</label>
                              </li> */}
                              <li> 
                              <b>{myinsuranceinfo.insurance_carrier}</b>
                                <label>Insurance Carrier</label>
                              </li>
                              <li> 
                                <b>{myinsuranceinfo.group_name}</b>
                                <label>Group name</label>
                              </li>
                              <li> 
                              <b>{myinsuranceinfo.policy_id}</b>                              
                                <label>Insurance ID</label>
                              </li> 
                              <li> 
                              <b style={{color: CheckExpiry && '#ca3030'}}>{myinsuranceinfo.expiry_date ? myinsuranceinfo.expiry_date : '-'}</b>                              
                                <label>Expiry</label>
                              </li> 
                            </ul>
                            <ul className="userMedicalInfo">
                              <li>
                                <b className="text-center">{UserHistoryNumber}</b>
                                <label>Appointments</label>
                              </li>
                            </ul>
                            </div>
                        </div> 
                    </div>

                    <div className={"patientHistoryMain " +(showSummay ? 'd-none':'')}>
                      <div className="whiteBg innerDivRecords ">
                                  <div className="titleDiv">
                                      <h2>All Appointments</h2>
                                      <ul className={"titleRightSideDiv " + (  UserHistoryData.length != 0 ? '':'d-none'  )}> 
                                        <li>   
                                            <div className="table-options">
                                              <label htmlFor="recordsPerPage">Showing</label>
                                              <select id="recordsPerPage" value={recordsPerPage} onChange={handleRecordsPerPageChange}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                              </select> 
                                            </div>
                                          </li> 
                                      </ul>
                                    </div>
                          { UserHistoryData.length != 0 ? (<>
                              <div className="table-container">
                                  <table id="patientTable">
                                    <thead>
                                      <tr>
                                        <th>Illnesses</th> 
                                        <th  style={{textAlign:'center'}}>Last Measurement</th> 
                                        <th  style={{textAlign:'center'}}>Height/Weight</th>
                                        <th  style={{textAlign:'center'}}>Consultation Date</th>
                                        <th  style={{textAlign:'center'}}>Status</th>
                                        <th  style={{textAlign:'right', paddingRight: '50px'}}>Action</th> 
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {renderTableBody()}
                                    </tbody>
                                  </table>
                                </div>
                                </>):(<>
                                  <div className="titleDiv text-center table-container d-flex justify-content-center align-items-center nodataRecords">
                                      <h2> No table record </h2>
                                      </div>
                                  </>)
    }
                      </div> 
                            
                        <div className={"paginationMain " +(showSummay ? 'd-none':'') + (  UserHistoryData.length != 0 ? '':'d-none'  )}>
                                {renderPagination()}
                                <span className="totalPageDiv">Total Pages: {totalPages}</span>
                        </div>
                    </div>

                     <div className={"patientSummaryMain " +(!showSummay ? 'd-none':'')}>
                      <div className="innerDivRecords scrollHeightDiv" style={{borderRadius: "14px", background: "#fff"}} >

                     
                 
                      <div className="whiteBg tab-content p-3 bg-white" style={{borderRadius: "14px"}}>

                      <div className="despInfoDiv position-relative  d-flex" style={{height: '70px'}}>
                        <div className="summaryReportBtn">
                            <h2>Summary</h2>
                            <button className="openModalViewAllBtn  " style={{position:"relative"}} onClick={openModalViewAll}>Final Report</button>
                            </div>
                            
                                <div className="headDespInfoDiv position-absolute top-0 end-0 ">
                            
                              <ul className="optionUpdateList">
                            
                                <li> 
                                <label>Physical  Exam 
                                    {/* <button className={'editIcon ' + (checkPhysicalExam || filledCheckPhysicalExam ?  'btnactive' : 'd-none') }
                                      onClick={selectedItem !== "completed" ? toggleDivPhysicalExam : null} 
                                      disabled={selectedItem !== "completed"}
                                    >
                                      <img src={ Icons[0].editIcon } className="logoImg w-100" alt="" /> 
                                    </button>   */}
                                    
                                    <button
                                        className={
                                          'editIcon ' + 
                                          (checkPhysicalExam || filledCheckPhysicalExam ? 'btnactive ' : '') + 
                                          (selectedItem?.toLowerCase() === "completed" ? 'd-none' : '')
                                        }
                                        onClick={toggleDivPhysicalExam}
                                      >
                                        <img src={Icons[0].editIcon} className="logoImg w-100" alt="Edit Icon" />
                                      </button>
                                  
                                  </label>
                          
                                {/* {bpHgTxt && bpMMTxt && heartrateTxt ? (  */}
                                {(checkPhysicalExam || filledCheckPhysicalExam) ? (
                                    <>  
                                    <b className={bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt}>
                                    {bpHgTxt && bpMMTxt ? (`${editbpHgTxt} / ${editbpMMTxt} - `) : null  }
                                    {heartrateTxt ?`${editheartrateTxt} BPM` : null } 
                                    </b>
                                    </> 
                                  ) :  (<>
                                  <button className="selectedItemBtn newClickBtn"  onClick={toggleDivPhysicalExam} > 
                                    Add BP/BPM  
                                </button>
                                    {/* Dropdown div to display the list */}
                                    {/* {isOpenPhysicalExam && ( */} 
                                    </>)} 
                                    {openDiv === 'PhysicalExam' && (

                  <div className="patientStatusBox  PhysicalExamDivBox" style={{ zIndex: '11'}} >
                    
                  <div className=" black-80 center stepFirstForm w-100">

                  <from  className="measure w-100" >


                  <div className='d-block mt-0 ' >

                  <div className='d-grid mt-2   gap-3 '>
                      <div className='w-100'>
                            <label className='labelTxt' style={{width:"120px"}}>Blood pressure</label>
                              <div className="inputDiv"> 
                            
                          <div className="timepicker">
                            <input
                              type="text"
                              className="hh"
                              maxLength="3"
                              placeholder="Mm"
                              ref={bpMMRef}
                              // onInput={(e) => handleInput(e, bpHgRef, null)}
                              // onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                              value={bpMMTxt}  // Ensure the input value reflects the state
                              onInput={(e) => {
                                setbpMMTxt(e.target.value);  // Update state when input changes
                                handleInput(e, bpHgRef, null);
                              }}
                              onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                            />
                            -
                            <input
                              type="text"
                              className="mm"
                              maxLength="3"
                              placeholder="Hg"
                              ref={bpHgRef}
                              // onInput={(e) => handleInput(e, null, bpMMRef)}
                              // onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                              value={bpHgTxt}  // Ensure the input value reflects the state
                              onInput={(e) => {
                                setbpHgTxt(e.target.value);  // Update state when input changes
                                handleInput(e, null, bpMMRef);
                              }}
                              onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                            />
                          </div>  
                                <label  style={{width:"80px", textAlign: 'right'}}>Mm - Hg</label>   
                            </div>
                            {errorMessage && <span className="errorTxt">{errorMessage}</span>}
                            {errors.bpHgTxt  && <span v>{errors.bpHgTxt }</span>}
                      </div>

                      <div className='w-100 '>
                      <label className='labelTxt pr-0' style={{width:"130px"}}>Heart rate</label>
                              <div className=" inputDiv"> 
                                  <input type="text"   
                                  style={{paddingLeft:"10px"}}
                                    value={heartrateTxt} 
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-digit characters
                                      const numericValue = value.replace(/\D/g, '');
                                      // Limit to 4 digits
                                      if (numericValue.length <= 3) {
                                        setheartrateTxt(numericValue);
                                      }
                                    }} 
                                  />  
                                  <label>bpm</label>   
                              </div>
                              {errors.heartrateTxt && <span  className="errorTxt" >{errors.heartrateTxt}</span>}
                      </div>

                    </div>
                  </div> 
                  <span className="d-flex gap-4" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  <button className="newBtn cancelBtn" onClick={handleCancelPhysicalExam} >Close</button>
                  <button onClick={handleSubmit} 
                    disabled={ShowLoader}
                    className="newBtn   "  
                    style={{ 
                      cursor: ShowLoader ? 'not-allowed' : 'pointer',
                    }}
                    type="submit" >
                      {ShowLoader ? 'Saving...' : 'Save'}</button>

                  </span>

                  </from>
                  </div>

                  </div>
                  )}
                                

                                </li>
                                <li>
                                  <label>Patient Status</label>
                                  {selectedItem ? (<>
                                      <button className={"selectedItemBtn color" +(selectedItem)}
                                      onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivStatus} 
                                      disabled={selectedItem?.toLowerCase() === "completed"}
                                      >
                                      {selectedItem}
                                      </button></>
                                  ) : (<>
                                    <button className={"selectedItemBtn cancelBtn"} onClick={toggleDivStatus}  >
                                    Add Status
                                    </button></>
                                  )}
                                  

                                      {/* Dropdown div to display the list */}
                                    {/* {isOpen && ( */}
                                  {openDiv === 'Status' && (
                                      <div className="patientStatusBox" style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px' , zIndex: '11'}}>
                                        <label className='labelTxt pr-0' style={{width:"130px"}}>Patient Status</label>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                          {items.map((item, index) => (
                                            <li
                                              className={activeItem?.toLowerCase()  === item?.toLowerCase() ?  'active' : ''}
                                              key={index}
                                              onClick={() => handleItemClick(item)} 
                                            >
                                              {item}
                                            </li>
                                          ))}
                                        </ul>

                                        {/* Save and Cancel buttons */}
                                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                                          <button  className="newBtn cancelBtn"  onClick={handleCancel} >
                                            Cancel
                                          </button>
                                          <button
                                          className="newBtn"
                                            onClick={handleSave}
                                            disabled={!activeItem || loading} // Disable if no item selected or loading
                                            style={{ 
                                              cursor: loading ? 'not-allowed' : 'pointer',
                                            }}
                                          >
                                            {loading ? 'Saving...' : 'Save'}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                </li>
                                {meetingStatusZoom  !== 'deactive' && (
                                  <>
                                <li className="text-left">
                                  <label>Patient Meeting</label> 
                                
                                    {selectedZoomMeet && Object.keys(selectedZoomMeet).length > 0 ? (
                                          <>
                                            {(!meetingStatusZoom || meetingStatusZoom === 'active') ? (
                                              <button className="blueBtnStyle"
                                              onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivZoomMeet} 
                                              disabled={selectedItem?.toLowerCase() === "completed"}
                                              >
                                                Meeting Details 
                                              </button>
                                            ) : (
                                              <button className="blueBtnStyle" disabled>
                                                Meeting Ended
                                              </button>
                                            )}
                                          </>
                                        ) : (
                                          <button className="selectedItemBtn newClickBtn"
                                          onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivZoomMeet  } 
                                          disabled={selectedItem?.toLowerCase() === "completed"}
                                          style={{ width: "100px" }}>
                                            Invite Zoom
                                          </button>
                                        )}
                                          
                                      {openDiv === 'ZoomMeet' && (
                                        <div  className="patientStatusBox ZoomMeetMain" style={{zIndex: '11'}} > 
                                        {selectedZoomMeet && Object.keys(selectedZoomMeet).length > 0 ? (
                                      <>
                                      <div className="p-2">
                                      <h2 className="mb-2">Meeting Details</h2>
                                      <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting ID</label>
                                          <b>{selectedZoomMeet.meeting_id}</b>                      
                                        </div> 
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting Title</label>
                                          <b>{selectedZoomMeet.topic}</b>                      
                                        </div> 
                                        
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Date Time</label>
                                          <b>{moment.unix(selectedZoomMeet.start_ts).format("MMM DD, YYYY - h:mm A")}</b>                      
                                        </div>
                                        
                                      
                                        <div className="meetingDetailsTxt">                    
                                          <button onClick={handleButtonClickZoomMeet}>Join Meeting</button>                     
                                        </div> 
                                        </div>
                                        
                                      </> ) : (<>                     
                                              <form onSubmit={handleSaveZoomInvite}>

                                                <div className="innerZoomMeet">
                                                <h2>Schedule Meeting</h2>
                                                <div>
                                                  <label className="labelTxt">Meeting Title</label>
                                                  <input type="text" className="inputStyle w-100" 
                                                      value={meetingTitle}
                                                      onChange={(e) => setMeetingTitle(e.target.value)}
                                                />
                                                {errors.meetingTitle && <span className="errorTxt">{errors.meetingTitle}</span>}
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  
                                                    
                                                    <span className="d-flex gap-4 datePickerDiv">
                                                    <span> 
                                                    <label className="labelTxt">Date</label>
                                                    <DatePicker
                                                            selected={startDate}
                                                            onChange={handleDateChange}
                                                            isClearable
                                                            placeholderText="Select a date"
                                                            dateFormat="MMM dd, yyyy"
                                                            showDisabledMonthNavigation
                                                            minDate={new Date()} // restrict to current and future dates
                                                            className="inputStyle" 
                                                            onKeyDown={(e) => e.preventDefault()}
                                                          /> 
                                                          </span>
                                                          <span>
                                                          <label className="labelTxt">Time</label>
                                                        <DatePicker
                                                        selected={startTime}
                                                        onChange={handleTimeChange}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={45}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        className="inputStyle inputTimeStyle" 
                                                          // Restrict to future dates only
                                                        minDate={now} 

                                                        // Conditional minTime based on whether today is selected
                                                        minTime={startTime && startTime.toDateString() === now.toDateString() ? now : new Date(0, 0, 0, 0, 0)}
                                                        maxTime={new Date(0, 0, 0, 23, 45)} // Allow up to end of the day

                                                        />
                                                        </span> 
                                                        </span>
                                                          
                                                </div> 
                                            
                                                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                                                    <button className="newBtn cancelBtn" onClick={handleCancelZoomMeet}>
                                                      Cancel
                                                    </button>
                                                        <button 
                                                            className="newBtn"
                                                            type="submit" 
                                                            style={{
                                                              cursor: loading ? 'not-allowed' : 'pointer',
                                                              width: '125px'
                                                            }}
                                                          >
                                                            {loading ? 'Saving...' : 'Invite Meeting'}
                                                          </button>
                                                  </div>
                                                </div>
                                              </form>
                                              </>
                                              )}
                                      </div>
                                    )}
                                </li>
                                </>
                                )}

                                {meetingStatusZoom  === 'deactive' && (
                                  <>
                                <li style={{width:"130px"}} className={checkFollowupMeet ?  '' : ' '}>
                              <label>Follow-up 
                            {/* {isPastDate && selectedItem?.toLowerCase() === "completed" && ( <img style={{ width: '18px', marginLeft: '5px' }} src={Icons[0].calendarEdit} className="logoImg  " alt="" onClick={toggleDivFollowup} /> )} */}
                            {isPastDate && selectedItem?.toLowerCase() !== "completed" && (
                                  <img 
                                    style={{ width: '18px', marginLeft: '5px' }} 
                                    src={Icons[0]?.calendarEdit} 
                                    className="logoImg" 
                                    alt="Edit Icon" 
                                    onClick={toggleDivFollowup} 
                                  />
                                )}
                              </label>
                              
                              <b>{followupZoomMeet}</b>
                              <button
                                className={"selectedItemBtn " + (followupZoomMeet ? 'blueBtnStyle newBlueBtn' : 'newClickBtn')}
                                onClick={toggleDivFollowup}
                                style={{ width: followupZoomMeet ? "120px" : "110px", padding: "5px" }}
                              >
                                {
                                  followupZoomMeet 
                                  ? "Meeting Active" 
                                  : checkFollowupMeet 
                                  ? "Request Sent" 
                                  : "Add Followup"}
                              </button>

                            
                                    {/* {followupZoomMeet && checkFollowupMeet ? (
                                      <button
                                        className="selectedItemBtn blueBtnStyle"
                                        onClick={toggleDivFollowup}
                                        style={{ width: "110px", padding: "5px" }}
                                      >
                                        Meeting Active
                                      </button>
                                    ) : checkFollowupMeet ? (
                                      <button
                                        className="selectedItemBtn newClickBtn"
                                        onClick={toggleDivFollowup}
                                        style={{ width: "110px", padding: "5px" }}
                                      >
                                        Patient Request
                                      </button>
                                    ) : (
                                      <button
                                        className="selectedItemBtn newClickBtn"
                                        onClick={toggleDivFollowup}
                                        style={{ width: "110px", padding: "5px" }}
                                      >
                                        Add Followup
                                      </button>
                                    )} */}
                            
                        {openDiv === 'Followup' && (
                          <form  onSubmit={handleSaveFollowup}>
                            <div className="patientStatusBox datePickerDiv followInviteForm ZoomMeetMain"  style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px', zIndex : '11' }}>
                            {!followupZoomMeet ? (<>
                              <h2>Schedule Follow up</h2>
                              {checkAllFollowupMeet ? (<>
                                        <div className="meetingDetailsTxt"> 
                                            <label className="labelTxt">Title: </label>
                                            <b>{selectedItemFollowup.title}</b>                      
                                        </div> 
                              </>) :(
                                <>
                                    <div>
                                          <label className="labelTxt">Follow-Up: Choose any one</label> 
                                            
                                          <ul className="followupOptList">          
                                              {options.map((option, index) => (
                                                  <li
                                                    key={index}
                                                    className={`option-item ${activeOption === option ? 'active' : ''}`} // Add "active" class to selected item
                                                    onClick={() => handleOptionClick(option)} // Update active option on click
                                                  >
                                                    {option}
                                                  </li>
                                                ))}
                                              </ul> 
                                        </div>
                                </>
                              )}
                                        
                                        
                                        
                                          
                                            {selectedItemFollowup?.description  ? 
                                          (<>
                                          <div className="meetingDetailsTxt align-items-start"> 
                                            <label className="labelTxt">Followup Description: </label>
                                            <b className="twoLineEllipsis"   title={selectedItemFollowup.description} >{selectedItemFollowup.description}</b>  
                                          </div>
                                            </> ):(
                                              <>
                                            <label className="labelTxt">Followup Description: </label>
                                            <div className="mt3 inputDiv fillDetailsForm newFillDetailForm d-flex border-0">
                                            <textarea  
                                              className="textareaFollowupNew"  
                                              value={transcripts.medicalcondition}
                                              onChange={handleTextChangeMedicalCondition}   > 
                                          </textarea>
                                          </div> </>
                                          )
                                        }
                                        
                                            
                                    
                                      
                                        <div className="mt-2 mb-2"> 
                                                  <span className="d-flex gap-4 datePickerDiv">
                                                  <span> 
                                                  <label className="labelTxt">Date</label>
                                                  <DatePicker
                                                          //  selected={startDate}
                                                          selected={followupDate || new Date()} 
                                                          // onChange={(date) => setStartDate(date)}
                                                          onChange={handleFollowDateChange}
                                                          isClearable
                                                          placeholderText="Select a date"
                                                          dateFormat="MMM dd, yyyy"
                                                          showDisabledMonthNavigation
                                                          minDate={new Date()} // restrict to current and future dates
                                                          className="inputStyle" 
                                                          onKeyDown={(e) => e.preventDefault()}
                                                        /> 
                                                        </span>
                                                        <span>
                                                        <label className="labelTxt">Time</label>
                                                      <DatePicker
                                                      selected={followupTime}
                                                      onChange={handleFollowTimeChange}
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      timeIntervals={45}
                                                      timeCaption="Time"
                                                      dateFormat="h:mm aa"
                                                      className="inputStyle inputTimeStyle" 
                                                        // Restrict to future dates only
                                                      minDate={now}  
                                                      // Conditional minTime based on whether today is selected
                                                      minTime={startTime && startTime.toDateString() === now.toDateString() ? now : new Date(0, 0, 0, 0, 0)}
                                                      maxTime={new Date(0, 0, 0, 23, 45)} // Allow up to end of the day

                                                      />
                                                      </span> 
                                                      </span> 
                                                
                                              </div> 
                  
                              {errors.followupDays && <div className="errorTxt" >{errors.followupDays}</div>} 

                              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                                <button className="newBtn cancelBtn" onClick={handleCancelFollowup}>
                                  Cancel
                                </button>
                                    <button 
                                        className="newBtn"
                                        type="submit" 
                                        style={{
                                          cursor: loading ? 'not-allowed' : 'pointer',
                                        }}
                                      >
                                        {loading ? 'Saving...' : 'Save'}
                                      </button>
                              </div>
                              </>):(<>
                            
                            <h2 style={{fontSize:'16px'}}  className="mb-2">Follow up Details </h2>
                                      {/* <div className="meetingDetailsTxt">
                                            <label className="labelTxt">Title</label>
                                            <b>{selectedItemFollowup.title}</b>                      
                                        </div> 
                                        <div className="meetingDetailsTxt">
                                            <label className="labelTxt">Description</label>
                                            <b>{selectedItemFollowup.description}</b>                      
                                        </div>  */}
                  
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting ID</label>
                                          <b>{selectedItemFollowup.zoom_meeting.meeting_id}</b>                      
                                        </div> 
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting Title</label>
                                          <b>{selectedItemFollowup.zoom_meeting.topic}</b>                      
                                        </div> 
                                        
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Date Time</label>
                                          <b>{moment.unix(selectedItemFollowup.zoom_meeting.start_ts).format("MMM DD, YYYY - h:mm A")}</b>                      
                                        </div>

                                        <div className="meetingDetailsTxt">                    
                                        {followupZoomMeet ? (
                                              <>
                  {/* 
                                                <label>Zoom Meeting is Active:</label> */}
                                                <button onClick={handleButtonClickFollowZoomMeet}>Join Meeting</button>                                
                                                
                                              </>
                                            ) : (
                                              <>
                                                <button className="" disabled={true} onClick={handleButtonClickZoomMeet}>Request in Progress</button>                  
                                              </>
                                            )}  
                                        </div>  
                          
                                        
                  
                            </>)
                  
                  }
                            </div>
                          </form>
                          
                        
                                    )}
                                </li>
                                </>
                                )}
                                
                              </ul>
                            </div>
                          </div> 
                          <div className="innerDivBox h-auto"> 
                          {FollowupData?.length > 0 ? (
                              FollowupData.map((item, index) => 
                              //   [...FollowupData]
                              // .sort((a, b) => new Date(b.created_on) - new Date(a.created_on)) // Sort by `created_on` in descending order
                              // .map((item, index) => 
                                item.followup_summary ? ( // Check if followup_summary exists
                                  <div key={index}> 
                                    <div className="despInfoDiv">
                                    <div className="headDespInfoDiv">
                                      <ul className="infoDivPatientList">
                                        <li>
                                          <label>
                                          {`${getOrdinalLabel(FollowupData.length - index + 1)} Consultation`}
                                      </label>
                                          <b>{moment(item.created_on).format('MMM DD, YYYY')}</b>
                                        </li>
                                        <li>
                                          <label>Follow up</label>
                                          <b>{item.title}</b>
                                        </li>
                                    

                                            { calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts) ?
                                                  (<>
                                                    <li  >
                                                      <label>Physician</label>
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Office</b>
                                                  </li>
                                                  <li  >
                                                      <label>Patient </label>                                    
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Home</b>
                                                  </li>
                                                  <li  >
                                                      <label>Communication</label>
                                                      <b>Audio & Video</b>
                                                  </li>
                                                    <li >
                                                    <label>Duration</label>{" "}
                                                    <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                                                    </li>
                                                    <li >
                                                    <label>Verbal consent </label>{" "}
                                                    <b>Obtained</b>
                                                    </li>
                                                    
                                                  
                                                  </>
                                                  ):null }

                    
                                      </ul>
                                      
                                      <a onClick={() => handleInputMainSummaryEditFollowup(item.followup_id, item.followup_summary)} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNewSummary " : "editBtnNewSummary "}>
                                        Edit <img src={editMode === item.followup_id ?  Icons[0].editIcon : Icons[0].editRed } /></a> 
                              
                                  </div>
                                  </div>
                                  {/* {!showSummaryEditFollowup ? ( */}
                                  {!editMode || editMode !== item.followup_id ? (
                                    <>     
                                      <pre className="innerDivBoxPC h-auto">{item.followup_summary} </pre>
                                    </>
                                ) :(
                                  <div className='textareaSummary  innerDivBox w-100' style={{height:'auto'}}>
                                    <textarea 
                                    value={editText}
                                    onChange={(e) => setEditText(e.target.value)}
                                    //  value={item.followup_summary} 
                                    //  onChange={handleInputChangeSummaryEditFollowup} 
                                    style={{    height: '200px', width:' 100%'}} /> 
                                    <hr></hr>
                                  <div className='editSaveOpt' >
                                      {ShowLoaderSummaryFollowup ? (<>
                                        <button  > Loading
                                        <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div> 
                                      </button>
                                      </>):(<>
                                        <button  onClick={() => handleSaveSummaryEditFollowup(item?.followup_id)}>  
                                      Save Changes
                                      </button>
                                      </>)

                                      }
                                      
                                      <button onClick={handleCancelClickSummaryEditFollowup}>Cancel</button>
                                    </div>
                                  </div> 

                                )}

                                  
                                    <hr style={{margin:'20px 0'}}></hr>
                                  </div>
                                ) : null // If followup_summary is null, render nothing
                              )
                            ) : null}
                            
                              <div className="despInfoDiv">
                                <div className="headDespInfoDiv">
                                  <ul className="infoDivPatientList">
                                    <li>
                                      <label>1st Consultation</label>
                                      <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
                                    </li>
                                    <li>
                                      <label>Illnesses</label>
                                      <b>{RecordData.illness}</b>
                                    </li>
                                    {meetingStatusZoom ?
                                                  (<>
                                                    <li  >
                                                      <label>Physician</label>
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Office</b>
                                                  </li>
                                                  <li  >
                                                      <label>Patient </label>                                    
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Home</b>
                                                  </li>
                                                  <li  >
                                                      <label>Communication</label>
                                                      <b>Audio & Video</b>
                                                  </li>
                                                    <li >
                                                    <label>Duration</label>{" "}
                                                    <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                                                    </li>

                                                    
                                                    <li >
                                                    <label>Verbal consent </label>{" "}
                                                    <b>Obtained</b>
                                                    </li>
                                                  
                                                  </>
                                                  ):null }

                                    
                                  
                                      
                                        
                                  </ul>
                                {!EmergencyQuest ? ( <>
                                
                                <a onClick={() => handleInputMainSummaryEditAIChatbot(followSummaryEditAIChatbot || RecordData.chatbot_summary )} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNewSummary " : "editBtnNewSummary "}>
                                  Edit <img src={!showSummaryEditAIChatbot ? Icons[0].editIcon : Icons[0].editRed } /></a> </>) :  ('')}
                                
                                  </div>
                            </div>
                    {/* {FollowupData.followup_summary ? <p className="innerDivBox">{FollowupData.followup_summary} </p> :  <p className="innerDivBox"> - </p> } */}
                      {!showSummaryEditAIChatbot ? (
                                    <>  
                                        {EmergencyQuest ?
                                        <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> 
                                        : 
                                        ConditionalDiagnosis ? <pre className="innerDivBoxPC h-auto" >{followSummaryEditAIChatbot } </pre> : 'No summary' 
                                        }

                                        {!EmergencyQuest && (
                                          <>
                                            {PCP_Pharmacy && PCP_Pharmacy.pcp && PCP_Pharmacy.pcp.name && PCP_Pharmacy.pcp.address ? (
                                              <>
                                                <b>Primary Care Physician (PCP): </b>
                                                {PCP_Pharmacy.pcp.name + ', ' + PCP_Pharmacy.pcp.address}
                                                <br />
                                              </>
                                            ) : null}

                                            {PCP_Pharmacy && PCP_Pharmacy.pharmacy && PCP_Pharmacy.pharmacy.name && PCP_Pharmacy.pharmacy.address ? (
                                              <>
                                                <b>Pharmacy information: </b>
                                                {PCP_Pharmacy.pharmacy.name + ', ' + PCP_Pharmacy.pharmacy.address}
                                              </>
                                            ) : null}
                                          </>
                                        )} 
                                    </>
                                ) :(
                                  <div className='textareaSummary innerDivBox w-100' style={{height:'auto'}}>
                                    <textarea value={followSummaryEditAIChatbot} onChange={handleInputChangeSummaryEditAIChatbot} style={{    height: '200px', width:' 100%'}} /> 
                                    <hr></hr>
                                  <div className='editSaveOpt' >
                                      {ShowLoaderSummaryAIChatbot ? (<>
                                        <button  > Loading
                                        <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div> 
                                      </button>
                                      </>):(<>
                                        <button  onClick={() => handleSaveSummaryEditAIChatbot()}>  
                                      Save Changes
                                      </button>
                                      </>)

                                      }
                                      
                                      <button onClick={handleCancelClickSummaryEditAIChatbot}>Cancel</button>
                                    </div>
                                  </div> 

                                )}

                          </div>

                          <hr></hr>


                          <div className="d-block gap-4">
                      
                      <div className="despInfoDiv PlanCareTab w-100 overflow-auto d-block border-0 h-100" ref={scrollupRef} style={{ marginTop: '0px' }}>
                      <div className="despInfoDiv">
                            <h2>Differential Diagnosis</h2>
                            </div>
                      {/* <div ></div> {/* The scroll-up target */}
                      {EmergencyQuest ? (<>
                      <div className="p-3">

                        <h2>Differential Diagnosis</h2> <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> 
                        Urgent: Patient has called for emergency assistance.</p> </div>
                      </>)
                        
                      : (<>
                        <div className="accordion" id="accordionExampleNew">
                      {FollowupData?.length > 0 &&
                          FollowupData.map((item, index) =>
                            item.followup_CD ? (
                              <AccordionSection
                                key={index}
                                setData={FollowupData}
                                // title={`Follow up - Differential Diagnosis`}
                                title={`Follow up - ${getOrdinalLabel(FollowupData.length - index + 1)} Consultation`}
                                content={followConditionalDiagnosis || item?.followup_CD}
                                onEditClick={() => handleInputMainPointFollowCD(item?.followup_id, item?.followup_CD, index+1)}
                                onSaveClick={() => handleSaveClickMPointFollowCD(item?.followup_id, index+1)}
                                onCancelClick={handleCancelClickMPointFollowCD}
                                showLoader={ShowLoader}
                                isEditing={showMainPointFollowCD}
                                // isEditing={editingAccordion === item?.followup_id}
                                // value={updatedContent+index+1}
                                // onChange={(e) => setUpdatedContent+index+1(e.target.value)}
                                value={followConditionalDiagnosis}
                                onChange={(e) => setFollowConditionalDiagnosis(e.target.value)}

                                EmergencyQuest={EmergencyQuest}
                                Icons={Icons}
                                onAccordionClick={handleAccordionClick} // Pass the scroll handler
                          
                                isActive={activeAccordion === `Follow up - Differential Diagnosis`}
                                // setActiveAccordion={setActiveAccordion}
                                setActiveAccordion={() =>
                                  handleAccordionToggle(`Follow up - Differential Diagnosis`)
                                }

                                // setActive={setActive} // Set the active accordion
                                // active={active === 'Follow_up_Details'} // Check if the section is active
                              
                              />
                            ) : null
                          )}

                        <AccordionSection
                          title="1st Consultation"
                          content={ConditionalDiagnosis || ''}
                          onEditClick={handleInputMainPointCD}
                          onSaveClick={handleSaveClickMPointCD}
                          onCancelClick={handleCancelClickMPointCD}
                          showLoader={ShowLoader}
                          isEditing={showMainPointCD}
                          value={ConditionalDiagnosis}
                          onChange={(e) => setConditionalDiagnosis(e.target.value)}
                          EmergencyQuest={EmergencyQuest}
                          Icons={Icons}
                          onAccordionClick={handleAccordionClick} // Pass the scroll handler
                          isActive={activeAccordion === 'Differential Diagnosis'} 
                          setActiveAccordion={() => handleAccordionToggle('Differential Diagnosis')}
                          />
                      </div> 
                      </>)
                      }   
                    
                      
                          </div> 
                      <div className="despInfoDiv PlanCareTab w-100 overflow-auto border-0 h-100">
                            <div className="despInfoDiv">
                            <h2>Plan of Care</h2>
                            </div>
                    
                      {EmergencyQuest ? (<>
                      <div className="p-3">
                        <h2>Plan of Care</h2> <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> 
                        Urgent: Patient has called for emergency assistance.</p> </div>
                      </>)
                        
                      : (<>
                        <div className="accordion" id="accordionExample">
                      {FollowupData?.length > 0 &&
                          FollowupData.map((item, index) =>
                            item.followup_CD ? (
                              <AccordionSectionPoC
                                key={index}
                                title={`Follow up - ${getOrdinalLabel(FollowupData.length - index + 1)} Consultation`}
                                content={followPlanOnCareData || item?.followup_PoC}
                                onEditClick={() => handleInputMainPointFollowPoC(followPlanOnCareData || item?.followup_PoC)}
                                onSaveClick={() => handleSaveClickMPointFollowPoC(item?.followup_id)}
                                onCancelClick={handleCancelClickMPointFollowPoC}
                                showLoader={ShowLoader}
                                isEditing={showMainPointFollowPoC}
                                value={followPlanOnCareData}
                                onChange={(e) => setFollowPlanOnCareData(e.target.value)}
                                EmergencyQuest={EmergencyQuest}
                                Icons={Icons}
                                onAccordionClick={handleAccordionClickPoc} // Pass the scroll handler
                                isActive={activeAccordionPoc === 'Follow up - Plan of Care'} 
                                setActiveAccordionPoc={() => handleAccordionTogglePoc('Follow up - Plan of Care')}
                                // setActive={setActive} // Set the active accordion
                                // active={active === 'Follow_up_Details'} // Check if the section is active
                              
                              />
                            ) : null
                          )}

                          
                        {/* <AccordionSectionPoC
                          title="Plan of Care - Online"
                          content={zoomPoC || ''}
                          onEditClick={handleInputMainPoint}
                          onSaveClick={handleSaveClickMPoint}
                          onCancelClick={handleCancelClickMPoint}
                          showLoader={ShowLoader}
                          isEditing={showMainPoint}
                          value={zoomPoC}
                          onChange={(e) => setPlanOnCareData(e.target.value)}
                          EmergencyQuest={EmergencyQuest}
                          Icons={Icons}
                          onAccordionClick={handleAccordionClickPoc} // Pass the scroll handler
                          isActive={activeAccordionPoc === 'Plan of Care - Online'} 
                          setActiveAccordionPoc={() => handleAccordionTogglePoc('Plan of Care - Online')}
                          // setActive={setActive} // Set the active accordion
                          // active={active === 'Differential_Diagnosis'} // Check if the section is active
                          /> */}

                        <AccordionSectionPoC
                          title="1st Consultation"
                          content={PlanOnCareData || ''}
                          onEditClick={handleInputMainPoint}
                          onSaveClick={handleSaveClickMPoint}
                          onCancelClick={handleCancelClickMPoint}
                          showLoader={ShowLoader}
                          isEditing={showMainPoint}
                          value={PlanOnCareData}
                          onChange={(e) => setPlanOnCareData(e.target.value)}
                          EmergencyQuest={EmergencyQuest}
                          Icons={Icons}
                          onAccordionClick={handleAccordionClickPoc} // Pass the scroll handler
                          isActive={activeAccordionPoc === 'Plan of Care'} 
                          setActiveAccordionPoc={() => handleAccordionTogglePoc('Plan of Care')}
                          // setActive={setActive} // Set the active accordion
                          // active={active === 'Differential_Diagnosis'} // Check if the section is active
                          />
                      </div> 
                      </>)
                      }  

                      {/* {FollowupData?.length > 0 ? (
                        FollowupData.map((item, index) => 
                          item.followup_PoC ? ( 
                            <div key={index}>
                              <h2>Follow up - Plan of Care
                                    {!EmergencyQuest ? ( <a onClick={() => handleInputMainPointFollowPoC(followPlanOnCareData || item?.followup_PoC)} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}> Edit <img src={!showMainPointFollowPoC ? Icons[0].editIcon : Icons[0].editRed } /></a>) :  ('')}
                            
                              </h2>
                          
                              {!showMainPointFollowPoC ? (
                                  <>     
                                    <pre className="innerDivBoxPC h-auto overflow-hidden">
                                      {followPlanOnCareData || item.followup_PoC}
                                    </pre>
                                  </>
                              ) :(
                                <div className='textareaSummary innerDivBox w-100'>
                                  <textarea value={followPlanOnCareData} onChange={handleInputChangeMPointFollowPoC} style={{    height: '80%', width:' 100%'}} /> 
                                  <hr></hr>
                                <div className='editSaveOpt' >
                                    {ShowLoader ? (<>
                                      <button  > Loading
                                      <div className="spinner-border text-light" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </div> 
                                    </button>
                                    </>):(<>
                                      <button  onClick={() => handleSaveClickMPointFollowPoC(item?.followup_id)}>  
                                    Save Changes
                                    </button>
                                    </>)

                                    }
                                    
                                    <button onClick={handleCancelClickMPointFollowPoC}>Cancel</button>
                                  </div>
                                </div> 

                              )}

                                  
                              <hr />
                            </div>
                          ) : null // If followup_PoC is empty or null, render nothing
                        )
                      ) : null}


                          <h2>Plan of Care  
                          {!EmergencyQuest ? ( <a onClick={handleInputMainPoint} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}> Edit <img src={!showMainPoint ? Icons[0].editIcon : Icons[0].editRed } /></a>) :  ('')}
                          </h2>
                          { FollowupData.followup_PoC ? <pre className="innerDivBoxPC h-auto overflow-hidden">{FollowupData.followup_PoC}</pre> : '' }          
                        
                          {!showMainPoint ? (
                                  <>     
                                  {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : PlanOnCareData ? <pre className="innerDivBoxPC h-auto  overflow-hidden">{PlanOnCareData}</pre> : 'No Plan On Care' }  
                                </>
                              ) :(
                                <div className='textareaSummary innerDivBox w-100'>
                                  <textarea value={PlanOnCareData} onChange={handleInputChangeMPoint} style={{    height: '80%', width:' 100%'}} /> 
                                  <hr></hr>
                                <div className='editSaveOpt' >
                                    {ShowLoader ? (<>
                                      <button  > Loading
                                      <div className="spinner-border text-light" role="status">
                                      <span className="visually-hidden">Loading...</span>
                                    </div> 
                                    </button>
                                    </>):(<>
                                      <button onClick={handleSaveClickMPoint}>  
                                    Save Changes
                                    </button>
                                    </>)

                                    }
                                    
                                    <button onClick={handleCancelClickMPoint}>Cancel</button>
                                  </div>
                                </div> 

                              )} */}
                              </div>
                      
                      </div>
                      <hr/>
                      <div className="despInfoDiv">
                            <h2>Reports </h2>
                            </div>
                            <div className="innerDivBoxPC h-auto overflow-hidden"> 
                              
                    
                    <ol className="reportListOptLink" style={{fontSize:'16px' }}>
                    {sortedGroupedDocuments.length > 0 ? (
                      sortedGroupedDocuments
                        .filter(({ documents }) => documents && documents.length > 0) // Filter out empty or null documents
                        .map(({ doc_type, documents, Name  }) => (
                          <li key={doc_type}>
                        
                            {documents.map((doc, index) => (
                              <div key={doc.doc_id} style={{ marginBottom: "0px" }}>
                                  <b style={{ textTransform: "capitalize", textDecoration: "none" }} className={ doc_type === "other_report"  && index !== 0 && 'd-none'}>
                                  {Name} 
                                  {/* {doc_type.replace('_', ' ')} */}
                                  </b>
                                {/* Document URL with conditional button */}
                                {doc.doc_url && (
                                  <span
                                    className={
                                      "pdfButtonModal" + (doc_type === "other_report" ? " d-none" : "")
                                    }
                                  >
                                    <button
                                      className="pdfButton pdfViewBtn"
                                      onClick={() => openModal(doc?.doc_url)}
                                    >
                                      View{" "}
                                      <img
                                        src={Icons[0].viewPDF}
                                        alt="PDF Icon"
                                        style={{ width: "24px", marginLeft: "10px" }}
                                      />
                                    </button>
                                  </span>
                                )}

                                {/* Document Text */}
                                {/* Document Text */}
                                {doc.doc_text &&(
                                  <p style={{fontSize:'16px', marginBottom: "0" }}>{doc.doc_text}</p>
                                )}

                                <div className={"d-flex justify-content-between align-items-center "  +(doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null) }>
                                  <h3
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      fontWeight: "600",
                                    }}
                                    className="my-0"
                                  >
                                  {
                                      doc_type === "other_report" ? (
                                        <>
                                          {index + 1}. Other Report Summary
                                        </>
                                      ) : doc_type === "pcp" || doc_type === "pharmacy" ? (
                                          null
                                      ) : doc.doc_summary ? (
                                        <>Report Summary</>
                                      ) : null
                                    }
                                  </h3>

                                  {/* Document URL Button (specific condition) */}
                                  {doc.doc_url && (
                                    <span
                                      className={
                                        "pdfButtonModal" +
                                        (doc_type !== "other_report" ? " d-none" : "")
                                      }
                                    >
                                      <button
                                        className="pdfButton pdfViewBtn"
                                        onClick={() => openModal(doc?.doc_url)}
                                      >
                                        View{" "}
                                        <img
                                          src={Icons[0].viewPDF}
                                          alt="PDF Icon"
                                          style={{ width: "24px", marginLeft: "10px" }}
                                        />
                                      </button>
                                    </span>
                                  )}
                                </div>

                                {/* Document Summary */}
                                {
                                  doc.doc_summary ? (
                                    <pre
                                      style={{ margin: "5px 0", textTransform: "capitalize" }}
                                      className={doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null}
                                    >
                                      {doc.doc_summary}
                                    </pre>
                                  ) : null
                                }

                                {/* Conditional Divider */}
                                {doc.doc_url && (
                                  <hr className={doc_type !== "other_report" ? " d-none" : ""} />
                                )}
                              </div>
                            ))}
                          </li>
                        ))
                    ) : (
                      <p style={{ textAlign: "center" }}>No reports found</p>
                    )}
                  </ol>


                        
                            </div>



                      </div>
                  
                      <nav className="d-flex d-none" style={{position:'relative'}}>
                        <div className="nav nav-tabs mb-0" id="nav-tab" role="tablist">
                          <button className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
                              onClick={() => handleTabClick(0)} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Summary</button>
                          <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
                              onClick={() => handleTabClick(1)} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Plan of Care</button>
                          <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
                              onClick={() => handleShowChat(2)} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Pre-Diagnosis</button>
                          <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
                              onClick={() => handleShowChat(3)} id="nav-reportData-tab" data-bs-toggle="tab" data-bs-target="#nav-reportData" type="button" role="tab" aria-controls="nav-reportData" aria-selected="false">Reports</button>
                        </div>
                        <button className="openModalViewAllBtn  " onClick={openModalViewAll}>Final Report</button>
                      </nav>
                      <div className="whiteBg tab-content p-3 bg-white d-none" id="nav-tabContent" >
                        <div className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                          <div className="despInfoDiv position-relative  d-flex" style={{height: '70px'}}>
                            <h2>Summary</h2>
                            <div className="headDespInfoDiv position-absolute top-0 end-0 ">
                            
                              <ul className="optionUpdateList">
                            
                                <li> 
                                <label>Physical  Exam 
                                    {/* <button className={'editIcon ' + (checkPhysicalExam || filledCheckPhysicalExam ?  'btnactive' : 'd-none') }
                                      onClick={selectedItem !== "completed" ? toggleDivPhysicalExam : null} 
                                      disabled={selectedItem !== "completed"}
                                    >
                                      <img src={ Icons[0].editIcon } className="logoImg w-100" alt="" /> 
                                    </button>   */}
                                    
                                    <button
                                        className={
                                          'editIcon ' + 
                                          (checkPhysicalExam || filledCheckPhysicalExam ? 'btnactive ' : '') + 
                                          (selectedItem?.toLowerCase() === "completed" ? 'd-none' : '')
                                        }
                                        onClick={toggleDivPhysicalExam}
                                      >
                                        <img src={Icons[0].editIcon} className="logoImg w-100" alt="Edit Icon" />
                                      </button>
                                  
                                  </label>
                          
                                {/* {bpHgTxt && bpMMTxt && heartrateTxt ? (  */}
                                {(checkPhysicalExam || filledCheckPhysicalExam) ? (
                                    <>  
                                    <b className={bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt}>
                                    {bpHgTxt && bpMMTxt ? (`${editbpHgTxt} / ${editbpMMTxt} - `) : null  }
                                    {heartrateTxt ?`${editheartrateTxt} BPM` : null } 
                                    </b>
                                    </> 
                                  ) :  (<>
                                  <button className="selectedItemBtn newClickBtn"  onClick={toggleDivPhysicalExam} > 
                                    Add BP/BPM  
                                </button>
                                    {/* Dropdown div to display the list */}
                                    {/* {isOpenPhysicalExam && ( */} 
                                    </>)} 
                                    {openDiv === 'PhysicalExam' && (

                  <div className="patientStatusBox  PhysicalExamDivBox" style={{ zIndex: '11'}} >
                    
                  <div className=" black-80 center stepFirstForm w-100">

                  <from  className="measure w-100" >


                  <div className='d-block mt-0 ' >

                  <div className='d-grid mt-2   gap-3 '>
                      <div className='w-100'>
                            <label className='labelTxt' style={{width:"120px"}}>Blood pressure</label>
                              <div className="inputDiv"> 
                            
                          <div className="timepicker">
                            <input
                              type="text"
                              className="hh"
                              maxLength="3"
                              placeholder="Mm"
                              ref={bpMMRef}
                              // onInput={(e) => handleInput(e, bpHgRef, null)}
                              // onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                              value={bpMMTxt}  // Ensure the input value reflects the state
                              onInput={(e) => {
                                setbpMMTxt(e.target.value);  // Update state when input changes
                                handleInput(e, bpHgRef, null);
                              }}
                              onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                            />
                            -
                            <input
                              type="text"
                              className="mm"
                              maxLength="3"
                              placeholder="Hg"
                              ref={bpHgRef}
                              // onInput={(e) => handleInput(e, null, bpMMRef)}
                              // onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                              value={bpHgTxt}  // Ensure the input value reflects the state
                              onInput={(e) => {
                                setbpHgTxt(e.target.value);  // Update state when input changes
                                handleInput(e, null, bpMMRef);
                              }}
                              onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                            />
                          </div>  
                                <label  style={{width:"80px", textAlign: 'right'}}>Mm - Hg</label>   
                            </div>
                            {errorMessage && <span className="errorTxt">{errorMessage}</span>}
                            {errors.bpHgTxt  && <span v>{errors.bpHgTxt }</span>}
                      </div>

                      <div className='w-100 '>
                      <label className='labelTxt pr-0' style={{width:"130px"}}>Heart rate</label>
                              <div className=" inputDiv"> 
                                  <input type="text"   
                                  style={{paddingLeft:"10px"}}
                                    value={heartrateTxt} 
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-digit characters
                                      const numericValue = value.replace(/\D/g, '');
                                      // Limit to 4 digits
                                      if (numericValue.length <= 3) {
                                        setheartrateTxt(numericValue);
                                      }
                                    }} 
                                  />  
                                  <label>bpm</label>   
                              </div>
                              {errors.heartrateTxt && <span  className="errorTxt" >{errors.heartrateTxt}</span>}
                      </div>

                    </div>
                  </div> 
                  <span className="d-flex gap-4" style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                  <button className="newBtn cancelBtn" onClick={handleCancelPhysicalExam} >Close</button>
                  <button onClick={handleSubmit} 
                    disabled={ShowLoader}
                    className="newBtn   "  
                    style={{ 
                      cursor: ShowLoader ? 'not-allowed' : 'pointer',
                    }}
                    type="submit" >
                      {ShowLoader ? 'Saving...' : 'Save'}</button>

                  </span>

                  </from>
                  </div>

                  </div>
                  )}
                                

                                </li>
                                <li>
                                  <label>Patient Status</label>
                                  {selectedItem ? (<>
                                      <button className={"selectedItemBtn color" +(selectedItem)}
                                      onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivStatus} 
                                      disabled={selectedItem?.toLowerCase() === "completed"}
                                      >
                                      {selectedItem}
                                      </button></>
                                  ) : (<>
                                    <button className={"selectedItemBtn cancelBtn"} onClick={toggleDivStatus}  >
                                    Add Status
                                    </button></>
                                  )}
                                  

                                      {/* Dropdown div to display the list */}
                                    {/* {isOpen && ( */}
                                  {openDiv === 'Status' && (
                                      <div className="patientStatusBox" style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px' , zIndex: '11'}}>
                                        <label className='labelTxt pr-0' style={{width:"130px"}}>Patient Status</label>
                                        <ul style={{ listStyle: 'none', padding: 0 }}>
                                          {items.map((item, index) => (
                                            <li
                                              className={activeItem?.toLowerCase()  === item?.toLowerCase() ?  'active' : ''}
                                              key={index}
                                              onClick={() => handleItemClick(item)} 
                                            >
                                              {item}
                                            </li>
                                          ))}
                                        </ul>

                                        {/* Save and Cancel buttons */}
                                        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                                          <button  className="newBtn cancelBtn"  onClick={handleCancel} >
                                            Cancel
                                          </button>
                                          <button
                                          className="newBtn"
                                            onClick={handleSave}
                                            disabled={!activeItem || loading} // Disable if no item selected or loading
                                            style={{ 
                                              cursor: loading ? 'not-allowed' : 'pointer',
                                            }}
                                          >
                                            {loading ? 'Saving...' : 'Save'}
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                </li>
                                {meetingStatusZoom  !== 'deactive' && (
                                  <>
                                <li className="text-left">
                                  <label>Patient Meeting</label> 
                                
                                    {selectedZoomMeet && Object.keys(selectedZoomMeet).length > 0 ? (
                                          <>
                                            {(!meetingStatusZoom || meetingStatusZoom === 'active') ? (
                                              <button className="blueBtnStyle"
                                              onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivZoomMeet} 
                                              disabled={selectedItem?.toLowerCase() === "completed"}
                                              >
                                                Meeting Details 
                                              </button>
                                            ) : (
                                              <button className="blueBtnStyle" disabled>
                                                Meeting Ended
                                              </button>
                                            )}
                                          </>
                                        ) : (
                                          <button className="selectedItemBtn newClickBtn"
                                          onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivZoomMeet  } 
                                          disabled={selectedItem?.toLowerCase() === "completed"}
                                          style={{ width: "100px" }}>
                                            Invite Zoom
                                          </button>
                                        )}
                                          
                                      {openDiv === 'ZoomMeet' && (
                                        <div  className="patientStatusBox ZoomMeetMain" style={{zIndex: '11'}} > 
                                        {selectedZoomMeet && Object.keys(selectedZoomMeet).length > 0 ? (
                                      <>
                                      <div className="p-2">
                                      <h2 className="mb-2">Meeting Details</h2>
                                      <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting ID</label>
                                          <b>{selectedZoomMeet.meeting_id}</b>                      
                                        </div> 
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting Title</label>
                                          <b>{selectedZoomMeet.topic}</b>                      
                                        </div> 
                                        
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Date Time</label>
                                          <b>{moment.unix(selectedZoomMeet.start_ts).format("MMM DD, YYYY - h:mm A")}</b>                      
                                        </div>
                                        
                                      
                                        <div className="meetingDetailsTxt">                    
                                          <button onClick={handleButtonClickZoomMeet}>Join Meeting</button>                     
                                        </div> 
                                        </div>
                                        
                                      </> ) : (<>                     
                                              <form onSubmit={handleSaveZoomInvite}>

                                                <div className="innerZoomMeet">
                                                <h2>Schedule Meeting</h2>
                                                <div>
                                                  <label className="labelTxt">Meeting Title</label>
                                                  <input type="text" className="inputStyle w-100" 
                                                      value={meetingTitle}
                                                      onChange={(e) => setMeetingTitle(e.target.value)}
                                                />
                                                {errors.meetingTitle && <span className="errorTxt">{errors.meetingTitle}</span>}
                                                </div>
                                                <div className="mt-2 mb-2">
                                                  
                                                    
                                                    <span className="d-flex gap-4 datePickerDiv">
                                                    <span> 
                                                    <label className="labelTxt">Date</label>
                                                    <DatePicker
                                                            selected={startDate}
                                                            onChange={handleDateChange}
                                                            isClearable
                                                            placeholderText="Select a date"
                                                            dateFormat="MMM dd, yyyy"
                                                            showDisabledMonthNavigation
                                                            minDate={new Date()} // restrict to current and future dates
                                                            className="inputStyle" 
                                                            onKeyDown={(e) => e.preventDefault()}
                                                          /> 
                                                          </span>
                                                          <span>
                                                          <label className="labelTxt">Time</label>
                                                        <DatePicker
                                                        selected={startTime}
                                                        onChange={handleTimeChange}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={45}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        className="inputStyle inputTimeStyle" 
                                                          // Restrict to future dates only
                                                        minDate={now} 

                                                        // Conditional minTime based on whether today is selected
                                                        minTime={startTime && startTime.toDateString() === now.toDateString() ? now : new Date(0, 0, 0, 0, 0)}
                                                        maxTime={new Date(0, 0, 0, 23, 45)} // Allow up to end of the day

                                                        />
                                                        </span> 
                                                        </span>
                                                          
                                                </div> 
                                            
                                                  <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                                                    <button className="newBtn cancelBtn" onClick={handleCancelZoomMeet}>
                                                      Cancel
                                                    </button>
                                                        <button 
                                                            className="newBtn"
                                                            type="submit" 
                                                            style={{
                                                              cursor: loading ? 'not-allowed' : 'pointer',
                                                              width: '125px'
                                                            }}
                                                          >
                                                            {loading ? 'Saving...' : 'Invite Meeting'}
                                                          </button>
                                                  </div>
                                                </div>
                                              </form>
                                              </>
                                              )}
                                      </div>
                                    )}
                                </li>
                                </>
                                )}

                                {meetingStatusZoom  === 'deactive' && (
                                  <>
                                <li style={{width:"130px"}} className={checkFollowupMeet ?  '' : ' '}>
                              <label>Follow-up 
                            {/* {isPastDate && selectedItem?.toLowerCase() === "completed" && ( <img style={{ width: '18px', marginLeft: '5px' }} src={Icons[0].calendarEdit} className="logoImg  " alt="" onClick={toggleDivFollowup} /> )} */}
                            {isPastDate && selectedItem?.toLowerCase() !== "completed" && (
                                  <img 
                                    style={{ width: '18px', marginLeft: '5px' }} 
                                    src={Icons[0]?.calendarEdit} 
                                    className="logoImg" 
                                    alt="Edit Icon" 
                                    onClick={toggleDivFollowup} 
                                  />
                                )}
                              </label>
                              
                              <b>{followupZoomMeet}</b>
                              <button
                                className={"selectedItemBtn " + (followupZoomMeet ? 'blueBtnStyle newBlueBtn' : 'newClickBtn')}
                                onClick={toggleDivFollowup}
                                style={{ width: followupZoomMeet ? "120px" : "110px", padding: "5px" }}
                              >
                                {
                                  followupZoomMeet 
                                  ? "Meeting Active" 
                                  : checkFollowupMeet 
                                  ? "Request Sent" 
                                  : "Add Followup"}
                              </button>

                            
                                    {/* {followupZoomMeet && checkFollowupMeet ? (
                                      <button
                                        className="selectedItemBtn blueBtnStyle"
                                        onClick={toggleDivFollowup}
                                        style={{ width: "110px", padding: "5px" }}
                                      >
                                        Meeting Active
                                      </button>
                                    ) : checkFollowupMeet ? (
                                      <button
                                        className="selectedItemBtn newClickBtn"
                                        onClick={toggleDivFollowup}
                                        style={{ width: "110px", padding: "5px" }}
                                      >
                                        Patient Request
                                      </button>
                                    ) : (
                                      <button
                                        className="selectedItemBtn newClickBtn"
                                        onClick={toggleDivFollowup}
                                        style={{ width: "110px", padding: "5px" }}
                                      >
                                        Add Followup
                                      </button>
                                    )} */}
                            
                        {openDiv === 'Followup' && (
                          <form  onSubmit={handleSaveFollowup}>
                            <div className="patientStatusBox datePickerDiv followInviteForm ZoomMeetMain"  style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px', zIndex : '11' }}>
                            {!followupZoomMeet ? (<>
                              <h2>Schedule Follow up</h2>
                              {checkAllFollowupMeet ? (<>
                                        <div className="meetingDetailsTxt"> 
                                            <label className="labelTxt">Title: </label>
                                            <b>{selectedItemFollowup.title}</b>                      
                                        </div> 
                              </>) :(
                                <>
                                    <div>
                                          <label className="labelTxt">Follow-Up: Choose any one</label> 
                                            
                                          <ul className="followupOptList">          
                                              {options.map((option, index) => (
                                                  <li
                                                    key={index}
                                                    className={`option-item ${activeOption === option ? 'active' : ''}`} // Add "active" class to selected item
                                                    onClick={() => handleOptionClick(option)} // Update active option on click
                                                  >
                                                    {option}
                                                  </li>
                                                ))}
                                              </ul> 
                                        </div>
                                </>
                              )}
                                        
                                        
                                        
                                          
                                            {selectedItemFollowup?.description  ? 
                                          (<>
                                          <div className="meetingDetailsTxt align-items-start"> 
                                            <label className="labelTxt">Followup Description: </label>
                                            <b className="twoLineEllipsis"   title={selectedItemFollowup.description} >{selectedItemFollowup.description}</b>  
                                          </div>
                                            </> ):(
                                              <>
                                            <label className="labelTxt">Followup Description: </label>
                                            <div className="mt3 inputDiv fillDetailsForm newFillDetailForm d-flex border-0">
                                            <textarea  
                                              className="textareaFollowupNew"  
                                              value={transcripts.medicalcondition}
                                              onChange={handleTextChangeMedicalCondition}   > 
                                          </textarea>
                                          </div> </>
                                          )
                                        }
                                        
                                            
                                    
                                      
                                        <div className="mt-2 mb-2"> 
                                                  <span className="d-flex gap-4 datePickerDiv">
                                                  <span> 
                                                  <label className="labelTxt">Date</label>
                                                  <DatePicker
                                                          //  selected={startDate}
                                                          selected={followupDate || new Date()} 
                                                          // onChange={(date) => setStartDate(date)}
                                                          onChange={handleFollowDateChange}
                                                          isClearable
                                                          placeholderText="Select a date"
                                                          dateFormat="MMM dd, yyyy"
                                                          showDisabledMonthNavigation
                                                          minDate={new Date()} // restrict to current and future dates
                                                          className="inputStyle" 
                                                          onKeyDown={(e) => e.preventDefault()}
                                                        /> 
                                                        </span>
                                                        <span>
                                                        <label className="labelTxt">Time</label>
                                                      <DatePicker
                                                      selected={followupTime}
                                                      onChange={handleFollowTimeChange}
                                                      showTimeSelect
                                                      showTimeSelectOnly
                                                      timeIntervals={45}
                                                      timeCaption="Time"
                                                      dateFormat="h:mm aa"
                                                      className="inputStyle inputTimeStyle" 
                                                        // Restrict to future dates only
                                                      minDate={now}  
                                                      // Conditional minTime based on whether today is selected
                                                      minTime={startTime && startTime.toDateString() === now.toDateString() ? now : new Date(0, 0, 0, 0, 0)}
                                                      maxTime={new Date(0, 0, 0, 23, 45)} // Allow up to end of the day

                                                      />
                                                      </span> 
                                                      </span> 
                                                
                                              </div> 
                  
                              {errors.followupDays && <div className="errorTxt" >{errors.followupDays}</div>} 

                              <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
                                <button className="newBtn cancelBtn" onClick={handleCancelFollowup}>
                                  Cancel
                                </button>
                                    <button 
                                        className="newBtn"
                                        type="submit" 
                                        style={{
                                          cursor: loading ? 'not-allowed' : 'pointer',
                                        }}
                                      >
                                        {loading ? 'Saving...' : 'Save'}
                                      </button>
                              </div>
                              </>):(<>
                            
                            <h2 style={{fontSize:'16px'}}  className="mb-2">Follow up Details </h2>
                                      {/* <div className="meetingDetailsTxt">
                                            <label className="labelTxt">Title</label>
                                            <b>{selectedItemFollowup.title}</b>                      
                                        </div> 
                                        <div className="meetingDetailsTxt">
                                            <label className="labelTxt">Description</label>
                                            <b>{selectedItemFollowup.description}</b>                      
                                        </div>  */}
                  
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting ID</label>
                                          <b>{selectedItemFollowup.zoom_meeting.meeting_id}</b>                      
                                        </div> 
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Meeting Title</label>
                                          <b>{selectedItemFollowup.zoom_meeting.topic}</b>                      
                                        </div> 
                                        
                                        <div className="meetingDetailsTxt">
                                          <label className="labelTxt">Date Time</label>
                                          <b>{moment.unix(selectedItemFollowup.zoom_meeting.start_ts).format("MMM DD, YYYY - h:mm A")}</b>                      
                                        </div>

                                        <div className="meetingDetailsTxt">                    
                                        {followupZoomMeet ? (
                                              <>
                  {/* 
                                                <label>Zoom Meeting is Active:</label> */}
                                                <button onClick={handleButtonClickFollowZoomMeet}>Join Meeting</button>                                
                                                
                                              </>
                                            ) : (
                                              <>
                                                <button className="" disabled={true} onClick={handleButtonClickZoomMeet}>Request in Progress</button>                  
                                              </>
                                            )}  
                                        </div>  
                          
                                        
                  
                            </>)
                  
                  }
                            </div>
                          </form>
                          
                        
                                    )}
                                </li>
                                </>
                                )}
                                
                              </ul>
                            </div>
                          </div> 
                          <div className="innerDivBox"> 
                          {FollowupData?.length > 0 ? (
                              FollowupData.map((item, index) => 
                              //   [...FollowupData]
                              // .sort((a, b) => new Date(b.created_on) - new Date(a.created_on)) // Sort by `created_on` in descending order
                              // .map((item, index) => 
                                item.followup_summary ? ( // Check if followup_summary exists
                                  <div key={index}> 
                                    <div className="despInfoDiv">
                                    <div className="headDespInfoDiv">
                                      <ul className="infoDivPatientList">
                                        <li>
                                          <label>
                                          {`${getOrdinalLabel(FollowupData.length - index + 1)} Consultation`}
                                      </label>
                                          <b>{moment(item.created_on).format('MMM DD, YYYY')}</b>
                                        </li>
                                        <li>
                                          <label>Follow up</label>
                                          <b>{item.title}</b>
                                        </li>
                                    

                                            { calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts) ?
                                                  (<>
                                                    <li  >
                                                      <label>Physician</label>
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Office</b>
                                                  </li>
                                                  <li  >
                                                      <label>Patient </label>                                    
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Home</b>
                                                  </li>
                                                  <li  >
                                                      <label>Communication</label>
                                                      <b>Audio & Video</b>
                                                  </li>
                                                    <li >
                                                    <label>Duration</label>{" "}
                                                    <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                                                    </li>
                                                    <li >
                                                    <label>Verbal consent </label>{" "}
                                                    <b>Obtained</b>
                                                    </li>
                                                    
                                                  
                                                  </>
                                                  ):null }

                    
                                      </ul>
                                      
                                      <a onClick={() => handleInputMainSummaryEditFollowup(item.followup_id, item.followup_summary)} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNewSummary " : "editBtnNewSummary "}>
                                        Edit <img src={editMode === item.followup_id ?  Icons[0].editIcon : Icons[0].editRed } /></a> 
                              
                                  </div>
                                  </div>
                                  {/* {!showSummaryEditFollowup ? ( */}
                                  {!editMode || editMode !== item.followup_id ? (
                                    <>     
                                      <pre className="innerDivBoxPC h-auto">{item.followup_summary} </pre>
                                    </>
                                ) :(
                                  <div className='textareaSummary  innerDivBox w-100' style={{height:'auto'}}>
                                    <textarea 
                                    value={editText}
                                    onChange={(e) => setEditText(e.target.value)}
                                    //  value={item.followup_summary} 
                                    //  onChange={handleInputChangeSummaryEditFollowup} 
                                    style={{    height: '200px', width:' 100%'}} /> 
                                    <hr></hr>
                                  <div className='editSaveOpt' >
                                      {ShowLoaderSummaryFollowup ? (<>
                                        <button  > Loading
                                        <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div> 
                                      </button>
                                      </>):(<>
                                        <button  onClick={() => handleSaveSummaryEditFollowup(item?.followup_id)}>  
                                      Save Changes
                                      </button>
                                      </>)

                                      }
                                      
                                      <button onClick={handleCancelClickSummaryEditFollowup}>Cancel</button>
                                    </div>
                                  </div> 

                                )}

                                  
                                    <hr style={{margin:'20px 0'}}></hr>
                                  </div>
                                ) : null // If followup_summary is null, render nothing
                              )
                            ) : null}
                            
                              <div className="despInfoDiv">
                                <div className="headDespInfoDiv">
                                  <ul className="infoDivPatientList">
                                    <li>
                                      <label>1st Consultation</label>
                                      <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
                                    </li>
                                    <li>
                                      <label>Illnesses</label>
                                      <b>{RecordData.illness}</b>
                                    </li>
                                    {meetingStatusZoom ?
                                                  (<>
                                                    <li  >
                                                      <label>Physician</label>
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Office</b>
                                                  </li>
                                                  <li  >
                                                      <label>Patient </label>                                    
                                                      <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Home</b>
                                                  </li>
                                                  <li  >
                                                      <label>Communication</label>
                                                      <b>Audio & Video</b>
                                                  </li>
                                                    <li >
                                                    <label>Duration</label>{" "}
                                                    <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                                                    </li>

                                                    
                                                    <li >
                                                    <label>Verbal consent </label>{" "}
                                                    <b>Obtained</b>
                                                    </li>
                                                  
                                                  </>
                                                  ):null }

                                    
                                  
                                      
                                        
                                  </ul>
                                {!EmergencyQuest ? ( <>
                                
                                <a onClick={() => handleInputMainSummaryEditAIChatbot(followSummaryEditAIChatbot || RecordData.chatbot_summary )} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNewSummary " : "editBtnNewSummary "}>
                                  Edit <img src={!showSummaryEditAIChatbot ? Icons[0].editIcon : Icons[0].editRed } /></a> </>) :  ('')}
                                
                                  </div>
                            </div>
                    {/* {FollowupData.followup_summary ? <p className="innerDivBox">{FollowupData.followup_summary} </p> :  <p className="innerDivBox"> - </p> } */}
                      {!showSummaryEditAIChatbot ? (
                                    <>  
                                        {EmergencyQuest ?
                                        <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> 
                                        : 
                                        ConditionalDiagnosis ? <pre className="innerDivBoxPC h-auto" >{followSummaryEditAIChatbot } </pre> : 'No summary' 
                                        }

                                        {!EmergencyQuest && (
                                          <>
                                            {PCP_Pharmacy && PCP_Pharmacy.pcp && PCP_Pharmacy.pcp.name && PCP_Pharmacy.pcp.address ? (
                                              <>
                                                <b>Primary Care Physician (PCP): </b>
                                                {PCP_Pharmacy.pcp.name + ', ' + PCP_Pharmacy.pcp.address}
                                                <br />
                                              </>
                                            ) : null}

                                            {PCP_Pharmacy && PCP_Pharmacy.pharmacy && PCP_Pharmacy.pharmacy.name && PCP_Pharmacy.pharmacy.address ? (
                                              <>
                                                <b>Pharmacy information: </b>
                                                {PCP_Pharmacy.pharmacy.name + ', ' + PCP_Pharmacy.pharmacy.address}
                                              </>
                                            ) : null}
                                          </>
                                        )} 
                                    </>
                                ) :(
                                  <div className='textareaSummary innerDivBox w-100' style={{height:'auto'}}>
                                    <textarea value={followSummaryEditAIChatbot} onChange={handleInputChangeSummaryEditAIChatbot} style={{    height: '200px', width:' 100%'}} /> 
                                    <hr></hr>
                                  <div className='editSaveOpt' >
                                      {ShowLoaderSummaryAIChatbot ? (<>
                                        <button  > Loading
                                        <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div> 
                                      </button>
                                      </>):(<>
                                        <button  onClick={() => handleSaveSummaryEditAIChatbot()}>  
                                      Save Changes
                                      </button>
                                      </>)

                                      }
                                      
                                      <button onClick={handleCancelClickSummaryEditAIChatbot}>Cancel</button>
                                    </div>
                                  </div> 

                                )}

                          </div>
                        

                          
                        
                            
                        </div>
                        <div className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div className="d-flex gap-4">
                      
                        <div className="despInfoDiv PlanCareTab w-50 overflow-auto" ref={scrollupRef} style={{ marginTop: '0px' }}>
                        {/* <div ></div> {/* The scroll-up target */}
                        {EmergencyQuest ? (<>
                        <div className="p-3">
                          <h2>Differential Diagnosis</h2> <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> 
                          Urgent: Patient has called for emergency assistance.</p> </div>
                        </>)
                          
                        : (<>
                          <div className="accordion" id="accordionExampleNew">
                        {FollowupData?.length > 0 &&
                            FollowupData.map((item, index) =>
                              item.followup_CD ? (
                                <AccordionSection
                                  key={index}
                                  setData={FollowupData}
                                  title={`Follow up - Differential Diagnosis`}
                                  content={followConditionalDiagnosis || item?.followup_CD}
                                  onEditClick={() => handleInputMainPointFollowCD(item?.followup_id, item?.followup_CD, index+1)}
                                  onSaveClick={() => handleSaveClickMPointFollowCD(item?.followup_id, index+1)}
                                  onCancelClick={handleCancelClickMPointFollowCD}
                                  showLoader={ShowLoader}
                                  isEditing={showMainPointFollowCD}
                                  // isEditing={editingAccordion === item?.followup_id}
                                  // value={updatedContent+index+1}
                                  // onChange={(e) => setUpdatedContent+index+1(e.target.value)}
                                  value={followConditionalDiagnosis}
                                  onChange={(e) => setFollowConditionalDiagnosis(e.target.value)}

                                  EmergencyQuest={EmergencyQuest}
                                  Icons={Icons}
                                  onAccordionClick={handleAccordionClick} // Pass the scroll handler
                            
                                  isActive={activeAccordion === `Follow up - Differential Diagnosis`}
                                  // setActiveAccordion={setActiveAccordion}
                                  setActiveAccordion={() =>
                                    handleAccordionToggle(`Follow up - Differential Diagnosis`)
                                  }

                                  // setActive={setActive} // Set the active accordion
                                  // active={active === 'Follow_up_Details'} // Check if the section is active
                                
                                />
                              ) : null
                            )}

                          <AccordionSection
                            title="Differential Diagnosis"
                            content={ConditionalDiagnosis || ''}
                            onEditClick={handleInputMainPointCD}
                            onSaveClick={handleSaveClickMPointCD}
                            onCancelClick={handleCancelClickMPointCD}
                            showLoader={ShowLoader}
                            isEditing={showMainPointCD}
                            value={ConditionalDiagnosis}
                            onChange={(e) => setConditionalDiagnosis(e.target.value)}
                            EmergencyQuest={EmergencyQuest}
                            Icons={Icons}
                            onAccordionClick={handleAccordionClick} // Pass the scroll handler
                            isActive={activeAccordion === 'Differential Diagnosis'} 
                            setActiveAccordion={() => handleAccordionToggle('Differential Diagnosis')}
                            />
                        </div> 
                        </>)
                        }   
                      
                        
                            </div> 
                        <div className="despInfoDiv PlanCareTab w-50 overflow-auto">

                        {EmergencyQuest ? (<>
                        <div className="p-3">
                          <h2>Differential Diagnosis</h2> <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> 
                          Urgent: Patient has called for emergency assistance.</p> </div>
                        </>)
                          
                        : (<>
                          <div className="accordion" id="accordionExample">
                        {FollowupData?.length > 0 &&
                            FollowupData.map((item, index) =>
                              item.followup_CD ? (
                                <AccordionSectionPoC
                                  key={index}
                                  title={`Follow up - Plan of Care`}
                                  content={followPlanOnCareData || item?.followup_PoC}
                                  onEditClick={() => handleInputMainPointFollowPoC(followPlanOnCareData || item?.followup_PoC)}
                                  onSaveClick={() => handleSaveClickMPointFollowPoC(item?.followup_id)}
                                  onCancelClick={handleCancelClickMPointFollowPoC}
                                  showLoader={ShowLoader}
                                  isEditing={showMainPointFollowPoC}
                                  value={followPlanOnCareData}
                                  onChange={(e) => setFollowPlanOnCareData(e.target.value)}
                                  EmergencyQuest={EmergencyQuest}
                                  Icons={Icons}
                                  onAccordionClick={handleAccordionClickPoc} // Pass the scroll handler
                                  isActive={activeAccordionPoc === 'Follow up - Plan of Care'} 
                                  setActiveAccordionPoc={() => handleAccordionTogglePoc('Follow up - Plan of Care')}
                                  // setActive={setActive} // Set the active accordion
                                  // active={active === 'Follow_up_Details'} // Check if the section is active
                                
                                />
                              ) : null
                            )}

                            
                          {/* <AccordionSectionPoC
                            title="Plan of Care - Online"
                            content={zoomPoC || ''}
                            onEditClick={handleInputMainPoint}
                            onSaveClick={handleSaveClickMPoint}
                            onCancelClick={handleCancelClickMPoint}
                            showLoader={ShowLoader}
                            isEditing={showMainPoint}
                            value={zoomPoC}
                            onChange={(e) => setPlanOnCareData(e.target.value)}
                            EmergencyQuest={EmergencyQuest}
                            Icons={Icons}
                            onAccordionClick={handleAccordionClickPoc} // Pass the scroll handler
                            isActive={activeAccordionPoc === 'Plan of Care - Online'} 
                            setActiveAccordionPoc={() => handleAccordionTogglePoc('Plan of Care - Online')}
                            // setActive={setActive} // Set the active accordion
                            // active={active === 'Differential_Diagnosis'} // Check if the section is active
                            /> */}

                          <AccordionSectionPoC
                            title="Plan of Care"
                            content={PlanOnCareData || ''}
                            onEditClick={handleInputMainPoint}
                            onSaveClick={handleSaveClickMPoint}
                            onCancelClick={handleCancelClickMPoint}
                            showLoader={ShowLoader}
                            isEditing={showMainPoint}
                            value={PlanOnCareData}
                            onChange={(e) => setPlanOnCareData(e.target.value)}
                            EmergencyQuest={EmergencyQuest}
                            Icons={Icons}
                            onAccordionClick={handleAccordionClickPoc} // Pass the scroll handler
                            isActive={activeAccordionPoc === 'Plan of Care'} 
                            setActiveAccordionPoc={() => handleAccordionTogglePoc('Plan of Care')}
                            // setActive={setActive} // Set the active accordion
                            // active={active === 'Differential_Diagnosis'} // Check if the section is active
                            />
                        </div> 
                        </>)
                        }  

                        {/* {FollowupData?.length > 0 ? (
                          FollowupData.map((item, index) => 
                            item.followup_PoC ? ( 
                              <div key={index}>
                                <h2>Follow up - Plan of Care
                                      {!EmergencyQuest ? ( <a onClick={() => handleInputMainPointFollowPoC(followPlanOnCareData || item?.followup_PoC)} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}> Edit <img src={!showMainPointFollowPoC ? Icons[0].editIcon : Icons[0].editRed } /></a>) :  ('')}
                              
                                </h2>
                            
                                {!showMainPointFollowPoC ? (
                                    <>     
                                      <pre className="innerDivBoxPC h-auto overflow-hidden">
                                        {followPlanOnCareData || item.followup_PoC}
                                      </pre>
                                    </>
                                ) :(
                                  <div className='textareaSummary innerDivBox w-100'>
                                    <textarea value={followPlanOnCareData} onChange={handleInputChangeMPointFollowPoC} style={{    height: '80%', width:' 100%'}} /> 
                                    <hr></hr>
                                  <div className='editSaveOpt' >
                                      {ShowLoader ? (<>
                                        <button  > Loading
                                        <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div> 
                                      </button>
                                      </>):(<>
                                        <button  onClick={() => handleSaveClickMPointFollowPoC(item?.followup_id)}>  
                                      Save Changes
                                      </button>
                                      </>)

                                      }
                                      
                                      <button onClick={handleCancelClickMPointFollowPoC}>Cancel</button>
                                    </div>
                                  </div> 

                                )}

                                    
                                <hr />
                              </div>
                            ) : null // If followup_PoC is empty or null, render nothing
                          )
                        ) : null}


                            <h2>Plan of Care  
                            {!EmergencyQuest ? ( <a onClick={handleInputMainPoint} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}> Edit <img src={!showMainPoint ? Icons[0].editIcon : Icons[0].editRed } /></a>) :  ('')}
                            </h2>
                            { FollowupData.followup_PoC ? <pre className="innerDivBoxPC h-auto overflow-hidden">{FollowupData.followup_PoC}</pre> : '' }          
                          
                            {!showMainPoint ? (
                                    <>     
                                    {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : PlanOnCareData ? <pre className="innerDivBoxPC h-auto  overflow-hidden">{PlanOnCareData}</pre> : 'No Plan On Care' }  
                                  </>
                                ) :(
                                  <div className='textareaSummary innerDivBox w-100'>
                                    <textarea value={PlanOnCareData} onChange={handleInputChangeMPoint} style={{    height: '80%', width:' 100%'}} /> 
                                    <hr></hr>
                                  <div className='editSaveOpt' >
                                      {ShowLoader ? (<>
                                        <button  > Loading
                                        <div className="spinner-border text-light" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div> 
                                      </button>
                                      </>):(<>
                                        <button onClick={handleSaveClickMPoint}>  
                                      Save Changes
                                      </button>
                                      </>)

                                      }
                                      
                                      <button onClick={handleCancelClickMPoint}>Cancel</button>
                                    </div>
                                  </div> 

                                )} */}
                                </div>
                        
                        </div>
                        </div>
                        <div className={`tab-pane fade ${activeTab === 2 ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <div className="despInfoDiv">
                            <h2>Pre-Diagnosis   </h2>
                            </div>
                          <div className="chatbotConversation  pb-0">
                        <div className="messageListBox mb-0">
                        {answerLoader ? ( 
                                      <>
                                      <div className= "quesListDiv " >
                                          <div className={"chatbot aiQuest "}>
                                              <span className="imgBox">
                                                <img src={Icons[0].chatbot} alt="Chatbot" />
                                              </span>
                                              <span> 
                                                  <div className="showLoaderDot">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                  </div> 
                                              </span>
                                            </div>
                                      </div>
                                      </>):(<>
                                      
                                        <div className="chatbot">
                                  <span className="imgBox">
                                    <img src={Icons[0].chatbot} alt="Chatbot" />
                                  </span>
                                  <span>
                                    <b className="AI_dateTime">
                                      AI 
                                    </b> 
                                        <label className="quesTxt">Hi, How may I help you today? You may select or type a symptom you’re facing currently.</label>
                                        <div className="questOpt">
                                          <b>Please select any</b>
                                          <ul className={!selectedIllness ? 'illnessesListOpt' : 'illnessesListOpt selected'}>
                                            {illnessesList?.map((item, index) => (
                                              <li
                                                key={index}
                                                className={selectedIllness === item ? 'active' : 'disabled'} 
                                                style={{ pointerEvents: selectedIllness && selectedIllness !== item ? 'none' : 'auto' }}
                                              >
                                                <b>{item}</b>
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      
                                  </span>
                                </div>

                            {ChatHistory.length > 0 && (
                                  <div className="quesListDiv">
                                    {ChatHistory.map((q, index) => (
                                      <div key={index}>
                                        <div className="chatbot aiQuest">
                                          <span className="imgBox">
                                            <img src={Icons[0].chatbot} alt="Chatbot" />
                                          </span>
                                          <span> 
                                                <b className="AI_dateTime">
                                                  AI 
                                                </b>
                                                <label className="quesTxt">{q.question}</label>
                                              
                                          </span>
                                        </div>
                                        {q.answer && (
                                          <div className="chatbot userReply">
                                            <span className="imgBox">
                                              <b>{fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('')}</b>
                                            
                                            </span>
                                            <span>
                                              <b className="AI_dateTime">
                                                YOU 
                                              </b>
                                              <label className="quesTxt">{q.answer}</label>
                                            </span>
                                          </div>
                                        )} 
                                      </div>
                                    ))}
                                  </div>
                                )} 
                                      
                                      </>)}
                                

                              </div> 
                      </div>
                      
                        </div>
                        <div className={`tab-pane fade ${activeTab === 3 ? 'active show' : ''}`} id="nav-reportData" role="tabpanel" aria-labelledby="nav-reportData-tab">
                        <div className="despInfoDiv">
                            <h2>Reports </h2>
                            </div>
                            <div className="innerDivBoxPC"> 
                              
                    
                    <ol className="reportListOptLink" style={{fontSize:'16px' }}>
                    {sortedGroupedDocuments.length > 0 ? (
                      sortedGroupedDocuments
                        .filter(({ documents }) => documents && documents.length > 0) // Filter out empty or null documents
                        .map(({ doc_type, documents, Name  }) => (
                          <li key={doc_type}>
                        
                            {documents.map((doc, index) => (
                              <div key={doc.doc_id} style={{ marginBottom: "0px" }}>
                                  <b style={{ textTransform: "capitalize", textDecoration: "none" }} className={ doc_type === "other_report"  && index !== 0 && 'd-none'}>
                                  {Name} 
                                  {/* {doc_type.replace('_', ' ')} */}
                                  </b>
                                {/* Document URL with conditional button */}
                                {doc.doc_url && (
                                  <span
                                    className={
                                      "pdfButtonModal" + (doc_type === "other_report" ? " d-none" : "")
                                    }
                                  >
                                    <button
                                      className="pdfButton pdfViewBtn"
                                      onClick={() => openModal(doc?.doc_url)}
                                    >
                                      View{" "}
                                      <img
                                        src={Icons[0].viewPDF}
                                        alt="PDF Icon"
                                        style={{ width: "24px", marginLeft: "10px" }}
                                      />
                                    </button>
                                  </span>
                                )}

                                {/* Document Text */}
                                {/* Document Text */}
                                {doc.doc_text &&(
                                  <p style={{fontSize:'16px', marginBottom: "0" }}>{doc.doc_text}</p>
                                )}

                                <div className={"d-flex justify-content-between align-items-center "  +(doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null) }>
                                  <h3
                                    style={{
                                      fontSize: "16px",
                                      marginTop: "14px",
                                      fontWeight: "600",
                                    }}
                                    className="my-0"
                                  >
                                  {
                                      doc_type === "other_report" ? (
                                        <>
                                          {index + 1}. Other Report Summary
                                        </>
                                      ) : doc_type === "pcp" || doc_type === "pharmacy" ? (
                                          null
                                      ) : doc.doc_summary ? (
                                        <>Report Summary</>
                                      ) : null
                                    }
                                  </h3>

                                  {/* Document URL Button (specific condition) */}
                                  {doc.doc_url && (
                                    <span
                                      className={
                                        "pdfButtonModal" +
                                        (doc_type !== "other_report" ? " d-none" : "")
                                      }
                                    >
                                      <button
                                        className="pdfButton pdfViewBtn"
                                        onClick={() => openModal(doc?.doc_url)}
                                      >
                                        View{" "}
                                        <img
                                          src={Icons[0].viewPDF}
                                          alt="PDF Icon"
                                          style={{ width: "24px", marginLeft: "10px" }}
                                        />
                                      </button>
                                    </span>
                                  )}
                                </div>

                                {/* Document Summary */}
                                {
                                  doc.doc_summary ? (
                                    <pre
                                      style={{ margin: "5px 0", textTransform: "capitalize" }}
                                      className={doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null}
                                    >
                                      {doc.doc_summary}
                                    </pre>
                                  ) : null
                                }

                                {/* Conditional Divider */}
                                {doc.doc_url && (
                                  <hr className={doc_type !== "other_report" ? " d-none" : ""} />
                                )}
                              </div>
                            ))}
                          </li>
                        ))
                    ) : (
                      <p style={{ textAlign: "center" }}>No reports found</p>
                    )}
                  </ol>


                        
                            </div>
                            
                            </div>
                      </div>
                    </div>

                    
                    </div> 
                </div>
                
              </div>
                 
             </div>
        </div> 
      </div>


       {/* Modal Popup for Viewing Documents */}
       <Modal
        className='viewDocModal'
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Document Viewer" 
      >
        <button
          onClick={closeModal}
          style={{
            float: 'right',
            cursor: 'pointer',
            background: 'red',
            color: 'white',
            border: 'none',
            padding: '4px 10px',
            borderRadius: '0px',
            marginBottom: '-9px',
          }}
        >
          Close
        </button>
        <div style={{ display: "flex", flexDirection: "column", height: "100%", clear:'both' }}>
        {/* Ensure selectedDocument exists before rendering */}
        {selectedDocument ? (
          <>
            <h3>{selectedDocument.name}</h3>
            {/* <iframe src={selectedDocument} width="100%" height="100%" type="application/pdf"  /> */}
            {/* Render PDF or Image based on file type */} 
            <embed src={selectedDocument} width="100%" height="100%" type="application/pdf"  className="innerContainer" />

            {/* <iframe
          src={selectedDocument}
          width="100%"
          height="600px"
          style={{ border: "none" }}
          title="PDF Viewer"
        /> */}
        {/* <PDFViewer pdfUrl={pdfUrl} onClose={closeModal} /> */}
          </>
        ) : (
          <p>Loading document...</p> // Fallback content if document is not available
        )}
        </div>
      </Modal>


     {/* Modal Popup for Viewing Documents */}
    <Modal
      className='ModalPhysicalExam'
        isOpen={isModalPhysicalExamOpen}
        onRequestClose={closeModalPhysicalExam}
        contentLabel="Document Viewer"
         
      >
        

        <div className=" black-80 center stepFirstForm">
          
          <from onSubmit={handleSubmit} className="measure w-100" >

          <h1  style={{fontSize:"24px"}}>Physical Exam</h1>
          <fieldset>
          <div className='d-block mt-2 ' style={{borderTop:"2px solid #aeaeae"}}>
                    
                    <div className='d-grid mt-2   gap-3 '>
                        <div className='w-100'>
                                <div className="mt3 inputDiv">
                                  <label className='labelTxt' style={{width:"120px"}}>Blood pressure</label>
                              
                            <div className="timepicker">
                              <input
                                type="text"
                                className="hh"
                                maxLength="3"
                                placeholder="Mm"
                                ref={bpMMRef}
                                // onInput={(e) => handleInput(e, bpHgRef, null)}
                                // onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                                value={bpMMTxt}  // Ensure the input value reflects the state
                                onInput={(e) => {
                                  setbpMMTxt(e.target.value);  // Update state when input changes
                                  handleInput(e, bpHgRef, null);
                                }}
                                onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                              />
                              -
                              <input
                                type="text"
                                className="mm"
                                maxLength="3"
                                placeholder="Hg"
                                 ref={bpHgRef}
                                // onInput={(e) => handleInput(e, null, bpMMRef)}
                                // onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                                value={bpHgTxt}  // Ensure the input value reflects the state
                                onInput={(e) => {
                                  setbpHgTxt(e.target.value);  // Update state when input changes
                                  handleInput(e, null, bpMMRef);
                                }}
                                onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                              />
                            </div>  
                                  <label  style={{width:"80px", textAlign: 'right'}}>Mm - Hg</label>   
                              </div>
                              {errorMessage && <span className="errorTxt">{errorMessage}</span>}
                              {errors.bpHgTxt  && <span className="errorTxt" >{errors.bpHgTxt }</span>}
                        </div>

                        <div className='w-100 '>
                                 <div className="mt3 inputDiv">
                                    <label className='labelTxt pr-0' style={{width:"130px"}}>Heart rate</label>
                                    <input type="text"   
                                     style={{paddingLeft:"20px"}}
                                      value={heartrateTxt} 
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // Remove any non-digit characters
                                        const numericValue = value.replace(/\D/g, '');
                                        // Limit to 4 digits
                                        if (numericValue.length <= 3) {
                                          setheartrateTxt(numericValue);
                                        }
                                      }} 
                                    />  
                                    <label>bpm</label>   
                                </div>
                                {errors.heartrateTxt && <span  className="errorTxt" >{errors.heartrateTxt}</span>}
                        </div>

                      </div>
                  </div>
                  </fieldset>
                  <span className="d-flex gap-4">
                  <button className="signInBtn mb-0" onClick={closeModalPhysicalExam} >Close</button>
                  {ShowLoader ? (<>
                      <button  className="signInBtn mb-0" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>  
                      <button onClick={handleSubmit} className="signInBtn mb-0" type="submit" >Update</button>
                     
                    </>)}
                    </span>
 
          </from>
        </div>
      
        
      </Modal>  

      
       {/* Modal Popup for Viewing Documents */}
         <Modal 
        isOpen={isModalOpenViewAll}
        onRequestClose={closeModalViewAll}
        contentLabel="Document Viewer"
       className={"modalViewAllMain"}
      >
        <button
          className="closeBtn"
          onClick={closeModalViewAll} 
        >
          Close 
        </button>
      
       
       
        <div className="innerModalDiv"  id="pdfContent">
                                  
  <div ref={contentRef} style={{padding: "20px"}}>


        <div className="titleDivRight">
                           <div className="d-flex gap-2 justify-content-start align-items-center mb-2"  style={{marginTop:'20px'}} >
                                <h2 className="capitalizeTxt m-0 d-flex align-items-center" style={{fontSize:'24px'}}>{fullName}
                                  
                                {!isGeneratingPdf && ( // Hide the "Copy All" button while PDF is generating
                                      <button
                                        className="copyAllclipBtn"
                                        onClick={() => handleCopyAll()}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> Copy All
                                      </button>
                                    )}
                                
                                    
                                    </h2>   
                              </div>
                           
                            <ul className="userInfoDetails" style={{listStyle: 'none', padding: '0',display: 'flex', marginBottom: '8px', gap: '20px'}}>
                              <li className={GenderTxt ? '' : 'd-none'} style={{    marginRight: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'}}>
                                <img src={Icons[0].admin_userIcon} style={{marginRight: '8px', width:'17px', height:'17px'}} />
                                <label className="capitalizeTxt" style={{fontSize:'14px'}}>{GenderTxt}</label>
                              </li>
                              <li className={DOBTxt ? '' : 'd-none'}
                              style={{    marginRight: '5px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'}}>
                                <img src={Icons[0].admin_calendarIcon}  style={{marginRight: '8px', width:'17px', height:'17px'}}  />
                                <label  style={{fontSize:'14px'}}>{moment(DOBTxt).format('MMM DD, YYYY')} {' '}({ageCal} years)</label>
                              </li>
                              <li style={{    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                <img src={Icons[0].admin_mailIcon} style={{marginRight: '8px', width:'17px', height:'17px'}}  />
                                <label  style={{fontSize:'14px'}} className="singleLineEllipsis">{email}</label>
                              </li>
                              <li style={{    marginRight: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                <img src={Icons[0].admin_phoneIcon} style={{marginRight: '8px', width:'17px', height:'17px'}}  />
                                <label  style={{fontSize:'14px'}}>{phoneNumber}</label>
                              </li>
                            </ul>

                            <ul className="infoDivPatientList" style={{listStyle: 'none', padding: '0',display: 'flex', marginBottom: '8px', gap: '20px'}}>
                            
                              <li style={{    marginRight: '5px',
    display: 'grid',
     gridTemplateColumns:'100%',
    alignItems: 'center',
    fontSize:'14px',
    justifyContent: 'center'}}>
                                <label>BMI</label>
                                <b>{BMITxt}</b>
                              </li>
                             <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    alignItems: 'center',
    fontSize:'14px',

    justifyContent: 'center'}}>
                                <label>Weight</label>
                                <b>{WeightTxt  ? WeightTxt : '0'}{' '}<span>lbs</span></b>                               
                              </li>
                              <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                <label>Height</label>
                                <b>{`${formatHeight(HeightTxt)} Feet `}
                                  {/* {HeightTxt ? HeightTxt : '0'} */}
                                  </b>                               
                              </li>
                              <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                              <label>Physical  Exam </label> 
                              {/* {bpHgTxt && bpMMTxt && heartrateTxt ? (  */}
                              {(checkPhysicalExam || filledCheckPhysicalExam) ? (
                                  <>
                                  <b className={bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt}>
                                  {bpHgTxt && bpMMTxt ? (`${bpHgTxt} / ${bpMMTxt} - `) : null  }
                                  {heartrateTxt ?`${heartrateTxt} BPM` : null } 
                                  </b>
                                  </> 
                                ) :  (<>-</>)}  
                              </li>
                            </ul>
                          <hr></hr>
                        </div> 

                  

                        {FollowupData?.length > 0 ? (
                          [...FollowupData]
                          .sort((a, b) => new Date(b.created_on) - new Date(a.created_on)) // Sort by `created_on` in descending order
                          .map((item, index) => 
            item.followup_summary ? ( // Check if followup_summary exists
              <div key={index}>
                <div className="despInfoDiv">
                <div className="headDespInfoDiv">
                  <ul className="infoDivPatientList"  style={{listStyle: 'none', padding: '10px 0px', display: 'flex', marginBottom: '8px', gap: '10px', alignItems: 'end'}}>
                    <li  style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                      <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >  {`${getOrdinalLabel(FollowupData.length - index + 1)} Consultation`}</label>
                      
                      <b>{moment(item.created_on).format('MMM DD, YYYY')}</b>
                    </li>
                    <li  style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                      <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >Follow up</label>
                      <b>{item.title}</b>
                    </li>
                    {
                      calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts) ?
                      (<>
                                 
                        <li style={{    marginRight: '5px',
display: 'grid',
gridTemplateColumns:'100%',
fontSize:'14px',
alignItems: 'center',
justifyContent: 'center'}}>
                          <label style={{
              fontSize: '14px',
              fontWeight: '800',
            }} >Physician</label>
                          <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Office</b>
                       </li>
                       <li style={{    marginRight: '5px',
display: 'grid',
gridTemplateColumns:'100%',
fontSize:'14px',
alignItems: 'center',
justifyContent: 'center'}}>
                          <label style={{
              fontSize: '14px',
              fontWeight: '800',
            }} >Patient</label>
                          <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Home</b>
                       </li>
                       <li style={{    marginRight: '5px',
display: 'grid',
gridTemplateColumns:'100%',
fontSize:'14px',
alignItems: 'center',
justifyContent: 'center'}}>
                          <label style={{
              fontSize: '14px',
              fontWeight: '800',
            }} >Communication</label>
                          <b>Audio & Video</b>
                       </li>

                       <li style={{    marginRight: '5px',
display: 'grid',
gridTemplateColumns:'100%',
fontSize:'14px',
alignItems: 'center',
justifyContent: 'center'}}>
                        <label style={{
              fontSize: '14px',
              fontWeight: '800',
            }} >Duration</label>{" "}
                        <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                        </li>

                        <li style={{    marginRight: '5px',
display: 'grid',
gridTemplateColumns:'100%',
fontSize:'14px',
alignItems: 'center',
justifyContent: 'center'}}>
                                 <label style={{
              fontSize: '14px',
              fontWeight: '800',
            }} >Verbal consent </label>{" "}
                                  <b>Obtained</b>
                                  </li>


                      </>
                      ):null }

                    {/* <li>
                        <label>Duration:</label>{" "}
                        <b>{calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)}</b>
                      </li> */}
                      
                                 
                  </ul>
                </div>
              </div>
              {(followSummaryEditFollowup || item.followup_summary) && (
                <>
                  <h2 style={{ fontSize: '22px' }}>
                    Follow up - Summary
                    {!isGeneratingPdf && ( // Hide the "Copy All" button while PDF is generating
                                      <button
                                      className="copyclipBtn"
                                      onClick={() => copyToClipboard(followSummaryEditFollowup)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> 
                                      </button>
                                    )}

                   
                  </h2>
                  <pre>{followSummaryEditFollowup || item.followup_summary}</pre>
                </>
              )}
              <hr></hr>
              </div>
            ) : null // If followup_summary is null, render nothing
          )
                            ) : null}
                              {FollowupData?.length > 0 ? (
                            FollowupData.map((item, index) => 
                              item.followup_PoC ? ( // Check if followup_PoC exists
                                <div key={index}>
                                  <h2  style={{ fontSize: '22px' }}>Follow up - Plan of Care

                                  {!isGeneratingPdf && ( // Hide the "Copy All" button while PDF is generating
                                      <button
                                      className="copyclipBtn"
                                      onClick={() => copyToClipboard(followPlanOnCareData || item?.followup_PoC)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> 
                                      </button>
                                    )}

                                 
                                  </h2>
                                  <pre className="innerDivBoxPC h-auto  overflow-hidden">
                                    {followPlanOnCareData || item?.followup_PoC}
                                  </pre>
                                  <hr />
                                </div>
                              ) : null // If followup_PoC is empty or null, render nothing
                            )
                          ) : null}

                              <ul className="infoDivPatientList"  style={{listStyle: 'none', padding: '10px 0px', display: 'flex', marginBottom: '8px', gap: '10px', alignItems: 'end'}}>
                              <li  style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >1st Consultation</label>
                                <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
                              </li>
                              <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >Illnesses</label>
                                <b>{RecordData.illness}</b>
                              </li>
   {meetingStatusZoom ?
                                (<>
                                 
                                  <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                    <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >Physician</label>
                                    <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Office</b>
                                 </li>
                                 <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                    <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >Patient</label>
                                    <b className="flexDiv"><img src={Icons[0].locationIcon} style={{width:'20px'}}/>Home</b>
                                 </li>
                                 <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                    <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >Communication</label>
                                    <b>Audio & Video</b>
                                 </li>

                                 <li style={{    marginRight: '5px',
        display: 'grid',
        gridTemplateColumns:'100%',
    fontSize:'14px',
    alignItems: 'center',
    justifyContent: 'center'}}>
                                  <label style={{
                        fontSize: '14px',
                        fontWeight: '800',
                      }} >Duration</label>{" "}
                                  <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                                  </li>
                                  <li style={{    marginRight: '5px',
display: 'grid',
gridTemplateColumns:'100%',
fontSize:'14px',
alignItems: 'center',
justifyContent: 'center'}}>
                                 <label style={{
              fontSize: '14px',
              fontWeight: '800',
            }} >Verbal consent </label>{" "}
                                  <b>Obtained</b>
                                  </li>
                                </>
                                ):null }
 


                                
                              </ul>
                        {EmergencyQuest ? (<><p className="innerDivBox urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p></>) : 
                         RecordData.chatbot_summary ? (<>
                         <h2 style={{fontSize:'22px'}}>Summary 

                         {!isGeneratingPdf && ( // Hide the "Copy All" button while PDF is generating
                                      <button
                                      className="copyclipBtn"
                                      onClick={() => copyToClipboard(followSummaryEditAIChatbot)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> 
                                      </button>
                                    )}
 
                                </h2>
                         <pre className="innerDivBox"> {followSummaryEditAIChatbot } </pre> </>) :  <p className="innerDivBox"> - </p> }
                        <hr></hr>
                        <h2 style={{fontSize:'22px'}}>Differential Diagnosis 
                        {!isGeneratingPdf && ( // Hide the "Copy All" button while PDF is generating
                                      <button
                                      className="copyclipBtn"
                                      onClick={() => copyToClipboard(ConditionalDiagnosis)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> 
                                      </button>
                                    )}

                                
                          </h2>   
                           
                          {!showMainPointCD ? (
                                  <>     
                                  {EmergencyQuest ? 
                                  <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p>
                                   : 
                                   ConditionalDiagnosis ? <pre className="innerDivBoxPC">{ConditionalDiagnosis}</pre> : 'No Differential Diagnosis' }  
                                </>
                              ) :( <></> )}
                          <hr></hr>
                          <h2 style={{fontSize:'22px'}}>Plan of Care 
                          {!isGeneratingPdf && ( // Hide the "Copy All" button while PDF is generating
                                      <button
                                      className="copyclipBtn"
                                      onClick={() => copyToClipboard(PlanOnCareData)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> 
                                      </button>
                                    )} 
                         
                                </h2>  
                          {!showMainPoint ? (
                                  <>     
                                  {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : PlanOnCareData ? <pre className="innerDivBoxPC">{PlanOnCareData}</pre> : 'No Plan On Care' }  
                                </>
                              ) :(  <></>)}

              {reportData && <>
                <hr></hr>
              </>}
              <div ref={hiddenDivRef}>
              {reportData && <>
               
                  <h2 style={{fontSize:'22px'}}>Reports

                          {/* <button className="copyclipBtn" 
                           onClick={handleCopy} 
                           style={{ cursor: 'pointer' }}> 
                                   <img src={Icons[0].copyclip} style={{width:'18px'}} />
                                </button> */}

                </h2>
              </> 
              }
           
             
             
  <ol className="reportListOptLink" style={{fontSize:'16px' }}>
  {sortedGroupedDocuments.length > 0 ? (
    sortedGroupedDocuments
      .filter(({ documents }) => documents && documents.length > 0) // Filter out empty or null documents
      .map(({ doc_type, documents, Name  }) => (
        <li key={doc_type}>
       
          {documents.map((doc, index) => (
            <div key={doc.doc_id} style={{ marginBottom: "0px" }}>
                 <b 
                 style={{ textTransform: "capitalize", textDecoration: "none", weight:'600', fontSize:'16px', 
                  marginBottom : doc_type !== "other_report" ? "0px" : "10px",
                  display : doc_type !== "other_report" ? null : "inline-block"}}
                  className={ doc_type === "other_report"  && index !== 0 && 'd-none'}>
                 {Name} 
                 {/* {doc_type.replace('_', ' ')} */}
                 </b>
              {/* Document URL with conditional button */}
              {doc.doc_url && (
                <span
                  className={
                    "pdfButtonModal" + (doc_type === "other_report" ? " d-none" : "")
                  }
                >
                  <button
                    className="pdfButton pdfViewBtn"
                    onClick={() => openModal(doc?.doc_url)}
                  >
                    View{" "}
                    <img
                      src={Icons[0].viewPDF}
                      alt="PDF Icon"
                      style={{ width: "24px", marginLeft: "10px" }}
                    />
                  </button>
                </span>
              )}

              {/* Document Text */}
              {doc.doc_text &&(
                 <p style={{fontSize:'16px', marginBottom: "0" }}>{doc.doc_text}</p>
              )}
              

              <div
                    className={"d-flex justify-content-between align-items-center "  +(doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null) }>
                <h3
                  style={{
                    fontSize: "16px",
                    marginTop: "14px",
                    fontWeight: "600",
                  }}
                  className="my-0"
                >
               {
                    doc_type === "other_report" ? (
                      <>
                        {index + 1}. Other Report Summary
                      </>
                    ) : doc_type === "pcp" || doc_type === "pharmacy" ? (
                       null
                    ) : doc.doc_summary ? (
                      <>Report Summary</>
                    ) : null
                  }
                </h3>

                {/* Document URL Button (specific condition) */}
                {doc.doc_url && (
                  <span
                    className={
                      "pdfButtonModal d-none" +
                      (doc_type !== "other_report" ? " d-none" : "")
                    }
                  >
                    <button
                      className="pdfButton pdfViewBtn"
                      onClick={() => openModal(doc?.doc_url)}
                    >
                      View{" "}
                      <img
                        src={Icons[0].viewPDF}
                        alt="PDF Icon"
                        style={{ width: "24px", marginLeft: "10px" }}
                      />
                    </button>
                  </span>
                )}
              </div>

              {/* Document Summary */}
              {
                  doc.doc_summary ? (
                    <pre
                      style={{ margin: "5px 0", textTransform: "capitalize" }}
                      className={doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null}
                    >
                      {doc.doc_summary}
                    </pre>
                  ) : null
                }

              {/* Conditional Divider */}
              {doc.doc_url && (
                <hr className={doc_type !== "other_report" ? " d-none" : ""} />
              )}
            </div>
          ))}
        </li>
      ))
  ) : (
    <p style={{ textAlign: "center" }}>No reports found</p>
  )}
</ol>
</div>
         
         
         
            {/* Render PDF or Image based on file type */} 
            </div>
            <div className="p-3 d-none11">
            <h2 style={{fontSize:'22px'}}>Select Standard Precaution</h2>
              <ul className="standardPerecautionList">
                {/* {fileArray.map((file) => (
                  <li key={file.id}>
                    <input
                      type="checkbox"
                      id={`file-${file.id}`}
                      onChange={() => handleCheckboxChange(file.id)}
                    />
                    <label htmlFor={`file-${file.id}`}>{file.fileName}</label>
                  </li>
                ))} */}
                  {fileArray.map((file) => (
                    <li key={file.id}>
                      <input
                        type="checkbox"
                        id={`file-${file.id}`}
                        onChange={() => handleCheckboxChange(file)}
                        checked={!!selectedFiles.find((f) => f.id === file.id)}
                      />
                      <label htmlFor={`file-${file.id}`}>{file.fileName}</label>
                    </li>
                  ))}

              </ul>
              </div>
              <div className=" buttonDivBox">
                                <button
                                        className="copyAllclipBtn"
                                        onClick={() => handleCopyAll()}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <img src={Icons[0].copyclip} style={{ width: '18px' }} /> Copy All
                                      </button>
                                <button
                                        className="copyAllclipBtn shareBtn" 
                                        onClick={(event) => handleSharetoPatient(event, fullName, selectedFiles, email)} 
                                        style={{ cursor: 'pointer' }}
                                      >
                                       <img src={Icons[0].shareBtn } style={{width:"18px"}}/> 
                                       {isLoadingEmailP ? "Sending..." : "Email to Patient"}
                                      </button>
                         <button 
                            onClick={() => downloadPdfNew(fullName, selectedFiles)}
                          // onClick={() => generatePDF(contentRef, {filename: 'page.pdf'})}
                            // onClick={() => generateAndMergePDF(fullName)}
                              className="downloadBtn " >
                                <img src={Icons[0].downloadBtn} /> 
                         </button>
              </div>
                         {/* <button
                                       onClick={() => handleShareBtn(fullName, selectedFiles)}
                                      
                                       className="shareBtn  "
                                      >  <img src={Icons[0].shareBtn } /> Share
                                    </button>  */}
                          <div className="shareReportDiv">
                              <h2>Send report others via email
                              {/* Share Patient Report */}
                              </h2>
                              <form className="d-grid justify-content-center align-items-start ">
                                 <span className="d-grid">
                                         <span  className=" shareReportInputDiv">
                                              <input
                                                type="text"
                                                value={emailList}
                                                //value={[...shareEmail, emailList].join(", ")}
                                                onChange={handleEmailChange}
                                                onFocus={handleInputFocus}
                                                onPaste={handlePaste}
                                                autoComplete="off"
                                                placeholder="Enter email addresses"
                                                style={{ borderColor: errorShare ? "red" : "initial" }}
                                              /> 
                                              <button style={{width: "80px"}} onClick={addEmailsToList}>Add</button>
                                         </span>
                                       
                                          {/* Display the emails in the list */}
                                          {shareEmail.length > 0 && (
                                            <div className="d-grid w-100">
                                              <strong>Email send to:</strong>
                                              <p className="d-grid" style={{width:'400px', margin:'auto'}}>{shareEmail.join(", ")}</p> {/* Show the list of emails */}
                                            </div>
                                          )}
                                       
                                     {errorShare.email && <span  className="errorTxt" >{errorShare.email}</span>} 
                                   </span>
                                    <button
                                      //  onClick={() => handleShareBtn(fullName, selectedFiles)}    
                                       onClick={(event) => handleShareBtn(event, fullName, selectedFiles)}                                  
                                       className="shareBtn "
                                      >  <img src={Icons[0].shareBtn } style={{width:"24px"}}/> 
                                             {isLoadingEmail ? "Sending..." : "Send Email"}
                                        </button> 
                             </form>
                              
                         </div>   
            </div>
      </Modal>

    </div>
  );
}

export default ViewPatientProfile;