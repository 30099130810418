import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { ToastContainer, toast } from "react-toastify"; 
import axios from 'axios';
import { Icons } from "../../assest/icons";
import Header from './header';
import SideBarMenu from './sideBarMenu';
import moment from 'moment'; 
import LoaderSpinner from '../loaderSpinner';
import {getBaseURL} from '../baseUrl';
import { SearchContext } from './SearchContext'; 
import Modal from 'react-modal';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'; 
import {  PDFDocument, rgb, StandardFonts } from "pdf-lib";
// import PDFViewer from './PDFViewer';

import HeightFormatter, { formatHeight } from '../HeightFormatter';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import AbdominalCT from '../../assest/document/AIGICare_PDF_AbdominalCT.pdf';
import AbdominalUS from '../../assest/document/AIGICare_PDF_AbdominalUS.pdf';
import AnemiaLabs from '../../assest/document/AIGICare_PDF_AnemiaLabs.pdf';
import BariumStudies from '../../assest/document/AIGICare_PDF_BariumStudies.pdf';
import BasicGastroLabs from '../../assest/document/AIGICare_PDF_BasicGastroLabs.pdf';
import ColonOrder from '../../assest/document/AIGICare_PDF_Colon_Order.pdf';
import Colonoscopy from '../../assest/document/AIGICare_PDF_Colonoscopy.pdf';
import ColonScreeningGuidelines from '../../assest/document/AIGICare_PDF_ColonScreeningGuidelines.pdf';
import Diverticulosis from '../../assest/document/AIGICare_PDF_Diverticulosis.pdf';
import EGDOrder from '../../assest/document/AIGICare_PDF_EGD_Order.pdf';
import FattyLiver from '../../assest/document/AIGICare_PDF_FattyLiver.pdf';
import FODMAP_Diet from '../../assest/document/AIGICare_PDF_FODMAP_Diet.pdf';
import GERD_Diet_and_Lifestyle from '../../assest/document/AIGICare_PDF_GERD_Diet_and_Lifestyle.pdf';
import HeapticLabs from '../../assest/document/AIGICare_PDF_HeapticLabs.pdf';
import High_Fiber_Diet from '../../assest/document/AIGICare_PDF_High_Fiber_Diet.pdf';
import InflammatoryBowelDisease from '../../assest/document/AIGICare_PDF_InflammatoryBowelDisease.pdf';
import IrritableBowelSyndrome from '../../assest/document/AIGICare_PDF_IrritableBowelSyndrome.pdf';
import ProceduralSedation from '../../assest/document/AIGICare_PDF_ProceduralSedation.pdf';
import StoolStudies from '../../assest/document/AIGICare_PDF_StoolStudies.pdf';
import StressManagement from '../../assest/document/AIGICare_PDF_StressManagement.pdf';
import TelehealthDisclaimer from '../../assest/document/AIGICare_PDF_TelehealthDisclaimer.pdf';
import UpperGIEndoscopy from '../../assest/document/AIGICare_PDF_UpperGIEndoscopy.pdf';
import Weight_Reducing_Diet from '../../assest/document/AIGICare_PDF_Weight_Reducing_Diet.pdf';

// Set the app element for accessibility (react-modal requirement)
Modal.setAppElement('#root');
 
 

function ViewFullReport() {  
    let navigate = useNavigate(); 
    const location = useLocation(); 
    const scrollupRef = useRef(null); // Create ref for scroll-up element
    const [active, setActive] = useState(''); // Track active accordion section

  
    const sessionid = location.state?.sessionid;
    const userProfileDataMemory =  JSON.parse(localStorage.getItem('UserProfile'))
    const [ShowLoaderPage, setShowLoaderPage] = useState(false);
  
    const [adminId, setAdminId] = useState(JSON.parse(localStorage.getItem('UserIdAdmin'))); 
 
    const [UserId, setUserId] = useState(userProfileDataMemory.user_id);
    const userid = UserId; 
 
    // const [searchTxtCheck, setSearchTxtCheck] = useState(searchString); 

    // const [backCurrentPage, setcurrentPageNo] = useState(currentPageNo);
    const [userProfileData, setUserProfileData] = useState([]);
    const [UserHistoryData, setUserHistoryData] = useState([]);
    const [UserHistoryNumber, setUserHistoryNumber] = useState('');
    const [ChatHistory, setChatHistory] = useState([]);
    const [RecordData, setRecordData] = useState([]);  
    const [BackReload, setBackReload] = useState(false);   
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userProfile, setUserProfile] = useState('');  
  const [specialty, setSpecialty] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState(''); 
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [originalProfile, setOriginalProfile] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCD, setIsEditingCD] = useState(false);
  const [isEditingFollowCD, setIsEditingFollowCD] = useState(false);
  const [isEditingFollowPoC, setIsEditingFollowPoC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(DOBTxt);
  const ageCal = moment().diff(DOBTxt, 'years'); 
  const [BMITxt, setBMITxt] = useState('');   

  const [AgeTxt, setAgeTxt] = useState(ageCal);  
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');
  const [ShowLoader, setShowLoader] = useState(false); 
  const [ShowLoaderSummaryAIChatbot, setShowLoaderSummaryAIChatbot] = useState(false); 
  const [ShowLoaderSummaryFollowup, setShowLoaderSummaryFollowup] = useState(false); 
 
  const [showCalendar, setShowCalendar] = useState(false);  
  const [isValid, setIsValid] = useState(false);
  const [isSession, setIsSession] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [showSummay, setShowSummary] = useState(false);
  // const totalPages = Math.ceil(UserHistoryData.length / recordsPerPage);
  // const totalPageGroups = Math.ceil(totalPages / 3);
  // const currentPageGroup = Math.ceil(currentPage / 3);

  const [illnessesList, setIllnessesList] = useState([]);
  const [selectedIllness, setSelectedIllness] = useState(null);
  const [answerLoader, setAnswerLoader] = useState(false);
  const [EmergencyQuest, setEmergencyQuest] = useState(false);
   
  const [activeTab, setActiveTab] = useState(0);
  const { searchQuery } = useContext(SearchContext);
 
  const [MainPointsDiscussed, setMainPointsDiscussed] = useState('');
  const [PlanOnCareData, setPlanOnCareData] = useState('');
  const [ConditionalDiagnosis, setConditionalDiagnosis] = useState('');
  const [followConditionalDiagnosis, setFollowConditionalDiagnosis] = useState('');
  const [followPlanOnCareData, setFollowPlanOnCareData] = useState('');

  const [followSummaryEditFollowup, setFollowSummaryEditFollowup] = useState('');
  const [followSummaryEditAIChatbot, setFollowSummaryEditAIChatbot] = useState('');
  const [summaryEditFollowup, setSummaryEditFollowup] = useState('');
  
  
 
  const [SessionIDCheck, setSessionIDCheck] = useState('');
  const [reportData, setReportData] = useState(null);
  const [PCP_Pharmacy, setPCP_Pharmacy] = useState(null);

  const [myinsuranceinfo, setMyinsuranceinfo] = useState([]);

  
const [documents, setDocuments] = useState([]); // Document list state
const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
const [isModalPhysicalExamOpen, setIsModalPhysicalExamOpen] = useState(false); // Modal open/close state
const [selectedDocument, setSelectedDocument] = useState(null); // Track the selected document
const [isModalOpenViewAll, setIsModalOpenViewAll] = useState(false); // Modal open/close state

const [SessionID, setSessionID] = useState('');

const [patientbpMMTxt, setpatientbpMMTxt] = useState('');
const [bpMMTxt, setbpMMTxt] = useState('');
const [bpHgTxt, setbpHgTxt] = useState('');
const [heartrateTxt, setheartrateTxt] = useState('');

const [editbpMMTxt, seteditbpMMTxt] = useState('');
const [editbpHgTxt, seteditbpHgTxt] = useState('');
const [editheartrateTxt, seteditheartrateTxt] = useState('');

const [errorMessage, setErrorMessage] = useState('');

const [errors, setErrors] = useState({}); 
 
const bpMMRef = useRef(null);
const bpHgRef = useRef(null); 

const [CheckBpMMTxt, setCheckBpMMTxt] = useState(bpMMTxt);
const [CheckBpHgTxt, setCheckBpHgTxt] = useState(bpHgTxt);
const [CheckHeartRateTxt, setCheckHeartRateTxt] = useState(heartrateTxt);

const [patientstatus, setPatientstatus] = useState('');

  const [isOpen, setIsOpen] = useState(false); // Track if the div is open
  const [selectedItem, setSelectedItem] = useState(null); // Button name
  const [activeItem, setActiveItem] = useState(null); // Track selected list item 
  const [isOpenPhysicalExam, setIsOpenPhysicalExam] = useState(false); // Track if the div is open
  const [checkPhysicalExam, setCheckPhysicalExam] = useState(false); // Track if the div is open
  const [filledCheckPhysicalExam, setfilledCheckPhysicalExam] = useState(false); // Track if the div is open

  // const [selectedItemFollowup, setSelectedItemFollowup] = useState(null); // Button name   
  const [isOpenFollowup, setIsOpenFollowup] = useState(false); // Track if the div is open
  const [checkFollowup, setCheckFollowup] = useState(false); // Track if the div is open
  const [filledCheckFollowup, setfilledCheckFollowup] = useState(false); // Track if the div is open
  const [followupDays, setFollowupDays] =  useState(new Date()); //useState('');
  const [followupTime, setFollowupTime] =  useState(new Date()); //useState('');
  const [followupDate, setFollowupDate] =  useState(new Date()); //useState('');
  const [futureDate, setFutureDate] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);
  const now = new Date();
  const [tempBpMMTxt, setTempBpMMTxt] = useState(''); // Temporary Mm value 
  const [openDiv, setOpenDiv] = useState(null);

  
const [followupZoomMeet, setFollowupZoomMeet] = useState(false); 
const [checkFollowupMeet, setCheckFollowupMeet] = useState(false); 
const [checkAllFollowupMeet, setCheckAllFollowupMeet] = useState(false); 

const [selectedItemFollowup, setSelectedItemFollowup] = useState([]); // Followup Date 
const [FollowupData, setFollowupData] = useState([]); // Followup Date 
const [MeetingDuration, setMeetingDuration] = useState([]); // Followup Date 

const [activeOption, setActiveOption] = useState('Routine'); // State to track the active option
  
const [meetingStatusZoom, setMeetingStatusZoom] = useState(''); // Button name   
const [dataProcessingStatus, setDataProcessingStatus] = useState([]); // Button name  

const [selectedZoomMeet, setSelectedZoomMeet] = useState([]); // Button name   
const [isOpenZoomMeet, setIsOpenZoomMeet] = useState(false); // Track if the div is open
const [checkZoomMeet, setCheckZoomMeet] = useState(false); // Track if the div is open 
const [meetingTitle, setMeetingTitle] = useState('AIGI Online Consultation');
const [duration, setDuration] = useState(0);  
const [startTime, setStartTime] = useState(new Date());
const [startDate, setStartDate] = useState(new Date());

const [activeSpeech, setActiveSpeech] = useState(null);
const [transcripts, setTranscripts] = useState({ medicalcondition: ''});
const [MedicalConditionsData, setMedicalConditionsData] = useState('');

const [CheckExpiry, setCheckExpiry] = useState(false);  

const [activeKey, setActiveKey] = useState(null);
// const [Icons, setIcons] = useState([{ editIcon: 'editIcon', editRed: 'editRed', admin_icon911: 'admin_icon911' }]);
 
 

const handleOptionClick = (option) => {
  setActiveOption(option); // Update the active option
};
 
const checkOrderList = [
  { Name: "Allergies", doc_type: "allergy" },
  { Name: "Medications", doc_type: "medications" },
  { Name: "Medical Conditions", doc_type: "medical_conditions" },
  { Name: "Past Surgical History", doc_type: "past_surgical_history" },
  { Name: "Social History", doc_type: "social_history" },
  { Name: "Primary Care Physician (PCP)", doc_type: "pcp" },
  { Name: "Pharmacy information", doc_type: "pharmacy" },
  { Name: "Upload Reports", doc_type: "other_report" }
];
const options = [
  'Test Results',
  'Refills',
  'Recall Colon',
  'Recall ECD',
  'Routine',
  'Persistant Symptoms',
];
 


 // console.log(filledCheckPhysicalExam +' '+ checkPhysicalExam )
 

  const handleDaysChange = (e) => {
    const days = e.target.value; 
    // Validate if input is a number
    if (/^\d*$/.test(days)) {
      setFollowupDays(days);
      setErrors({}); 
      setErrorMessage(''); // Clear error if valid input
  
      // Calculate future date only if days is a valid number
      if (days && !isNaN(days)) { 
        const today = new Date();
        today.setDate(today.getDate() + parseInt(days, 10)); // Use parseInt with radix 10
        setFutureDate(today.toISOString().split('T')[0]); // Format as yyyy-mm-dd
        calculateRemainingDays(today);
      } else {
        setFutureDate(null); // Reset if input is invalid
      }
    } else {
      setErrorMessage('Please enter a valid number of days');
      setFollowupDays(''); // Clear the invalid input
      setFutureDate(null); // Reset future date if input is invalid 
    }
  }; 

   // Calculate remaining days based on futureDate
   const calculateRemainingDays = (targetDate) => {
    const currentDate = new Date();
    const differenceInTime = targetDate - currentDate;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    setRemainingDays(differenceInDays);
  };
 
  const handleFollowTimeChange = (time) => {
    setFollowupTime(time);
  };
  
  const handleFollowDateChange = (date) => {
    setFollowupDate(date);

    const now = new Date();
  
    // Check if the selected date is today
    if (date.toDateString() === now.toDateString()) {
      // Set time to the current time or the next available interval
      setFollowupTime(new Date(date.setHours(now.getHours(), now.getMinutes(), 0, 0)));
    } else {
      // Set time to 9:00 AM for other dates
      setFollowupTime(new Date(date.setHours(9, 0, 0, 0)));
    }    
  
  };
  
  
  const handleUserRemove = async () => { 
    const userIdRemove = "674f54ea68a6001c54d4439d"; 
    // fetch(getBaseURL+'/delete_by_id/'+userIdRemove, {
      fetch(getBaseURL+'/delete_by_id/'+userIdRemove, {
     
      method: 'DELETE',  
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){ 
      } 
    })
    .catch((error) => {
      console.log('Error:', error);
    });
    
  };

  
const handleTextChangeMedicalCondition = (e) => {   
  setTranscripts({ ...transcripts, medicalcondition: e.target.value });     
  const valueTxt =  e.target.value;
  setMedicalConditionsData(valueTxt);    
};

  // Handle form submission
  const handleSaveFollowup = async (event) => { 
    event.preventDefault();
    setLoading(true);
    const startdatetime = formatDateTimeForAPI(followupDate, followupTime);  
  
  
  
    setShowLoader(true);
    const topicTxt = checkAllFollowupMeet? selectedItemFollowup.title : activeOption;

    const data = {
      admin_id: adminId,
      session_id: SessionIDCheck,
      user_id: userid, 
      topic: topicTxt,
      start_time: startdatetime,
      attendee: email, 
      description: transcripts.medicalcondition,
    }; 
    try { 
      const response = await fetch(`${getBaseURL}/api/zoom/followup/create/meeting`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = response.headers.get('Content-Type').includes('application/json')
      ? await response.json()
      : {}; 
    if (response.ok) { 
      toast.success(result.message || 'Data updated successfully!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedItemFollowup(result);
      if (result.zoom_meeting && Object.keys(result.zoom_meeting).length > 0) {
        setFollowupZoomMeet(true);
      }
  
      // Reset form
      setActiveOption('Routine');
      setFollowupDays('');
      setFutureDate('');
      setOpenDiv(null);
      setErrors({});
    } else {
      toast.error(result.message || 'An unexpected error occurred.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.error('Error occurred:', error);
    toast.error(error.message  || 'An unexpected error occurred. Please try again.', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    setLoading(false);
    setShowLoader(false);
  }
}; 
 
  const handleDateChange = (date) => {
    setStartDate(date);

    const now = new Date();
  
    // Check if the selected date is today
    if (date.toDateString() === now.toDateString()) {
      // Set time to the current time or the next available interval
      setStartTime(new Date(date.setHours(now.getHours(), now.getMinutes(), 0, 0)));
    } else {
      // Set time to 9:00 AM for other dates
      setStartTime(new Date(date.setHours(9, 0, 0, 0)));
    }
  };

 
  const handleTimeChange = (time) => {
    setStartTime(time);
  };

  // Ensure `startTime` stays on the same day as `startDate`
  useEffect(() => {
    if (startDate && startTime && startDate.toDateString() !== startTime.toDateString()) {
      setStartTime(new Date(startDate.setHours(startTime.getHours(), startTime.getMinutes())));
    }
  }, [startDate]);


  const validateForm = () => {
    const newErrors = {};
    if (!meetingTitle) newErrors.meetingTitle = 'Meeting title is required';
    if (!startDate) newErrors.startDate = 'Meeting date is required';
    if (!startTime) newErrors.startTime = 'Meeting time is required';
    // if (!duration || isNaN(duration) || duration <= 0) {
    //   newErrors.duration = 'Meeting duration should be a positive number';
    // }
    setErrors(newErrors);
    setLoading(false);
    return Object.keys(newErrors).length === 0;
  };

  const formatDateTimeForAPI = (date, time) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(time.getHours()).padStart(2, '0');
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };
   

//console.log(bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt)

const handleInput = (e, nextRef, prevRef) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Convert value to an integer for validation
  const intValue = parseInt(value, 10);

  // Clear the error message when user types
  if (fieldName === "Mm" || fieldName === "Hg") {
    setErrors((prevErrors) => ({ ...prevErrors, bpHgTxt: '' }));
  }

  // Forward jump when the max length is reached
  if (value.length === e.target.maxLength) {
    // Blood Pressure Mm Validation (0-200)
    if (fieldName === "Mm") {
      if (intValue < 0 || intValue > 200 || isNaN(intValue)) {
        setErrorMessage("Mm value must be between 0 and 200");
        bpMMRef.current.focus();
        return;
      } else {
        setbpMMTxt(value);  // Set the bpMMTxt state
      }
    }

    // Blood Pressure Hg Validation (0-200)
    if (fieldName === "Hg") {
      if (intValue < 0 || intValue > 200 || isNaN(intValue)) {
        setErrorMessage("Hg value must be between 0 and 200");
        bpHgRef.current.focus();
        return;
      } else {
        setbpHgTxt(value);  // Set the bpHgTxt state
      }
    }

    // Clear error message if validation passes
    setErrorMessage("");
    if (nextRef) {
      nextRef.current.focus();
    }
  } 
  // Backward jump when "Backspace" is pressed and the input is empty
  else if (e.nativeEvent.inputType === "deleteContentBackward" && value.length === 0) {
    if (prevRef) {
      prevRef.current.focus();
    }
  }
};
  
const handleKeyDown = (e, nextRef, prevRef) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Right Arrow Key
  if (e.key === "ArrowRight") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }
    
    if (nextRef) {
      nextRef.current.focus();
    }
  }

  // Left Arrow Key
  if (e.key === "ArrowLeft") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    if (prevRef) {
      prevRef.current.focus();
    }
  }
};

  

// Add document dynamically
const addDocument = (name, url) => {
  const newDocument = { name, url };
  setDocuments([...documents, newDocument]); // Add to the document list
};


  // Example list of items
  const items = ['New', 'Progress', 'Completed'];

  // Open or close the dropdown div
  // const toggleDiv = () => setIsOpen((prev) => !prev);
  // const toggleDiv = () => setIsOpen(true);
  const toggleDivStatus = () => {
    setOpenDiv(openDiv === 'Status' ? null : 'Status');
    setActiveItem(selectedItem)
  };
  // Handle item selection and update the button name
  const handleItemClick = (item) => {
     setActiveItem(item);// Update button name 
  };
 

  // Monitor input changes and set `checkPhysicalExam` when all values are filled
  useEffect(() => {
    if (bpHgTxt && bpMMTxt && heartrateTxt && tempBpMMTxt) {
      setCheckPhysicalExam(true); // All inputs are filled
      setfilledCheckPhysicalExam(true); // All inputs are filled
    }  
  }, [bpHgTxt, bpMMTxt, heartrateTxt]); // Runs whenever these values change

   
 
  useEffect(() => {
    if (searchQuery) {
      navigate('/admin/dashboard');
    }
  }, [searchQuery, navigate]);

   // Fetch illnesses
   const illnessesArray = async () => {
    const response = await axios.get(getBaseURL+'/illnesses');  
    setIllnessesList(response.data.illnesses); 
  };

  const handleMyInsurance = async (UserIdNew) => {  
    fetch(getBaseURL+'/get_insurance_by_userid/'+UserIdNew, {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){ 
        localStorage.setItem('myinsuranceinfo', JSON.stringify(data.data));
         
          setMyinsuranceinfo(data.data)
          const currentDate = moment(); // Get today's date
          const expiry = moment(data.data.expiry_date); // Parse the expiry date using moment
          
          if (expiry.isBefore(currentDate, 'day')) {
            setCheckExpiry(true); // Expiry date is before today
          } else {
            setCheckExpiry(false); // Expiry date is today or in the future
          }
      } 
    })
    .catch((error) => {
      console.log('Error:', error);
    });
    
  };
 
 
 

  const handleFindRecord = (id) => {
    
  };
  const handleShowSummary = async (id) => {
    setShowLoaderPage(true);
  
    try {
      const foundRecord = UserHistoryData.find((item) => item.created_on === id);
  
      if (foundRecord) {  
        if (foundRecord?.zoom_meeting?.meeting_status === 'active') {
  
          const data = {
            meeting_id: foundRecord?.zoom_meeting?.meeting_id,
            session_id: foundRecord?.session_id,
          }; 
          try {
            const response = await fetch(getBaseURL + '/api/zoom/process/meeting', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            });
  
            const result = await response.json(); 
            if (response.status === 200 || response.status === 201) { 
              showPatientDataResult(result.session_data);
            } else if (response.status === 400) {
              // toast.error(result.message || 'User not found or metrics not updated.', {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              showPatientDataResult(foundRecord);
            } else {
              toast.error(result.message || 'An unexpected error occurred. Please try again.', {
                position: toast.POSITION.TOP_RIGHT,
              });
              showPatientDataResult(foundRecord);
            }
          } catch (error) {
            console.error('Error:', error);
            toast.error(error.message || 'Network error. Please try again later.', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          showPatientDataResult(foundRecord);
        }
      } else {
        setRecordData(null);
      }
    } catch (error) {
      console.error('Error in handleShowSummary:', error);
      toast.error('An error occurred. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setShowLoaderPage(false);
    }
    
  };

  const calculateDuration = (startTs, endTs) => { 
    const durationInSeconds = endTs - startTs;
    const durationInMinutes = Math.floor(durationInSeconds / 60);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
  
    const hourString = hours > 0 ? `${hours} hour` : "";
    const minuteString = minutes > 0 ? `${minutes} minute` : "";
  
    return [hourString, minuteString].filter(Boolean).join(" and ");
  };

    // Monitor input changes and set `checkPhysicalExam` when all values are filled 
    const showPatientDataResult = async (foundRecord) => { 
      const filteredConversation = foundRecord.conversation.filter(convo => Object.keys(convo).length !== 0); 
      // Set all necessary states based on foundRecord
      setRecordData(foundRecord);
      setSelectedIllness(foundRecord.illness);
      setChatHistory(filteredConversation);
      setPlanOnCareData(foundRecord.plan_of_care);
      setConditionalDiagnosis(foundRecord.conditional_diagnosis);
      setSessionIDCheck(foundRecord.session_id);
      setPatientstatus(foundRecord.patientstatus);
      setSelectedItem(foundRecord.patientstatus);
      setActiveItem(foundRecord.patientstatus);
      setbpHgTxt(foundRecord.bp_hg);
      setbpMMTxt(foundRecord.bp_mm);
      setheartrateTxt(foundRecord.heartrate);
      setHeightTxt(foundRecord.height ? foundRecord.height : 0);
      seteditbpHgTxt(foundRecord.bp_hg);
      seteditbpMMTxt(foundRecord.bp_mm);
      seteditheartrateTxt(foundRecord.heartrate); 

      setpatientbpMMTxt(foundRecord.bmi);
      setTempBpMMTxt(foundRecord.bp_mm);
      setFutureDate(foundRecord.follow_up_date);
      // setSelectedItemFollowup(foundRecord.follow_up_date);
      setSelectedZoomMeet(foundRecord?.zoom_meeting || null);
      setMeetingStatusZoom(foundRecord?.zoom_meeting?.meeting_status);
      setDataProcessingStatus(foundRecord?.zoom_meeting?.data_processing_status);
    
      // Check if the answer to the specific question is "yes"
      const emergencyQuestion = filteredConversation.find(convo =>
        convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
      );
    
      if (emergencyQuestion) {
        setEmergencyQuest(true);
      }
    
      setChatHistory([]);
      setShowSummary(true);
    
      if (foundRecord.session) {
        adminCheckedSession(foundRecord.user_id, foundRecord.session_id);
      }
      try {
        ///api/doctor/followup/<session_id>/active_request
        const response = await axios.get(getBaseURL+'/api/doctor/followup/'+foundRecord.session_id+'/all_request'); 
        if (response.status === 200 || response.status === 201) {
          if (response?.data?.data) {
            setFollowupData(response.data.data); 
 
          }  

        }
        
  // Check if the response status is successful
  // if (response.status === 200 || response.status === 201) {
  //   console.log(response?.data?.data);

  //   // Ensure response data is an array
  //   if (Array.isArray(response?.data?.data) && response.data.data.length > 0) {
  //     const followupData = response.data.data;

  //     // Set the follow-up data
  //     setFollowupData(followupData);

  //     // Iterate through the follow-up data array
  //     followupData.forEach((item) => {
  //       if (item.zoom_meeting && Object.keys(item.zoom_meeting).length > 0) {
  //         console.log("Zoom Meeting Details:", item.zoom_meeting);

  //         // Update selected follow-up item (last one found with zoom_meeting)
  //         setSelectedItemFollowup(item);

  //         // Set zoom meeting flag to true (once a valid zoom_meeting is found)
  //         setFollowupZoomMeet(true);
  //       }
  //     });
  //   }
  // }
        
        } catch (error) {
         
        } finally {
          
        }
        try {
          ///api/doctor/followup/<session_id>/active_request
          const response = await axios.get(getBaseURL+'/api/doctor/followup/'+foundRecord.session_id+'/active_request'); 
          // if (response.status === 200 || response.status === 201) { 
          //   console.log(response.data.data)
          //   setSelectedItemFollowup(response.data.data)
          //   if (response.data.data.zoom_meeting && Object.keys(response.data.data.zoom_meeting).length > 0) {
          //     // If zoom_meeting is not null and has keys, set it to true
          //     setFollowupZoomMeet(true);
          //   } 
          //   // setIllnessesList(response.data.illnesses);      
          //   if(response.data.data.patient_request)  {     
          //     setCheckFollowupMeet(true)
          //   }   
          // }  
          if (response.status === 200 || response.status === 201) { 
            const { activeFlag, data } = response.data;
          
            // Set the check for all follow-up meetings
            setCheckAllFollowupMeet(activeFlag); // true if activeFlag is false, and vice versa 
            // Update selected follow-up item
            setSelectedItemFollowup(data);
          
            // Check if zoom meeting exists
            if (data.zoom_meeting && Object.keys(data.zoom_meeting).length > 0) {
              setFollowupZoomMeet(true);
            } 
          
            // Check for patient request
            if (data.patient_request) {
              setCheckFollowupMeet(true);
            }  
          }

          } catch (error) {  
          
          } finally {
            
          } 
        
      try {
        const response = await axios.get(getBaseURL+'/illnesses'); 
        if (response.status === 200) {
          setIllnessesList(response.data.illnesses);        
          setShowLoaderPage(false)
        }
    
        } catch (error) {
         
       } finally {
          
        }

      try { 
        const url = `${getBaseURL}/api/medical/reports/fetch/${foundRecord.reports_id}`;
        // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
          
        if (response.data && response.data.status === 'success') { 
          setReportData(response.data.content);
        }
        } catch (error) {
          //console.error('Error posting data:', error);
       } finally {
          
        }

        try { 
          const url = `${getBaseURL}/api/patient/fetch/details/pcp-pharmacy/${foundRecord.reports_id}`;
          // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
          const response = await axios.get(url, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
            
          if (response.data && response.data.status === 'success') {  
            setPCP_Pharmacy(response.data.data);
          }
          } catch (error) {
            //console.error('Error posting data:', error);
         } finally {
            
          }
        


    };
 
  const groupedDocuments = (reportData || []).reduce((acc, doc) => {
    if (!doc.doc_type) {
      return acc;
    }
    const docType = doc.doc_type.trim();

    if (!acc[docType]) {
      acc[docType] = [];
    }
  
    acc[docType].push({
      doc_id: doc.doc_id,
      doc_summary: doc.doc_summary,
      doc_text: doc.doc_text,
      doc_url: doc.doc_url,
    });
 

  return acc;
}, {});
 
if (PCP_Pharmacy && PCP_Pharmacy.pcp) {
groupedDocuments["pcp"] = [
  ...(groupedDocuments["pcp"] || []),
  {
    doc_summary: `${PCP_Pharmacy.pcp.name}`,  
    doc_text: null,
    doc_url: null,
  },
];
}
// Push Pharmacy into groupedDocuments
if (PCP_Pharmacy && PCP_Pharmacy.pharmacy) {
groupedDocuments["pharmacy"] = [
  ...(groupedDocuments["pharmacy"] || []),
  {
     doc_summary: `${PCP_Pharmacy.pharmacy.name}`, 
     doc_text: null,
     doc_url: null,
  },
];
} 

// Map checkOrderList to match groupedDocuments and sort by order
const sortedGroupedDocuments = checkOrderList.map(orderItem => ({
  Name: orderItem.Name,
  doc_type: orderItem.doc_type,
  documents: groupedDocuments[orderItem.doc_type] || []
})); 

  const adminCheckedSession = async (patientId, sessionId) => {
    try {
      const response = await axios.post(`${getBaseURL}/admin_log`, {
        
          patient_id: patientId,
          admin_id: adminId,
          session_id: sessionId,  
      
      }); 
  
    
      if( response.data.status == 'success'){ 
        setBackReload(true)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      setShowLoader(false);
    }
  }

  const handleShowChat = (id) => {
    const filteredConversation = RecordData.conversation.filter(convo => Object.keys(convo).length !== 0);
 
    setActiveTab(id);
    setAnswerLoader(true)
    setTimeout(() =>  { 
      setChatHistory(filteredConversation); 
      setAnswerLoader(false) 
    }, 1000);
  }
  const handleHideSummary = () => { 
    setActiveTab(0);
    setRecordData([])
    setSelectedIllness() 
    if(BackReload){
      window.location.reload(); 
    }
    setShowSummary(false)
    setAnswerLoader(false) 
    setEmergencyQuest(false)
   };
   
  const handleShowDashboard = () => {
    setShowSummary(false) 
   }; 

   useEffect(() => {  
   const userdataProfile =  JSON.parse(localStorage.getItem('UserProfile'))
    const userid = userdataProfile.user_id; 
    handleMyInsurance(userid)
    const fetchRecords = async () => {
    
      setShowLoaderPage(true)
 
      try {
        // setShowLoaderPage(true)
        // Make a GET request to fetch records from the API
        const response = await axios.get(getBaseURL+'/get_user_sessions/'+userid, {
          headers: {
            'Content-Type': 'application/json, text/html', // Specify the Content-Type header
          },
        });
        console.log(userid +'  '+JSON.stringify(response.data))
        setShowLoaderPage(true)
        // Set user profile data
        setUserProfileData(response.data.user_details);  
      // Sort history data by created_on date in descending order
        const sortedHistory = [...response.data.history];  
        setUserHistoryData(sortedHistory);
        setUserHistoryNumber(response.data.history.length); 
        setBMITxt(response.data.bmi ? response.data.bmi : 0);
        setFullName(response.data.user_details.fullName);
        setEmail(response.data.user_details.email);
        setDOBTxt(response.data.user_details.dob);
        setPhoneNumber(response.data.user_details.phoneNumber ? '+'+response.data.user_details.phoneNumber : '');
        setUserProfile(response.data.user_details.userProfile);
        setInsuranceIdTxt(response.data.user_details.insurance ? response.data.user_details.insurance : 0);
        setGenderTxt(response.data.user_details.gender ? response.data.user_details.gender : '');
        setHeightTxt(response.data.user_details.height ? response.data.user_details.height : 0);
        setWeightTxt(response.data.user_details.weight ? response.data.user_details.weight : 0);        
        setIsSession(response.data.session_status ? response.data.session_status : 'false');        
    
      } catch (error) {
        console.error('Error fetching records:', error);
        // Handle error, show an error message, etc.
      }
      
      setShowLoaderPage(false)
    };
    fetchRecords();
  }, []); 

  useEffect(() => {
    const handlePopstate = () => {
      setActiveTab(0); // Reset the active tab to the default tab index
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    setShowMainPoint(false);
  };

  useEffect(() => {
    setActiveTab(0); // Set the default active tab when the component mounts
  }, []);

  // Function to process the user data
  const processUserData = () => { 
    if (!RecordData) return []; // If no recordData, return empty array

    return [RecordData].map(user => {
      // Remove empty objects from the conversation array
      const filteredConversation = user.conversation.filter(convo => Object.keys(convo).length !== 0); 
      
      // Check if the answer to the specific question is "yes"
      const emergencyQuestion = filteredConversation.find(convo => 
        convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
      );

      if (emergencyQuestion) {
        alert("Emergency care is needed!");
      }

      return {
        ...user,
        conversation: filteredConversation
      };
    });
  };
  const [ImageHide, setImageHide] = useState(false); 
  const [showMainPoint, setShowMainPoint] = useState(false);
  const [showMainPointCD, setShowMainPointCD] = useState(false);
  const [showMainPointFollowCD, setShowMainPointFollowCD] = useState(false);
  const [showSummaryEditFollowup, setShowSummaryEditFollowup] = useState(false);
  const [showSummaryEditAIChatbot, setShowSummaryEditAIChatbot] = useState(false);
  const [showMainPointFollowPoC, setShowMainPointFollowPoC] = useState(false);
  
  const handleInputMainPoint = () => { 
        setShowMainPoint(false);   
        {!PlanOnCareData ? 
        (<>
          setShowMainPoint(false);
          </>
        ):(
          <>
          setShowMainPoint(true);
          </>
        )
      }
    setShowMainPoint(true);
    setPlanOnCareData(PlanOnCareData);
   };
  const handleCancelClickMPoint = () => {
    setShowLoader(false);
    setShowMainPoint(false);
  };
  const handleInputChangeMPoint= (e) => {
    setPlanOnCareData(e.target.value);
  };
  const handleSaveClickMPoint = () => {
    setShowLoader(true);
    setTimeout(() => { 
    setIsEditing(false); 
    handleSaveMPoint();
    }, 2000); 
  };
  const handleSaveMPoint = async (event) => {   
    try {   
      const reqData = JSON.stringify({ session_id: SessionIDCheck, plan_of_care: PlanOnCareData }) ;
   
      const response = await axios.put(getBaseURL+'/update_plan_of_care', reqData, {
        headers: {
          'Content-Type': 'application/json', // Specify content type as application/json
        },
      });
      if( response.data.status == 'success'){
        
          setShowLoader(false); 
          setShowMainPoint(false); 
          setBackReload(true)
          
      } 
    } catch (error) {
      console.error('Error updating summary:', error);
      // Handle error, show an error message, etc.
    }
  };
//conditionaldiagnosis  
  const handleInputMainPointCD = () => { 
    setShowMainPointCD(false);   
    {!ConditionalDiagnosis ? 
    (<>
      setShowMainPointCD(false);
      </>
    ):(
      <>
      setShowMainPointCD(true);
      </>
    )
  }
setShowMainPointCD(true);
setConditionalDiagnosis(ConditionalDiagnosis);
};
const handleCancelClickMPointCD = () => {
setShowLoader(false);
setShowMainPointCD(false);
};
const handleInputChangeMPointCD = (e) => {
  setConditionalDiagnosis(e.target.value);
};
const handleSaveClickMPointCD = () => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingCD(false); 
handleSaveMPointCD();
}, 2000); 
};
const handleSaveMPointCD = async (event) => {   
// try {   
  const data = { 
    admin_id: adminId,
    session_id : SessionIDCheck,   
    user_id: userid, 
    conditional_diagnosis: ConditionalDiagnosis , 
};  
  try {
    const response = await fetch(getBaseURL+'/api/admin/update/conditionaldiagnosis', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
 
    const result = await response.json();
    if (response.status === 200) {
      toast.success(result.message, {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      setShowLoader(false); 
      setShowMainPointCD(false); 
      setBackReload(true)

    } else if (response.status === 400 || response.status === 409 ) {
      // Handle 400 or 500 errors with appropriate messages
      toast.error(result.message || 'Failed to save the item.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setShowLoader(false);
    } 
  } catch (error) {
    console.error('Error:', error);
    toast.error('An unexpected error occurred.');
  } finally {
    setLoading(false); // Stop loading
  } 
};

//Follow conditionaldiagnosis  
const handleInputMainPointFollowCD = (followup_CD) => { 
  setShowMainPointFollowCD(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowMainPointFollowCD(false);
    </>
  ):(
    <>
    setShowMainPointFollowCD(true);
    </>
  )
}
setShowMainPointFollowCD(true);
setFollowConditionalDiagnosis(followup_CD);
}; 
const handleCancelClickMPointFollowCD = () => {
setShowLoader(false);
setShowMainPointFollowCD(false);
};
const handleInputChangeMPointFollowCD = (e) => {
  setFollowConditionalDiagnosis(e.target.value);
};
const handleSaveClickMPointFollowCD = (followup_id) => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingFollowCD(false); 
handleSaveMPointFollowCD(followup_id);
}, 2000); 
};
const handleSaveMPointFollowCD = async (followup_id) => {   
// try {   
const data = { 
  followup_id: followup_id,
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  followup_cd: followConditionalDiagnosis,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/followup/update/followup_cd', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoader(false); 
    setShowMainPointFollowCD(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoader(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};

//Follow Plan of Care  
const handleInputMainPointFollowPoC = (followup_PoC) => { 
  setShowMainPointFollowPoC(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowMainPointFollowPoC(false);
    </>
  ):(
    <>
    setShowMainPointFollowPoC(true);
    </>
  )
}
setShowMainPointFollowPoC(true);
setFollowPlanOnCareData(followup_PoC);
}; 
const handleCancelClickMPointFollowPoC = () => {
setShowLoader(false);
setShowMainPointFollowPoC(false);
};
const handleInputChangeMPointFollowPoC = (e) => {
  setFollowPlanOnCareData(e.target.value);
};
const handleSaveClickMPointFollowPoC = (followup_id) => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingFollowPoC(false); 
handleSaveMPointFollowPoC(followup_id);
}, 2000); 
};
const handleSaveMPointFollowPoC = async (followup_id) => {   
// try {   
const data = { 
  followup_id: followup_id,
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  followup_poc: followPlanOnCareData,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/followup/update/followup_poc', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoader(false); 
    setShowMainPointFollowPoC(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoader(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};


//Summary Edit Followup 
const handleInputMainSummaryEditFollowup  = (followup_CD) => { 
  setShowSummaryEditFollowup(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowSummaryEditFollowup(false);
    </>
  ):(
    <>
    setShowSummaryEditFollowup(true);
    </>
  )
}
setShowSummaryEditFollowup(true);
setFollowSummaryEditFollowup(followup_CD);
}; 
const handleCancelClickSummaryEditFollowup = () => {
setShowLoaderSummaryFollowup(false);
setShowSummaryEditFollowup(false);
};
const handleInputChangeSummaryEditFollowup = (e) => {
  setFollowSummaryEditFollowup(e.target.value);
};
const handleSaveClickSummaryEditFollowup= (followup_id) => {
setShowLoaderSummaryFollowup(true);
setTimeout(() => { 
setIsEditingFollowCD(false); 
handleSaveMPointFollowCD(followup_id);
}, 2000); 
};
const handleSaveSummaryEditFollowup = async (followup_id) => {   
  setShowLoaderSummaryFollowup(true);
// try {   
const data = { 
  followup_id: followup_id,
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  followup_summary: followSummaryEditFollowup,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/followup/update/summary', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoaderSummaryFollowup(false); 
    setShowSummaryEditFollowup(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoaderSummaryFollowup(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};



//Summary Chatbot Followup 
const handleInputMainSummaryEditAIChatbot  = (followup_CD) => { 
  setShowSummaryEditAIChatbot(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowSummaryEditAIChatbot(false);
    </>
  ):(
    <>
    setShowSummaryEditAIChatbot(true);
    </>
  )
}
setShowSummaryEditAIChatbot(true);
setFollowSummaryEditAIChatbot(followup_CD);
}; 
const handleCancelClickSummaryEditAIChatbot = () => {
setShowLoaderSummaryAIChatbot(false);
setShowSummaryEditAIChatbot(false);
};
const handleInputChangeSummaryEditAIChatbot = (e) => {
  setFollowSummaryEditAIChatbot(e.target.value);
};
const handleSaveClickSummaryEditAIChatbot= () => {
setShowLoaderSummaryAIChatbot(true); 
};
const handleSaveSummaryEditAIChatbot = async () => {   
setShowLoaderSummaryAIChatbot(true); 

// try {   
const data = {  
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  chatbot_summary: followSummaryEditAIChatbot,   
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/update/session/main_summary', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoaderSummaryAIChatbot(false); 
    setShowSummaryEditAIChatbot(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoaderSummaryAIChatbot(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
} 
};


//---------------------------------//

const handleButtonClickFollowZoomMeet = () => {
  const zoomSchedulerUrl = selectedItemFollowup.zoom_meeting.doctor_url; // Zoom Scheduler page URL 
  window.open(zoomSchedulerUrl, "_blank", "noopener,noreferrer");
};
const handleButtonClickZoomMeet = () => {
  const zoomSchedulerUrl = selectedZoomMeet.doctor_url; // Zoom Scheduler page URL 
  window.open(zoomSchedulerUrl, "_blank", "noopener,noreferrer");
};

  // Function to copy email to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(email)
      .then(() => { 
        toast.success("Email copied to clipboard!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.error("Failed to copy email: ", error);
        toast.error("Failed to copy email", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

 // Sample file array
 const fileArray = [
  { id: 1, fileName: 'Abdominal CT', url: AbdominalCT },
  { id: 2, fileName: 'Abdominal US', url: AbdominalUS },
  { id: 3, fileName: 'Anemia Labs', url: AnemiaLabs },
  { id: 4, fileName: 'Barium Studies', url: BariumStudies },
  { id: 5, fileName: 'Basic Gastro Labs', url: BasicGastroLabs },
  { id: 6, fileName: 'Colon Order', url: ColonOrder },
  { id: 7, fileName: 'Colonoscopy', url: Colonoscopy },
  { id: 8, fileName: 'Colon Screening Guidelines', url: ColonScreeningGuidelines },
  { id: 9, fileName: 'Diverticulosis', url: Diverticulosis },
  { id: 10, fileName: 'EGD Order', url: EGDOrder },
  { id: 11, fileName: 'FattyLiver', url: FattyLiver }, 
  { id: 12, fileName: 'FODMAP Diet', url: FODMAP_Diet },
  { id: 13, fileName: 'GERD Diet and Lifestyle', url: GERD_Diet_and_Lifestyle },
  { id: 14, fileName: 'Heaptic Labs', url: HeapticLabs },
  { id: 15, fileName: 'High Fiber Diet', url: High_Fiber_Diet },    
  { id: 16, fileName: 'Inflammatory Bowel Disease', url: InflammatoryBowelDisease },
  { id: 17, fileName: 'Irritable Bowel Syndrome', url: IrritableBowelSyndrome },
  { id: 18, fileName: 'Procedural Sedation', url: ProceduralSedation },
  { id: 19, fileName: 'Stool Studies', url: StoolStudies },
  { id: 20, fileName: 'Stress Management', url: StressManagement },
  { id: 21, fileName: 'Telehealth Disclaimer', url: TelehealthDisclaimer },
  { id: 22, fileName: 'Upper GI Endoscopy', url: UpperGIEndoscopy },
  { id: 23, fileName: 'Weight Reducing Diet', url: Weight_Reducing_Diet },
];

  const contentRef = useRef(); 
  const [selectedFiles, setSelectedFiles] = useState([]);

 
const handleCheckboxChange = (file) => {
  setSelectedFiles((prevSelectedFiles) => {
    const exists = prevSelectedFiles.find((f) => f.id === file.id);
    if (exists) {
      // Remove the file if it already exists in the selected list
      return prevSelectedFiles.filter((f) => f.id !== file.id);
    } else {
      // Add the file if not already selected
      return [...prevSelectedFiles, file];
    }
  });
};
  

  
  const downloadPDF = (patientName) => {
    setTimeout(() => {
      const input = contentRef.current;
  
      if (!input) {
        console.error("Element not found in the document.");
        return;
      }
  
      html2canvas(input).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
  
        // Define margins
        const marginLeft = 10; // Left margin in mm
        const marginTop = 10; // Top margin in mm
        const marginRight = 10; // Right margin in mm
        const marginBottom = 10; // Bottom margin in mm
  
        const pdfWidth = pdf.internal.pageSize.getWidth() - marginLeft - marginRight; // Content width
        const pdfHeight = pdf.internal.pageSize.getHeight() - marginTop - marginBottom; // Content height
  
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
  
        const ratio = pdfWidth / canvasWidth; // Scale factor for canvas to fit PDF width
        const scaledHeight = canvasHeight * ratio;
  
        let currentHeight = 0;
        let pageNumber = 1; // Start page number
  
        while (currentHeight < canvasHeight) {
          const sectionCanvas = document.createElement("canvas");
          const sectionContext = sectionCanvas.getContext("2d");
  
          sectionCanvas.width = canvasWidth;
          sectionCanvas.height = Math.min(canvasHeight - currentHeight, pdfHeight / ratio);
  
          sectionContext.drawImage(
            canvas,
            0,
            currentHeight,
            canvasWidth,
            sectionCanvas.height,
            0,
            0,
            canvasWidth,
            sectionCanvas.height
          );
  
          const sectionImageData = sectionCanvas.toDataURL("image/png");
  
          if (currentHeight > 0) pdf.addPage();
  
          pdf.addImage(
            sectionImageData,
            "PNG",
            marginLeft,
            marginTop,
            pdfWidth,
            (sectionCanvas.height * pdfWidth) / canvasWidth
          );
  
          // Add page number in the footer
          const pageHeight = pdf.internal.pageSize.getHeight();
          pdf.setFontSize(8);
          pdf.text(`Page ${pageNumber}`, pdf.internal.pageSize.getWidth() / 2, pageHeight - 4, { align: "center" });
  
          pageNumber++;
          currentHeight += sectionCanvas.height;
        }
  
        // Generate filename
        const now = new Date();
        const date = now.toISOString().split("T")[0]; // yyyy-mm-dd format
        const time = now.toTimeString().split(" ")[0].replace(/:/g, "-"); // HH-MM-SS format
        const PatientNameTxt = patientName.replace(/ /g, "_");
        const filename = `${date}_${time}_${PatientNameTxt}_Report.pdf`;
  
        pdf.save(filename);
      }).catch((error) => {
        console.error("Error generating PDF:", error);
      });
    }, 100); // Delay of 100ms or adjust as needed
  };
  
  
 

// Function to handle generating and merging PDFs
const generateAndMergePDF = async (patientName) => {
  try {
    const modalContent = contentRef.current;

    // Generate PDF from modal content
    const canvas = await html2canvas(modalContent);
    const contentPdf = new jsPDF("p", "mm", "a4");

    const pageWidth = contentPdf.internal.pageSize.getWidth() - 20;
    const pageHeight = contentPdf.internal.pageSize.getHeight() - 26;
    const imageHeight = (canvas.height * pageWidth) / canvas.width;

    let currentHeight = 10;
    let pageCount = 1;

    while (currentHeight < imageHeight) {
      const sectionHeight = Math.min(pageHeight, imageHeight - currentHeight);
      const sectionCanvas = document.createElement("canvas");
      sectionCanvas.width = canvas.width;
      sectionCanvas.height = (sectionHeight * canvas.width) / pageWidth;

      const ctx = sectionCanvas.getContext("2d");
      ctx.drawImage(
        canvas,
        0,
        currentHeight * (canvas.width / pageWidth),
        canvas.width,
        sectionCanvas.height,
        0,
        0,
        canvas.width,
        sectionCanvas.height
      );

      const imgData = sectionCanvas.toDataURL("image/png");
      if (pageCount > 1) contentPdf.addPage();
      contentPdf.addImage(imgData, "PNG", 10, 10, pageWidth, sectionHeight);

      currentHeight += sectionHeight;
      pageCount++;
    }

    const contentPdfBlob = contentPdf.output("arraybuffer");
    const contentPdfDoc = await PDFDocument.load(contentPdfBlob);
 
    // Create a new merged PDF document
    const mergedPdfDoc = await PDFDocument.create();

    // Add modal content pages to merged PDF
    const contentPages = await mergedPdfDoc.copyPages(contentPdfDoc, contentPdfDoc.getPageIndices());
    contentPages.forEach((page) => mergedPdfDoc.addPage(page));

    // Filter and merge selected files
    const validSelectedFiles = selectedFiles.filter((file) => file.url); 
    if (validSelectedFiles.length === 0) {
      console.warn("No valid files selected for merging.");
    }

    for (const file of validSelectedFiles) {
      const fileBlob = await fetch(file.url).then((res) => res.blob());
      const filePdf = await PDFDocument.load(await fileBlob.arrayBuffer());
      const filePages = await mergedPdfDoc.copyPages(filePdf, filePdf.getPageIndices());
      filePages.forEach((page) => mergedPdfDoc.addPage(page));
    }

      // Add Header with patient name and page number
      const totalPages = mergedPdfDoc.getPageCount();
      for (let i = 0; i < totalPages; i++) {
        const page = mergedPdfDoc.getPage(i);
        const pageHeight = page.getHeight();
        const pageWidth = page.getWidth();
  
        // Draw patient name and page number in the header, right-aligned
        page.drawText(`Page ${i + 1} of ${totalPages}`, {
          x: pageWidth - 70, // Right-aligned with a margin from the right
          y: pageHeight - 20, // Place at the top of the page
          size: 9,
          color: rgb(0, 0, 0), // Black text
          font: await mergedPdfDoc.embedFont(StandardFonts.Helvetica), // Use Helvetica font
        });
      }

    // Serialize and download the merged PDF
    const mergedPdfBytes = await mergedPdfDoc.save();
    const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });

    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const time = now.toTimeString().split(" ")[0].replace(/:/g, "-");
    const filename = `${date}_${time}_${fullName.replace(/ /g, "_")}_Merged_Report.pdf`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  } catch (error) {
    console.error("Error generating merged PDF:", error);
  }
};

// Example usage: Call this function on form submission or button click


const handleShare= async () => {
  try {
    // Step 1: Get the text content from the modal
    const content = contentRef.current;
    const textContent = content.innerText; // Get text-only content
    console.log(textContent)

    // Step 2: Convert text content to a Blob
    const textBlob = new Blob([textContent], { type: 'text/plain' });

    // Step 3: Convert canvas content to image Blob (if canvas exists)
    const canvas = document.getElementById('canvasId'); // Replace with your canvas ID
    let canvasBlob = null;

    if (canvas) {
      canvasBlob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/png');
      });
    }

    // Step 4: Create FormData to send both text and image
    const formData = new FormData();
    formData.append('file', textBlob, 'modal-content.txt'); // Name of the file
    if (canvasBlob) {
      formData.append('canvasImage', canvasBlob, 'canvas-image.png'); // Attach canvas image
    }
    formData.append('email', 'christopher.mani@programming.com'); // Replace with your email input logic

    // Step 5: Send the FormData to the backend API
    const response = await fetch(getBaseURL+'/api/patient/report/send-email', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      alert('Content sent successfully!');
    } else {
      alert('Failed to send content.');
    }
  } catch (error) {
    console.error('Error sending content:', error);
  }
};

const handleShare111 = async () => {
  try {
    // Step 1: Get the modal content
    const content = contentRef.current;
    if (!content) {
      alert('Content not found.');
      return;
    }

    // Step 2: Convert the modal content to a canvas
    const canvas = await html2canvas(content);

    // Step 3: Generate a PDF from the canvas
    const pdf = new jsPDF("p", "mm", "a4");
    const imgData = canvas.toDataURL('image/png');
    const imgWidth = 190; // PDF width in mm (A4 width - margins)
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    let positionY = 10;
    const pageHeight = pdf.internal.pageSize.height;

    // If the content is larger than one page, split across pages
    while (positionY < imgHeight) {
      pdf.addImage(imgData, 'PNG', 10, positionY, imgWidth, imgHeight - positionY);
      positionY += pageHeight - 20; // Adjust position for next page
      if (positionY < imgHeight) pdf.addPage();
    }

    // Step 4: Convert the generated PDF to a Blob
    const pdfBlob = pdf.output('blob');

    // Step 5: Prepare FormData for API request
    const formData = new FormData();
    formData.append('pdf', pdfBlob, 'document.pdf'); // Attach the PDF file
    formData.append('email', 'christopher.mani@programming.com'); // Replace with dynamic email input

    // Step 6: Send the PDF to the backend API
    const response = await fetch(`${getBaseURL}/api/patient/report/send-email`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      alert('Email sent successfully!');
    } else {
      const errorText = await response.text();
      alert(`Failed to send email: ${errorText}`);
    }
  } catch (error) {
    console.error('Error sharing content:', error);
    alert('An error occurred while sharing content.');
  }
};

  
 
 
  const isPastDate = moment(selectedItemFollowup).isBefore(moment(), "day");

  const getOrdinalLabel = (number) => {
    const j = number % 10;
    const k = number % 100;
  
    if (j === 1 && k !== 11) {
      return `${number}st`;
    }
    if (j === 2 && k !== 12) {
      return `${number}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${number}rd`;
    }
    return `${number}th`;
  };
  
  return (
    <div>
      {ShowLoaderPage && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
        <Header /> 
        <div className="appointmenttable ">
 
 <div className='container-fluid p-0 adminDashboardMain '>
      

         <div className="contentInnerBody">
          
           
           <div className="contentMainBox viewPatientMain "> 
                  

                     <div className={"patientSummaryMain modalViewAllReportMain mt-1" }>
                 
    <div className="innerDivRecords me-1" >
    <div className="innerModalDiv " >
                          
  <div ref={contentRef} style={{padding: "20px"}}>


        <div className="titleDivRight">
                           <div className="d-flex gap-2 justify-content-start align-items-center mb-2"  style={{marginTop:'0px'}} >
                                <h2 className="capitalizeTxt m-0" >{fullName}</h2>   
                              </div>
                           
                            <ul className="userInfoDetails">
                              <li className={GenderTxt ? '' : 'd-none'} >
                                <img src={Icons[0].admin_userIcon} />
                                <label className="capitalizeTxt">{GenderTxt}</label>
                              </li>
                              <li className={DOBTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_calendarIcon} />
                                <label>{moment(DOBTxt).format('MMM DD, YYYY')} {' '}({ageCal} years)</label>
                              </li>
                              <li>
                                <img src={Icons[0].admin_mailIcon} />
                                <label className="singleLineEllipsis">{email}</label>
                              </li>
                              <li>
                                <img src={Icons[0].admin_phoneIcon} />
                                <label>{phoneNumber}</label>
                              </li>
                            </ul>

                            <ul className="infoDivPatientList">
                            
                              <li>
                                <label>BMI</label>
                                <b>{BMITxt}</b>
                              </li>
                             <li>
                                <label>Weight</label>
                                <b>{WeightTxt  ? WeightTxt : '0'}{' '}<span>lbs</span></b>                               
                              </li>
                              <li>
                                <label>Height</label>
                                <b>{`${formatHeight(HeightTxt)} Feet `}
                                  {/* {HeightTxt ? HeightTxt : '0'} */}
                                  </b>                               
                              </li>
                              <li>
                              <label>Physical  Exam </label> 
                              {/* {bpHgTxt && bpMMTxt && heartrateTxt ? (  */}
                              {(checkPhysicalExam || filledCheckPhysicalExam) ? (
                                  <>
                                  <b className={bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt}>
                                  {bpHgTxt && bpMMTxt ? (`${bpHgTxt} / ${bpMMTxt} - `) : null  }
                                  {heartrateTxt ?`${heartrateTxt} BPM` : null } 
                                  </b>
                                  </> 
                                ) :  (<>-</>)}  
                              </li>
                            </ul>
                          <hr></hr>
                        </div> 

                        {FollowupData?.length > 0 ? (
          FollowupData.map((item, index) => 
            item.followup_summary ? ( // Check if followup_summary exists
              <div key={index}>
                <div className="despInfoDiv">
                <div className="headDespInfoDiv">
                  <ul className="infoDivPatientList">
                    <li>
                      <label> {
                         `${getOrdinalLabel(index+2)} Consultation`
                          }</label>
                      
                      <b>{moment(item.created_on).format('MMM DD, YYYY')}</b>
                    </li>
                    <li>
                      <label>Follow up</label>
                      <b>{item.title}</b>
                    </li>
                    {
                      calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)
                        ? <li>
                          <label>Duration:</label>
                          <b>{calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)}</b>
                          </li>
                        : null
                    }

                    {/* <li>
                        <label>Duration:</label>{" "}
                        <b>{calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)}</b>
                      </li> */}
                      
                                  <li>
                                    <label>Location</label>
                                    <b>Home</b>
                                 </li>
                  </ul>
                </div>
              </div>
              <p>{item.followup_summary}</p>
              <hr></hr>
              </div>
            ) : null // If followup_summary is null, render nothing
          )
                            ) : null}
                              {FollowupData?.length > 0 ? (
                            FollowupData.map((item, index) => 
                              item.followup_PoC ? ( // Check if followup_PoC exists
                                <div key={index}>
                                  <h2>Follow up - Plan of Care</h2>
                                  <pre className="innerDivBoxPC h-auto  overflow-hidden">
                                    {item.followup_PoC}
                                  </pre>
                                  <hr />
                                </div>
                              ) : null // If followup_PoC is empty or null, render nothing
                            )
                          ) : null}

                              <ul className="infoDivPatientList">
                              <li>
                                <label>1st Consultation</label>
                                <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
                              </li>
                              <li>
                                <label>Illnesses</label>
                                <b>{RecordData.illness}</b>
                              </li>
                              {meetingStatusZoom ?
                                (<>
                                  <li>
                                  <label>Duration:</label>{" "}
                                  <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                                  </li>
                                  <li>
                                    <label>Location</label>
                                    <b>Home</b>
                                 </li>
                                </>
                                ):null }

                                
                              </ul>
                        {EmergencyQuest ? (<><p className="innerDivBox urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p></>) :  RecordData.chatbot_summary ? <p className="innerDivBox">{RecordData.chatbot_summary} </p> :  <p className="innerDivBox"> - </p> }
                        <hr></hr>
                        <h2>Differential Diagnosis </h2>
                          {!showMainPointCD ? (
                                  <>     
                                  {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : ConditionalDiagnosis ? <pre className="innerDivBoxPC">{ConditionalDiagnosis}</pre> : 'No Differential Diagnosis' }  
                                </>
                              ) :( <></> )}
                          <hr></hr>
                          <h2>Plan of Care </h2>  
                          {!showMainPoint ? (
                                  <>     
                                  {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : PlanOnCareData ? <pre className="innerDivBoxPC">{PlanOnCareData}</pre> : 'No Plan On Care' }  
                                </>
                              ) :(  <></>)}

              {reportData && <>
                <hr></hr>  <h2>Reports</h2>
              </> 
              }
           
             
             
  <ol className="reportListOptLink">
  {sortedGroupedDocuments.length > 0 ? (
    sortedGroupedDocuments
      .filter(({ documents }) => documents && documents.length > 0) // Filter out empty or null documents
      .map(({ doc_type, documents, Name  }) => (
        <li key={doc_type}>
       
          {documents.map((doc, index) => (
            <div key={doc.doc_id} style={{ marginBottom: "0px" }}>
                 <b style={{ textTransform: "capitalize", textDecoration: "none" }} className={ doc_type === "other_report"  && index !== 0 && 'd-none'}>
                 {Name} 
                 {/* {doc_type.replace('_', ' ')} */}
                 </b>
        

              {/* Document Text */}
              <p>{doc.doc_text}</p>

              <div className={"d-flex justify-content-between align-items-center "  +(doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null) }>
                <h3
                  style={{
                    fontSize: "16px",
                    marginTop: "14px",
                    fontWeight: "600",
                  }}
                  className="my-0"
                >
               {
                    doc_type === "other_report" ? (
                      <>
                        {index + 1}. Other Report Summary
                      </>
                    ) : doc_type === "pcp" || doc_type === "pharmacy" ? (
                      <>Name</>
                    ) : doc.doc_summary ? (
                      <>Report Summary</>
                    ) : null
                  }
                </h3>
 
              </div>

              {/* Document Summary */}
              {
                  doc.doc_summary ? (
                    <pre
                      style={{ margin: "5px 0", textTransform: "capitalize" }}
                      className={doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null}
                    >
                      {doc.doc_summary}
                    </pre>
                  ) : null
                }

              {/* Conditional Divider */}
              {doc.doc_url && (
                <hr className={doc_type !== "other_report" ? " d-none" : ""} />
              )}
            </div>
          ))}
        </li>
      ))
  ) : (
    <p style={{ textAlign: "center" }}>No reports found</p>
  )}
</ol>
         
         
         
            {/* Render PDF or Image based on file type */} 
            </div>
            <div className="p-3 d-none11">
            <h2>Select Standard Precaution</h2>
              <ul className="standardPerecautionList">
                {/* {fileArray.map((file) => (
                  <li key={file.id}>
                    <input
                      type="checkbox"
                      id={`file-${file.id}`}
                      onChange={() => handleCheckboxChange(file.id)}
                    />
                    <label htmlFor={`file-${file.id}`}>{file.fileName}</label>
                  </li>
                ))} */}
                  {fileArray.map((file) => (
                    <li key={file.id}>
                      <input
                        type="checkbox"
                        id={`file-${file.id}`}
                        onChange={() => handleCheckboxChange(file)}
                        checked={!!selectedFiles.find((f) => f.id === file.id)}
                      />
                      <label htmlFor={`file-${file.id}`}>{file.fileName}</label>
                    </li>
                  ))}

              </ul>
              </div>
              <button onClick={() => generateAndMergePDF(fullName)}  className="downloadBtn " >
                                <img src={Icons[0].downloadBtn} /> 
                              </button>
                              <button
        onClick={handleShare}
        className="shareBtn d-none"
      >
        Share
      </button>
            </div>
 
	</div>
                    </div> 
                </div>
                
              </div>
                 
             </div>
        </div> 
      </div>
      </div>

  

     

    </div>
  );
}

export default ViewFullReport;