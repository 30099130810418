import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom"; 
import { Icons } from "../../assest/icons";

function SideBarMenu() {
  const location = useLocation();
  const usernameTxt =  JSON.parse(localStorage.getItem('UserProfileAdmin'));
  
  const [UserName, setUserName] = useState(usernameTxt.fullName); 
  let navigate = useNavigate(); 
  const handleSettings = () => {
    navigate("/userprofile"); 
  };  
  const handleforgotpwd = () => { 
    navigate("/changepassword");
  }
  const handledashboardScreen = () => { 
    navigate("/admin/dashboard");
  }
  const handleAdminScreen = () => { 
    navigate("/admin/adminusers");
  }
  const handleViewDocuments = () => { 
    navigate("/admin/viewdocuments");
  }
  
  const handleLogout = () => { 
    navigate("/admin");
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
  return ( 
        <div className="containerDiv whiteBg sideBarMenu" >
          <div className="logDiv d-inline-block">
            <Link to="/">
              <img src={Icons[0].logoIcon} className="logoImg companyLogo" alt="" />
            </Link>
          </div>
        
          <ul className=" w-100 d-inline-block sidenavList mt-2"> 
                <li className={`homeIconDiv ${location.pathname === '/admin/dashboard' ? 'active' : ''}`}  onClick={() => handledashboardScreen()}>
                   
                        <img src={Icons[0].admin_paitentIcon} className="logoImg companyLogo  filterInvert" alt="" />
                        Patients
                   
                </li>
                <li className={`homeIconDiv ${location.pathname === '/admin/adminusers' || location.pathname === '/admin/adduseradmin'  || location.pathname === '/admin/adminusersinfo' ? 'active' : ''}`}  onClick={() => handleAdminScreen()}>
                    
                     <img src={Icons[0].admin_adminIcon} className="logoImg companyLogo filterInvertBlack" alt="" />
                        Admin Users
               
                </li> 
                <hr></hr>
                <li   onClick={() => handleViewDocuments()}>
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg companyLogo filterInvertBlack" alt="" />
                     {/* Procedures List */}
                     Standard Precaution
                </li>
            </ul>

            <ul className="w-100  d-inline-block sidenavList bottomPosition"> 
                <li className='homeIconDiv'  onClick={() => handleLogout()} >
                      <img src={Icons[0].admin_logoutIcon} className="logoImg companyLogo" alt="" />
                      Log out
                </li> 
            </ul>

        </div>  
  )
}

export default SideBarMenu
