 
 
import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { useParams } from 'react-router-dom';
import { Link, useLocation, useNavigate, useHistory } from "react-router-dom"; 
import { Icons } from "../assest/icons";
import Header from './header';
import LoaderSpinner from './loaderSpinner';
import SideBarMenu from './admin/sideBarMenu';
import { getBaseURL } from './baseUrl';
import axios from 'axios';
import * as RecordRTC from 'recordrtc';  
import moment from 'moment';  
import { ToastContainer, toast } from "react-toastify";   
import { SearchContext } from './admin/SearchContext'; 
import Modal from 'react-modal';
import HeightFormatter, { formatHeight } from './HeightFormatter';

function DashboardView() {  
  let navigateNew = useNavigate();
  const routeChange = (sessionid) => {
    navigateNew(`/patienthistory/${sessionid}-${currentPage}`); // Include the user ID in the path
  };
 
  const [TotalUser, setTotalUser] = useState('');
  const location = useLocation();  
  const initialPage = location.state?.backCurrentPage || 1; 
  console.log(initialPage)

  const searchTextPage = location.state?.searchTxt || '';  
  const [ShowLoader, setShowLoader] = useState(false);
  const [patientDataList, setPatientDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  // const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [currentPageGroup, setCurrentPageGroup] = useState(1); 
  const [perPage, setPerPage] = useState(10);  
  const userProfileData =  JSON.parse(localStorage.getItem('UserProfile')); 
  const [UserId, setUserId] = useState(userProfileData.user_id);

  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const [searchText, setSearchText] = useState( location.state?.searchTxt || ''); 
  const [CheckString, setCheckString] = useState(searchText.length >0 ? true : false);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);  
    setCheckString(e.target.value.length > 0 ? true : false)  
  };

  const handleFilterIconClick = () => {
    setSearchText(searchText); 
    fetchData(1, recordsPerPage, searchText);
  };
  const handleResetSearch = () => {
    setSearchText(''); 
    setCurrentPage(1);
    setCheckString(false);
    fetchData(currentPage, recordsPerPage, searchText);
  };

  useEffect(() => {  
    fetchData(currentPage, recordsPerPage, searchText);
  }, [currentPage, recordsPerPage, searchText]);

  const fetchData = async (page, perPage, querytxt) => {
    setShowLoader(true)
    try {
      const response = await axios.get(getBaseURL+'/get_user_sessions/'+UserId,  {
        params: {
          page: page,
          per_page: perPage, 
          query:querytxt,
        },
      }); 
      setPatientDataList(response.data.history);
      // setTotalPages(response.data.history.length);
      setTotalRecords(response.data.history.length);
      setShowLoader(false)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {  
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };
 
  const handlePrevGroup = () => {
    if (currentPageGroup > 1) {
      setCurrentPageGroup(currentPageGroup - 1);
      setCurrentPage((currentPageGroup - 2) * 3 + 1);
    }
  };

  const handleNextGroup = () => { 
    const maxGroup = Math.ceil(totalPages / 3);
    if (currentPageGroup < maxGroup) {
      setCurrentPageGroup(currentPageGroup + 1);
      setCurrentPage(currentPageGroup * 3 + 1); 
    }
  };
 
   // Function to get the first and last characters
   const getFirstAndLastChars = (str) => {
      const words = str.split(' ');
      if (words.length === 0) return '';
    
      const firstChar = words[0].charAt(0).toUpperCase();
      const lastChar = words[words.length - 1].charAt(0).toUpperCase();
    
      return `${firstChar}${lastChar}`;
  }; 
  
  const renderTableBody = () => {
    if (!patientDataList || patientDataList.length === 0) {
      return (
        <tr>
          <td colSpan="4" style={{ textAlign: 'center' }}>No data found</td>
        </tr>
      );
    }
 

    // Calculate the range of records for the current page
  const start = (currentPage - 1) * recordsPerPage;
  const end = start + recordsPerPage;

  // Slice the data for the current page
  const pageData = patientDataList.slice(start, end);
   
    return pageData.map((patient, index) => (
 
      <tr key={index}  onClick={() => routeChange(patient.session_id )} style={{cursor:'pointer'}} className={patient.session === 'true' ? 'active' : ''}>
        <td><span className="d-flex align-items-center"><img src={Icons[0].admin_listIcon} style={{marginRight:'10px'}}/>{patient.illness}</span></td>
   
    
        <td style={{textAlign:'center'}}> 
          {patient.weight || patient.height ? (
            <>  
            {patient.height ?`${formatHeight(patient.height)}  / ` : null}
            {patient.weight ?`${patient.weight} lbs` : null}
            </>
            ) : (
              "-"
            )}
        </td>
        <td style={{textAlign:'center'}}> {moment(patient.created_on).format('MMM DD, YYYY')}</td>
        <td style={{textAlign:'center', textTransform:'capitalize'}}> {patient.patientstatus ? patient.patientstatus : '-'}</td>
        <td style={{textAlign:'right'}}> <button className="viewBtnClass">View</button></td> 
    </tr>
    ));
  };
  const renderPagination = () => {
    if (!patientDataList || patientDataList.length === 0) {
      return null; // Return null to hide the pagination when there is no data
    }
  
    let pages = [];
    const startPage = (Math.floor((currentPage - 1) / 3) * 3) + 1; // Start page for the current block of pagination
    const endPage = Math.min(startPage + 2, totalPages); // End page for the current block of pagination
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`page-button ${i === currentPage ? 'active' : ''}`} // Add 'active' class if i === currentPage
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
  
    return (
      <div className="pagination">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        {pages}
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    );
  };
  
 
useEffect(() => { 
setShowLoader(true) 
}, []);



  return (
    <div>
     {ShowLoader && <LoaderSpinner />} 
      <div className='container-fluid p-0 adminDashboardMain'>
        <SideBarMenu/>
      
        <div className='container-fluid p-0 contentBody'>
             <Header />
             <div className="innerBodyBox bgInnerDiv patientDataListMain">

              <div className="contentInnerBody">
                <div className="titleDiv">
                  <h2>All Patients</h2>
                 

                 <div className=""> 
                      <ul className="titleRightSideDiv"> 
                        <li>   
                            <div className="table-options">
                              <label htmlFor="recordsPerPage">Showing</label>
                              <select id="recordsPerPage"
                               value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select> 
                            </div>
                          </li> 
                      </ul>
                  </div>

                </div>
                <div className="contentMainBox"> 
                        <div className="table-container">
                          <table id="patientTable">
                            <thead>
                            <tr>
                                        <th>Illnesses</th>
                                        {/* <th  style={{textAlign:'center'}}>Last Measurement</th> */}
                                        <th  style={{textAlign:'center'}}>Height/Weight</th>
                                        <th  style={{textAlign:'center'}}>Consultation Date</th>
                                        <th  style={{textAlign:'center'}}>Status</th>
                                        <th  style={{textAlign:'right', paddingRight: '50px'}}>Action</th> 
                                      </tr>
                            </thead>
                            <tbody>
                              {renderTableBody()}
                            </tbody>
                          </table>
                        </div>
                        <div className="paginationMain">
                             {renderPagination()}
                             <span className={"totalPageDiv "+ (!totalPages ? 'd-none' : '')}>Total Pages: {totalPages}</span>
                        </div>
                </div>
              </div>
                 
             </div>
        </div> 
      </div>
    </div>
  );
}

export default DashboardView;