import  React, { useState, useRef, useEffect  } from "react"; 
import { Link, useNavigate } from "react-router-dom";
import { Icons } from "../assest/icons";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; 
import { getBaseURL } from './baseUrl';
import { useGoogleLogin } from "@react-oauth/google";
import Modal from 'react-modal';
import { ToastContainer, toast } from "react-toastify"; 
import LeftSideLogin from "./leftsidelogin";
import ConsentNotice from "./consenttext";
 
 
 
function SingupScreen() {  
  let navigate = useNavigate();  
  const [showCalendar, setShowCalendar] = useState(false);
  

  const [UserData, setUserData] = useState([]);

  const [ShowLoader, setShowLoader] = useState(false);

  const [GShowLoader, setGShowLoader] = useState(false);
  const [UserId, setUserId] = useState('');
  const [firstStep, setFirstStep] = useState(true);
  const [twoStep, setTwoStep] = useState(false);
  const [threeStep, setThreeStep] = useState(false);
  const [finalStep, setFinalStep] = useState(false);

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [selectedToggle, setSelectedToggle] = useState(true);
  const [DOBTxt, setDOBTxt] = useState('');
  const [GenderTxt, setGenderTxt] = useState('');
  const [InsuranceIdTxt, setInsuranceIdTxt] = useState('');
  const [ClearBtn, setClearBtn] = useState(false);

  const [AgeTxt, setAgeTxt] = useState('');
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false); 
  
  const [focused, setFocused] = React.useState(false);
  const [passwordType, setPasswordType] = useState("password");
  
  const [isCheckedAgree, setIsCheckedAgree] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isGmail, setIsGmail] = useState(false);

  const [timer, setTimer] = useState(120); // Initial timer value
  const [resendAvailable, setResendAvailable] = useState(false); // Resend button state
  const intervalRef = useRef(null); // Store interval reference to clear it later
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state


// Open modal and set selected document
const openModal = (doc) => {  
  setIsModalOpen(true);
};

// Close modal and reset selected document
const closeModal = () => {
  setIsModalOpen(false); 
};

  const handleChange = () => {
    setIsChecked(!isChecked);
    setSelectedToggle(!selectedToggle); 
  };


  const handleClickPassword = (event) => { 
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
    setErrors('');
};
const handleEmailChange = (event) => {
   // Regular expression for email validation
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const { value } = event.target;
  setEmail(value);
    // Validate email
    if (!emailRegex.test(value)) {
      setErrors('Invalid email address');
    } else {
      setErrors('');
    } 

};
  function handleOnFocus() {
    setFocused(true);
  }

  function handleOnBlur() {
    setFocused(false);
  }
  const [errorMessage, setErrorMessage] = useState('');

  const nav = useNavigate();
  const routeChange = () =>{     
    localStorage.setItem('UserLoginStatus',  JSON.stringify(true) );
   
    nav("/dashboard", { state:UserData}); 
  }
  const routeFillUserInfo = () =>{     
    localStorage.setItem('UserLoginStatus',  JSON.stringify(true) );  
    nav("/filluserinfo", { state:UserData}); 
  }

  const handleResetFormStart= () => {
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setPassword(''); 
    setErrors('');
  };
  const handleResetForm1 = () => { 
    setPassword(''); 
    setErrors('');
  };
  const handleResetForm2 = () => { 
    setDOBTxt('');
    setGenderTxt('');
    setInsuranceIdTxt('');
    setErrors('');
  }; 
  const handleOptionChangeToogle = () => {
    setSelectedToggle(!selectedToggle); 
  };
  const handleClickFirst = () => {
    setFirstStep(false);
    setTwoStep(false);
    setThreeStep(true);
    setFinalStep(false);
  };  
  const handleClickTwo = () => {
    setFirstStep(false);
    setTwoStep(true);
    setThreeStep(false);
    setFinalStep(false); 
    startTimer();
    setResendAvailable(false); // Disable resend button immediately
  };  
 
  const handleClickThree = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
  const handleClickFinal = () => {
    setFirstStep(false);
    setTwoStep(false);
    setFinalStep(true);
    setThreeStep(false);
  };
  const handlePhoneNumberChange = (event) => {
    // Remove non-numeric characters from the input value
    const value = event.target.value.replace(/\D/g, '');
    setPhoneNumber(value);
};

 // Timer function to start the countdown
 const startTimer = () => {
  setTimer(120); // Reset the timer to 30 seconds
  setResendAvailable(false); // Disable the resend button
  clearInterval(intervalRef.current); // Clear any existing interval

  intervalRef.current = setInterval(() => {
    setTimer((prev) => {
      if (prev <= 1) {
        clearInterval(intervalRef.current); // Stop the timer at 0
        setResendAvailable(true); // Enable resend button
        return 0; // Ensure timer does not go below 0
      }
      return prev - 1; // Decrement the timer
    });
  }, 1000);
};

  // Clear the timer on component unmount to prevent memory leaks
  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

const handleFullNameChange = (event) => {
  // Remove non-text characters from the input value
  const value = event.target.value.replace(/[^a-zA-Z\s]/g, '');
  //const value = event.target.value ;
  setFullName(value);
  setErrors('');
};

  // const handleSubmit = (event) => {

  //       event.preventDefault();
    
  //       // Simple form validation
  //       let formErrors = {};
  //       if (!fullName.trim()) {
  //           formErrors.fullName = 'Please enter your full name';
  //       }
  //       if (!email.trim()) {
  //           formErrors.email = 'Please enter your email';
  //       }
  //       if (!isValidEmail(email)) {
  //         formErrors.email = 'Please enter a valid email address'; 
  //       }
  //       if (!phoneNumber.trim()) {
  //           formErrors.phoneNumber = 'Please enter your phone number';
  //       }
  //       if (!password.trim()) {
  //           formErrors.password = 'Please enter your password';
  //       }
  //       if (!isValidNewPassword(password)) {
  //         formErrors.password = 'Password must be at least 8 characters long'; 
  //       }
  //       if (Object.keys(formErrors).length > 0) {
  //           setErrors(formErrors);
  //           return;
  //       }
  //       setShowLoader(true)
  //       // If validation passes, send data to API
  //       const data = { 
  //         fullName: fullName,
  //         email: email,
  //         phoneNumber: phoneNumber,
  //         password: password,
  //         dob: "",
  //         gender: "",
  //         insurance_ID: "",
  //         age: "",
  //         height: "",
  //         weight: ""
  //     };
    
  //     // Here, you would replace 'apiEndpoint' with your actual API endpoint
  //     // fetch(getBaseURL+'/register', {
  //       fetch(getBaseURL+'/api/two_factor/send_otp', {
        
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(data),
  //     })
     
  //     .then(response => response.json())
  //     .then(data => {
  //       if( data.status == 'success'){
  //         toast.success("User profile registered successfully!", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }); 
           
  //         localStorage.setItem('UserProfile', JSON.stringify(data));
  //         setUserData(data)
  //         setUserId(data.user_id)
  //          setShowLoader(false)
  //         handleResetForm1()
  //         routeFillUserInfo()
  //       }
  //       if( data.status == 'error'){
  //             toast.error(data.message, {
  //              position: toast.POSITION.TOP_RIGHT,
  //             });
  //             setShowLoader(false)
  //       }
  //         // Optionally, you can redirect the user or show a success message
  //     }) 
  //     .catch((err) => { 
  //       if (err.response && err.response.status === 400) { 
  //         setErrorMessage('Bad Request: Please check your input data.');
  //       } else {
  //         // Handle other types of errors
  //         setErrorMessage('An error occurred. Please try again later.');
  //       }
  //         console.log('Error:', err);
  //         // Optionally, you can display an error message to the user
  //     });
  //   };


  const handleSubmit = async (event) => {
    // event.preventDefault(); // Prevent form reload 
    if (event) event.preventDefault(); // Prevent form reload if event exists

    // Simple form validation
    let formErrors = {};
    if (!fullName.trim()) {
      formErrors.fullName = 'Please enter your full name';
    }
    if (!email.trim()) {
      formErrors.email = 'Please enter your email';
    }
    if (!isValidEmail(email)) {
      formErrors.email = 'Please enter a valid email address';
    }
    if (!phoneNumber.trim()) {
      formErrors.phoneNumber = 'Please enter your phone number';
    }
    // if (!password.trim()) {
    //   formErrors.password = 'Please enter your password';
    // }
      // Conditionally validate the password if isGmail is true
      // if (isGmail) {
      //   if (!password.trim()) {
      //     formErrors.password = 'Please enter your password';
      //   } else if (!isValidNewPassword(password)) {
      //     formErrors.password = 'Password must be at least 8 characters long';
      //   }
      // }

       // Only validate password if isGmail is false
   
    // Validate password only if isGmail is false
    if (!isGmail) {
      if (!password.trim()) {
        formErrors.password = 'Please enter your password';
      } else if (!isValidNewPassword(password)) {
        formErrors.password = 'Password must be at least 8 characters long';
      }
    }
 
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return; // Stop if there are validation errors
    } 
    setShowLoader(true); // Show loader during API request
    if (!isCheckedAgree) { 
      openModal(); // Open modal if the checkbox is not checked
      return; // Stop execution if not agreed
    }
    // const formData = new FormData(); 
   
    // formData.append('fullName', fullName);
    // formData.append('email', email);
    // formData.append('phoneNumber', phoneNumber);
    // if (!isGmail) {
    //   formData.append('password', password); 
    // }
    // Data to send to the API
    // const data = {
    //   fullName: fullName,
    //   email: email,
    //   phoneNumber: phoneNumber,
    //   password: password,
    //   // dob: "",       // Optional fields
    //   // gender: "",    // Optional fields
    //   // insurance_ID: "", 
    //   // age: "",
    //   // height: "",
    //   // weight: ""
    // };
    const data = {
      fullName: fullName,
      email: email,
      phoneNumber: phoneNumber,
      login_from: isGmail ? "google" : "direct",
      ...(isGmail ? {} : { password: password }), // Conditionally add password
    };
    try {
      // API Call to send OTP
      // const response = await fetch(getBaseURL+'/api/two_factor/send_otp', {
      const response = await fetch(getBaseURL+'/api/register', {
        
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data), 
      });
  
      const result = await response.json();
  
      if (response.status === 200) {
        toast.success("User profile update successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
        // toast.success("OTP sent successfully!", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });

         // Save user profile in localStorage and state
         localStorage.setItem('UserProfile', JSON.stringify(result));
         setUserData(result);
         setUserId(result.user_id);
         routeFillUserInfo(); 
        // // Save user profile in localStorage and state
        // localStorage.setItem('UserProfile', JSON.stringify(result));
        // setUserData(result);
        // setUserId(result.user_id);
  
        setShowLoader(false); // Hide loader
        handleResetForm1(); // Reset form
    
        handleClickTwo();
      //  routeFillUserInfo(); // Redirect to the next step
  
      } else if (response.status === 400 || response.status === 409 || response.status === 500) {
        // Handle 400 or 500 errors with appropriate messages
        toast.error(result.message || 'An error occurred. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setShowLoader(false);
      }
    } catch (error) { 
      // Handle network or unknown errors
      setErrorMessage('An unexpected error occurred. Please try again later.');
      setShowLoader(false); // Hide loader in case of error
    }
  };

    const isValidEmail = (email) => {
      // Regular expression to validate email format
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    };
    const isValidNewPassword = (password) => {
      return password.length >= 8;
    };
   
    const isValidDateFormat = (dateString) => {
      const regex = /^\d{4}-\d{2}-\d{2}$/;
      return regex.test(dateString);
  };
  const onChangeCalendar = (date) => {
     const dateNew = new Date(date);     
    setDOBTxt(new Date(date));
    setShowCalendar(false);
    setErrors('');
};
  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
};
    const handleClearDate = () => {
      setClearBtn(false);
      // Clear the date by setting the dob state to an empty string
      setDOBTxt('');
  };
  const handleGenderChange = (e) => {
    setGenderTxt(e.target.value);
  };
  
//   const handleGenderChange = (event) => {
//     let { value } = event.target; 
//         // Allow delete action
//         if (value === '') {
//           setGenderTxt(''); 
//       } 
//       // Convert input to uppercase for comparison
//       value = value.toUpperCase();
//     // Allow only 'M' or 'F' characters
//     if ( value === 'M' || value === 'F') { 
//         setGenderTxt(value === 'M' ? 'Male' :  'Female');
//     }
// }; 
const handleSubmitPart2 = (event) => { 

      event.preventDefault(); 
      // Simple form validation
      let formErrors = {}; 
      if (!DOBTxt.length > 0) {
        formErrors.DOBTxt = 'Please select a date';
      }  
      if (!GenderTxt.trim()) {
          formErrors.GenderTxt = 'Please enter your gender';
      }
      if (!InsuranceIdTxt.trim()) {
          formErrors.InsuranceIdTxt = 'Please enter your Insurance ID';
      }   
      if (Object.keys(formErrors).length > 0) {
          setErrors(formErrors);
          return;
      }
      setShowLoader(true)
      // If validation passes, send data to API
      const dataReq = { 
        fullName: fullName,
        email: email,
        phoneNumber: phoneNumber, 
        dob: DOBTxt,
        gender:GenderTxt,
        insurance: InsuranceIdTxt,    
        user_id: UserId,
    }; 
  
    // Here, you would replace 'apiEndpoint' with your actual API endpoint
    fetch(getBaseURL+'/update_user', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataReq),
    })
     
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){
        toast.success("User profile update successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        }); 
        localStorage.setItem('UserProfile', JSON.stringify(data));
        setUserData(data)
       setShowLoader(false)
        }
      if( data.status == 'error'){ 
          toast.error(data.message, {
             position: toast.POSITION.TOP_RIGHT,
            });
            setShowLoader(false)
      }
        // Optionally, you can redirect the user or show a success message
    })
    .catch((error) => {
        console.error('Error:', error);
        // Optionally, you can display an error message to the user
    });
  };
  const formatDate = (date) => {
     return date ? date.toString().slice(0, 10) : '';
};
const disablePastDates = ({ activeStartDate, date, view }) => {
  // Disable dates before the current date
  // return date >= new Date();
  return date.getMonth() > new Date().getMonth() || date.getFullYear() > new Date().getFullYear() || date >= new Date();
};

const InsuranceIdHandleKeyDown = (e) => {
 
};

const InsuranceIdHandleChange = (e) => {
    // Get the new value
    const value = e.target.value;
    let filteredValue = '';

  // Iterate over each character in the input value
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    // Allow alphanumeric characters and hyphens
    if ((char >= 'A' && char <= 'Z') || (char >= 'a' && char <= 'z') || (char >= '0' && char <= '9') || char === '-') {
      filteredValue += char;
    }
  }
    // Update the state with the filtered value
    setInsuranceIdTxt(filteredValue); 
};


const [showAccessToken, setAccessToken] = useState('');  
const [UserLoginStatus, setUserLoginStatus] = useState('');  

const googleLogin = useGoogleLogin({
  onSuccess: async (tokenResponse) => { 
    setAccessToken(tokenResponse.access_token);
    checkGoogleLogin(tokenResponse.access_token)
  },
  onError: (errorResponse) => console.log(errorResponse),
});
const checkGoogleLogin = async (event) => {   
  setGShowLoader(true); 
  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  // fetch(getBaseURL+'/send_access_token', {
     // Prepare the data to be sent in the request body
  const data = {
    access_token: event, // The Google access token
  };

  // Send POST request to the API endpoint
  fetch(getBaseURL + '/api/google/user_info/from_access_token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data), // Convert data to JSON string
  })
    .then((response) => {
      // Check if the response status is not 200 (error scenario)
      if (!response.ok) {
        return response.json().then((errData) => {
          throw new Error(errData.message || 'Something went wrong!');
        }); 
      }
      return response.json(); // Parse the JSON response
    })
    .then((data) => {
      // Handle successful response
      if (data.status === 'success') {
        toast.success('Gmail verification successful. Please verify your phone number.', {
          position: toast.POSITION.TOP_RIGHT,
        }); 
        setFullName(data.fullName)
        setEmail(data.email)
        setIsGmail(data.isGmail)
        setGShowLoader(false)
         // Save user profile data in localStorage
        localStorage.setItem('UserProfile', JSON.stringify(data));

        // Redirect based on whether the user is new or existing
        // if (data.isExist === 'false') {
        //   nav('/filluserinfo'); // Redirect to user info page if new user
        // } else {
        //   nav('/dashboard'); // Redirect to dashboard if user already exists
        // }
      } else if (data.status === 'error') {
        // Handle case where status is "error" in the response body
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setGShowLoader(false)
      }
    })
    .catch((error) => {
      // Catch any errors (e.g., network errors or API errors)
      console.error('Error:', error);
      toast.error(`Error: ${error.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setGShowLoader(false)
    });
}; 

  const [otp, setOtp] = useState(new Array(4).fill('')); // OTP state 
  const inputRefs = useRef([]); // Ref for managing input focus

// Handle input change for OTP fields
const handleOTPChange = (element, index) => {
  if (isNaN(element.value)) return; // Allow only numbers

  const newOtp = [...otp];
  newOtp[index] = element.value;
  setOtp(newOtp);

  // Move to the next input field automatically
  if (element.nextSibling) {
    element.nextSibling.focus();
  }
};

 // Handle form submission
 const handleSubmitOTP = async (e) => {
  e.preventDefault(); // Prevent page refresh
  setShowLoader(true); // Show loader during the API call

  const enteredOtp = otp.join(''); // Join OTP fields into a single string
 // const phoneNumber = "+919601500840"; // Use your phone number here

  try { 
    const response = await fetch(getBaseURL+'/api/two_factor/verify_otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: phoneNumber,
        otp: enteredOtp,
      }),
    });
    const result = await response.json();
    if (response.status === 201) { 
      toast.success('User registered successfully!', {
        position: toast.POSITION.TOP_RIGHT,
       }); 
        // Save user profile in localStorage and state
        localStorage.setItem('UserProfile', JSON.stringify(result));
        setUserData(result);
        setUserId(result.user_id);
       routeFillUserInfo(); 
    } else if (response.status === 400 || response.status === 404) {
      
      toast.error('OTP Invalid or expired. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
       }); 
    } else { 
      toast.error('Something went wrong. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
       }); 
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Network error. Please try again later.');
  } finally {
    setShowLoader(false); // Hide loader after API call completes
  }
};
// Handle backspace functionality
const handleBackspace = (e, index) => {
  if (e.key === 'Backspace' && !otp[index] && index > 0) {
    inputRefs.current[index - 1].focus(); // Move focus to the previous input
  }
};

  // Handle OTP Paste functionality
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text').slice(0, otp.length); // Extract required digits
    const newOtp = [...otp];

    for (let i = 0; i < pasteData.length; i++) {
      if (!isNaN(pasteData[i])) {
        newOtp[i] = pasteData[i];
      }
    }
    setOtp(newOtp);

    // Focus on the next empty field (or last field if all are filled)
    const lastFilledIndex = Math.min(pasteData.length - 1, otp.length - 1);
    inputRefs.current[lastFilledIndex].focus();
  };


// Handle Resend OTP functionality
const handleResend = async (e) => {
  e.preventDefault(); // Prevent form submission or page refresh
  setOtp(new Array(4).fill('')); // Clear OTP input fields
 

  // const phoneNumber = "+917837002393"; // Use your phone number here

  try {
    const response = await fetch(getBaseURL+'/api/two_factor/resend_otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber: '+',phoneNumber,
      }),
    }); 
    if (response.status === 200) { 
      toast.success('New OTP sent successfully!', {
        position: toast.POSITION.TOP_RIGHT,
       }); 
      //  setTimer(30); // Reset the timer
       startTimer();
       setResendAvailable(false); // Disable resend button immediately
    } else if (response.status === 404) { 
      toast.error('Phone number not found. Please check and try again.', {
        position: toast.POSITION.TOP_RIGHT,
       }); 
    } else { 
      toast.error('Something went wrong. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
       }); 
    }
  } catch (error) {
    console.error('Error:', error);
    alert('Network error. Please try again later.');
  }
};
// Check if all OTP fields are filled
const isOtpComplete = otp.every((digit) => digit !== '');
// // Timer logic for enabling the resend button
// useEffect(() => {
//   if (timer > 0) {
//     const countdown = setInterval(() => {
//       setTimer((prev) => prev - 1);
//     }, 1000);
//     return () => clearInterval(countdown);
//   } else {
//     setResendAvailable(true);
//   }
// }, [timer]);

// Use useEffect to monitor state changes
useEffect(() => {
  // console.log(isCheckedAgree);
}, [isCheckedAgree]);

const handleAgree = async () => { 
  setIsCheckedAgree(true); 
  closeModal(); // Close modal after agreeing
  // handleSubmit();
  await handleSubmit();
};

const handleDecline = () => {
  closeModal(); // Just close the modal on decline
};


  return (
    <div>
      <div className='container-fluid p-0 bgImageUrl'>
       
        <div className='container p-0 contentBody loginScreen'>

        <LeftSideLogin/>
          <div className="col-lg-5 d-inline-block position-relative">
            <div className="loginBoxDiv">
            <div className={"singUpFirstStep "+(firstStep ? "":"d-none")}>
                <h3 className="titleDespTxt">Sign up to AIGI Care</h3>
                <span className={"otherSignInOpt " + (isGmail ? 'd-none' : '')}>
               
                <ul className="mb-1">
                  <li className="w-100" onClick={() => googleLogin()}>
                    <img src={Icons[0].gmailImg} className="" alt="" />
                        {GShowLoader ? (<>
                          Sign up with Google <div className="spinner-border " role="status">  </div> 
                        
                        </>):(<>
                          Sign up with Google
                        </>)} 
                  
                  </li> 
                </ul>
                <p class="optionSignin "><span>or</span></p>
              </span>

                <form onSubmit={handleSubmit} autoComplete="off">
               
                  <div className='inputGroupDiv'> 
                      <div className={"inputTxtBox registerInputDiv " + (isGmail ? 'disabled' : '')}>
                        <img src={Icons[0].userImg} className="logoImg" alt="" />
                          <input type="text" value={fullName} placeholder="Full Name" maxLength="100" onChange={handleFullNameChange} readOnly={isGmail} />  
                      
                      </div>
                      {errors.fullName && <span  className="errorTxt" >{errors.fullName}</span>}
                </div>
                  <div className='inputGroupDiv'> 
                      <div className={"inputTxtBox registerInputDiv " + (isGmail ? 'disabled' : '')}>
                        <img src={Icons[0].mailImg} className="logoImg" alt="" />
                          <input type="text" value={email} placeholder="Email id" autoComplete="off" onChange={handleEmailChange} readOnly={isGmail}/>  
                      
                      </div>
                      {errors.email && <span  className="errorTxt" >{errors.email}</span>}
                </div>
                  <div className="mb-4">
                    <PhoneInput 
                      autocomplete="off" 
                       defaultCountry="US"
                       country={"us"}
                      id="phone"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber} 
                      international={false}
                      error={!isValid && phoneNumber.trim().length > 0}
                      onBlur={() => setIsValid(!!phoneNumber.trim())}
                      inputProps={{
                        name: 'phone',
                        required: true,
                        autoFocus: true, 
                        autoComplete: 'off',
                      }}  
                    />
                    {errors.phoneNumber && <span  className="errorTxt" >{errors.phoneNumber}</span>}
                  </div>                  
                  
                  <div className={'inputGroupDiv ' + (isGmail ? 'd-none' : '')}> 
                      <div className="inputTxtBox registerInputDiv">
                            <img src={Icons[0].lockedImg} className="logoImg" alt="" />
                              <input type={passwordType}  value={password} placeholder="Password" autocomplete="new-password" onChange={handlePasswordChange}/>   
                              <label className='eyePwdIcon' onClick={handleClickPassword}>  
                                <img src={passwordType === "password" ? ( Icons[0].closeEye  ) : ( Icons[0].opneEye  )} className="logoImg" alt="" />
                              </label>                
                          </div>
                          {errors.password && <span  className="errorTxt" >{errors.password}</span>}
                    </div>
                  
                  <div className="optDivBox mt-4">
                  <div className="switch-container d-flex">
                        <label>
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleChange}
                            className="switch"
                          />
                          <div className="switch-slider">
                            <div className="switch-knob"></div>
                          </div>
                        </label>

                        <span style={{ marginLeft: "5px" }} > 
                          {/* I agree to the  */}
                          By signing up, you agree to our
                          <b style={{cursor:"pointer", color: '#0d6efd' }} onClick={() => openModal()}> Terms of Use & Privacy Policy.</b>
                          {/* By signing up, you agree to our 
                          <b className="d-none"
                          style={{ cursor: selectedToggle ? "pointer" : "default", color: "#0d6efd" }}
                          onClick={selectedToggle ? () => openModal() : null}
                          // style={{cursor:"pointer", color: '#0d6efd' }} onClick={() => openModal()}
                            > {" "}
                            Terms and Privacy Policy</b>  */}
                          {/* Terms and Privacy Policy */}
                          </span>
                      </div>

                    {/* <span className="switchToogle "><input type="checkbox" id="switch"   onChange={handleOptionChangeToogle} /><label for="switch" >Toggle</label>
                     I agree to the Terms of Use & Privacy Policy.</span>  */}
                    
                  </div>
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn " type="submit" disabled={selectedToggle}>Continue</button>
                    </>)}
                 
                 <div className="signupDiv">
                  Already have an account <Link to="/">Sign in</Link>
                  </div>
                </form>
             </div>

             <div className={"singUpThreeStep "+(twoStep ? "":"d-none")}>
                    {/* <h3 className="titleDespTxt">OTP Verification</h3>  
                    <p>Enter the OTP sent to +1 234 267 9900</p>
                    
                    <p>Didn't you receive the OTP? <button>Resend OTP</button></p>
                    {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn " type="submit" disabled={selectedToggle}>OK</button>
                    </>)} */}
                     <div  className="VerificationOTPMain">
                     
                     <img src={Icons[0].OTPIcon} className="" alt="" />
                        <h3 className="titleDespTxt">OTP Verification</h3>
                        <p>Enter the OTP sent to +{phoneNumber}</p>
                          <form onSubmit={handleSubmitOTP}>
                              <div onPaste={handlePaste}>
                                {otp.map((data, index) => (
                                  <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={data}
                                    className="inputOTP"
                                    ref={(el) => (inputRefs.current[index] = el)}
                                    onChange={(e) => handleOTPChange(e.target, index)}
                                    onKeyDown={(e) => handleBackspace(e, index)}
                                    onFocus={(e) => e.target.select()} 
                                    style={{ 
                                      borderBottom: `2px solid ${data ? '#2743FD' : 'gray'}`, // Dynamic border color 
                                      transition: 'border-color 0.2s', // Smooth transition
                                    }}
                                  />
                                ))}
                              </div>
                          <div  className=" ResendOTPBtn" 
                                style={{ 
                                      color: `${resendAvailable ? '#333' : '#B9B9B9'}`,  
                                      marginTop: '10px' 
                                    }}>
                           Didn't you receive the OTP?  <button onClick={handleResend} disabled={!resendAvailable}>
                            Resend OTP
                          </button> 
                          <p className="countdownTxt">{!resendAvailable && `${timer} seconds`}</p>
                        </div> 

                          {ShowLoader ? (<>
                            <button  className="signInBtn" style={{ marginTop: '20px' }}> Loading
                            <div className="spinner-border text-light  " role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div> 
                          </button>
                          </>):(<>
                            <button className="signInBtn style={{ marginTop: '20px' }}" type="submit" disabled={!isOtpComplete}>OK</button>
                          </>)} 
                    
                        </form>
                      
                      </div>
              </div>
           

              <div className={"singUpThreeStep "+(threeStep ? "":"d-none")}>
              <h3 className="titleDespTxt">Please fill details to continue</h3>
                <form  onSubmit={handleSubmitPart2} autoComplete="off">
                  <div className={"inputTxtBox registerInputDiv "+ (fullName.length > 0 ? 'disabled' : '')}>
                    <img src={Icons[0].userImg} className="logoImg" alt="" />
                    <input type="text" value={fullName} placeholder="Full Name"  readOnly />
                  </div>
                  <div className={"inputTxtBox registerInputDiv "+ (email.length > 0 ? 'disabled' : '')}>
                    <img src={Icons[0].mailImg} className="logoImg" alt="" />
                    <input type="text" value={email} placeholder="Email id" readOnly  /> 
                  </div>
                  <div className={"registerInputDiv "+ (email.length > 0 ? 'disabled' : '')}>
                    <PhoneInput
                      defaultCountry="US"
                      country={"us"}
                     id="phone"
                     placeholder="Enter phone number"
                     value={phoneNumber}
                     onChange={setPhoneNumber} 
                     international={false}
                     error={!isValid && phoneNumber.trim().length > 0}
                     onBlur={() => setIsValid(!!phoneNumber.trim())}
                     inputProps={{
                       name: 'phone',
                       required: true,
                       autoFocus: true
                     }} 
                    />
                  </div> 
                      <div className="inputTxtBox  registerInputDiv">
                        <img src={Icons[0].dateIcon} className="logoImg" alt="" />
                        <input type="text"  maxLength="11"  value={formatDate(DOBTxt)} placeholder="Date of Birth (YYYY-MM-DD)"  
                         
                            /> 
                          <button className="toggleCalendarBtn"  onClick={toggleCalendar}> <img src={Icons[0].calendarIcon} className="logoImg" alt="" /></button>
                          {showCalendar && (
                              <Calendar 
                                className= 'inputCaledar' 
                                onChange={onChangeCalendar}
                                value={DOBTxt}
                                maxDate={new Date()} 
                                tileDisabled={disablePastDates} 
                              />
                          )}
     </div>
                  {errors.DOBTxt && <span  className="errorTxt" >{errors.DOBTxt}</span>} 
                  
                      <div className="inputTxtBox  registerInputDiv d-flex">
                        <img src={Icons[0].GenderImg} className="logoImg" alt="" />
                        <div className="gender-selector">
                            <label>
                              <input
                                type="radio"
                                value="Male"
                                checked={GenderTxt === 'Male'}
                                onChange={handleGenderChange}
                              />
                              Male
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="Female"
                                checked={GenderTxt === 'Female'}
                                onChange={handleGenderChange}
                              />
                              Female
                            </label>
                            <label>
                              <input
                                type="radio"
                                value="Other"
                                checked={GenderTxt === 'Other'}
                                onChange={handleGenderChange}
                              />
                              Other
                            </label>
                          </div>
                           {/* <input type="text" value={GenderTxt} placeholder="Press M or F for gender" onChange={handleGenderChange} maxLength="1"   
                        onKeyUp={(e) => { if (e.key === 'Backspace') {     setGenderTxt('');  } }}/>  */}
                  </div>
                  {errors.GenderTxt && <span  className="errorTxt" >{errors.GenderTxt}</span>} 
                  <div className="inputTxtBox registerInputDiv ">
                    <img src={Icons[0].InsuranceIDIcon} className="logoImg" alt="" />
                
                   <input type="text"
                    value={InsuranceIdTxt}  
                    // onChange={(e) => setInsuranceIdTxt(e.target.value)}  
                    placeholder="Insurance ID"
                    maxLength="40"
                    onKeyDown={InsuranceIdHandleKeyDown}
                    onChange={InsuranceIdHandleChange}
                     />
                  </div>
                  {errors.InsuranceIdTxt && <span  className="errorTxt" >{errors.InsuranceIdTxt}</span>} 
                   
                  {ShowLoader ? (<>
                      <button  className="signInBtn" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn registerBtn mb-0"   >Finish</button>
                    </>)}
              </form>
              </div>
              <div className={"singUpFinalStep  "+(finalStep ? "":"d-none")}>
                    <span className="emailVerificationDiv">
                    <img src={Icons[0].checkedIcon} className="logoImg" alt="" />
                    <h3>Success!</h3>
                    <p>Congratulations! Your account has beed created successfully. Continue to the dashboard.</p>
                    <button className="signInBtn mb-0"   onClick={routeChange} >Continue</button>
               
                    </span>
              </div>
            </div>

           
          </div>
          
        </div>
      
      </div>

        {/* Modal Popup for Terms of Use & Privacy Policy */}
        <Modal
       className='consentCheckModal modalViewAllMain'
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Document Viewer"
       
      >
       
        <div className="innerModalDiv overflow-hidden"> 
        <ConsentNotice  onAgree={handleAgree} onDecline={handleDecline} />
        {/* Ensure selectedDocument exists before rendering */}
        </div>
      </Modal>

    </div>
  );
}

export default SingupScreen;