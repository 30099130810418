import  React, { useState, useRef, useEffect  } from "react";   
import { Link, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; 
import moment from 'moment'; 
import { getBaseURL } from '../baseUrl';

const PageOne = ({ onButtonClick}) => {

  const location = useLocation(); 
  const [data, setdata] = useState(location.state);
  const [ShowLoader, setShowLoader] = useState(false);

  const userProfileData =  JSON.parse(localStorage.getItem('UserProfile'))

  const [UserId, setUserId] = useState(userProfileData.user_id);
  const [GenderTxt, setGenderTxt] = useState(userProfileData.gender);
  var years = moment().diff(userProfileData.dob);
  const [AgeTxt, setAgeTxt] = useState(years);  
  const [WeightTxt, setWeightTxt] = useState('');
  const [HeightTxt, setHeightTxt] = useState('');
  
  const [CheckWeightTxt, setCheckWeightTxt] = useState('');
  const [CheckHeightTxt, setCheckHeightTxt] = useState('');

  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const [unit, setUnit] = useState('cm'); // Initial unit is 'cm' 
  const [weightUnit, setWeightUnit] = useState('lbs');

   // Function to convert kg to lbs
   const convertKgToLbs = (kg) => (parseFloat(kg) * 2.20462).toFixed(0);

   // Function to convert feet to cm
   const convertFeetToCm = (feet) => (parseFloat(feet) * 30.48).toFixed(0);
 
   const bpMMRef = useRef(null);
   const bpHgRef = useRef(null); 

  const [bpMMTxt, setbpMMTxt] = useState('');
  const [bpHgTxt, setbpHgTxt] = useState('');
  const [heartrateTxt, setheartrateTxt] = useState('');


   const [errorMessage, setErrorMessage] = useState('');
   
   
   const [CheckBpMMTxt, setCheckBpMMTxt] = useState(bpMMTxt);
   const [CheckBpHgTxt, setCheckBpHgTxt] = useState(bpHgTxt);
   const [CheckHeartRateTxt, setCheckHeartRateTxt] = useState(heartrateTxt);

   const [feet, setFeet] = useState('');
   const [inches, setInches] = useState(''); 
   const inchRef = useRef(null);

   function convertToFeetAndInches(heightDecimal) { 

      // Ensure the value is a valid number
      if (isNaN(heightDecimal)) { 
        return { feet: 0, inches: 0 }; // Return default values in case of invalid input
      }

      // Split the value into feet and inches
      const feet = Math.floor(heightDecimal); // Extract the whole number for feet
      const inches = Math.round((heightDecimal - feet) * 10); // Extract the decimal part, convert to inches and round it

      return { feet, inches };
  }

   


   const handleMyInsurance = async (UserIdNew) => {  
    fetch(getBaseURL+'/get_insurance_by_userid/'+UserIdNew, {
      method: 'GET', 
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(data => {
      if( data.status == 'success'){ 
        localStorage.setItem('myinsuranceinfo', JSON.stringify(data.data)); 
      } 
    })
    .catch((error) => {
      // console.log('Error:', error);
    });
    
  };

  useEffect(() => {
    handleMyInsurance(UserId)
    const ageCal = moment().diff(userProfileData.dob, 'years'); 
    setAgeTxt(userProfileData.dob ? ageCal : '');    
    setHeightTxt(userProfileData.height ? userProfileData.height : '');  
    setWeightTxt(userProfileData.weight ? userProfileData.weight : '');  
    setCheckWeightTxt(userProfileData.weight ? userProfileData.weight : '');  
    setCheckHeightTxt(userProfileData.height ? userProfileData.height : '');  
      // Blood Pressure and Heart Rate Initialization
    setbpMMTxt(userProfileData.bp_mm ? userProfileData.bp_mm : '');  
    setbpHgTxt(userProfileData.bp_hg ? userProfileData.bp_hg : '');   
    setheartrateTxt(userProfileData.heartrate ? userProfileData.heartrate : '');    
    localStorage.setItem('UserId', JSON.stringify(UserId));

     // Ensure height is a valid number or fallback to default
  const heightDecimal = parseFloat(userProfileData.height);
  if (!isNaN(heightDecimal)) {
    const { feet, inches } = convertToFeetAndInches(heightDecimal);
    setFeet(feet);
    setInches(inches);
  } else { 
    setFeet(0);
    setInches(0);
  }
   
  }, [UserId]);

 

  const handleSubmit = (event) => {

    event.preventDefault(); 
    // Simple form validation
    let formErrors = {};
    setErrorMessage('');
    
    // if (!feet.trim()) {
    //     formErrors.feet = 'Please enter your height';
    // } 
    if (!String(feet).trim()) {
      formErrors.feet = 'Please enter your height';
    }
    // if (!bpHgTxt.trim() || !bpMMTxt.trim()){
    //   formErrors.bpHgTxt = 'Please enter your blood pressure (Mm - Hg)';
    // }  
    // if (!heartrateTxt.trim()) {
    //   formErrors.heartrateTxt = 'Please enter your heart rate';
    // } 
    if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        return;
    }
    setShowLoader(true)
    // // Check if weight and height have changed
    // const hasChanged = WeightTxt !== CheckWeightTxt || HeightTxt !== CheckHeightTxt;

    // if (!hasChanged) {
    //   // Redirect to another page if no changes
    //   setShowLoader(false)
    //   onButtonClick("pagetwo")
    //   return;
    // }
    const hasChanged = 
    WeightTxt !== CheckWeightTxt ||
    feet !== CheckHeightTxt;
    // HeightTxt !== CheckHeightTxt ||
    // bpMMTxt !== CheckBpMMTxt ||  // Add blood pressure Mm check
    // bpHgTxt !== CheckBpHgTxt ||  // Add blood pressure Hg check
    // heartrateTxt !== CheckHeartRateTxt;  // Add heart rate check
  
  if (!hasChanged) {
    // Redirect to another page if no changes
    setShowLoader(false);
    onButtonClick("pagetwo");
    return;
  }

  let heightFeet =  `${feet}.${inches || 0}`
    // If validation passes, send data to API
    const data = { 
      age: AgeTxt,
      height: `${feet}.${inches || 0}`,
      // height: HeightTxt,
      weight: WeightTxt,
      gender:GenderTxt,    
      user_id: UserId,
      bp_mm: '',
      bp_hg: '',
      heartrate:'',
      patientstatus:'new'
  }; 
  // Here, you would replace 'apiEndpoint' with your actual API endpoint
  fetch(getBaseURL+'/update_user_info', {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
  })
   
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){
      toast.success("Data updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      }); 
      localStorage.setItem('UserProfile', JSON.stringify(data));
      setShowLoader(false)
      onButtonClick("pagetwo")
    }
 
      // Optionally, you can redirect the user or show a success message
  })
  .catch((error) => {
      //console.error('Error:', error);
      // Optionally, you can display an error message to the user
  });
};
const handleFeetChange = (e) => {
  const value = e.target.value;

  // Allow only numbers between 1 and 9 for feet
  if (/^\d{0,1}$/.test(value) && (value === "" || (value >= 1 && value <= 9))) {
    setFeet(value);
    setErrors({ ...errors, feet: "" });

    // Auto-focus to inches if feet is entered
    if (value && value.length === 1) {
      inchRef.current.focus();
    }
  } else {
    setErrors({ ...errors, feet: "Feet must be between 1 and 9." });
  }
};

const handleInchChange = (e) => {
  const value = e.target.value;

  // Allow only numbers between 0 and 11 for inches
  if (/^\d{0,2}$/.test(value) && (value === "" || (value >= 0 && value <= 11))) {
    setInches(value);
    setErrors({ ...errors, inches: "" });
  } else {
    setErrors({ ...errors, inches: "Inches must be between 0 and 11." });
  }
};

const handleValidation = () => {
  let newErrors = {};
  if (!feet) newErrors.feet = 'Feet is required.';
  if (!inches) newErrors.inches = 'Inches is required.';
  setErrors(newErrors);

  return Object.keys(newErrors).length === 0;
};


 // Handle unit change (cm or feet)
 const handleUnitChange = (event) => {
  const newUnit = event.target.value;
  setUnit(newUnit);

  // Convert height if a value exists
  if (HeightTxt) {
    setHeightTxt(convertHeight(HeightTxt, newUnit));
  }
};

  // Convert the value based on the selected unit
  const convertHeight = (value, toUnit) => {
    if (toUnit === 'feet') {
      return (parseFloat(value) * 0.0328084).toFixed(2); // Convert cm to feet
    } else if (toUnit === 'cm') {
      return (parseFloat(value) * 30.48).toFixed(0); // Convert feet to cm
    }
    return value;
  };
  const MAX_HEIGHT = 9; // Maximum allowable height in feet

  const handleHeightChange = (event) => {
    let value = event.target.value;

  // Allow only digits and a single decimal point
  const decimalValue = value.replace(/[^0-9.]/g, ''); // Remove non-numeric characters except decimal

  // Split into integer and decimal parts
  const parts = decimalValue.split('.');

  // If more than one decimal point, join only the first decimal part
  if (parts.length > 2) {
    value = parts[0] + '.' + parts.slice(1).join('');
  }

  // If there are more than two digits after the decimal point, slice to keep only two digits
  if (parts[1] && parts[1].length > 2) {
    value = parts[0] + '.' + parts[1].slice(0, 2);
  }

    // Convert the value to a number for validation
    const numericValue = parseFloat(value);

    // Check if value exceeds the maximum height
    if (value !== '' && numericValue > MAX_HEIGHT) {
      setErrors({ HeightTxt: 'Height cannot exceed 9 feet.' });
    } else {
      setErrors({});
    }
  setHeightTxt(value);
  };

 
 // Handle unit change (lbs or kg)
 const handleWeightUnitChange = (event) => {
  const newUnit = event.target.value;
  setWeightUnit(newUnit);
  setWeightTxt(WeightTxt);
};

// Handle weight input change
const handleWeightChange = (event) => {
  const value = event.target.value;

  // Allow only numeric values and up to 2 decimal places
  const numericValue = value.match(/^\d*\.?\d{0,2}$/);
  if (numericValue) {
    setWeightTxt(numericValue[0]);
  }
};
 
const handleInput = (e, nextRef, prevRef) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Convert value to an integer for validation
  const intValue = parseInt(value, 10);

  // Clear the error message when user types
  if (fieldName === "Mm" || fieldName === "Hg") {
    setErrors((prevErrors) => ({ ...prevErrors, bpHgTxt: '' }));
  }

  // Forward jump when the max length is reached
  if (value.length === e.target.maxLength) {
    // Blood Pressure Mm Validation (0-200)
    if (fieldName === "Mm") {
      if (intValue < 0 || intValue > 200 || isNaN(intValue)) {
        setErrorMessage("Mm value must be between 0 and 200");
        bpMMRef.current.focus();
        return;
      } else {
        setbpMMTxt(value);  // Set the bpMMTxt state
      }
    }

    // Blood Pressure Hg Validation (0-200)
    if (fieldName === "Hg") {
      if (intValue < 0 || intValue > 200 || isNaN(intValue)) {
        setErrorMessage("Hg value must be between 0 and 200");
        bpHgRef.current.focus();
        return;
      } else {
        setbpHgTxt(value);  // Set the bpHgTxt state
      }
    }

    // Clear error message if validation passes
    setErrorMessage("");
    if (nextRef) {
      nextRef.current.focus();
    }
  } 
  // Backward jump when "Backspace" is pressed and the input is empty
  else if (e.nativeEvent.inputType === "deleteContentBackward" && value.length === 0) {
    if (prevRef) {
      prevRef.current.focus();
    }
  }
};
  
const handleKeyDown = (e, nextRef, prevRef) => {
  const value = e.target.value;
  const fieldName = e.target.placeholder;

  // Right Arrow Key
  if (e.key === "ArrowRight") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }
    
    if (nextRef) {
      nextRef.current.focus();
    }
  }

  // Left Arrow Key
  if (e.key === "ArrowLeft") {
    if (value === "") {
      setErrorMessage(`${fieldName} cannot be empty`);
      e.target.focus();
      return;
    } else {
      setErrorMessage(""); // Clear error if field is not empty
    }

    if (prevRef) {
      prevRef.current.focus();
    }
  }
};
 


  return (
    <main
      className="pt5 black-80 center stepFirstForm"
      style={{ maxWidth: "50%",   margin: "20px auto" }}
    >
       <form onSubmit={handleSubmit} className="measure w-100">
        <h2>Health Stats</h2> 
        <fieldset id="sign_up" className="ba b--transparent ph0 mh0">
     
          <div className="mt3 inputDiv disabledTxtField">
            <label className='labelTxt'>Age</label>
            <input type="text" value={AgeTxt}  onChange={(e) => setAgeTxt(e.target.value)} readOnly   />  
            <label>Years</label>  
         </div> 
          {/* <div className="mt3 inputDiv">
            <label className='labelTxt'>Height</label>
            <input 
              type="text" 
              onChange={handleHeightChange} 
              placeholder="Enter value in feet"
              value={HeightTxt}
            /> 
             <label>Feet</label>  
                  </div>
                  {errors.HeightTxt && <span  className="errorTxt" >{errors.HeightTxt}</span>}
                   */}
       <div className="mt3 inputDiv justify-content-start">
      <label className="labelTxt">Height</label>
      <div style={{ display: "flex", gap: "10px" }}>
        <div>
          <input
            type="text"
            onChange={handleFeetChange}
            placeholder="Feet"
            value={feet}
            maxLength={1}
            style={{ width: '40px',
              borderBottom: '1px solid #999',
              textAlign: 'center',
              marginRight: '5px'}}
          />
          <label>Feet</label>
        </div>
        <div>
          <input
            type="text"
            ref={inchRef}
            onChange={handleInchChange}
            placeholder="Inches"
            value={inches}
            maxLength={2}
            style={{ width: "50px", borderBottom: '1px solid #999',
              textAlign: 'center',
              marginRight: '5px'}}
          />
          <label>Inches</label>
        </div>
      </div>
      
    </div>
    {errors.feet && <span className="errorTxt">{errors.feet}</span>}
    {errors.inches && <span className="errorTxt">{errors.inches}</span>}

          <div className="mt3 inputDiv">
            <label className='labelTxt'>Weight</label>
            <input type="text"   
              value={WeightTxt} 
              placeholder="Enter weight in lbs"
              onChange={(e) => {
                const value = e.target.value;
                // Remove any non-digit characters
                const numericValue = value.replace(/\D/g, '');
                // Limit to 4 digits
                if (numericValue.length <= 3) {
                  setWeightTxt(numericValue);
                }
              }} 
             />   <label>lbs</label>   
                  </div>
                  {errors.WeightTxt && <span  className="errorTxt" >{errors.WeightTxt}</span>}
                
                  <div className='d-block  mt-4 d-none' style={{borderTop:"2px solid #aeaeae"}}>
                    <h3 className='mt-4 mb-0 ' style={{fontSize:"18px"}}>
                      Physical Exam
                    </h3>
                    <div className='d-flex justify-content-between gap-3'>
                        <div className='w-50'>
                                <div className="mt3 inputDiv">
                                  <label className='labelTxt' style={{width:"150px"}}>Blood pressure</label>
                                  {/* <input type="text"   
                                    value={WeightTxt} 
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      // Remove any non-digit characters
                                      const numericValue = value.replace(/\D/g, '');
                                      // Limit to 4 digits
                                      if (numericValue.length <= 3) {
                                        setWeightTxt(numericValue);
                                      }
                                    }} 
                                    style={{width:"70%"}}
                                  />   */}
                            <div className="timepicker">
                              <input
                                type="text"
                                className="hh"
                                maxLength="3"
                                placeholder="Mm"
                                ref={bpMMRef}
                                // onInput={(e) => handleInput(e, bpHgRef, null)}
                                // onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                                value={bpMMTxt}  // Ensure the input value reflects the state
                                onInput={(e) => {
                                  setbpMMTxt(e.target.value);  // Update state when input changes
                                  handleInput(e, bpHgRef, null);
                                }}
                                onKeyDown={(e) => handleKeyDown(e, bpHgRef, null)}
                              />
                              -
                              <input
                                type="text"
                                className="mm"
                                maxLength="3"
                                placeholder="Hg"
                                 ref={bpHgRef}
                                // onInput={(e) => handleInput(e, null, bpMMRef)}
                                // onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                                value={bpHgTxt}  // Ensure the input value reflects the state
                                onInput={(e) => {
                                  setbpHgTxt(e.target.value);  // Update state when input changes
                                  handleInput(e, null, bpMMRef);
                                }}
                                onKeyDown={(e) => handleKeyDown(e, null, bpMMRef)}
                              />
                            </div>  
                                  <label  style={{width:"80px"}}>Mm - Hg</label>   
                              </div>
                              {errorMessage && <span className="errorTxt">{errorMessage}</span>}
                              {errors.bpHgTxt  && <span className="errorTxt" >{errors.bpHgTxt }</span>}
                        </div>

                        <div className='w-50'>
                                 <div className="mt3 inputDiv">
                                    <label className='labelTxt' style={{width:"100px"}}>Heart rate</label>
                                    <input type="text"   
                                      value={heartrateTxt} 
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // Remove any non-digit characters
                                        const numericValue = value.replace(/\D/g, '');
                                        // Limit to 4 digits
                                        if (numericValue.length <= 3) {
                                          setheartrateTxt(numericValue);
                                        }
                                      }} 
                                    />  
                                    <label>bpm</label>   
                                </div>
                                {errors.heartrateTxt && <span  className="errorTxt" >{errors.heartrateTxt}</span>}
                        </div>

                      </div>
                  </div>
                

    
                

                  
        </fieldset>
        <div>
        {ShowLoader ? (<>
                      <button  className="signInBtn mb-0" > Loading
                      <div className="spinner-border text-light  " role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button className="signInBtn mb-0" type="submit" >Next</button>
                    </>)}

         
        </div>
      </form>
    </main>
  );
};

export default PageOne;
