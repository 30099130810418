import React, { useState } from "react";
import axios from "axios";

const NPIRegistry = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [placeOptions, setPlaceOptions] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null); 

  const handleSearchChange = async (e) => {
    const query = e.target.value.trim();
    setSearchQuery(query);
  
    if (query.length > 2) { // Only search when the query is sufficiently long
      try {
        const response = await axios.post(
          `https://places.googleapis.com/v1/places:searchText`,
          {
            textQuery: `${query}`, // Enhance query with "pharmacy"
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Goog-Api-Key': "AIzaSyBlhG-E470kB32nqMKUjVmT8LNcMKCTDdU",
              'X-Goog-FieldMask': 'places.displayName,places.formattedAddress,places.id,places.name', // Specify the fields you want
            },
          }
        );
 
        console.log(response.data);
        if (response.data && response.data.places) {
          // Filter results to include only places in the US
          const usPlaces = response.data.places.filter((place) =>
            place.formattedAddress && place.formattedAddress.includes("")
          );
  
          setPlaceOptions(usPlaces); // Set filtered results
          console.log(usPlaces); // Log the filtered results
        } else {
          setPlaceOptions([]);
        }
      } catch (error) {
        console.error("Error fetching places:", error);
        setPlaceOptions([]);
      }
    } else {
      setPlaceOptions([]); // Clear options if the query is too short
    }
  };
  
  const handlePlaceSelect = (place) => {
    setSelectedPlace(place);
    setSearchQuery(place.displayName.text);
    setPlaceOptions([]);
  };

  // Modify the rendering to match the new response structure
  return (
    <div>
      <h2>Search for a Place</h2>
      <input
        type="text"
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Type a place name..."
        style={{ width: "300px", padding: "8px" }}
      />
        {/* Place options list */}
        {placeOptions.length > 0 && (
        <ul style={{ listStyleType: "none", padding: 0, marginTop: "10px" }}>
          {placeOptions.map((place) => (
            <li
              key={place.id}
              style={{
                border: "1px solid #ddd",
                padding: "10px",
                cursor: "pointer",
                marginBottom: "5px",
                borderRadius: "4px",
              }}
              onClick={() => handlePlaceSelect(place)}
            >
              <strong>{place.displayName.text}</strong>
              <p>{place.formattedAddress}</p>
            </li>
          ))}
        </ul>
      )}
     {/* Selected place details */}
     {selectedPlace && (
        <div style={{ marginTop: "20px" }}>
          <h3>Selected Place Details</h3>
          <p>
            <strong>Name:</strong> {selectedPlace.displayName.text}
          </p>
          <p>
            <strong>Address:</strong> {selectedPlace.formattedAddress}
          </p>
        </div>
      )}
    </div>
  );
};

export default NPIRegistry;