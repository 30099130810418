import  React, { useState, useRef, useEffect, useContext  } from "react"; 
import { useParams } from 'react-router-dom';
import * as RecordRTC from 'recordrtc';
import { Link, useLocation, useNavigate } from "react-router-dom"; 
import { Icons } from "../assest/icons";
import Header from './header';
import moment from 'moment'; 
import { getBaseURL } from './baseUrl';
import { ToastContainer, toast } from "react-toastify"; 
import axios from 'axios';   
import LoaderSpinner from './loaderSpinner'; 
import { SearchContext } from './admin/SearchContext'; 
import Modal from 'react-modal';
import HeightFormatter, { formatHeight } from './HeightFormatter';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Set the app element for accessibility (react-modal requirement)
Modal.setAppElement('#root');
 
function PatientHistory() { 
  const { sessionIDNew } = useParams();

  let sessionid = '';
let currentPageNew = '';

useEffect(() => {
  if (sessionIDNew) {
    const parts = sessionIDNew.split('-');
    if (parts.length === 2) {
      sessionid = parts[0];
      currentPageNew = parts[1]; 

      fetchPatientHistory(sessionid); // Example usage
      setCurrentPage(currentPageNew);    // Example usage
    } else {
      console.error("Invalid sessionIDNew format:", sessionIDNew);
    }
  }
}, [sessionIDNew]);

  

 const userProfileData =  JSON.parse(localStorage.getItem('UserProfile'))
  const [myinsuranceinfo, setMyinsuranceinfo] = useState([]);  
  const [CheckExpiry, setCheckExpiry] = useState(false);  
  const [UserId, setUserId] = useState(userProfileData.user_id);
  const [GenderTxt, setGenderTxt] = useState(userProfileData.gender);
  var years = moment().diff(userProfileData.dob);
  const [AgeTxt, setAgeTxt] = useState(years);  
  const [fullName, setFullName] = useState(userProfileData.fullName);
  const [email, setEmail] = useState(userProfileData.email);
  const [phoneNumber, setPhoneNumber] = useState(userProfileData.phoneNumber); 
  const [DOBTxt, setDOBTxt] = useState(userProfileData.dob);   
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCD, setIsEditingCD] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');   
  var years = moment().diff(DOBTxt);
  const ageCal = moment().diff(DOBTxt, 'years');    

  // const [userProfileData, setUserProfileData] = useState(JSON.parse(localStorage.getItem('UserProfile')));

// --------------------------------------------------



let navigate = useNavigate();
const routeChange = () => { 
  navigate('/admin/dashboard', {
     state: {backCurrentPage: backCurrentPage, searchTxt: searchTxtCheck }
  
  }); 
}
const handleBackButton = () => { 
   navigate('/dashboard', { state: { currentPage: currentPage } });
  //  navigate('/dashboardview', { state: { backCurrentPage: currentPage } });
};
const newappointmentLink = () => { 
  if(CheckExpiry){
    navigate('/myinsurance'); 
  }else{
    navigate('/newappointment'); 
  }

}

const location = useLocation(); 
// const { id, currentPageNo, searchString } = location.state;

const [ShowLoaderPage, setShowLoaderPage] = useState(false);
const [userid, setuserid] = useState(UserId); 
const [adminId, setAdminId] = useState(JSON.parse(localStorage.getItem('UserIdAdmin'))); 

const [searchTxtCheck, setSearchTxtCheck] = useState(''); 
// const [searchTxtCheck, setSearchTxtCheck] = useState(searchString); 

const [backCurrentPage, setcurrentPageNo] = useState(''); 
// const [backCurrentPage, setcurrentPageNo] = useState(currentPageNo); 
const [UserHistoryData, setUserHistoryData] = useState([]);
const [patientHistorySession, setPatientHistorySession] = useState([]);
const [UserHistoryNumber, setUserHistoryNumber] = useState('');
const [ChatHistory, setChatHistory] = useState([]);
const [RecordData, setRecordData] = useState([]);  
const [BackReload, setBackReload] = useState(false);     
const [userProfile, setUserProfile] = useState('');  
const [specialty, setSpecialty] = useState('');
const [selectedToggle, setSelectedToggle] = useState(true); 
const [InsuranceIdTxt, setInsuranceIdTxt] = useState(''); 
const [originalProfile, setOriginalProfile] = useState({});   
var years = moment().diff(DOBTxt); 
const [BMITxt, setBMITxt] = useState('');   
 
const [WeightTxt, setWeightTxt] = useState('');
const [HeightTxt, setHeightTxt] = useState('');
const [ShowLoader, setShowLoader] = useState(false); 
 
const [showCalendar, setShowCalendar] = useState(false);  
const [isValid, setIsValid] = useState(false);
const [isSession, setIsSession] = useState(false);

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage, setRecordsPerPage] = useState(10);
const [showSummay, setShowSummary] = useState(false);
const totalPages = Math.ceil(UserHistoryData.length / recordsPerPage);
const totalPageGroups = Math.ceil(totalPages / 3);
const currentPageGroup = Math.ceil(currentPage / 3);

const [illnessesList, setIllnessesList] = useState([]);
const [selectedIllness, setSelectedIllness] = useState(null);
const [answerLoader, setAnswerLoader] = useState(false);
const [EmergencyQuest, setEmergencyQuest] = useState(false);

const [activeTab, setActiveTab] = useState(0);
const { searchQuery } = useContext(SearchContext);

const [MainPointsDiscussed, setMainPointsDiscussed] = useState('');
const [PlanOnCareData, setPlanOnCareData] = useState('');
const [ConditionalDiagnosis, setConditionalDiagnosis] = useState('');

const [SessionIDCheck, setSessionIDCheck] = useState('');
const [reportData, setReportData] = useState(null);
const [checkFollowupMeet, setCheckFollowupMeet] = useState(false); 
const [checkAllFollowupMeet, setCheckAllFollowupMeet] = useState(false); 
const [selectedItemFollowup, setSelectedItemFollowup] = useState([]); // Followup Date   
const [checkPhysicalExam, setCheckPhysicalExam] = useState(false); // Track if the div is open
const [bpMMTxt, setbpMMTxt] = useState('');
const [bpHgTxt, setbpHgTxt] = useState('');
const [heartrateTxt, setheartrateTxt] = useState('');
const [selectedItem, setSelectedItem] = useState(null); // Button name
const [openDiv, setOpenDiv] = useState(null);
const [followupZoomMeet, setFollowupZoomMeet] = useState(false); 
const [checkZoomMeet, setCheckZoomMeet] = useState(false); 
const [selectedZoomMeet, setSelectedZoomMeet] = useState([]); // Button name    
const [isOpenFollowup, setIsOpenFollowup] = useState(false); // Track if the div is open
const [checkFollowup, setCheckFollowup] = useState(false); // Track if the div is open
const [filledCheckFollowup, setfilledCheckFollowup] = useState(false); // Track if the div is open
const [followupDays, setFollowupDays] =  useState(new Date()); //useState('');
const [futureDate, setFutureDate] = useState(null);
const [remainingDays, setRemainingDays] = useState(null);
const now = new Date();
const [errorMessage, setErrorMessage] = useState('');

// console.log(followupZoomMeet +'  -  '+ selectedItemFollowup +'  -  '+ JSON.stringify(selectedZoomMeet))

const [errors, setErrors] = useState({}); 
const [activeSpeech, setActiveSpeech] = useState(null);
const [transcripts, setTranscripts] = useState({ medicalcondition: ''});
const { transcript, resetTranscript, listening, browserSupportsSpeechRecognition } = useSpeechRecognition();
const socket = useRef(null);
const recorder = useRef(null);
const [isRecording, setIsRecording] = useState({medicalcondition: false,});
const [activeMic, setActiveMic] = useState(null); 
const [isConnecting, setIsConnecting] = useState(false);
const [MedicalConditionsData, setMedicalConditionsData] = useState('');
const [MedicalConditionFile, setMedicalConditionFile] = useState(null);
const [fileNameMedicalCondition, setFileNameMedicalCondition] = useState('');
const [medicalConditionSuggestions, setMedicalConditionSuggestions] = useState([]); 
 
const [FollowupData, setFollowupData] = useState([]); // Followup Date 

const [followSummaryFollowup, setFollowSummaryFollowup] = useState('');
const [summaryAIChatbot, setSummaryAIChatbot] = useState('');
const [reportIDForAll, setReportIDForAll] = useState('');

const [activeOption, setActiveOption] = useState('Routine'); // State to track the active option
const [PCP_Pharmacy, setPCP_Pharmacy] = useState(null);
 
const handleOptionClick = (option) => {
  setActiveOption(option); // Update the active option
};



const checkOrderList = [
  { Name: "Allergies", doc_type: "allergy" },
  { Name: "Medications", doc_type: "medications" },
  { Name: "Medical Conditions", doc_type: "medical_conditions" },
  { Name: "Past Surgical History", doc_type: "past_surgical_history" },
  { Name: "Social History", doc_type: "social_history" },
  { Name: "Primary Care Physician (PCP)", doc_type: "pcp" },
  { Name: "Pharmacy information", doc_type: "pharmacy" },
  { Name: "Upload Reports", doc_type: "other_report" }
];

const options = [
  'Test Results',
  'Refills',
  'Recall Colon',
  'Recall ECD',
  'Routine',
  'Persistant Symptoms',
];

const toggleDivFollowup = () => {
  setCheckFollowup(false);    
  setOpenDiv(openDiv === 'Followup' ? null : 'Followup');
};

// Handle cancel button click
const closeFollowup = () => {
  setCheckFollowup(false); // Close the div without saving  
    setOpenDiv('') 
    setErrors({});       
    setErrorMessage(''); 
};
 // Handle cancel button click
 const handleCancelFollowup = () => {
  setIsOpenFollowup(false); // Close the div without saving 
    // Reset temporary input values 
    setFollowupDays(''); 
    setFutureDate('');
    setOpenDiv('')
    // Clear any errors
    setErrors({}); 
    setErrorMessage('');
    setLoading(false);
};
 
  // Handle form submission
  const handleSaveFollowup = async (event) => { 
    event.preventDefault();
    setLoading(true);
  
    let formErrors = {};
    // setErrorMessage('');
  
    // if (!followupDays.trim()) {
   if (!followupDays || (typeof followupDays === 'string' && !followupDays.trim())) {
    formErrors.followupDays = 'Please enter follow-up days';
    }
  
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setLoading(false);
      return;
    }
  
    setShowLoader(true);
  
    const data = {
      admin_id: adminId,
      session_id: SessionIDCheck,
      user_id: userid,
      follow_up_date: followupDays,
    };
  
    try {
      const response = await fetch(getBaseURL + '/api/admin/update/followupdate', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      const result = await response.json();
  
      if (response.status === 200) {
        toast.success(result.message || 'Data updated successfully!', {
          position: toast.POSITION.TOP_RIGHT,
        });
  
        setSelectedItemFollowup(futureDate);

  
        // Reset the form after successful submission
        setFollowupDays('');
        setFutureDate('');
        setOpenDiv(null);
        setErrors({});
        setLoading(false);
        setShowLoader(false);
      } else if (response.status === 404) {
        toast.error(result.message || 'User not found or metrics not updated.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        setShowLoader(false);
      } else {
        toast.error('An unexpected error occurred. Please try again.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        setShowLoader(false);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Network error. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setShowLoader(false);
    }
  };
 
useEffect(() => {
if (searchQuery) {
  navigate('/admin/dashboard');
}
}, [searchQuery, navigate]);

// Fetch illnesses
const illnessesArray = async () => {
const response = await axios.get(getBaseURL+'/illnesses');  
setIllnessesList(response.data.illnesses); 
};

const handleRecordsPerPageChange = (event) => {
setRecordsPerPage(Number(event.target.value));
setCurrentPage(1);
};

const handlePageChange = (page) => {
setCurrentPage(page);
};

const handleNextGroup = () => {
if (currentPage < totalPages) {
  setCurrentPage(currentPage + 1);
}
};

const handlePrevGroup = () => {
if (currentPage > 1) {
  setCurrentPage(currentPage - 1);
}
};

const handleFindRecord = (id) => {

};
// const handleShowSummary = async (id) => {  
// setShowLoaderPage(true)
// const foundRecord = UserHistoryData.find((item) => item.created_on === id); 
// if (foundRecord) { 
//   const filteredConversation = foundRecord.conversation.filter(convo => Object.keys(convo).length !== 0); 
//   setRecordData(foundRecord);
//   setSelectedIllness(foundRecord.illness) 
//   setChatHistory(filteredConversation)  
//   setPlanOnCareData(foundRecord.plan_of_care)
//   setConditionalDiagnosis(foundRecord.conditional_diagnosis);
//   setSessionIDCheck(foundRecord.session_id) 
//   // setSelectedItemFollowup(foundRecord.follow_up_date ? foundRecord.follow_up_date  : '')
//   setCheckPhysicalExam(foundRecord.bp_hg ? 'true'  : 'false')
//   setbpHgTxt(foundRecord.bp_hg ? foundRecord.bp_hg : '')
//   setbpMMTxt(foundRecord.bp_mm ? foundRecord.bp_mm  : '')
//   setheartrateTxt(foundRecord.heartrate ? foundRecord.heartrate : '')
//   setSelectedItem(foundRecord.patientstatus)
//   setSelectedZoomMeet(foundRecord.zoom_meeting || null) 
//   // Check if the answer to the specific question is "yes"
//   const emergencyQuestion = filteredConversation.find(convo => 
//     convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
//   );

  
//   if (emergencyQuestion) {
//     // alert("Emergency care is needed!");
//     setEmergencyQuest(true)
//   } 
//   setChatHistory([]);
//   setShowSummary(true)   
//   if(foundRecord.session){ 
//     adminCheckedSession(foundRecord.user_id, foundRecord.session_id)
//   }
  
// } else {
//   setRecordData(null); 
// }
// try {
//   ///api/doctor/followup/<session_id>/active_request
//   // const response = await axios.get(getBaseURL+'/api/doctor/followup/'+foundRecord.session_id+'/all_request'); 
//   // if (response.status === 200 || response.status === 201) {
//   //   console.log(response?.data?.data)
//   //   if (response?.data?.data) {
//   //     setFollowupData(response.data.data); 
//   //   }  
//   //   if (response?.data?.data?.zoom_meeting && Object.keys(response?.data?.data?.zoom_meeting).length > 0) {
//   //     console.log(response.data.data.zoom_meeting)
//   //         // Update selected follow-up item
//   //   setSelectedItemFollowup(response?.data?.data); 
//   //     // If zoom_meeting is not null and has keys, set it to true
//   //     setFollowupZoomMeet(true); 
//   //   } 
//   // } 
//   const response = await axios.get(getBaseURL + '/api/doctor/followup/'+SessionIDCheck+'/all_request');

 
//   // Check if the response status is successful
//   if (response.status === 200 || response.status === 201) {
 

//     // Ensure response data is an array
//     if (Array.isArray(response?.data?.data) && response.data.data.length > 0) {
//       const followupData = response.data.data;

//       // Set the follow-up data
//       setFollowupData(followupData);

//       // Iterate through the follow-up data array
//       followupData.forEach((item) => {
//         if (item.zoom_meeting && Object.keys(item.zoom_meeting).length > 0) {
//           console.log("Zoom Meeting Details:", item.zoom_meeting);

//           // Update selected follow-up item (last one found with zoom_meeting)
//           setSelectedItemFollowup(item);

//           // Set zoom meeting flag to true (once a valid zoom_meeting is found)
//           setFollowupZoomMeet(true);
//         }
//       });
//     }
//   }

//   } catch (error) {
   
//   } finally {
    
//   }
// try {
//   // const response = await axios.get(getBaseURL+'/api/doctor/followup/'+foundRecord.session_id); 
//   const response = await axios.get(getBaseURL+'/api/doctor/followup/'+SessionIDCheck+'/active_request'); 
//   if (response.status === 200 || response.status === 201) {
//     const { activeFlag, data } = response.data;
          
//     // Set the check for all follow-up meetings
//     setCheckAllFollowupMeet(activeFlag); // true if activeFlag is false, and vice versa
 
//     // Update selected follow-up item
//     setSelectedItemFollowup(data); 
//     // setSelectedItemFollowup(response.data.data)
//     if (response.data.data.zoom_meeting && Object.keys(response.data.data.zoom_meeting).length > 0) {
//       // If zoom_meeting is not null and has keys, set it to true
//       setFollowupZoomMeet(true);
//     } 
//     // setIllnessesList(response.data.illnesses);        
//     setCheckFollowupMeet(activeFlag)
   
//   }
  
//   } catch (error) {
   
//   } finally {
    
//   }

// try {
// const response = await axios.get(getBaseURL+'/illnesses'); 
// if (response.status === 200) {
//   setIllnessesList(response.data.illnesses);        
//   setShowLoaderPage(false)
// }

// } catch (error) {
 
// } finally {
  
// }
// try { 
//   // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
//   const url = `${getBaseURL}/api/medical/reports/fetch/${foundRecord.reports_id}`;
//   const response = await axios.get(url, {
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   });
    
//   if (response.data && response.data.status === 'success') {
//     setReportData(response.data.content);
//   }
//   } catch (error) {
//     //console.error('Error posting data:', error);
//  } finally {
    
//   }

//   try { 
//     const url = `${getBaseURL}/api/patient/fetch/details/pcp-pharmacy/${foundRecord.reports_id}`;
//     // const url = `${getBaseURL}/download/${foundRecord.reports_id}`;
//     const response = await axios.get(url, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
      
//     if (response.data && response.data.status === 'success') {  
//       setPCP_Pharmacy(response.data.data);
//     }
//     } catch (error) {
//       //console.error('Error posting data:', error);
//    } finally {
      
//     }

// };
// Group reportData by doc_type
const groupedDocuments = (reportData || []).reduce((acc, doc) => {
  if (!doc.doc_type) {
    return acc;
  }
  const docType = doc.doc_type.trim();

  if (!acc[docType]) {
    acc[docType] = [];
  }

  acc[docType].push({
    doc_id: doc.doc_id,
    doc_summary: doc.doc_summary,
    doc_text: doc.doc_text,
    doc_url: doc.doc_url,
  });


return acc;
}, {});


if (PCP_Pharmacy && PCP_Pharmacy.pcp && PCP_Pharmacy.pcp.name && PCP_Pharmacy.pcp.address) {
  groupedDocuments["pcp"] = [
    ...(groupedDocuments["pcp"] || []),
    {
      doc_summary: `${PCP_Pharmacy.pcp.name}, ${PCP_Pharmacy.pcp.address}`,  
      doc_text: null,
      doc_url: null,
    },
  ];
  }
  // Push Pharmacy into groupedDocumentspharmacy
  if (PCP_Pharmacy && PCP_Pharmacy.pharmacy && PCP_Pharmacy.pharmacy.name && PCP_Pharmacy.pharmacy.address) {
  groupedDocuments["pharmacy"] = [
    ...(groupedDocuments[""] || []),
    {
       doc_summary: `${PCP_Pharmacy.pharmacy.name}, ${PCP_Pharmacy.pharmacy.address}`, 
       doc_text: null,
       doc_url: null,
    },
  ];
  }  

// Map checkOrderList to match groupedDocuments and sort by order
const sortedGroupedDocuments = checkOrderList.map(orderItem => ({
Name: orderItem.Name,
doc_type: orderItem.doc_type,
documents: groupedDocuments[orderItem.doc_type] || []
}));

const adminCheckedSession = async (patientId, sessionId) => {
try {
  const response = await axios.post(`${getBaseURL}/admin_log`, {
    
      patient_id: patientId,
      admin_id: adminId,
      session_id: sessionId,  
  
  }); 


  if( response.data.status == 'success'){ 
    setBackReload(true)
  }

} catch (error) {
  console.error('Error fetching data:', error);
  setShowLoader(false);
}
}

const handleShowChat = (id) => {
const filteredConversation = RecordData.conversation.filter(convo => Object.keys(convo).length !== 0);

setActiveTab(id);
setAnswerLoader(true)
setTimeout(() =>  { 
  setChatHistory(filteredConversation); 
  setAnswerLoader(false) 
}, 1000);
}
const handleHideSummary = () => { 
setActiveTab(0);
setRecordData([])
setSelectedIllness() 

if(BackReload){
  window.location.reload(); 
}
setShowSummary(false)
setAnswerLoader(false) 
setEmergencyQuest(false)
};

const handleShowDashboard = () => {
setShowSummary(false)
// routeChange()
};
// const renderTableBody = () => {
// const start = (currentPage - 1) * recordsPerPage;
// const end = start + recordsPerPage;
// const pageData = UserHistoryData?.slice(start, end);

// return pageData.map((UserHistoryData, index) => (
//   // className={UserHistoryData.session === 'true' ? 'active' : ''}
//   <tr key={index}  onClick={() => handleShowSummary(UserHistoryData.created_on)} style={{cursor:'pointer'}} >
//     <td><span className="d-flex align-items-center"><img src={Icons[0].admin_listIcon} style={{marginRight:'10px'}}/>{UserHistoryData.illness}</span></td>
   
//     {/* <td style={{textAlign:'center'}}>
//     {UserHistoryData.bp_hg && UserHistoryData.bp_mm && UserHistoryData.heartrate ? (
//       <>
//       {UserHistoryData.bp_hg && UserHistoryData.bp_mm ? (`${UserHistoryData.bp_hg} / ${UserHistoryData.bp_mm} -`) : null  }
//       {UserHistoryData.heartrate ?`${UserHistoryData.heartrate} BPM` : null } 
//       </> 
//     ) :  "-"}    

//     </td> */}
//     <td style={{textAlign:'center'}}> 
//       {UserHistoryData.weight || UserHistoryData.height ? (
//         <> 
       
//         {UserHistoryData.height ?`${formatHeight(UserHistoryData.height)}  / ` : null}
//         {UserHistoryData.weight ?`${UserHistoryData.weight} lbs` : null}
//         </>
//         ) : (
//           "-"
//         )}
//   </td>
//     <td style={{textAlign:'center'}}> {moment(UserHistoryData.created_on).format('MMM DD, YYYY')}</td>
//      <td style={{textAlign:'center', textTransform:'capitalize'}}> {UserHistoryData.patientstatus ? UserHistoryData.patientstatus : '-'}</td>
//     <td style={{textAlign:'right'}}> <button className="viewBtnClass">View</button></td> 
//   </tr>
// ));

 
// };

const renderPagination = () => {
let pages = [];
const startPage = (currentPageGroup - 1) * 3 + 1;
const endPage = Math.min(startPage + 2, totalPages);

for (let i = startPage; i <= endPage; i++) {
  pages.push(
    <button
      key={i}
      className={`page-button ${i === currentPage ? 'active' : ''}`}
      onClick={() => handlePageChange(i)}
    >
      {i}
    </button>
  );
}

return (
  <div className="pagination">
    <button
      onClick={handlePrevGroup}
      disabled={currentPage === 1}
    >
      Previous
    </button>
    {pages}
    <button
      onClick={handleNextGroup}
      disabled={currentPage === totalPages}
    >
      Next
    </button>
  
  </div>
);
};


useEffect(() => {
const fetchRecords = async () => {

setShowLoaderPage(true) 
  try {
    // setShowLoaderPage(true)
    // Make a GET request to fetch records from the API
    const response = await axios.get(getBaseURL+'/get_user_sessions/'+userid, {
      headers: {
        'Content-Type': 'application/json, text/html', // Specify the Content-Type header
      },
    });
 // Ensure the response and history exist before proceeding
 if( response.data.status === 'success'){
// if (response.data && Array.isArray(response.data.history)) {
    const { history, user_details, session_status, bmi } = response.data;
     // const foundRecordChk = response.data.history.find((item) => item.session_id === sessionid); 
    const foundRecordChk = history.find((item) => item.session_id === sessionid);
    // if (!foundRecordChk) throw new Error("Session not found");
      // Check if foundRecordChk exists
      if (!foundRecordChk) {
        console.error("Session not found for sessionid:", sessionid);
        // Optionally set a state to display a user-friendly message
        setRecordData(null);
        setShowLoaderPage(false); // Stop loader as there's no further action
        return;
      }
    setPatientHistorySession(foundRecordChk) 
    setSessionIDCheck(foundRecordChk.session_id)  
      
   


    // Set user profile data
  // setUserProfileData(response.data.user_details);

  // Sort history data by created_on date in descending order
    const sortedHistory = [...response.data.history];  
    setUserHistoryData(sortedHistory);
    setUserHistoryNumber(history.length) ; 

    setBMITxt(bmi|| 0);
    setFullName(user_details.fullName);
    setEmail(user_details.email);
    setDOBTxt(user_details.dob);
    setPhoneNumber(user_details.phoneNumber ? '+'+user_details.phoneNumber : '');
    setUserProfile(user_details.userProfile);
    setInsuranceIdTxt(user_details.insurance || 0);
    setGenderTxt(user_details.gender || '');
    setHeightTxt(user_details.height || 0);
    setWeightTxt(user_details.weight || 0);        
    setIsSession(session_status || 'false');
   

    const foundRecord = foundRecordChk;
  //  UserHistoryData.find((item) => item.session_id === id);  

 

  setRecordData(foundRecord);
  // Process conversation data
  const filteredConversation = foundRecordChk.conversation
  ? foundRecordChk.conversation.filter((convo) => Object.keys(convo).length !== 0)
  : [];
  setChatHistory(filteredConversation);


  setSelectedIllness(foundRecord.illness) 
  // setChatHistory(filteredConversation)  
  setSummaryAIChatbot(foundRecord.chatbot_summary)
  setPlanOnCareData(foundRecord.plan_of_care)
  setConditionalDiagnosis(foundRecord.conditional_diagnosis);
  setSessionIDCheck(sessionid) 
  // setSelectedItemFollowup(foundRecord.follow_up_date || '')
  setCheckPhysicalExam(foundRecord.bp_hg ? 'true'  : 'false')
  setbpHgTxt(foundRecord.bp_hg || '')
  setbpMMTxt(foundRecord.bp_mm || '')
  setheartrateTxt(foundRecord.heartrate || '')
  setSelectedItem(foundRecord.patientstatus)
  setSelectedZoomMeet(foundRecord.zoom_meeting || null) 
  setReportIDForAll(foundRecord.reports_id || null)

 
 // Check for emergency care
      const emergencyQuestion = filteredConversation.find(
        (convo) =>
          convo.question === "Do you need emergency care? (YES/NO)" &&
          convo.answer.toLowerCase() === "yes"
      );
  
  if (emergencyQuestion) {
    // alert("Emergency care is needed!");
    setEmergencyQuest(true)
  } 
  setChatHistory([]);
  setShowSummary(true)   
  if(foundRecord.session){ 
    adminCheckedSession(foundRecord.user_id, sessionid)
  }
  // setShowLoaderPage(false)
  // Call dependent functions
     fetchPatientHistory(sessionid, foundRecord.reports_id);
} else {
  console.error("Invalid API response or missing history data:", response.data);
}

 
  } catch (error) {
    console.error('Error fetching records:', error);
    // Handle error, show an error message, etc.
  } 
};
fetchRecords();
}, [userid, sessionid]);

const fetchPatientHistory = async (newSessionid, newReportIDChk) => {  
  //  UserHistoryData.find((item) => item.session_id === id);  
 
try { 
  const response = await axios.get(getBaseURL + '/api/doctor/followup/'+newSessionid+'/all_request');

 
  // Check if the response status is successful
  if (response.status === 200 || response.status === 201) {
 

    // Ensure response data is an array
    if (Array.isArray(response?.data?.data) && response.data.data.length > 0) {
      const followupData = response.data.data;

      // Set the follow-up data
      setFollowupData(followupData);

      // Iterate through the follow-up data array
      followupData.forEach((item) => {
        if (item.zoom_meeting && Object.keys(item.zoom_meeting).length > 0) { 

          // Update selected follow-up item (last one found with zoom_meeting)
          setSelectedItemFollowup(item);

          // Set zoom meeting flag to true (once a valid zoom_meeting is found)
          setFollowupZoomMeet(true);
        }
      });
    }
  }

  } catch (error) {
   
  } finally {
    
  }
try {
  // const response = await axios.get(getBaseURL+'/api/doctor/followup/'+patientHistorySession.session_id); 
  const response = await axios.get(getBaseURL+'/api/doctor/followup/'+newSessionid+'/active_request'); 
  if (response.status === 200 || response.status === 201) {
    const { activeFlag, data } = response.data;
          
    // Set the check for all follow-up meetings
    setCheckAllFollowupMeet(activeFlag); // true if activeFlag is false, and vice versa
 
    // Update selected follow-up item
    setSelectedItemFollowup(data); 
    // setSelectedItemFollowup(response.data.data)
    if (response.data.data.zoom_meeting && Object.keys(response.data.data.zoom_meeting).length > 0) {
      // If zoom_meeting is not null and has keys, set it to true
      setFollowupZoomMeet(true);
    } 
    // setIllnessesList(response.data.illnesses);        
    setCheckFollowupMeet(activeFlag)
   
  }
  
  } catch (error) {
   
  } finally {
    
  }

try {
const response = await axios.get(getBaseURL+'/illnesses'); 
if (response.status === 200) {
  setIllnessesList(response.data.illnesses);         
}

} catch (error) {
 
} finally {
  
}
try { 
  // const url = `${getBaseURL}/download/${patientHistorySession.reports_id}`;
  const url = `${getBaseURL}/api/medical/reports/fetch/${newReportIDChk}`;
  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
    
  if (response.data && response.data.status === 'success') {
    setReportData(response.data.content);
  }
  } catch (error) {
    //console.error('Error posting data:', error);
 } finally {
    
  }

  try { 
    const url = `${getBaseURL}/api/patient/fetch/details/pcp-pharmacy/${newReportIDChk}`;
    // const url = `${getBaseURL}/download/${patientHistorySession.reports_id}`;
    const response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
      
    if (response.data && response.data.status === 'success') {  
      setPCP_Pharmacy(response.data.data);
    }
    } catch (error) {
      //console.error('Error posting data:', error);
   } finally {
      
    }
    setShowLoaderPage(false)
    
};



useEffect(() => {
const handlePopstate = () => {
  setActiveTab(0); // Reset the active tab to the default tab index
};

window.addEventListener('popstate', handlePopstate);

return () => {
  window.removeEventListener('popstate', handlePopstate);
};
}, []);

const handleTabClick = (index) => {
setActiveTab(index);
setShowMainPoint(false);
};

useEffect(() => {
setActiveTab(0); // Set the default active tab when the component mounts
}, []);

// Function to process the user data
const processUserData = () => { 
if (!RecordData) return []; // If no recordData, return empty array

return [RecordData].map(user => {
  // Remove empty objects from the conversation array
  const filteredConversation = user.conversation.filter(convo => Object.keys(convo).length !== 0); 
  
  // Check if the answer to the specific question is "yes"
  const emergencyQuestion = filteredConversation.find(convo => 
    convo.question === "Do you need emergency care? (YES/NO)" && convo.answer.toLowerCase() === "yes"
  );

  if (emergencyQuestion) {
    alert("Emergency care is needed!");
  }

  return {
    ...user,
    conversation: filteredConversation
  };
});
};
const [ImageHide, setImageHide] = useState(false); 
const [showMainPoint, setShowMainPoint] = useState(false);
const handleInputMainPoint = () => { 
    setShowMainPoint(false);   
    {!PlanOnCareData ? 
    (<>
      setShowMainPoint(false);
      </>
    ):(
      <>
      setShowMainPoint(true);
      </>
    )
  }
setShowMainPoint(true);
setPlanOnCareData(PlanOnCareData);
};
const handleCancelClickMPoint = () => {
setShowLoader(false);
setShowMainPoint(false);
};
const handleInputChangeMPoint= (e) => {
setPlanOnCareData(e.target.value);
};
const handleSaveClickMPoint = () => {
setShowLoader(true);
setTimeout(() => { 
setIsEditing(false); 
handleSaveMPoint();
}, 2000); 
};
const handleSaveMPoint = async (event) => {   
try {   
  const reqData = JSON.stringify({ session_id: SessionIDCheck, plan_of_care: PlanOnCareData }) ;

  const response = await axios.put(getBaseURL+'/update_plan_of_care', reqData, {
    headers: {
      'Content-Type': 'application/json', // Specify content type as application/json
    },
  });
  if( response.data.status == 'success'){
    
      setShowLoader(false); 
      setShowMainPoint(false); 
      setBackReload(true)
      
  } 
} catch (error) {
  console.error('Error updating summary:', error);
  // Handle error, show an error message, etc.
}
};

const [showMainPointCD, setShowMainPointCD] = useState(false);

//conditionaldiagnosis  
const handleInputMainPointCD = () => { 
  setShowMainPointCD(false);   
  {!ConditionalDiagnosis ? 
  (<>
    setShowMainPointCD(false);
    </>
  ):(
    <>
    setShowMainPointCD(true);
    </>
  )
}
setShowMainPointCD(true);
setConditionalDiagnosis(ConditionalDiagnosis);
};
const handleCancelClickMPointCD = () => {
setShowLoader(false);
setShowMainPointCD(false);
};
const handleInputChangeMPointCD = (e) => {
setConditionalDiagnosis(e.target.value);
};
const handleSaveClickMPointCD = () => {
setShowLoader(true);
setTimeout(() => { 
setIsEditingCD(false); 
handleSaveMPointCD();
}, 2000); 
};

const handleSaveMPointCD = async (event) => {   
// try {   
const data = { 
  admin_id: adminId,
  session_id : SessionIDCheck,   
  user_id: userid, 
  conditional_diagnosis: ConditionalDiagnosis , 
};  
try {
  const response = await fetch(getBaseURL+'/api/admin/update/conditionaldiagnosis', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });

  const result = await response.json();
  if (response.status === 200) {
    toast.success(result.message, {
      position: toast.POSITION.TOP_RIGHT,
    }); 
    setShowLoader(false); 
    setShowMainPointCD(false); 
    setBackReload(true)

  } else if (response.status === 400 || response.status === 409 ) {
    // Handle 400 or 500 errors with appropriate messages
    toast.error(result.message || 'Failed to save the item.', {
      position: toast.POSITION.TOP_RIGHT,
    });
    setShowLoader(false);
  } 
} catch (error) {
  console.error('Error:', error);
  toast.error('An unexpected error occurred.');
} finally {
  setLoading(false); // Stop loading
}


};

//  --------------------------------------------------

useEffect(() => {
  handleMyInsurance(UserId)
  const ageCal = moment().diff(userProfileData.dob, 'years'); 
  setAgeTxt(userProfileData.dob ? ageCal : '');   

  localStorage.setItem('UserId', JSON.stringify(UserId));
 
}, [UserId]);

const handleMyInsurance = async (UserIdNew) => {  
  fetch(getBaseURL+'/get_insurance_by_userid/'+UserIdNew, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })
  .then(response => response.json())
  .then(data => {
    if( data.status == 'success'){ 
      localStorage.setItem('myinsuranceinfo', JSON.stringify(data.data));       
        setMyinsuranceinfo(data.data)
       const currentDate = moment(); // Get today's date
        const expiry = moment(data.data.expiry_date); // Parse the expiry date using moment
        
        if (expiry.isBefore(currentDate, 'day')) {
          setCheckExpiry(true); // Expiry date is before today
        } else {
          setCheckExpiry(false); // Expiry date is today or in the future
        }
    } 
  })
  .catch((error) => {
    // console.log('Error:', error);
  });
  
}; 
const [documents, setDocuments] = useState([]); // Document list state
const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state
const [selectedDocument, setSelectedDocument] = useState(null); // Track the selected document

// Open modal and set selected document
const openModal = (doc) => { 
  setSelectedDocument(doc);
  setIsModalOpen(true);
};

// Close modal and reset selected document
const closeModal = () => {
  setIsModalOpen(false);
  setSelectedDocument(null);
};

// Add document dynamically
const addDocument = (name, url) => {
  const newDocument = { name, url };
  setDocuments([...documents, newDocument]); // Add to the document list
};
const handleButtonClickFollowZoomMeet = () => {
  const zoomSchedulerUrl = selectedItemFollowup.zoom_meeting.patient_url; // Zoom Scheduler page URL 
  window.open(zoomSchedulerUrl, "_blank", "noopener,noreferrer");
};

const toggleDivZoomMeet = () => {
  setCheckZoomMeet(false);    
  setOpenDiv(openDiv === 'ZoomMeet' ? null : 'ZoomMeet');
};
const handleButtonClickZoomMeet = () => {
  const zoomSchedulerUrl = selectedZoomMeet.patient_url; // Zoom Scheduler page URL 
  window.open(zoomSchedulerUrl, "_blank", "noopener,noreferrer");
};

const getToken = async () => {
  const response = await fetch(getBaseURL+'/new_token'); // Update this URL to your actual token endpoint
  const data = await response.json();
  if (data.error) { 
    return null;
  }
  return data.token;
};

const generateTranscript = async (targetTextArea) => {
  try {
    const token = await getToken();
    if (!token) return;
    setIsConnecting(true);
    socket.current = new WebSocket(`wss://api.assemblyai.com/v2/realtime/ws?sample_rate=16000&token=${token}`);

    const texts = {};
    socket.current.onmessage = (voicePrompt) => {
     // let msg = '';
      let msg = transcripts[targetTextArea] || ''; 
      const res = JSON.parse(voicePrompt.data);
      if (res.text && res.text.trim() !== '') {  // Check if res.text is not null or blank
        texts[res.audio_start] = res.text;
      }
      // texts[res.audio_start] = res.text;
      const keys = Object.keys(texts).filter(key => key !== 'undefined').sort((a, b) => a - b);
      keys.forEach((key) => {
        msg += ` ${texts[key]}`;
      });

      setTranscripts((prevState) => ({
        ...prevState,
        [targetTextArea]: msg.trim(), // || '',
      }));
    };

    socket.current.onerror = (event) => {
     // console.error('WebSocket error:', event);
      socket.current.close();
      socket.current = null;
      setIsConnecting(false);
    };

    socket.current.onclose = (event) => { 
      socket.current = null;
      setIsConnecting(false);
    };

    socket.current.onopen = () => {
      setIsConnecting(false);
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          recorder.current = new RecordRTC(stream, {
            type: 'audio',
            mimeType: 'audio/webm;codecs=pcm',
            recorderType: RecordRTC.StereoAudioRecorder,
            timeSlice: 250,
            desiredSampRate: 16000,
            numberOfAudioChannels: 1,
            bufferSize: 4096,
            audioBitsPerSecond: 128000,
            ondataavailable: (blob) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64data = reader.result;
                if (socket.current) {
                  socket.current.send(JSON.stringify({ audio_data: base64data.split('base64,')[1] }));
                }
              };
              reader.readAsDataURL(blob);
            },
          });
          recorder.current.startRecording();
        })
        .catch((err) => console.error('getUserMedia error:', err));
    };

    setIsRecording((prevState) => ({
      ...prevState,
      [targetTextArea]: true,
    }));

    setActiveMic(targetTextArea);
  } catch (error) {
    console.error('Error generating transcript:', error);
  }
};

const endTranscription = (targetTextArea) => {
  if (socket.current) {
    socket.current.send(JSON.stringify({ terminate_session: true }));
    socket.current.close();
    socket.current = null;
  }

  if (recorder.current) {
    recorder.current.stopRecording();
    recorder.current = null;
  }

  setIsRecording((prevState) => ({
    ...prevState,
    [targetTextArea]: false,
  }));

  setActiveMic(null);
};


const handleMicClick = (targetTextArea) => {
  if (isConnecting) { 
   // console.log('Connecting, please wait...');
    return;
  }
  if (activeMic === targetTextArea) {
    endTranscription(targetTextArea);
  } else {
    if (activeMic) {
      endTranscription(activeMic);
    }
    generateTranscript(targetTextArea);
  }
};

const handleTextChangeMedicalCondition = (e) => {   
  setTranscripts({ ...transcripts, medicalcondition: e.target.value });     
  const valueTxt =  e.target.value;
  setMedicalConditionsData(valueTxt);    
};
 
  const [statusMessage, setStatusMessage] = useState("");

  const handleFollowUpSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!activeOption || !transcripts.medicalcondition) {
      setStatusMessage("400 Bad Request: Missing required fields.");
      return;
    }

    const requestBody = {
      session_id:  SessionIDCheck,
      user_id: userid, // Pass the session ID 
      description: transcripts.medicalcondition, // Description from transcripts
      title: activeOption,   // Title from the active option
    };

    setLoading(true);
    setStatusMessage(""); // Reset status message

    try {
      const response = await axios.post(getBaseURL+"/api/patient/followup/request", requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 && response.data.status === "success") {
        setStatusMessage("200 OK: " + response.data.message); 
        setSelectedItemFollowup(response.data.data);
        // setSelectedItemFollowup(futureDate);
        setCheckFollowupMeet(true) 
        // // Reset the form after successful submission
        setOpenDiv(null);
        setErrors({});
        setLoading(false);
        setShowLoader(false);

      } else {
        setStatusMessage("Error: " + response.data.message);
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;

        if (status === 400) {
          setStatusMessage("400 Bad Request: " + data.message);
        } else if (status === 500) {
          setStatusMessage("500 Internal Server Error: " + data.message);
        } else {
          setStatusMessage("Unexpected Error: " + error.message);
        }
      } else {
        setStatusMessage("Network Error: Unable to connect to the server.");
      }
    } finally {
      setLoading(false);
    }
  };


  const calculateDuration = (startTs, endTs) => { 
    const durationInSeconds = endTs - startTs;
    const durationInMinutes = Math.floor(durationInSeconds / 60);
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
  
    const hourString = hours > 0 ? `${hours} hour` : "";
    const minuteString = minutes > 0 ? `${minutes} minute` : "";
  
    return [hourString, minuteString].filter(Boolean).join(" and ");
  };




  return (
    <div>
      <div className='container-fluid p-0'>
        <Header />
        <div className='container-fluid p-0 contentBody PatientDashboardMain w-100'>
        {ShowLoaderPage && <LoaderSpinner />} 
        <div className="sideBarInfoDiv"> 
                       
                        <div className="titleDivRight">
                          
                          
                            <ul className="userInfoDetails">
                              <li  className="imgBox">
                                 <b> 
                                  {fullName.split(' ').map(word => word.charAt(0).toUpperCase()).join('') }  
                                </b>  
                              </li>
                            <li>
                            <h2 className="capitalizeTxt">{fullName}</h2>
                              </li>
                            <li>
                              <span style={{display:"flex", justifyContent:'center',alignItems:'center'}}>
                                <img src={Icons[0].admin_mailIcon}  />
                                <label className="singleLineEllipsis">{email}</label>
                                </span>
                              </li>
                              
                              <li>
                                <img src={Icons[0].admin_phoneIcon} />
                                <label>{phoneNumber}</label>
                              </li>

                              <li className="dateNgenderTxt">
                              <span style={{display:"flex", justifyContent:'space-between'}} className="w-100">
                                <b className={GenderTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_userIcon} />
                                <label className="capitalizeTxt">{GenderTxt}</label>
                              </b>
                              <b className={DOBTxt ? '' : 'd-none'}>
                                <img src={Icons[0].admin_calendarIcon} />
                                <label>{moment(DOBTxt).format('MMM DD, YYYY')}{' '}({ageCal} years)</label>
                                </b>
                                </span>
                              </li>
                            </ul>
                            <div className="listOptDiv">
                            <ul className={"userMedicalInfo " + (CheckExpiry && ' dateExpiryTxt')}>
                              {CheckExpiry &&
                                <h3> <img src={Icons[0].errorImg} style={{width:'24px', marginRight:'5px'}} /> Health card has expired </h3>
                              }
                              <li> 
                                <b>{myinsuranceinfo.group_name}</b>
                                <label>Group name</label>
                              </li>
                              <li className="float-right"> 
                              <b>{myinsuranceinfo.policy_id}</b>                              
                                <label>Insurance ID</label>
                              </li> 
                              <li> 
                              <b>{myinsuranceinfo.insurance_carrier}</b>
                                <label>Insurance Carrier</label>
                              </li>
                            </ul>
                             <ul className="OptionDivInfo">
                              <li>
                              <button onClick={newappointmentLink} className={'w-100 ' +(CheckExpiry && ' dateExpiryBtn')}> 
                              <img src={Icons[0].addIcon}  />
                                  Click here to start
                                  </button>
                              </li>
                              {/* <li>
                                <b className="text-center">{UserHistoryNumber}</b>
                                <label>Appointments</label>
                              </li> */}
                            </ul>  
                            </div>
                        </div>  
        </div>
         
        <div className="appointmenttable ">
 
      <div className='container-fluid p-0 adminDashboardMain '>
           

              <div className="contentInnerBody">
               
                
                <div className="contentMainBox viewPatientMain"> 
                  

                   

                     <div className={"patientSummaryMain " +(!showSummay ? 'd-none':'')}>
                 
    <div className="innerDivRecords " >
 
		<nav>
			<div className="nav nav-tabs mb-0" id="nav-tab" role="tablist">
				<button className={`nav-link ${activeTab === 0 ? 'active' : ''}`}
            onClick={() => handleTabClick(0)} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Summary</button>
				{/* <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => handleTabClick(1)} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Plan of Care</button> */}
				<button className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleShowChat(2)} id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Pre-Diagnosis</button>
			  <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`}
            onClick={() => handleShowChat(3)} id="nav-reportData-tab" data-bs-toggle="tab" data-bs-target="#nav-reportData" type="button" role="tab" aria-controls="nav-reportData" aria-selected="false">Reports</button>
			</div>
      <button className="backBtn" onClick={handleBackButton}                        
                            ><img src={Icons[0].admin_backBtn} />Back</button>
		</nav>
		<div className="whiteBg tab-content p-3 bg-white" id="nav-tabContent" >
			<div className={`tab-pane fade ${activeTab === 0 ? 'active show' : ''}`} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
        <div className="despInfoDiv  position-relative  d-flex" style={{height: '70px'}}>
          <h2>Summary</h2>
          <div  className="headDespInfoDiv position-absolute top-0 end-0 ">
            {/* <ul className="infoDivPatientList" >
            <li>
              <label>Consultation Date</label>
              <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
            </li>
            <li>
              <label>Illnesses</label>
              <b>{RecordData.illness}</b>
            </li>
          </ul> */}
          <ul className="optionUpdateList" style={{width:'auto'}}>
        
        
      
        <li  className= {checkPhysicalExam === 'true' ? '' : 'd-none'}> 
             <label>Physical  Exam  </label>
              <b className={bpHgTxt +' '+ bpMMTxt +'  '+ heartrateTxt}>
                  {bpHgTxt && bpMMTxt ? (`${bpHgTxt} / ${bpMMTxt} - `) : null  }
                  {heartrateTxt ?`${heartrateTxt} BPM` : null } 
                  </b>
              </li>
              <li className={selectedItem ? '' : 'd-none'}>
                <label>Patient Status</label>
                    <button className={"selectedItemBtn color" +(selectedItem)} >
                        {selectedItem}
                    </button>
                </li>  
                {selectedZoomMeet?.meeting_status  !== 'deactive' && (
                <>
                <li className={"text-left "+(selectedZoomMeet && Object.keys(selectedZoomMeet).length > 0 ? '' :'d-none')}>
                <label>Patient Meeting</label>    
                {(!selectedZoomMeet.meeting_status || selectedZoomMeet.meeting_status === 'active') ? (
                            <button className="blueBtnStyle"
                            onClick={selectedItem?.toLowerCase() === "completed" ? null : toggleDivZoomMeet} 
                            disabled={selectedItem?.toLowerCase() === "completed"}
                            >
                              Meeting Details 
                            </button>
                          ) : (
                            <button className="blueBtnStyle" disabled>
                              Meeting Ended
                            </button>
                          )}
                          
               
                      {/* <button className="blueBtnStyle" onClick={toggleDivZoomMeet}>
                        Meeting Details 
                      </button> */}

          {openDiv === 'ZoomMeet' && (
             <div  className="patientStatusBox ZoomMeetMain"  style={{zIndex: '11'}}> 
              {selectedZoomMeet && Object.keys(selectedZoomMeet).length > 0 ? (
                    <>
                    <div className="p-2">
                     <h2 className="mb-2">Meeting Details</h2>
                     <div className="meetingDetailsTxt">
                         <label className="labelTxt">Meeting ID</label>
                         <b>{selectedZoomMeet.meeting_id}</b>                      
                      </div> 
                      <div className="meetingDetailsTxt">
                         <label className="labelTxt">Meeting Title</label>
                         <b>{selectedZoomMeet.topic}</b>                      
                      </div> 
                      
                      <div className="meetingDetailsTxt">
                         <label className="labelTxt">Date Time</label>
                         <b>{moment.unix(selectedZoomMeet.start_ts).format("MMM DD, YYYY - h:mm A")}</b>                      
                      </div>
                   
                      <div className="meetingDetailsTxt">                    
                        <button onClick={handleButtonClickZoomMeet}>Join Meeting</button>                     
                      </div> 
                      </div>
                      
                    </> ):('')}
                    </div>
                  )}
                   
                </li>
                </> )}
                {selectedZoomMeet?.meeting_status  === 'deactive' && (<>
                <li style={{width:"130px"}} 
         className={
          selectedZoomMeet.meeting_status === 'deactive' 
            ? '' : 'd-none' 
        }
        >
            <label className={followupZoomMeet}>Follow-up</label> 
  <button
  className={"selectedItemBtn " + (followupZoomMeet ? 'blueBtnStyle newBlueBtn' : 'newClickBtn')}
  onClick={toggleDivFollowup}
  style={{ width: followupZoomMeet ? "120px" : "110px", padding: "5px" }}
>
  {
    followupZoomMeet 
    ? "Meeting Active" 
    : checkFollowupMeet 
    ? "Request Sent" 
    : "Add Followup"}
</button>

      {openDiv === 'Followup' && (
 <div className="patientStatusBox datePickerDiv followupSetMain  ZoomMeetMain" style={{ border: '1px solid #ccc', marginTop: '10px', padding: '10px', zIndex: '11'}}>
         
         {selectedItemFollowup && Object.keys(selectedItemFollowup).length > 0 ? (<>
           
          <h2 style={{fontSize:'16px'}}  className="mb-2">Follow up Details </h2>
                  
                                     
                      {followupZoomMeet ? (
                            <>
                              <div className="meetingDetailsTxt">
                         <label className="labelTxt">Meeting ID</label>
                         <b>{selectedItemFollowup.zoom_meeting.meeting_id}</b>                      
                      </div> 
                      <div className="meetingDetailsTxt">
                         <label className="labelTxt">Meeting Title</label>
                         <b>{selectedItemFollowup.zoom_meeting.topic}</b>                      
                      </div> 
                      
                      <div className="meetingDetailsTxt">
                         <label className="labelTxt">Date Time</label>
                         <b>{moment.unix(selectedItemFollowup.zoom_meeting.start_ts).format("MMM DD, YYYY - h:mm A")}</b>                      
                      </div>
                      <div className="meetingDetailsTxt">  
                              {/* <label>Zoom Meeting is Active:</label> */}
                              <button onClick={handleButtonClickFollowZoomMeet}>Join Meeting</button>  
                              </div>  
                              
                            </>
                          ) : (
                            <>
                              <div className="meetingDetailsTxt">
                         <label className="labelTxt">Title</label>
                         <b>{selectedItemFollowup.title}</b>                      
                      </div>                          
                       
                         <div className="meetingDetailsTxt align-items-start"> 
                          <label className="labelTxt">Description: </label>
                          <b className="twoLineEllipsis" title={selectedItemFollowup.description} >{selectedItemFollowup.description}</b> 
                          </div>
                            <div className="meetingDetailsTxt">  
                              <button className="newBtn cancelBtn" disabled={true}  >Request in Progress</button>  
                              </div>                  
                            </>
                          )}  
                    
        
                      

         </>):(<>
          <form>
            
            <div>
              <h3 style={{fontSize:'16px'}}  className="mb-2">Follow-Up: Choose any one</h3>
              <ul className="followupOptList">          
              {options.map((option, index) => (
                  <li
                    key={index}
                    className={activeOption === option ? 'active' : ''} // Apply "active" class to the selected item
                    onClick={() => handleOptionClick(option)} // Handle click event
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
            <h3 style={{fontSize:'16px'}} className="mb-0">Follow-up Description</h3>
           <div className="mt3 inputDiv fillDetailsForm newFillDetailForm d-flex">
                <textarea  
                    className="textareaFollowup"  
                    value={transcripts.medicalcondition}
                    onChange={handleTextChangeMedicalCondition} 
                    placeholder={activeMic === 'medicalcondition' ? 'Mic On ' : ""} > 
                </textarea>
            <span className="attachmentSpan">
              <a onClick={() => handleMicClick('medicalcondition')}  
              disabled={isConnecting || activeMic === 'connecting'} 
              className={ (activeMic === 'medicalcondition' ? 'deactiveBtnClass' : 'activeBtnClass')}> 
                  <img src={Icons[0].micIcon}  className= "logoImg "   alt="" />
              </a> 
            </span> 
            </div> 

            <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', gap: '20px' }}>
              <button className="newBtn cancelBtn" onClick={handleCancelFollowup}>
                Cancel
              </button> 
              <button 
                     onClick={handleFollowUpSubmit}
                      className="newBtn"
                      type="submit"
                      disabled={loading}
                      style={{
                        cursor: loading ? 'not-allowed' : 'pointer',
                      }}
                    >
                      {loading ? 'Saving...' : 'Save'}
             </button>
            </div>
         
        </form>
         </>)
 
         }
       
        </div>
      
                  )}


                </li>
                </>)}
        </ul>
        </div>

        </div>
        <div className="innerDivBox"> 
        {FollowupData?.length > 0 ? (
          FollowupData.map((item, index) => 
            item.followup_summary ? ( // Check if followup_summary exists
              <div key={index}>
                <div className="despInfoDiv">
                <div className="headDespInfoDiv">
                  <ul className="infoDivPatientList">
                    <li>
                      <label>Consultation Date</label>
                      <b>{moment(item.created_on).format('MMM DD, YYYY')}</b>
                    </li>
                    <li>
                      <label>Follow up</label>
                      <b>{item.title}</b>
                    </li>
                    {
                      calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)
                        ? <li>
                          <label>Duration:</label>
                          <b>{calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)}</b>
                          </li>
                        : null
                    }
                    {/* <li>
                        <label>Duration:</label>{" "}
                        <b>{calculateDuration(item.zoom_meeting.start_ts, item.zoom_meeting.end_ts)}</b>
                      </li> */}
                      <li>
                         <label>Location</label>
                          <b>Home</b>
                      </li>
                  </ul>
                </div>
              </div>
              <p>{item.followup_summary}</p>
              <hr className="my-4"></hr>
              </div>
            ) : null // If followup_summary is null, render nothing
          )
        ) : null}
				   <div className="despInfoDiv">
      <div className="headDespInfoDiv">
            <ul className="infoDivPatientList">
              <li>
                <label>Consultation Date</label>
                <b> {moment(RecordData.created_on).format('MMM DD, YYYY')}</b>
              </li>
              <li>
                <label>Illnesses</label>
                <b>{RecordData.illness}</b>
              </li>
              {selectedZoomMeet.length > 0 ?
                      (<>
                        <li>
                        <label>Duration:</label>{" "}
                        <b>{calculateDuration(selectedZoomMeet.start_ts, selectedZoomMeet.end_ts)}  </b>
                        </li>
                        <li>
                            <label>Location</label>
                            <b>Home</b>
                        </li>
                      </>
                      ): null } 
                
            </ul>
            </div>
          </div>
          {EmergencyQuest ? (<><p className="innerDivBox urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p></>) :  summaryAIChatbot ? <p className="">{summaryAIChatbot} </p> :  <p className="innerDivBox"> - </p> }
          </div>
          
			</div>
			<div className={`tab-pane fade ${activeTab === 1 ? 'active show' : ''}`} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div className="d-flex gap-4">
               <div className="despInfoDiv PlanCareTab w-50">
          <h2>Plan of Care  
          {!EmergencyQuest ? ( <a onClick={handleInputMainPoint} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}> Edit <img src={!showMainPoint ? Icons[0].editIcon : Icons[0].editRed } /></a>) :  ('')}
          </h2>
        
         
          {!showMainPoint ? (
                  <>     
                   {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : PlanOnCareData ? <pre className="innerDivBoxPC">{PlanOnCareData}</pre> : 'No Plan On Care' }  
                   {!EmergencyQuest && (
                        <>
                          {PCP_Pharmacy && PCP_Pharmacy.pcp && PCP_Pharmacy.pcp.name && PCP_Pharmacy.pcp.address ? (
                            <>
                              <b>Primary Care Physician (PCP): </b>
                              {PCP_Pharmacy.pcp.name + ', ' + PCP_Pharmacy.pcp.address}
                              <br />
                            </>
                          ) : null}

                          {PCP_Pharmacy && PCP_Pharmacy.pharmacy && PCP_Pharmacy.pharmacy.name && PCP_Pharmacy.pharmacy.address ? (
                            <>
                              <b>Pharmacy information: </b>
                              {PCP_Pharmacy.pharmacy.name + ', ' + PCP_Pharmacy.pharmacy.address}
                            </>
                          ) : null}
                        </>
                      )} 
                 </>
              ) :(
                <div className='textareaSummary innerDivBox w-100'>
                  <textarea value={PlanOnCareData} onChange={handleInputChangeMPoint} style={{    height: '80%', width:' 100%'}} /> 
                  <hr></hr>
                 <div className='editSaveOpt' >
                    {ShowLoader ? (<>
                      <button  > Loading
                      <div className="spinner-border text-light" role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSaveClickMPoint}>  
                    Save Changes
                    </button>
                    </>)

                    }
                    
                    <button onClick={handleCancelClickMPoint}>Cancel</button>
                  </div>
                </div> 

              )}
               </div>
                <div className="despInfoDiv PlanCareTab w-50  ">
          <h2>Conditional Diagnosis
          {!EmergencyQuest ? ( <a onClick={handleInputMainPointCD} style={{marginLeft:'10px', cursor:'pointer'}}  className={ImageHide ? "editBtnNew " : "editBtnNew "}> Edit <img src={!showMainPointCD ? Icons[0].editIcon : Icons[0].editRed } /></a>) :  ('')}
          </h2>
          {!showMainPointCD ? (
                  <>     
                   {EmergencyQuest ? <p className="innerDivBoxPC urgentTxt"><img src={Icons[0].admin_icon911} /> Urgent: Patient has called for emergency assistance.</p> : ConditionalDiagnosis ? <pre className="innerDivBoxPC">{ConditionalDiagnosis}</pre> : 'No Conditional Diagnosis' }  
                 </>
              ) :(
                <div className='textareaSummary innerDivBox w-100'>
                  <textarea value={ConditionalDiagnosis} onChange={handleInputChangeMPointCD} style={{    height: '80%', width:' 100%'}} /> 
                  <hr></hr>
                 <div className='editSaveOpt' >
                    {ShowLoader ? (<>
                      <button  > Loading
                      <div className="spinner-border text-light" role="status">
                       <span className="visually-hidden">Loading...</span>
                    </div> 
                    </button>
                    </>):(<>
                      <button onClick={handleSaveClickMPointCD}>  
                    Save Changes
                    </button>
                    </>)

                    }
                    
                    <button onClick={handleCancelClickMPointCD}>Cancel</button>
                  </div>
                </div> 

              )}
               </div> 

      </div>
      </div>
			<div className={`tab-pane fade ${activeTab === 2 ? 'active show' : ''}`} id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div className="despInfoDiv">
          <h2>Pre-Diagnosis</h2>
          </div>
        <div className="chatbotConversation  pb-0">
      <div className="messageListBox mb-0">
      {answerLoader ? ( 
                    <>
                    <div className= "quesListDiv " >
                        <div className={"chatbot aiQuest "}>
                            <span className="imgBox">
                              <img src={Icons[0].chatbot} alt="Chatbot" />
                            </span>
                            <span> 
                                <div className="showLoaderDot">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                </div> 
                            </span>
                          </div>
                    </div>
                    </>):(<>
                    
                      <div className="chatbot">
                <span className="imgBox">
                  <img src={Icons[0].chatbot} alt="Chatbot" />
                </span>
                <span>
                  <b className="AI_dateTime">
                    AI 
                  </b> 
                      <label className="quesTxt">Hi, How may I help you today? You may select or type a symptom you’re facing currently.</label>
                      <div className="questOpt">
                        <b>Please select any</b>
                        <ul className={!selectedIllness ? 'illnessesListOpt' : 'illnessesListOpt selected'}>
                          {illnessesList?.map((item, index) => (
                            <li
                              key={index}
                              className={selectedIllness === item ? 'active' : 'disabled'} 
                              style={{ pointerEvents: selectedIllness && selectedIllness !== item ? 'none' : 'auto' }}
                            >
                              <b>{item}</b>
                            </li>
                          ))}
                        </ul>
                      </div>
                     
                </span>
              </div>

           {ChatHistory.length > 0 && (
                <div className="quesListDiv">
                  {ChatHistory.map((q, index) => (
                    <div key={index}>
                      <div className="chatbot aiQuest">
                        <span className="imgBox">
                          <img src={Icons[0].chatbot} alt="Chatbot" />
                        </span>
                        <span> 
                              <b className="AI_dateTime">
                                AI 
                              </b>
                              <label className="quesTxt">{q.question}</label>
                             
                        </span>
                      </div>
                      {q.answer && (
                        <div className="chatbot userReply">
                          <span className="imgBox">
                            <b>{fullName?.split(' ').map(word => word.charAt(0).toUpperCase()).join('')}</b>
                          
                          </span>
                          <span>
                            <b className="AI_dateTime">
                              YOU 
                            </b>
                            <label className="quesTxt">{q.answer}</label>
                          </span>
                        </div>
                      )} 
                    </div>
                  ))}
                </div>
              )} 
                    
                    </>)}
              

            </div> 
     </div>
		 
			</div>
      <div className={`tab-pane fade ${activeTab === 3 ? 'active show' : ''}`} id="nav-reportData" role="tabpanel" aria-labelledby="nav-reportData-tab">
      <div className="despInfoDiv">
          <h2>Reports </h2>
          </div>
          <div className="innerDivBoxPC"> 
           
        
<ol className="reportListOptLink">
  {sortedGroupedDocuments.length > 0 ? (
    sortedGroupedDocuments
      .filter(({ documents }) => documents && documents.length > 0) // Filter out empty or null documents
      .map(({ doc_type, documents, Name  }) => (
        <li key={doc_type}>
       
          {documents.map((doc, index) => (
            <div key={doc.doc_id} style={{ marginBottom: "0px" }}>
                 <b style={{ textTransform: "capitalize", textDecoration: "none" }} className={ doc_type === "other_report"  && index !== 0 && 'd-none'}>
                 {Name} 
                 {/* {doc_type.replace('_', ' ')} */}
                 </b>
              {/* Document URL with conditional button */}
              {doc.doc_url && (
                <span
                  className={
                    "pdfButtonModal" + (doc_type === "other_report" ? " d-none" : "")
                  }
                >
                  <button
                    className="pdfButton pdfViewBtn"
                    onClick={() => openModal(doc?.doc_url)}
                  >
                    View{" "}
                    <img
                      src={Icons[0].viewPDF}
                      alt="PDF Icon"
                      style={{ width: "24px", marginLeft: "10px" }}
                    />
                  </button>
                </span>
              )}

              {/* Document Text */}
              <p>{doc.doc_text}</p>

              <div className={"d-flex justify-content-between align-items-center "  +(doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null) }>
                <h3
                  style={{
                    fontSize: "16px",
                    marginTop: "14px",
                    fontWeight: "600",
                  }}
                  className="my-0"
                >
                 {
                    doc_type === "other_report" ? (
                      <>
                        {index + 1}. Other Report Summary
                      </>
                    ) : doc_type === "pcp" || doc_type === "pharmacy" ? (
                        null
                    ) : doc.doc_summary ? (
                      <>Report Summary</>
                    ) : null
                  }
                </h3>

                {/* Document URL Button (specific condition) */}
                {doc.doc_url && (
                  <span
                    className={
                      "pdfButtonModal" +
                      (doc_type !== "other_report" ? " d-none" : "")
                    }
                  >
                    <button
                      className="pdfButton pdfViewBtn"
                      onClick={() => openModal(doc?.doc_url)}
                    >
                      View{" "}
                      <img
                        src={Icons[0].viewPDF}
                        alt="PDF Icon"
                        style={{ width: "24px", marginLeft: "10px" }}
                      />
                    </button>
                  </span>
                )}
              </div>

              {/* Document Summary */}
              {
                doc.doc_summary ? (
                  <pre
                    style={{ margin: "5px 0", textTransform: "capitalize" }}
                    className={doc.doc_summary.length === 4 && doc.doc_summary === "None" ? 'd-none' : null}
                  >
                    {doc.doc_summary}
                  </pre>
                ) : null
              }

              {/* Conditional Divider */}
              {doc.doc_url && (
                <hr className={doc_type !== "other_report" ? " d-none" : ""} />
              )}
            </div>
          ))}
        </li>
      ))
  ) : (
    <p style={{ textAlign: "center" }}>No reports found</p>
  )}
</ol>

          </div>
          
          </div>
		</div>
	</div>
                    </div> 
                </div>
                
              </div>
                 
             
      
      </div>
    
        </div>
         
         
        </div> 
      </div>


       {/* Modal Popup for Viewing Documents */}
       <Modal
       className='viewDocModal'
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Document Viewer"
       
      >
        <button
          onClick={closeModal}
          style={{
            float: 'right',
            cursor: 'pointer',
            background: 'red',
            color: 'white',
            border: 'none',
            padding: '4px 10px',
            borderRadius: '0px',
          }}
        >
          Close
        </button>
      
        {/* Ensure selectedDocument exists before rendering */}
        {selectedDocument ? (
          <>
            <h3>{selectedDocument.name}</h3>
            <embed src={selectedDocument} width="100%" height="100%" type="application/pdf"  className="innerContainer" />
            {/* Render PDF or Image based on file type */} 
          </>
        ) : (
          <p>Loading document...</p> // Fallback content if document is not available
        )}
      </Modal>

    </div>
  );
}

export default PatientHistory;