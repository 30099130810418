import React from 'react';

/**
 * Function to format a decimal height value into feet and inches
 * @param {number} heightDecimal - Height in decimal form (e.g., 8.6)
 * @returns {string} - Formatted height (e.g., "8'6\"" or "8'")
 */
export const formatHeight = (heightDecimal) => {
  if (heightDecimal == null) return '';
  
  const feet = Math.floor(heightDecimal); // Get the integer part (feet)
  const inches = Math.round((heightDecimal - feet) * 12); // Convert decimal to inches

  return inches === 0 ? `${feet}'` : `${feet}'${inches}"`;
};

/**
 * Component to display formatted height
 * @param {object} props - Component props
 * @param {number} props.heightDecimal - Height in decimal form
 * @returns {JSX.Element} - Formatted height as a React element
 */
const HeightFormatter = ({ heightDecimal }) => {
  const formattedHeight = formatHeight(heightDecimal);

  return <span>{formattedHeight}</span>;
};

export default HeightFormatter;